export const ISalaryPeriodicity = Object.freeze({
  code: "id",
  label: "label",
});

export class SalaryPeriodicityModel {
  constructor(data = {}) {
    this[ISalaryPeriodicity.code] = data[ISalaryPeriodicity.code];
    this[ISalaryPeriodicity.label] = data[ISalaryPeriodicity.label];
  }

  getCode() {
    return this[ISalaryPeriodicity.code];
  }

  getLabel() {
    return this[ISalaryPeriodicity.label];
  }
}
