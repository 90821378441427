import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import localeData from "dayjs/plugin/localeData";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import localizedFormat from "dayjs/plugin/localizedFormat";
import duration from "dayjs/plugin/duration";
import customParseFormat from "dayjs/plugin/customParseFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import humanizeDuration from "humanize-duration";
import { API_DATE_FORMAT_FULL, API_DATE_FORMAT_SHORT, DISPLAY_DATE_FORMAT_SHORT } from "@core/constants";

// specify the locale language
import("dayjs/locale/fr").then(() => {
  dayjs.locale("fr");
});
dayjs.extend(advancedFormat);
dayjs.extend(customParseFormat);
dayjs.extend(duration);
dayjs.extend(localizedFormat);
dayjs.extend(localeData);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(relativeTime);

const HOURS_SYMBOL = "h";
const MINUTES_SYMBOL = "";

export class DatesHelpers {
  static get DAY_DURATION_MINUTES() {
    return 1440;
  }

  /**
   * Returns an array of weekdays
   * @static
   * @returns {Array} - [{ id: 1, label: "Lundi"}, { id: 2, label: "Mardi"}...]
   */
  static get weekdays() {
    const daysArray = [];
    const weekDays = [...dayjs.weekdays()];
    // dayjs returns sunday as first day so we move it to last
    weekDays.push(weekDays.shift());
    weekDays.forEach((day, index) => {
      daysArray.push({
        id: index + 1,
        label: day,
      });
    });
    return daysArray;
  }

  /**
   * Return new dayjs(date) if date exists and is valid, undefined otherwise
   * @param {String, Date} date
   * @returns {dayjs}
   */
  static getDateMomentIfExist(date, format) {
    if (date) {
      const dateMoment = dayjs(date, format);
      return dateMoment.isValid() ? dateMoment : undefined;
    }
    return undefined;
  }

  static getDayjs(date, format, isStrict = false) {
    return dayjs(date, format, isStrict);
  }

  static getFormattedDate(date, format = API_DATE_FORMAT_SHORT) {
    if (!date) {
      return undefined;
    }
    const dateDayjs = DatesHelpers.getDayjs(date, format);
    return dateDayjs.format(DISPLAY_DATE_FORMAT_SHORT);
  }

  /**
   * Return the value as a padded string
   *
   * @static
   * @param {*} value
   * @returns - ex: 02
   */
  static formatTime(value) {
    return String(value).padStart(2, 0);
  }

  /**
   * Transform the provided amount of minutes to a string "hh{symbol}mm{symbol}"
   * @static
   * @param {*} mins - amount of minutes to transform
   * @param {*} [hoursSymbol=HOURS_SYMBOL]
   * @param {*} [minutesSymbol=MINUTES_SYMBOL]
   * @returns {String} - ex: 03h15
   */
  static formatMinsToHrsMins(mins, hoursSymbol = HOURS_SYMBOL, minutesSymbol = MINUTES_SYMBOL) {
    const minutesDuration = parseInt(mins, 10);
    const h = Math.floor(minutesDuration / 60);
    const m = minutesDuration % 60;
    return `${DatesHelpers.formatTime(h)}${hoursSymbol}${DatesHelpers.formatTime(m)}${minutesSymbol}`;
  }

  /**
   * Transform the provided amount of minutes to an object  { hours, minutes }
   * @static
   * @param {Number} mins - amount of minutes to transform
   * @returns {Object} -  { hours, minutes }
   */
  static convertMinsToHrsMins(mins) {
    const minutesDuration = parseInt(mins, 10);
    const hours = Math.floor(minutesDuration / 60);
    const minutes = minutesDuration % 60;
    return { hours, minutes };
  }

  /**
   * Transform the provided amount of seconds to a string HH:MM:SS
   * @static
   * @param {Number|String} secs - amount of seconds to transform
   * @returns {String} - HH:MM:SS
   */
  static convertSecondsToHHMMSS(secs) {
    const secondsDuration = parseInt(secs, 10);
    const hours = Math.floor(secondsDuration / 3600);
    const minutes = Math.floor((secondsDuration - hours * 3600) / 60);
    const seconds = secondsDuration - hours * 3600 - minutes * 60;
    return `${DatesHelpers.formatTime(hours)}:${DatesHelpers.formatTime(minutes)}:${DatesHelpers.formatTime(seconds)}`;
  }

  /**
   * Returns a duration in ms between two specified dates
   * @static
   * @param {Object} start - dayjs object
   * @param {Object} end - dayjs object
   * @returns {Number} - duration in ms
   */
  static getDaysjsDuration(start, end) {
    return dayjs.duration(start.diff(end));
  }

  /**
   * Return the difference in measurement (default days) between two dates.
   * if inclusive, it will return the difference + 1
   * @static
   * @param {*} { start, end, inclusive = true, measurement = "days" }
   * @returns {Number} - number of days between two dates
   */
  static numberOfDaysBetweenDates({ start, end, inclusive = true, measurement = "day" }) {
    return Math.abs(dayjs(end).diff(start, measurement)) + (inclusive ? 1 : 0);
  }

  /**
   *
   * @param {*} startDate
   * @param {*} endDate
   * @returns {Array}
   */
  static getDatesRangeArray(startDate, endDate) {
    const dates = [];
    let currentDate = dayjs(startDate);
    const endDateObj = dayjs(endDate);

    while (currentDate.isBefore(endDateObj) || currentDate.isSame(endDateObj, "day")) {
      dates.push(currentDate.format("YYYY-MM-DD"));
      currentDate = currentDate.add(1, "day");
    }

    return dates;
  }

  /**
   * Returns a formatted date
   * @static
   * @param {Date} date
   * @returns {String} - formatted date
   */
  static formatToFullDate(date, format) {
    return dayjs(date).format(format || API_DATE_FORMAT_FULL);
  }

  /**
   * Returns a formatted date
   * @static
   * @param {Date} date
   * @returns {String} - formatted date
   */
  static formatForAPI(date, { inputFormat = "DD/MM/YYYY", short = true } = {}) {
    return dayjs(date, inputFormat).format(short ? API_DATE_FORMAT_SHORT : API_DATE_FORMAT_FULL);
  }

  /**
   * Returns a date with hours and minutes set from the time parameter
   * @static
   * @param {Date} date
   * @param {Number} time - in minutes
   * @returns {Date} - full Date
   */
  static getFullDateHourMinutes(date, time) {
    const { hours, minutes } = DatesHelpers.convertMinsToHrsMins(time);
    return new Date(date).setHours(hours, minutes, 0, 0);
  }

  static dateIsOneDay(date) {
    return date instanceof Date || (date && date.start && dayjs(date.start).isSame(dayjs(date.end)));
  }

  static dateIsToday(date) {
    return date ? dayjs(date).isSame(dayjs(), "day") : false;
  }

  /**
   * @static
   * @param {Number} index
   * @returns {String} - the localized day ("lundi", "mardi")
   */
  static getLocalizedDayFromIndex(index) {
    const normalizedIndex = index % 7; // dayjs's week starts at 0, sunday
    return dayjs.weekdays()[normalizedIndex];
  }

  static getNextWeekFromDate(date) {
    const weekStart = DatesHelpers.getDayjs(date).add(1, "day").toDate();
    const weekEnd = DatesHelpers.getDayjs(date).add(1, "week").toDate();
    return { start: weekStart, end: weekEnd };
  }

  static getPreviousWeekFromDate(date) {
    const weekStart = DatesHelpers.getDayjs(date).add(-1, "week").toDate();
    const weekEnd = DatesHelpers.getDayjs(date).add(-1, "day").toDate();

    return { start: weekStart, end: weekEnd };
  }

  static humanizeDuration(milliseconds, { largest = 2, units = ["y", "mo", "w", "d"], round = true } = {}) {
    return humanizeDuration(milliseconds, { language: "fr", largest, units, round });
  }

  /**
   * @static
   * @param {Number} ttl
   * @param {Number} start
   * @param {Number} end
   * @returns {Boolean}
   */
  static isTimeLimitExpired(ttl, start = Date.now(), end = Date.now()) {
    return Math.abs(end - start) > ttl;
  }

  static isSameOrBefore(date = dayjs(), referenceDate = dayjs()) {
    return date.isSameOrBefore(referenceDate);
  }

  static isSameOrAfter(date = dayjs(), referenceDate = dayjs()) {
    return date.isSameOrAfter(referenceDate);
  }
}
