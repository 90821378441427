import { BO_DATE_FORMAT } from "@core/constants";
import { DatesHelpers } from "@core/helpers";
import { ITimesheetShift, TimesheetShiftModel } from "./timesheet-shift.model";

export const ITimesheetSchedule = Object.freeze({
  date: "date",
  start: "start",
  end: "end",
  breakDuration: "breakDuration",
});

export class TimesheetScheduleModel {
  constructor(data = {}) {
    this[ITimesheetSchedule.date] = data[ITimesheetSchedule.date];
    this[ITimesheetSchedule.start] = data[ITimesheetSchedule.start];
    this[ITimesheetSchedule.end] = data[ITimesheetSchedule.end];
    this[ITimesheetSchedule.breakDuration] = data[ITimesheetSchedule.breakDuration];
  }

  getDate() {
    return DatesHelpers.getDayjs(this[ITimesheetSchedule.date]);
  }

  getStartTime() {
    return this[ITimesheetSchedule.start];
  }

  setStartTime(value) {
    this[ITimesheetSchedule.start] = value;
  }

  getEndTime() {
    return this[ITimesheetSchedule.end];
  }

  setEndTime(value) {
    this[ITimesheetSchedule.end] = value;
  }

  getBreakDuration() {
    return this[ITimesheetSchedule.breakDuration];
  }

  setBreakDuration(value) {
    this[ITimesheetSchedule.breakDuration] = value;
  }

  isEndBeforeStart() {
    return this.getEndTime() < this.getStartTime();
  }

  generateTimesheetShift() {
    const { hours, minutes } = DatesHelpers.convertMinsToHrsMins(this.getStartTime());
    const date = this.getDate().hour(hours).minute(minutes).format(BO_DATE_FORMAT);
    const duration = (this.getEndTime() - this.getStartTime()) / 60;
    const breakDuration = this.getBreakDuration() / 60;

    return new TimesheetShiftModel({
      [ITimesheetShift.date]: date,
      [ITimesheetShift.duration]: duration,
      [ITimesheetShift.breakDuration]: breakDuration,
    });
  }
}
