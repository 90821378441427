import { DatesHelpers } from "@core/helpers";

export const ICandidateOfferTimeline = Object.freeze({
  date: "date",
  status: "status",
  label: "label",
});

export class OfferTimelineOptionModel {
  constructor(data = {}) {
    this[ICandidateOfferTimeline.date] = data[ICandidateOfferTimeline.date];
    this[ICandidateOfferTimeline.status] = data[ICandidateOfferTimeline.status];
    this[ICandidateOfferTimeline.label] = data[ICandidateOfferTimeline.label];
  }

  getDate() {
    return this[ICandidateOfferTimeline.date];
  }

  getDisplayDate() {
    const date = this.getDate() ? DatesHelpers.getDayjs(this.getDate()) : null;
    return date ? date.format("dddd DD MMM") : "";
  }

  getStatus() {
    return this[ICandidateOfferTimeline.status];
  }

  getLabel() {
    return this[ICandidateOfferTimeline.label];
  }
}
