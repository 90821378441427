export const IMarketingCampaign = Object.freeze({
  actionBtnLabel: "action_label",
  cancelBtnLabel: "cancel_label",
  code: "code_campagne_i_o",
  createdAt: "created_at",
  etabId: "etablissement_id",
  candidateId: "candidate_id",
  campaignId: "id",
  message: "message",
  param: "param",
  route: "route",
  seen: "vu",
  sendDate: "date_envoi",
  title: "title",
  type: "type",
});

export class MarketingCampaignModel {
  constructor(data = {}) {
    this[IMarketingCampaign.actionBtnLabel] = data[IMarketingCampaign.actionBtnLabel];
    this[IMarketingCampaign.cancelBtnLabel] = data[IMarketingCampaign.cancelBtnLabel];
    this[IMarketingCampaign.code] = data[IMarketingCampaign.code];
    this[IMarketingCampaign.createdAt] = data[IMarketingCampaign.createdAt];
    this[IMarketingCampaign.etabId] = data[IMarketingCampaign.etabId];
    this[IMarketingCampaign.campaignId] = data[IMarketingCampaign.campaignId];
    this[IMarketingCampaign.message] = data[IMarketingCampaign.message];
    this[IMarketingCampaign.param] = data[IMarketingCampaign.param];
    this[IMarketingCampaign.route] = data[IMarketingCampaign.route];
    this[IMarketingCampaign.seen] = data[IMarketingCampaign.seen];
    this[IMarketingCampaign.sendDate] = data[IMarketingCampaign.sendDate];
    this[IMarketingCampaign.title] = data[IMarketingCampaign.title];
    this[IMarketingCampaign.type] = data[IMarketingCampaign.type];
  }

  getBody() {
    return this[IMarketingCampaign.message] || "";
  }

  getCampaignId() {
    return this[IMarketingCampaign.campaignId];
  }

  getType() {
    return this[IMarketingCampaign.type];
  }

  getTitle() {
    return this[IMarketingCampaign.title];
  }

  getActionBtnLabel() {
    return this[IMarketingCampaign.actionBtnLabel];
  }

  getCancelBtnLabel() {
    return this[IMarketingCampaign.cancelBtnLabel];
  }

  getParam() {
    return this[IMarketingCampaign.param];
  }

  getRoute() {
    return this[IMarketingCampaign.route];
  }
}
