export const IExperienceWarning = Object.freeze({
  id: "id",
  username: "username",
  type: "type",
});

export class ExperienceWarningModel {
  constructor(data = {}) {
    this[IExperienceWarning.id] = data[IExperienceWarning.id];
    this[IExperienceWarning.type] = data[IExperienceWarning.type];
    this[IExperienceWarning.username] = data[IExperienceWarning.username];
  }

  getId() {
    return this[IExperienceWarning.id];
  }

  getType() {
    return this[IExperienceWarning.type];
  }

  getUsername() {
    return this[IExperienceWarning.username];
  }
}
