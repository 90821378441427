export const IReportCandidateReason = Object.freeze({
  id: "id",
  label: "label",
  requiredComment: "required_comment",
});

export class ReportCandidateReasonModel {
  constructor(data = {}) {
    this[IReportCandidateReason.id] = data[IReportCandidateReason.id];
    this[IReportCandidateReason.label] = data[IReportCandidateReason.label];
    this[IReportCandidateReason.requiredComment] = data[IReportCandidateReason.requiredComment];
  }

  getId() {
    return this[IReportCandidateReason.id];
  }

  getLabel() {
    return this[IReportCandidateReason.label];
  }

  getRequiredComment() {
    return this[IReportCandidateReason.requiredComment];
  }
}
