import { DatesHelpers } from "@core/helpers";
import { API_DATE_FORMAT_SHORT } from "@core/constants";
import { ICreationStepPlanningDay, CreationStepPlanningDayModel } from "./creation-step-planning-day.model";

export const ICreationStepPlanning = Object.freeze({
  days: "days",
  dates: "dates",
  hours: "hours",
  fluctuating: "fluctuating",
});

export class CreationStepPlanningModel {
  constructor(data = {}) {
    this[ICreationStepPlanning.days] = data[ICreationStepPlanning.days] || [];
    this[ICreationStepPlanning.dates] = data[ICreationStepPlanning.dates];
    this[ICreationStepPlanning.hours] = data[ICreationStepPlanning.hours];
    this[ICreationStepPlanning.fluctuating] = data[ICreationStepPlanning.fluctuating];
  }

  getDays() {
    return this[ICreationStepPlanning.days];
  }

  getDates() {
    return this[ICreationStepPlanning.dates];
  }

  getDaysLength() {
    const start = this.getDates().start;
    const end = this.getDates().end;
    return DatesHelpers.numberOfDaysBetweenDates({ start, end });
  }

  setPlanningDays() {
    const days = this.getDays().map((day) => new CreationStepPlanningDayModel(day));
    if (!this.getDates()) {
      // For AE demands api return only an array of shifts
      // We have to guess start and end dates
      // We also need to fill the gaps with missing dates in days array
      const isRange = days.length > 1;
      const start = days[0].date;
      let end = null;
      if (isRange) {
        end = days[days.length - 1].getDate();
        // here, add missing days
        const datesRange = DatesHelpers.getDatesRangeArray(start, end);
        const newDatesArray = datesRange.map((date, index) => {
          const foundDay = days.find((day) => day.getDate() === date);
          if (foundDay) {
            foundDay[ICreationStepPlanningDay.id] = index + 1;
            foundDay[ICreationStepPlanningDay.selected] = true;
          }
          return (
            foundDay ??
            new CreationStepPlanningDayModel({
              [ICreationStepPlanningDay.selected]: false,
              [ICreationStepPlanningDay.date]: date,
              [ICreationStepPlanningDay.id]: index + 1,
            })
          );
        });
        this[ICreationStepPlanning.days] = newDatesArray;
      } else {
        // A single shift, we just need to setup the id
        days[0].setId(1);
        days[0][ICreationStepPlanningDay.selected] = true;
        this[ICreationStepPlanning.days] = days;
      }
      this[ICreationStepPlanning.dates] = { start, end };
    } else {
      // For permanent demands we need to update days objects by adding selected and id values
      const planningDaysModel = days.map((day, index) => {
        if (day[ICreationStepPlanningDay.selected] === undefined) {
          day[ICreationStepPlanningDay.selected] = true;
        }
        day.setId(index + 1);
        return day;
      });
      this[ICreationStepPlanning.days] = planningDaysModel;
    }
  }

  getPlanningTotalHours() {
    const days = this.getDays().map((day) => new CreationStepPlanningDayModel(day));
    let duration = 0;
    days.forEach((day) => {
      duration += day.getWorkingDuration();
    });
    return duration;
  }

  isSpecified() {
    return this.getDays().length > 0;
  }

  selectedDays() {
    return this[ICreationStepPlanning.days].filter((d) => d.isSelected()) || [];
  }

  selectedDaysCount() {
    return this.selectedDays().length;
  }

  initFromWeek() {
    this[ICreationStepPlanning.days].length = 0; // reset the array
    DatesHelpers.weekdays.forEach((weekday) => {
      const day = {
        [ICreationStepPlanningDay.id]: weekday.id,
        [ICreationStepPlanningDay.selected]: true,
      };
      this[ICreationStepPlanning.days].push(new CreationStepPlanningDayModel(day));
    });
  }

  initFromDate({ start, end, defaultSchedule = {} }) {
    this[ICreationStepPlanning.days].length = 0; // reset the array
    const number = end ? DatesHelpers.numberOfDaysBetweenDates({ start, end }) : 1;
    for (let i = 0; i < number; i++) {
      const day = {
        ...defaultSchedule, // set default schedule if any
        [ICreationStepPlanningDay.id]: i,
        [ICreationStepPlanningDay.selected]: true, // selected by default
        [ICreationStepPlanningDay.date]: DatesHelpers.getDayjs(start)
          .startOf("days") // we want to start from 00:00
          .add(i, "days"),
      };
      this[ICreationStepPlanning.days].push(new CreationStepPlanningDayModel(day));
    }
  }

  toggleDay(dayId) {
    const day = this.getDay(dayId);
    if (day.isSelected()) {
      day.unselect();
    } else {
      day.select();
    }
  }

  isDaySelected(dayId) {
    const day = this.getDay(dayId);
    return day.isSelected();
  }

  scheduleDay({ dayId, property, value }) {
    this.getDay(dayId)[property] = value;
  }

  getDay(dayId) {
    return this.getDays().find((d) => d.getId() === dayId) || new CreationStepPlanningDayModel();
  }

  noDaySelected() {
    return this.getDays().every((d) => d.selected === false);
  }

  isValid() {
    return this.getDays().every((d) => d.isValid()) && !this.noDaySelected();
  }

  getTotalDurationInMinutes() {
    const allDays = this.getDays();
    let duration = 0;
    allDays.forEach((day) => {
      duration += day.getTotalDuration() - day.getBreakDuration();
    });

    return duration;
  }

  getAPIFormattedDates() {
    const dates = this.getDates();
    return {
      start: dates.start ? DatesHelpers.getDayjs(this.dates.start).format(API_DATE_FORMAT_SHORT) : undefined,
      end: dates.end ? DatesHelpers.getDayjs(this.dates.end).format(API_DATE_FORMAT_SHORT) : undefined,
    };
  }

  getHours() {
    return this[ICreationStepPlanning.hours];
  }

  isFluctuating() {
    return this[ICreationStepPlanning.fluctuating] === true;
  }
}
