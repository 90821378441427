export const JOBS_CONTRACTS = [
  {
    code: "extra",
    label: "CORE.MODELS.CONTRACTS.@AE",
    color: "secondary",
  },
  {
    code: "interim",
    label: "CORE.MODELS.CONTRACTS.@INTERIM",
    color: "interim-green",
  },
  {
    code: "cdi",
    label: "CORE.MODELS.CONTRACTS.@CDI",
    color: "primary",
  },
  {
    code: "saisonnier",
    label: "CORE.MODELS.CONTRACTS.@SEASON",
    color: "season-orange",
  },
];

export const JOBS_CONTRACTS_TYPE = {
  CDI: "cdi",
  SEASON: "saisonnier",
  INTERIM: "interim",
  AE: "auto_e",
  EXTRA: "extra",
};

export const JOBS_GOOGLE_JOBS = {
  FULL_TIME: "FULL_TIME",
  PART_TIME: "PART_TIME",
};

export const JOBS_APPLICATION_STATUS = {
  DOWNLOAD: "download",
  OPEN_APPLY: "openapply",
  APPLY: "apply",
};
