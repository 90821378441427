import { DatesHelpers, ScheduleHelpers } from "@core/helpers";
import { DEFAULT_BREAK_DURATION } from "@core/constants";
import { i18n } from "@core/plugins/i18n";

export const ICreationStepPlanningDay = Object.freeze({
  id: "id",
  selected: "selected",
  date: "date",
  start: "start",
  end: "end",
  break: "break",
});

export class CreationStepPlanningDayModel {
  constructor(data = {}) {
    this[ICreationStepPlanningDay.id] = data[ICreationStepPlanningDay.id];
    this[ICreationStepPlanningDay.selected] = data[ICreationStepPlanningDay.selected];
    this[ICreationStepPlanningDay.date] = data[ICreationStepPlanningDay.date];
    this[ICreationStepPlanningDay.start] = data[ICreationStepPlanningDay.start];
    this[ICreationStepPlanningDay.end] = data[ICreationStepPlanningDay.end];
    this[ICreationStepPlanningDay.break] = data[ICreationStepPlanningDay.break];
  }

  getId() {
    return this[ICreationStepPlanningDay.id];
  }

  setId(id) {
    this[ICreationStepPlanningDay.id] = id;
  }

  getDate() {
    return this[ICreationStepPlanningDay.date];
  }

  getStartHour() {
    return this[ICreationStepPlanningDay.start];
  }

  getEndHour() {
    return this[ICreationStepPlanningDay.end];
  }

  getScheduleDisplay() {
    const start = this.getFormattedTimeDisplay(this.getStartHour());
    const end = this.getFormattedTimeDisplay(this.getEndHour());
    return `${start} -> ${end}`;
  }

  getFormattedTimeDisplay(durationInMinutes) {
    const formattedTime = DatesHelpers.formatMinsToHrsMins(durationInMinutes % ScheduleHelpers.DAY_DURATION_MINUTES);
    const nextDay = durationInMinutes >= ScheduleHelpers.DAY_DURATION_MINUTES;
    const formattedNextDay = nextDay ? ` ${i18n.t("CORE.MODELS.SCHEDULE.@NEXT_DAY")}` : "";
    return `${formattedTime}${formattedNextDay}`;
  }

  getBreakDisplay() {
    if (this.getBreakDuration() > 60) {
      return DatesHelpers.formatMinsToHrsMins(this.getBreakDuration());
    }
    return `${this.getBreakDuration()}min`;
  }

  select() {
    this[ICreationStepPlanningDay.selected] = true;
  }

  unselect() {
    this[ICreationStepPlanningDay.selected] = false;
    this[ICreationStepPlanningDay.start] = undefined;
    this[ICreationStepPlanningDay.end] = undefined;
    this[ICreationStepPlanningDay.break] = DEFAULT_BREAK_DURATION;
  }

  isSelected() {
    return this[ICreationStepPlanningDay.selected] === true;
  }

  endBeforeStart() {
    return (
      this[ICreationStepPlanningDay.start] > 0 &&
      this[ICreationStepPlanningDay.end] > 0 &&
      this[ICreationStepPlanningDay.end] < this[ICreationStepPlanningDay.start] + ScheduleHelpers.MINIMAL_SHIFT_DURATION * 60
    );
  }

  breakTooLong() {
    return (
      this[ICreationStepPlanningDay.start] > 0 &&
      this[ICreationStepPlanningDay.end] > 0 &&
      this[ICreationStepPlanningDay.break] > (this[ICreationStepPlanningDay.end] - this[ICreationStepPlanningDay.start]) / 2
    );
  }

  getFullStartDate() {
    return DatesHelpers.getFullDateHourMinutes(this[ICreationStepPlanningDay.date], this[ICreationStepPlanningDay.start]);
  }

  getFullEndDate() {
    return DatesHelpers.getFullDateHourMinutes(this[ICreationStepPlanningDay.date], this[ICreationStepPlanningDay.end]);
  }

  getTotalDuration() {
    const duration = this[ICreationStepPlanningDay.end] - this[ICreationStepPlanningDay.start];
    return Number.isNaN(duration) ? 0 : duration;
  }

  getBreakDuration() {
    return this[ICreationStepPlanningDay.break] || 0;
  }

  getWorkingDuration() {
    return this.getTotalDuration() - this.getBreakDuration();
  }

  isToday() {
    return DatesHelpers.dateIsToday(this[ICreationStepPlanningDay.date]);
  }

  startHourIsValid() {
    return !this.isToday() || this[ICreationStepPlanningDay.start] > DatesHelpers.getDayjs().hour() * 60;
  }

  isValid() {
    return (
      !this.isSelected() ||
      (!this.endBeforeStart() &&
        this.startHourIsValid() &&
        !this.breakTooLong() &&
        this[ICreationStepPlanningDay.start] !== undefined &&
        this[ICreationStepPlanningDay.end] !== undefined &&
        this[ICreationStepPlanningDay.break] !== undefined)
    );
  }
}
