import { createStore } from "vuex";
import createHomeModule from "./modules/jobs-home.module";
import createJobsSearchModule from "./modules/jobs-search.module";
import createDynamicModule from "./modules/jobs-dynamic.module";
import createDetailModule from "./modules/jobs-detail.module";
import createMetaDataModule from "./modules/jobs-metadata.module";

/*
 * If not building with SSR mode, you can
 * directly export the Store instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Store instance.
 */

export default function (/* { ssrContext } */) {
  const store = createStore({
    modules: {
      home: createHomeModule(),
      jobsSearch: createJobsSearchModule(),
      dynamic: createDynamicModule(),
      detail: createDetailModule(),
      metaData: createMetaDataModule(),
    },

    // enable strict mode (adds overhead!)
    // for dev mode only
    strict: process.env.DEV,
  });

  return store;
}
