import { JobOutfitModel } from "./jobs-outfit.model";

export const IJobClothing = Object.freeze({
  outfits: "outfits",
  comment: "comment",
  provided: "provided",
});

export class JobClothingModel {
  constructor(data = {}) {
    this[IJobClothing.outfits] = data[IJobClothing.outfits];
    this[IJobClothing.comment] = data[IJobClothing.comment];
    this[IJobClothing.provided] = data[IJobClothing.provided];
  }

  getOutfits() {
    return (this[IJobClothing.outfits] || []).map((outfit) => new JobOutfitModel(outfit));
  }

  getComment() {
    return this[IJobClothing.comment] || "";
  }

  isProvided() {
    return this[IJobClothing.provided] === true;
  }

  isSpecified() {
    return (
      this.getOutfits().length > 0 || //
      this.getComment().length > 0 ||
      this.isProvided()
    );
  }
}
