import { DatesHelpers, JavascriptHelpers, NumericHelpers } from "@core/helpers";
import { EXTRA_STATUS } from "@core/constants";
import { CandidateReportsModel, CandidateSkillModel } from "@core/models/common";
import { QualificationModel } from "@core/models/common/qualification.model";
import { ShiftModel } from "@core/models/etab/recruitments/shift.model";
import { ExperienceModel } from "./experience.model";
import { EducationModel } from "./education.model";
import { LanguageModel } from "./language.model";
import { CandidateTagModel } from "./candidate-tag.model";

export const IExtra = Object.freeze({
  address: "adresse",
  bestExperiences: "best_experiences_for_profil",
  birthDate: "naissance",
  blocked: "bloque",
  canceledComment: "raison_annulation",
  candidateReports: "candidate_reports",
  cgu: "cgu",
  city: "ville",
  createdAt: "created_at",
  dpaePossible: "dpae_possible",
  education: "formations",
  email: "email",
  experiences: "experience_valide",
  experiencesAmount: "nombre_experience_valide",
  favorite: "favori",
  firstname: "prenom",
  genre: "genre",
  iban: "numero_iban",
  id: "id",
  jobsAmount: "nombre_job_valide",
  languages: "langues",
  lastname: "nom",
  longTerm: "longTerm",
  missionEndDate: "mission_end_date",
  missionQualification: "qualification",
  missionStartDate: "mission_start_date",
  motivationText: "motivation_text",
  nationality: "nationalite",
  note: "note_global",
  phone: "telephone",
  photo: "photo",
  urlPhoto: "url_photo",
  postalCode: "code_postal",
  presentation: "presentation",
  price: "prix",
  qualificationsOnExperiences: "qualifications_on_experiences",
  shifts: "shifts",
  shortTerm: "court_terme",
  skills: "competences",
  statusApp: "status_app",
  statusCandidate: "status_candidate",
  statusContact: "status_contact",
  statusDossierAdmin: "status_dossier_admin",
  statusExtra: "status_extra",
  statusAnswerExtra: "status_reponse_extra",
  theoricalPrice: "prix_previsionnel",
  vacations: "vacations",
  vehicle: "vehicule",
  tags: "tags",
  fromJoPool: "is_from_jo_pool",
});

export class ExtraModel {
  constructor(data = {}) {
    this[IExtra.candidateReports] = data[IExtra.candidateReports];
    this[IExtra.address] = data[IExtra.address];
    this[IExtra.bestExperiences] = data[IExtra.bestExperiences];
    this[IExtra.birthDate] = data[IExtra.birthDate];
    this[IExtra.blocked] = data[IExtra.blocked];
    this[IExtra.canceledComment] = data[IExtra.canceledComment];
    this[IExtra.cgu] = data[IExtra.cgu];
    this[IExtra.city] = data[IExtra.city];
    this[IExtra.createdAt] = data[IExtra.createdAt];
    this[IExtra.dpaePossible] = data[IExtra.dpaePossible];
    this[IExtra.education] = data[IExtra.education];
    this[IExtra.email] = data[IExtra.email];
    this[IExtra.experiences] = data[IExtra.experiences];
    this[IExtra.experiencesAmount] = data[IExtra.experiencesAmount];
    this[IExtra.favorite] = data[IExtra.favorite];
    this[IExtra.firstname] = data[IExtra.firstname];
    this[IExtra.genre] = data[IExtra.genre];
    this[IExtra.iban] = data[IExtra.iban];
    this[IExtra.id] = data[IExtra.id];
    this[IExtra.jobsAmount] = data[IExtra.jobsAmount];
    this[IExtra.languages] = data[IExtra.languages];
    this[IExtra.lastname] = data[IExtra.lastname];
    this[IExtra.longTerm] = data[IExtra.longTerm];
    this[IExtra.missionEndDate] = data[IExtra.missionEndDate];
    this[IExtra.missionQualification] = data[IExtra.missionQualification];
    this[IExtra.missionStartDate] = data[IExtra.missionStartDate];
    this[IExtra.motivationText] = data[IExtra.motivationText];
    this[IExtra.nationality] = data[IExtra.nationality];
    this[IExtra.note] = data[IExtra.note];
    this[IExtra.phone] = data[IExtra.phone];
    this[IExtra.photo] = data[IExtra.photo];
    this[IExtra.postalCode] = data[IExtra.postalCode];
    this[IExtra.presentation] = data[IExtra.presentation];
    this[IExtra.price] = data[IExtra.price];
    this[IExtra.qualificationsOnExperiences] = data[IExtra.qualificationsOnExperiences];
    this[IExtra.shifts] = data[IExtra.shifts];
    this[IExtra.shortTerm] = data[IExtra.shortTerm];
    this[IExtra.skills] = data[IExtra.skills];
    this[IExtra.statusApp] = data[IExtra.statusApp];
    this[IExtra.statusCandidate] = data[IExtra.statusCandidate];
    this[IExtra.statusContact] = data[IExtra.statusContact];
    this[IExtra.statusDossierAdmin] = data[IExtra.statusDossierAdmin];
    this[IExtra.statusExtra] = data[IExtra.statusExtra];
    this[IExtra.statusAnswerExtra] = data[IExtra.statusAnswerExtra];
    this[IExtra.theoricalPrice] = data[IExtra.theoricalPrice];
    this[IExtra.vacations] = data[IExtra.vacations];
    this[IExtra.vehicle] = data[IExtra.vehicle];
    this[IExtra.urlPhoto] = data[IExtra.urlPhoto];
    this[IExtra.tags] = data[IExtra.tags];
    this[IExtra.fromJoPool] = data[IExtra.fromJoPool];
  }

  getId() {
    return this[IExtra.id];
  }

  getAge() {
    const birthDate = DatesHelpers.getDayjs(new Date(this[IExtra.birthDate]));
    if (birthDate.isValid()) {
      return DatesHelpers.getDayjs().diff(birthDate, "years");
    }
    return null;
  }

  getIdentity() {
    const phone = JavascriptHelpers.isValidPhoneNumber(this[IExtra.phone]) ? this[IExtra.phone] : undefined;
    return {
      firstname: this[IExtra.firstname],
      lastname: this[IExtra.lastname],
      phone,
      email: this[IExtra.email],
      postalCode: this[IExtra.postalCode],
      city: this[IExtra.city],
    };
  }

  getFirstname() {
    return this[IExtra.firstname];
  }

  getFullName() {
    return `${this[IExtra.firstname]} ${this[IExtra.lastname]}`;
  }

  getPicture() {
    return this[IExtra.urlPhoto] || this[IExtra.photo];
  }

  getCandidateReports() {
    return new CandidateReportsModel(this[IExtra.candidateReports]);
  }

  getQualificationsOnExperiences() {
    return this[IExtra.qualificationsOnExperiences] || [];
  }

  getMissionQualification() {
    return new QualificationModel(this[IExtra.missionQualification]);
  }

  isSelected() {
    return this[IExtra.statusCandidate] === EXTRA_STATUS.SELECTED;
  }

  isCandidate() {
    return this[IExtra.statusCandidate] === EXTRA_STATUS.CANDIDATE;
  }

  isCanceled() {
    return this[IExtra.statusCandidate] === EXTRA_STATUS.CANCELED;
  }

  isIgnored() {
    return this[IExtra.statusCandidate] === EXTRA_STATUS.IGNORED;
  }

  hasRefused() {
    return this[IExtra.statusCandidate] === EXTRA_STATUS.CANCELED;
  }

  isFavorite() {
    return this[IExtra.favorite] === true;
  }

  isFromJoPool() {
    return this[IExtra.fromJoPool] === 1;
  }

  isMotorized() {
    return this[IExtra.vehicle] === 1;
  }

  getPresentation() {
    return this[IExtra.presentation] || "";
  }

  getBestExperiences() {
    return (this[IExtra.bestExperiences] || []).map((experience) => new ExperienceModel(experience));
  }

  getExperiences() {
    return (this[IExtra.experiences] || []).map((experience) => new ExperienceModel(experience));
  }

  getSkills() {
    return (this[IExtra.skills] || []).map((skill) => new CandidateSkillModel(skill));
  }

  getEducation() {
    return (this[IExtra.education] || []).map((education) => new EducationModel(education));
  }

  getLanguages() {
    return (this[IExtra.languages] || []).map((language) => new LanguageModel(language));
  }

  getShifts() {
    return (this[IExtra.shifts] || []).map((shift) => new ShiftModel(shift));
  }

  getTags() {
    return new CandidateTagModel(this[IExtra.tags]);
  }

  hasFirstShiftStarted() {
    const shifts = this.getShifts();
    if (shifts.length > 0) {
      return shifts[0].hasStarted();
    }
    return false;
  }

  getMissionDates() {
    const start = this[IExtra.missionStartDate] ? DatesHelpers.getDayjs(this[IExtra.missionStartDate]) : undefined;
    const end = this[IExtra.missionEndDate] ? DatesHelpers.getDayjs(this[IExtra.missionEndDate]) : undefined;

    if (start && end) {
      return {
        start,
        end,
      };
    }
    return undefined;
  }

  getMotivationText(limit = null) {
    const text = this[IExtra.motivationText] || "";
    if (limit && text?.length > limit) {
      return `${text.substring(0, limit)}...`;
    }
    return text;
  }

  getTheoricalPrice() {
    return NumericHelpers.formatNumberAsCurrency({ number: this[IExtra.theoricalPrice], minimumFractionDigits: 0, maximumFractionDigits: 0 });
  }

  getCanceledComment() {
    return this[IExtra.canceledComment] || "";
  }
}
