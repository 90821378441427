import { DEMAND_TYPES, EXTRA_STATUS, RECRUITMENT_TYPES } from "@core/constants";
import { DatesHelpers } from "@core/helpers";
import { QualificationModel } from "@core/models/common/qualification.model";
import { CandidateDashboardModel } from "./candidate-dashboard.model";
import { AddressDashboardModel } from "./address-dashboard.model";
import { RelaunchDemandModel } from "./relauch-demand.model";
import { i18n } from "@core/plugins/i18n";

export const IRecruitmentDashboard = Object.freeze({
  demandId: "id",
  templateId: "template_id",
  missionId: "mission_id",
  timeBeforeExpiration: "time_before_expiration",
  qualification: "qualification", // QualificationModel
  startDate: "start_date",
  startTime: "start_time",
  endDate: "end_date",
  endTime: "end_time",
  address: "address", // AddressDashboardModel
  candidates: "candidates", // array of CandidateDashboardModel
  isFilled: "is_filled",
  isBookingExpress: "is_booking_express",
  hasCandidateRefusedBookingExpress: "has_candidate_refused_booking_express",
  wishedCandidateAmount: "wished_candidate_amount",
  selectedCandidateAmount: "selected_candidate_amount",
  appliedCandidateAmount: "applied_candidate_amount",
  cancelledCandidateAmount: "cancelled_candidate_amount",
  type: "type",
  hasStarted: "has_started",
  precision: "precision",
  duration: "duration",
  openToNovice: "open_to_novice",
  isUnlocked: "is_unlocked",
  isBoostedOffer: "is_boosted",
  relaunch: "relaunch_auto",
});

export class RecruitmentDashboardModel {
  constructor(data = {}) {
    this[IRecruitmentDashboard.demandId] = data[IRecruitmentDashboard.demandId];
    this[IRecruitmentDashboard.templateId] = data[IRecruitmentDashboard.templateId];
    this[IRecruitmentDashboard.missionId] = data[IRecruitmentDashboard.missionId];
    this[IRecruitmentDashboard.timeBeforeExpiration] = data[IRecruitmentDashboard.timeBeforeExpiration];
    this[IRecruitmentDashboard.qualification] = data[IRecruitmentDashboard.qualification];
    this[IRecruitmentDashboard.startDate] = data[IRecruitmentDashboard.startDate];
    this[IRecruitmentDashboard.startTime] = data[IRecruitmentDashboard.startTime];
    this[IRecruitmentDashboard.endDate] = data[IRecruitmentDashboard.endDate];
    this[IRecruitmentDashboard.endTime] = data[IRecruitmentDashboard.endTime];
    this[IRecruitmentDashboard.address] = data[IRecruitmentDashboard.address];
    this[IRecruitmentDashboard.candidates] = data[IRecruitmentDashboard.candidates];
    this[IRecruitmentDashboard.isFilled] = data[IRecruitmentDashboard.isFilled];
    this[IRecruitmentDashboard.isBookingExpress] = data[IRecruitmentDashboard.isBookingExpress];
    this[IRecruitmentDashboard.hasCandidateRefusedBookingExpress] = data[IRecruitmentDashboard.hasCandidateRefusedBookingExpress];
    this[IRecruitmentDashboard.wishedCandidateAmount] = data[IRecruitmentDashboard.wishedCandidateAmount];
    this[IRecruitmentDashboard.selectedCandidateAmount] = data[IRecruitmentDashboard.selectedCandidateAmount];
    this[IRecruitmentDashboard.appliedCandidateAmount] = data[IRecruitmentDashboard.appliedCandidateAmount];
    this[IRecruitmentDashboard.cancelledCandidateAmount] = data[IRecruitmentDashboard.cancelledCandidateAmount];
    this[IRecruitmentDashboard.type] = data[IRecruitmentDashboard.type];
    this[IRecruitmentDashboard.hasStarted] = data[IRecruitmentDashboard.hasStarted];
    this[IRecruitmentDashboard.precision] = data[IRecruitmentDashboard.precision];
    this[IRecruitmentDashboard.duration] = data[IRecruitmentDashboard.duration];
    this[IRecruitmentDashboard.openToNovice] = data[IRecruitmentDashboard.openToNovice];
    this[IRecruitmentDashboard.isUnlocked] = data[IRecruitmentDashboard.isUnlocked];
    this[IRecruitmentDashboard.isBoostedOffer] = data[IRecruitmentDashboard.isBoostedOffer];
    this[IRecruitmentDashboard.relaunch] = data[IRecruitmentDashboard.relaunch];
  }

  getDemandId() {
    return this[IRecruitmentDashboard.demandId];
  }

  getTemplateId() {
    return this[IRecruitmentDashboard.templateId];
  }

  getMissionId() {
    return this[IRecruitmentDashboard.missionId];
  }

  getType() {
    return this[IRecruitmentDashboard.type] || "";
  }

  isBookingExpress() {
    return this[IRecruitmentDashboard.isBookingExpress] === true;
  }

  hasCandidateRefusedBookingExpress() {
    return this[IRecruitmentDashboard.hasCandidateRefusedBookingExpress] === true;
  }

  getCurrentEtablissement() {
    return new AddressDashboardModel(this[IRecruitmentDashboard.address]);
  }

  getMissionAddress() {
    return this.getCurrentEtablissement().getFormattedAddress();
  }

  isCDI() {
    return this.getType().toLowerCase() === DEMAND_TYPES.CDI.toLowerCase();
  }

  isSeason() {
    return this.getType().toLowerCase() === DEMAND_TYPES.SEASON.toLowerCase();
  }

  isAE() {
    return this.getType().toLowerCase() === DEMAND_TYPES.AE.toLowerCase();
  }

  isInterim() {
    return this.getType().toLowerCase() === DEMAND_TYPES.INTERIM.toLowerCase();
  }

  isMER() {
    return this.getType().toLowerCase() === DEMAND_TYPES.MER.toLowerCase();
  }

  isPermanent() {
    const demandTypes = RECRUITMENT_TYPES.PERMANENT.demandTypes;
    return demandTypes.includes(this.getType().toUpperCase());
  }

  getStartDate() {
    return DatesHelpers.getDateMomentIfExist(this[IRecruitmentDashboard.startDate]);
  }

  getCDIStartDate() {
    return DatesHelpers.isSameOrAfter(DatesHelpers.getDayjs(), this[IRecruitmentDashboard.startDate])
      ? i18n.global.t("ETAB.PAGES.DEMANDS.CREATION.@DATE_ASAP")
      : DatesHelpers.getFormattedDate(this[IRecruitmentDashboard.startDate]);
  }

  getSeasonalStartDate() {
    return i18n.global.t("ETAB.PAGES.RECRUITMENT.TILE.@DATES_DISPLAY_NO_TIME", {
      start: DatesHelpers.getFormattedDate(this[IRecruitmentDashboard.startDate]),
      end: DatesHelpers.getFormattedDate(this[IRecruitmentDashboard.endDate]),
    });
  }

  getStartTime() {
    return this[IRecruitmentDashboard.startTime];
  }

  getEndDate() {
    return DatesHelpers.getDateMomentIfExist(this[IRecruitmentDashboard.endDate]);
  }

  getEndTime() {
    return this[IRecruitmentDashboard.endTime];
  }

  isOneDay() {
    return this.getStartDate().isSame(this.getEndDate(), "day");
  }

  isTemporary() {
    const demandTypes = RECRUITMENT_TYPES.TEMPORARY.demandTypes;
    return demandTypes.includes(this.getType());
  }

  isExpired() {
    return this.getSecondsToExpiration() === 0;
  }

  getQualification() {
    return new QualificationModel(this[IRecruitmentDashboard.qualification]);
  }

  getQualificationLabel() {
    return this.getQualification().getLabel();
  }

  getPrecisions() {
    return this[IRecruitmentDashboard.precision];
  }

  getSecondsToExpiration() {
    return parseInt(this[IRecruitmentDashboard.timeBeforeExpiration], 10) || 0;
  }

  isNovicesAllowed() {
    return this[IRecruitmentDashboard.openToNovice] === true;
  }

  getCandidates() {
    return (this[IRecruitmentDashboard.candidates] || []).map((candidate) => new CandidateDashboardModel(candidate));
  }

  getWishedCandidateAmount() {
    return this[IRecruitmentDashboard.wishedCandidateAmount];
  }

  getSelectedCandidateAmount() {
    return this[IRecruitmentDashboard.selectedCandidateAmount];
  }

  getCanceledCandidates() {
    return this.getCandidates().filter((candidate) => candidate.status === EXTRA_STATUS.CANCELED);
  }

  getAppliedCandidateAmount() {
    return this[IRecruitmentDashboard.appliedCandidateAmount];
  }

  getCandidateCancellationCount() {
    return this[IRecruitmentDashboard.cancelledCandidateAmount] || 0;
  }

  isFilled() {
    return this[IRecruitmentDashboard.isFilled];
  }

  isUnlocked() {
    return this[IRecruitmentDashboard.isUnlocked] === true;
  }

  hasStarted() {
    return this[IRecruitmentDashboard.hasStarted];
  }

  getDuration() {
    return this[IRecruitmentDashboard.duration];
  }

  isBoostedOffer() {
    return this[IRecruitmentDashboard.isBoostedOffer];
  }

  hasCandidate() {
    return Boolean(this.getAppliedCandidateAmount() + this.getSelectedCandidateAmount());
  }

  getRelaunchInfo() {
    return this[IRecruitmentDashboard.relaunch] ? new RelaunchDemandModel(this[IRecruitmentDashboard.relaunch]) : null;
  }
}
