export const ICreationStepExtraNumber = Object.freeze({
  quantity: "quantity",
  candidate: "candidate",
});

export class CreationStepExtraNumberModel {
  constructor(data = {}) {
    this[ICreationStepExtraNumber.quantity] = data[ICreationStepExtraNumber.quantity];
    this[ICreationStepExtraNumber.candidate] = data[ICreationStepExtraNumber.candidate];
  }

  getQuantity() {
    return this[ICreationStepExtraNumber.quantity] || 1;
  }

  getSelectedCandidate() {
    return this[ICreationStepExtraNumber.candidate];
  }
}
