export const IContactCategory = Object.freeze({
  id: "id",
  name: "name",
});

export class ContactCategoryModel {
  constructor(data = {}) {
    this[IContactCategory.id] = data[IContactCategory.id];
    this[IContactCategory.name] = data[IContactCategory.name];
  }

  getId() {
    return this[IContactCategory.id];
  }

  getName() {
    return this[IContactCategory.name];
  }
}
