export const IActiveContracts = Object.freeze({
  name: "name",
  label: "label",
  icon: "icon",
  isActivated: "activated",
  lockMessage: "lock_message",
});

export class ActiveContractsModel {
  constructor(data = {}) {
    this[IActiveContracts.name] = data[IActiveContracts.name];
    this[IActiveContracts.label] = data[IActiveContracts.label];
    this[IActiveContracts.icon] = data[IActiveContracts.icon];
    this[IActiveContracts.isActivated] = data[IActiveContracts.isActivated];
    this[IActiveContracts.lockMessage] = data[IActiveContracts.lockMessage];
  }

  getName() {
    return this[IActiveContracts.name];
  }

  getLabel() {
    return this[IActiveContracts.label];
  }

  getIcon() {
    return this[IActiveContracts.icon];
  }

  isActivated() {
    return this[IActiveContracts.isActivated];
  }

  getLockMessage() {
    return this[IActiveContracts.lockMessage];
  }
}
