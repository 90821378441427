export const ISkill = Object.freeze({
  legacyLabel: "nom",
  label: "name",
  id: "id",
});

export class CandidateSkillModel {
  constructor(data = {}) {
    this[ISkill.label] = data[ISkill.label] || data[ISkill.legacyLabel];
    this[ISkill.id] = data[ISkill.id];
  }

  getId() {
    return this[ISkill.id];
  }

  getLabel() {
    return this[ISkill.label];
  }
}
