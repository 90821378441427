export const ICreationStepQualification = Object.freeze({
  favorite: "favorite",
  id: "id",
  name: "name",
  qualificationId: "qualification_id",
});

export class CreationStepQualificationModel {
  constructor(data = {}) {
    this[ICreationStepQualification.favorite] = data[ICreationStepQualification.favorite];
    this[ICreationStepQualification.id] = data[ICreationStepQualification.id];
    this[ICreationStepQualification.name] = data[ICreationStepQualification.name];
    this[ICreationStepQualification.qualificationId] = data[ICreationStepQualification.qualificationId];
  }

  getId() {
    return this[ICreationStepQualification.id];
  }

  getName() {
    return this[ICreationStepQualification.name] || "";
  }

  isFavorite() {
    return this[ICreationStepQualification.favorite] === true;
  }
}
