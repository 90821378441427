import { QualificationModel } from "@core/models/common/qualification.model";
import { CONTRACTS, CANDIDATE_SHIFT_STATUS } from "@core/constants";
import { DatesHelpers, JavascriptHelpers } from "@core/helpers";
import { CandidateEtabModel } from "./candidate-etab.model";
import { CandidateOfferAddressModel } from "./candidate-offer-address.model";
import { CandidateOfferSalaryModel } from "./candidate-offer-salary.model";
import { CandidateOfferScheduleDayModel } from "./candidate-offer-schedule-day.model";

export const ICandidateDashboardShift = Object.freeze({
  id: "encoded_id",
  startDate: "start_date",
  qualification: "qualification",
  etab: "etab",
  salary: "salary",
  address: "address",
  status: "status",
  shift: "shift",
  contractType: "contract",
});

export class CandidateDashboardShiftModel {
  constructor(data = {}) {
    this[ICandidateDashboardShift.id] = data[ICandidateDashboardShift.id];
    this[ICandidateDashboardShift.startDate] = data[ICandidateDashboardShift.startDate];
    this[ICandidateDashboardShift.qualification] = data[ICandidateDashboardShift.qualification];
    this[ICandidateDashboardShift.etab] = data[ICandidateDashboardShift.etab];
    this[ICandidateDashboardShift.salary] = data[ICandidateDashboardShift.salary];
    this[ICandidateDashboardShift.address] = data[ICandidateDashboardShift.address];
    this[ICandidateDashboardShift.status] = data[ICandidateDashboardShift.status];
    this[ICandidateDashboardShift.shift] = data[ICandidateDashboardShift.shift];
    this[ICandidateDashboardShift.contractType] = data[ICandidateDashboardShift.contractType];
  }

  // required for shift cancelation
  isSelectedStatus() {
    return true;
  }

  getId() {
    return this[ICandidateDashboardShift.id];
  }

  getAddress() {
    return new CandidateOfferAddressModel(this[ICandidateDashboardShift.address]);
  }

  getFormattedAddress() {
    return this.getAddress().getFormattedAdress();
  }

  getEtab() {
    return new CandidateEtabModel(this[ICandidateDashboardShift.etab]);
  }

  getEtabPicture() {
    return this.getEtab().getFirstPicture();
  }

  getEtabName() {
    return this.getEtab().getName();
  }

  getEtabCategory() {
    return this.getEtab().getCategory().getName();
  }

  getQualification() {
    return new QualificationModel(this[ICandidateDashboardShift.qualification]);
  }

  getQualificationLabel() {
    return this.getQualification().getLabel();
  }

  getStartDate() {
    return this[ICandidateDashboardShift.startDate];
  }

  getStatus() {
    return this[ICandidateDashboardShift.status];
  }

  getStartDateDisplay() {
    const date = DatesHelpers.formatToFullDate(this.getStartDate(), "dddd LL");
    return JavascriptHelpers.capitalizeFirstLetter(date);
  }

  getSalary() {
    return new CandidateOfferSalaryModel(this[ICandidateDashboardShift.salary]);
  }

  getShift() {
    return new CandidateOfferScheduleDayModel(this[ICandidateDashboardShift.shift]);
  }

  getContractType() {
    return this[ICandidateDashboardShift.contractType];
  }

  isAE() {
    return this.getContractType() === CONTRACTS.AE;
  }

  isInterim() {
    return this.getContractType() === CONTRACTS.INTERIM;
  }

  isTemporary() {
    return this.isAE() || this.isInterim();
  }

  getStatusLabel() {
    switch (this[ICandidateDashboardShift.status]) {
      case CANDIDATE_SHIFT_STATUS.COMING_SOON.status:
        return CANDIDATE_SHIFT_STATUS.COMING_SOON.label;
      case CANDIDATE_SHIFT_STATUS.CANCELLED_BY_CANDIDATE.status:
        return CANDIDATE_SHIFT_STATUS.CANCELLED_BY_CANDIDATE.label;
      case CANDIDATE_SHIFT_STATUS.IN_PROGRESS.status:
        return CANDIDATE_SHIFT_STATUS.IN_PROGRESS.label;
      case CANDIDATE_SHIFT_STATUS.CANCELLED_BY_ETAB.status:
        return CANDIDATE_SHIFT_STATUS.CANCELLED_BY_ETAB.label;

      default:
        return "";
    }
  }

  getStatusColor() {
    switch (this[ICandidateDashboardShift.status]) {
      case CANDIDATE_SHIFT_STATUS.COMING_SOON.status:
        return CANDIDATE_SHIFT_STATUS.COMING_SOON.color;
      case CANDIDATE_SHIFT_STATUS.CANCELLED_BY_CANDIDATE.status:
        return CANDIDATE_SHIFT_STATUS.CANCELLED_BY_CANDIDATE.color;
      case CANDIDATE_SHIFT_STATUS.IN_PROGRESS.status:
        return CANDIDATE_SHIFT_STATUS.IN_PROGRESS.color;
      case CANDIDATE_SHIFT_STATUS.CANCELLED_BY_ETAB.status:
        return CANDIDATE_SHIFT_STATUS.CANCELLED_BY_ETAB.color;

      default:
        return "";
    }
  }

  isComingSoonStatus() {
    return this.getStatus() === CANDIDATE_SHIFT_STATUS.COMING_SOON.status;
  }

  isCancelledByCandidateStatus() {
    return this.getStatus() === CANDIDATE_SHIFT_STATUS.CANCELLED_BY_CANDIDATE.status;
  }

  isCancelledByEtabStatus() {
    return this.getStatus() === CANDIDATE_SHIFT_STATUS.CANCELLED_BY_ETAB.status;
  }

  isInProgressStatus() {
    return this.getStatus() === CANDIDATE_SHIFT_STATUS.IN_PROGRESS.status;
  }
}
