import { BoReferenceContactModel } from "@core/models/back-office/experiences/reference-contact.model";
import { CandidateExperienceModel, ICandidateExperience } from "@core/models/common/candidate-experience.model";
import { CANDIDATE_EXPERIENCE_STATUSES } from "@core/constants";
import { BoExtraModel } from "../extra.model";

export class BoCandidateExperienceModel extends CandidateExperienceModel {
  constructor(data = {}) {
    super(data);
    this[ICandidateExperience.extra] = data[ICandidateExperience.extra];
    this[ICandidateExperience.extraId] = data[ICandidateExperience.extraId];
    this[ICandidateExperience.status] = data[ICandidateExperience.status];
    this[ICandidateExperience.reference] = data[ICandidateExperience.reference];
    this[ICandidateExperience.allowVerification] = data[ICandidateExperience.allowVerification] || false;
    this[ICandidateExperience.isSelfVerified] = data[ICandidateExperience.isSelfVerified] || false;
    this[ICandidateExperience.internalComment] = data[ICandidateExperience.internalComment];
    this[ICandidateExperience.managerComment] = data[ICandidateExperience.managerComment];
  }

  getExtraId() {
    return this[ICandidateExperience.extraId];
  }

  getExtra() {
    return new BoExtraModel(this[ICandidateExperience.extra]);
  }

  getStatus() {
    return this[ICandidateExperience.status];
  }

  getDisplayedStatus() {
    const status = Object.entries(CANDIDATE_EXPERIENCE_STATUSES).find(([key, value]) => value.code === this.getStatus());
    return status[1]?.label;
  }

  getStatusColor() {
    const status = Object.entries(CANDIDATE_EXPERIENCE_STATUSES).find(([key, value]) => value.code === this.getStatus());
    return status[1]?.color;
  }

  getReference() {
    return this[ICandidateExperience.reference] ? new BoReferenceContactModel(this[ICandidateExperience.reference]) : null;
  }

  isReferenceSet() {
    return this.getReference() !== null;
  }

  isPending() {
    return this[ICandidateExperience.status] === CANDIDATE_EXPERIENCE_STATUSES.PENDING.code;
  }

  isPendingInternal() {
    return this[ICandidateExperience.status] === CANDIDATE_EXPERIENCE_STATUSES.PENDING_INTERNAL_VERIFICATION.code;
  }

  isVerified() {
    return this[ICandidateExperience.status] === CANDIDATE_EXPERIENCE_STATUSES.VERIFIED.code;
  }

  isUnverified() {
    return this[ICandidateExperience.status] === CANDIDATE_EXPERIENCE_STATUSES.UNVERIFIED.code;
  }

  isRefused() {
    return this[ICandidateExperience.status] === CANDIDATE_EXPERIENCE_STATUSES.REFUSED.code;
  }

  allowVerification() {
    return this[ICandidateExperience.allowVerification] === true;
  }

  isSelfVerified() {
    return this[ICandidateExperience.isSelfVerified] === true;
  }

  getInternalComment() {
    return this[ICandidateExperience.internalComment] || "";
  }

  getStartDate() {
    return this[ICandidateExperience.startDate];
  }

  getEndDate() {
    return this[ICandidateExperience.endDate];
  }
}
