export const IRecapStep = Object.freeze({
  slug: "slug",
  icon: "icon",
  label: "label",
  data: "data",
  hasError: "has_error",
  error: "error",
});

export class RecapStepModel {
  constructor(data = {}) {
    this[IRecapStep.slug] = data[IRecapStep.slug];
    this[IRecapStep.icon] = data[IRecapStep.icon];
    this[IRecapStep.label] = data[IRecapStep.label];
    this[IRecapStep.data] = data[IRecapStep.data];
    this[IRecapStep.hasError] = data[IRecapStep.hasError];
    this[IRecapStep.error] = data[IRecapStep.error];
  }

  getSlug() {
    return this[IRecapStep.slug];
  }

  getIcon() {
    return this[IRecapStep.icon];
  }

  getLabel() {
    return this[IRecapStep.label];
  }

  getData() {
    return this[IRecapStep.data];
  }

  hasError() {
    return !!this[IRecapStep.hasError];
  }

  getError() {
    return this[IRecapStep.error] || "";
  }
}
