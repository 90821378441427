export const IAEBanner = Object.freeze({
  title: "title",
  color: "type",
  text: "text",
});

export class AEBannerModel {
  constructor(data = {}) {
    this[IAEBanner.title] = data[IAEBanner.title];
    this[IAEBanner.color] = data[IAEBanner.color];
    this[IAEBanner.text] = data[IAEBanner.text];
  }

  getTitle() {
    return this[IAEBanner.title];
  }

  getColor() {
    return this[IAEBanner.color];
  }

  getText() {
    return this[IAEBanner.text];
  }

  isBannerSet() {
    return this.getText() && this.getColor();
  }
}
