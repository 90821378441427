import { createRouter, createMemoryHistory, createWebHistory, createWebHashHistory } from "vue-router";
import getRoutes from "./routes";

export default function ({ store, ssrContext }) {
  const createHistory = (baseURL = process.env.VUE_ROUTER_BASE) =>
    process.env.SERVER
      ? createMemoryHistory(baseURL)
      : process.env.VUE_ROUTER_MODE === "history"
        ? createWebHistory(baseURL)
        : createWebHashHistory(baseURL);

  const router = createRouter({
    history: createHistory(process.env.VUE_ROUTER_BASE),
    scrollBehavior(to, from, savedPosition) {
      // we want to always scroll to top if not precised otherwise
      const doNotScrollTop = Object.prototype.hasOwnProperty.call(to.params, "doNotScrollTop");
      if (doNotScrollTop) {
        return null;
      }
      return savedPosition || { left: 0, top: 0 };
    },
    routes: getRoutes({ store, ssrContext }),
    // Leave these as they are and change in quasar.conf.js instead!
    // quasar.conf.js -> build -> vueRouterMode
    // quasar.conf.js -> build -> publicPath
  });

  return router;
}
