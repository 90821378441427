import { QualificationModel } from "@core/models/common/qualification.model";
import { CANDIDATES_OFFER_STATUS, CONTRACTS, CONTRACTS_ALL } from "@core/constants";
import { DatesHelpers } from "@core/helpers";
import { CandidateEtabModel } from "./candidate-etab.model";
import { CandidateOfferAddressModel } from "./candidate-offer-address.model";
import { CandidateOfferSalaryModel } from "./candidate-offer-salary.model";
import { CandidateOfferClothingModel } from "./candidate-offer-clothing.model";

export const ICandidateOffer = Object.freeze({
  id: "encoded_id",
  startDate: "start_date",
  endDate: "end_date",
  publicationDate: "publication_date",
  schedule: "forecast_schedule",
  qualification: "qualification",
  etab: "etab",
  salary: "salary",
  address: "address",
  contractType: "contract_type",
  isBookingExpress: "is_booking_express",
  nbDays: "nb_days",
  status: "status",
  offerComment: "offer_comment",
  clothing: "clothing",
  typicalSchedule: "typical_schedule",
  shifts: "shifts",
  shareUrl: "share_url",
});

export class CandidateOfferModel {
  constructor(data = {}) {
    this[ICandidateOffer.id] = data[ICandidateOffer.id];
    this[ICandidateOffer.startDate] = data[ICandidateOffer.startDate];
    this[ICandidateOffer.endDate] = data[ICandidateOffer.endDate];
    this[ICandidateOffer.publicationDate] = data[ICandidateOffer.publicationDate];
    this[ICandidateOffer.schedule] = data[ICandidateOffer.schedule];
    this[ICandidateOffer.qualification] = data[ICandidateOffer.qualification];
    this[ICandidateOffer.etab] = data[ICandidateOffer.etab];
    this[ICandidateOffer.salary] = data[ICandidateOffer.salary];
    this[ICandidateOffer.address] = data[ICandidateOffer.address];
    this[ICandidateOffer.contractType] = data[ICandidateOffer.contractType];
    this[ICandidateOffer.isBookingExpress] = data[ICandidateOffer.isBookingExpress];
    this[ICandidateOffer.nbDays] = data[ICandidateOffer.nbDays];
    this[ICandidateOffer.status] = data[ICandidateOffer.status];
    this[ICandidateOffer.offerComment] = data[ICandidateOffer.offerComment];
    this[ICandidateOffer.clothing] = data[ICandidateOffer.clothing];
    this[ICandidateOffer.typicalSchedule] = data[ICandidateOffer.typicalSchedule];
    this[ICandidateOffer.shifts] = data[ICandidateOffer.shifts];
    this[ICandidateOffer.shareUrl] = data[ICandidateOffer.shareUrl];
  }

  getId() {
    return this[ICandidateOffer.id]?.toString();
  }

  getContractType() {
    return (this[ICandidateOffer.contractType] || "").toLowerCase();
  }

  getContractTypeObject() {
    const contractTypeCode = this.getContractType();
    return CONTRACTS_ALL.find((c) => contractTypeCode === c.code) || {};
  }

  getContractTypeDisplay() {
    return this.getContractTypeObject().label;
  }

  getContractTypeColor() {
    return this.getContractTypeObject().themeColor;
  }

  isCDI() {
    return this.getContractType() === CONTRACTS.CDI;
  }

  isSeason() {
    return this.getContractType() === CONTRACTS.SEASON;
  }

  isAE() {
    return this.getContractType() === CONTRACTS.AE;
  }

  isInterim() {
    return this.getContractType() === CONTRACTS.INTERIM;
  }

  isPermanent() {
    return this.isCDI() || this.isSeason();
  }

  isTemporary() {
    return this.isAE() || this.isInterim();
  }

  getAddress() {
    return new CandidateOfferAddressModel(this[ICandidateOffer.address]);
  }

  getStreet() {
    return this.getAddress().getStreet() || "";
  }

  getCity() {
    return this.getAddress().getCity() || "";
  }

  getFormattedAddress() {
    return `${this.getAddress().getPostalCode()} ${this.getAddress().getCity()}`;
  }

  getEtab() {
    return new CandidateEtabModel(this[ICandidateOffer.etab]);
  }

  getTitle() {
    return `${this.getQualificationLabel()} - ${this.getCity()} - Extracadabra`;
  }

  getEtabPicture() {
    return this.getEtab().getFirstPicture();
  }

  getEtabName() {
    return this.getEtab().getName();
  }

  getEtabCategory() {
    return this.getEtab().getCategory().getName();
  }

  getQualification() {
    return new QualificationModel(this[ICandidateOffer.qualification]);
  }

  getQualificationLabel() {
    return this.getQualification().getLabel();
  }

  getStartDate() {
    return this[ICandidateOffer.startDate];
  }

  getEndDate() {
    return this[ICandidateOffer.endDate];
  }

  getDelayBeforeStartDate() {
    const startDate = DatesHelpers.getDayjs(this.getStartDate(), "YYYY-MM-DD");
    if (startDate.isValid()) {
      return startDate.diff(DatesHelpers.getDayjs(), "hours");
    }
    return null;
  }

  hasNowPassedDealine(deadline = 24) {
    const delayBeforeStartDate = this.getDelayBeforeStartDate();
    return delayBeforeStartDate ? delayBeforeStartDate > 0 && delayBeforeStartDate < deadline : false;
  }

  getStartDateDisplay() {
    return DatesHelpers.getFormattedDate(this.getStartDate());
  }

  getEndDateDisplay() {
    return DatesHelpers.getFormattedDate(this.getEndDate());
  }

  getSchedule() {
    return this[ICandidateOffer.schedule] || "";
  }

  getPublicationDate() {
    return this[ICandidateOffer.publicationDate];
  }

  getPublicationDateFromNow() {
    const date = this.getPublicationDate();
    return DatesHelpers.getDateMomentIfExist(date)?.fromNow();
  }

  getSalary() {
    return new CandidateOfferSalaryModel(this[ICandidateOffer.salary]);
  }

  getNbDays() {
    return this[ICandidateOffer.nbDays] || 0;
  }

  getStatus() {
    return this[ICandidateOffer.status];
  }

  isPendingStatus() {
    return this.getStatus() === CANDIDATES_OFFER_STATUS.PENDING;
  }

  isAppliedStatus() {
    return this.getStatus() === CANDIDATES_OFFER_STATUS.APPLIED;
  }

  isSelectedStatus() {
    return this.getStatus() === CANDIDATES_OFFER_STATUS.SELECTED;
  }

  isHiredStatus() {
    return this.getStatus() === CANDIDATES_OFFER_STATUS.HIRED;
  }

  isRefusedStatus() {
    return this.getStatus() === CANDIDATES_OFFER_STATUS.REFUSED;
  }

  isCanceledMissionStatus() {
    return this.getStatus() === CANDIDATES_OFFER_STATUS.CANCELLED_MISSION;
  }

  isCanceledByEtabStatus() {
    return this.getStatus() === CANDIDATES_OFFER_STATUS.CANCELLED_ETAB;
  }

  getOfferComment() {
    return this[ICandidateOffer.offerComment];
  }

  getClothing() {
    return new CandidateOfferClothingModel(this[ICandidateOffer.clothing]);
  }

  getShareUrl() {
    return this[ICandidateOffer.shareUrl];
  }

  isCdiAsapOffer() {
    const offerDate = DatesHelpers.getDayjs(this.getStartDate());
    return this.isCDI() && DatesHelpers.isSameOrBefore(offerDate);
  }

  hasEnded() {
    return false;
  }

  shouldDisplayTimeSheetBtns() {
    return false;
  }

  getActivityReportUrl() {
    return null;
  }

  isReviewable() {
    return false;
  }
}
