export const IEtabContact = Object.freeze({
  etabId: "etab_id",
  id: "etab_contact_id",
  email: "etab_contact_email",
  categories: "categories",
});

export class EtabContactModel {
  constructor(data = {}) {
    this[IEtabContact.etabId] = data[IEtabContact.etabId];
    this[IEtabContact.id] = data[IEtabContact.id];
    this[IEtabContact.email] = data[IEtabContact.email];
    this[IEtabContact.categories] = data[IEtabContact.categories];
  }

  getEtabId() {
    return this[IEtabContact.etabId];
  }

  getId() {
    return this[IEtabContact.id];
  }

  getEmail() {
    return this[IEtabContact.email];
  }

  getContactCategories() {
    return this[IEtabContact.categories] || [];
  }

  isCategorySelected(categoryId) {
    return this.getContactCategories().some((contactCategoryId) => contactCategoryId === categoryId.toString());
  }
}
