import { DatesHelpers, JavascriptHelpers, ScheduleHelpers } from "@core/helpers";
import { CandidateOfferScheduleDayModel } from "./candidate-offer-schedule-day.model";
import { CandidateOfferModel } from "./candidate-offer.model";

export const ICandidateOfferTemporary = Object.freeze({
  isBookingExpress: "is_booking_express",
  shifts: "shifts",
  isReviewable: "is_reviewable",
  hasEnded: "has_ended",
  activityReportUri: "candidate_activity_report_uri",
});

export class CandidateOfferTemporaryModel extends CandidateOfferModel {
  constructor(data = {}) {
    super(data);
    this[ICandidateOfferTemporary.isBookingExpress] = data[ICandidateOfferTemporary.isBookingExpress];
    this[ICandidateOfferTemporary.shifts] = data[ICandidateOfferTemporary.shifts];
    this[ICandidateOfferTemporary.isReviewable] = data[ICandidateOfferTemporary.isReviewable];
    this[ICandidateOfferTemporary.activityReportUri] = data[ICandidateOfferTemporary.activityReportUri];
    this[ICandidateOfferTemporary.hasEnded] = data[ICandidateOfferTemporary.hasEnded];
  }

  isBookingExpress() {
    return this[ICandidateOfferTemporary.isBookingExpress];
  }

  getShifts() {
    return (this[ICandidateOfferTemporary.shifts] || []).map((shift) => new CandidateOfferScheduleDayModel(shift));
  }

  isScheduleUnfilled() {
    return this.getShifts().length === 0;
  }

  isReviewable() {
    return this[ICandidateOfferTemporary.isReviewable];
  }

  shouldDisplayTimeSheetBtns() {
    return (this.isSelectedStatus || this.isCanceledMissionStatus) && (this.isReviewable() || this.getActivityReportUrl());
  }

  getActivityReportUrl() {
    return this[ICandidateOfferTemporary.activityReportUri];
  }

  hasEnded() {
    return this[ICandidateOfferTemporary.hasEnded];
  }

  getTemporarySchedule({ startDate }) {
    const displayDateFormat = "ddd DD MMM";
    const formattedShifts = [];
    const shifts = this.getShifts();
    if (shifts.length > 0) {
      const lastShift = shifts[shifts.length - 1];
      const endDate = lastShift.getDate();
      let dayjsStartDate = DatesHelpers.getDayjs(startDate);
      const numberOfDays = DatesHelpers.numberOfDaysBetweenDates({ start: startDate, end: endDate });
      for (let i = 0; i < numberOfDays; i++) {
        const formattedStartDate = dayjsStartDate.format("YYYY-MM-DD");
        const foundShift = shifts.find((shift) => shift.getDate() === formattedStartDate);
        formattedShifts.push({
          key: i,
          name: JavascriptHelpers.capitalizeFirstLetter(dayjsStartDate.format(displayDateFormat)),
          schedule: foundShift ? ScheduleHelpers.formatDaySchedule({ daySchedule: foundShift }) : null,
        });
        dayjsStartDate = dayjsStartDate.add(1, "days");
      }
    }

    return formattedShifts;
  }
}
