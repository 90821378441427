export const IEtabDashboardAddress = Object.freeze({
  postalCode: "postal_code",
  city: "city",
  street: "street",
});

export class AddressDashboardModel {
  constructor(data = {}) {
    this[IEtabDashboardAddress.postalCode] = data[IEtabDashboardAddress.postalCode];
    this[IEtabDashboardAddress.city] = data[IEtabDashboardAddress.city];
    this[IEtabDashboardAddress.street] = data[IEtabDashboardAddress.street];
  }

  getStreet() {
    return this[IEtabDashboardAddress.street] || "";
  }

  getCity() {
    return this[IEtabDashboardAddress.city] || "";
  }

  getPostalCode() {
    return this[IEtabDashboardAddress.postalCode] || "";
  }

  getFormattedAddress() {
    return `${this.getStreet()} ${this.getPostalCode()} ${this.getCity()}`;
  }
}
