// HOME
export const GETTER_JOBS_GET_POPULAR_SEARCHES = "home/GETTER_JOBS_GET_POPULAR_SEARCHES";
export const GETTER_JOBS_GET_LOCALIZATION_SEARCHES = "home/GETTER_JOBS_GET_LOCALIZATION_SEARCHES";
export const GETTER_JOBS_GET_DYNAMICS_PAGES = "home/GETTER_JOBS_GET_DYNAMICS_PAGES";
export const GETTER_JOBS_GET_JOB_OFFERS = "home/GETTER_JOBS_GET_JOB_OFFERS";

// QUALIFICATIONS
export const GETTER_JOBS_QUALIFICATIONS_GET_ALL = "search/GETTER_JOBS_QUALIFICATIONS_GET_ALL";
export const GETTER_JOBS_CONTRACTS_GET_ALL = "search/GETTER_JOBS_CONTRACTS_GET_ALL";
export const GETTER_JOBS_SEARCH_RESULTS_GET_ALL = "search/GETTER_JOBS_SEARCH_RESULTS_GET_ALL";
// DYNAMIC
export const GETTER_JOBS_DYNAMIC_GET_CURRENT_PAGE_CONTENT = "dynamic/GETTER_JOBS_DYNAMIC_GET_CURRENT_PAGE_CONTENT";
export const GETTER_JOBS_DYNAMIC_GET_CURRENT_PAGE_METADATA = "dynamic/GETTER_JOBS_DYNAMIC_GET_CURRENT_PAGE_METADATA";
// DETAIL
export const GETTER_JOBS_DETAIL_GET_CURRENT_OFFER = "detail/GETTER_JOBS_DETAIL_GET_CURRENT_OFFER";
export const GETTER_JOBS_DETAIL_GET_CURRENT_ETAB = "detail/GETTER_JOBS_DETAIL_GET_CURRENT_ETAB";
export const GETTER_JOBS_DETAIL_GET_SEO = "detail/GETTER_JOBS_DETAIL_GET_SEO";
export const GETTER_JOBS_DETAIL_GET_SIMILAR_OFFERS = "detail/GETTER_JOBS_DETAIL_GET_SIMILAR_OFFERS";
export const GETTER_JOBS_DETAIL_GET_ETAB_OFFERS = "detail/GETTER_JOBS_DETAIL_GET_ETAB_OFFERS";
// SEO
export const GETTER_JOBS_GET_CURRENT_METADATA = "homepage/GETTER_JOBS_GET_CURRENT_METADATA";
