import { JobEtabDetailModel, JobOfferDetailModel, JobsSimilarOffersModel, JobsEtabOffersModel } from "@core/models";

import { JobsService } from "@core/services/jobs";
import {
  ACTION_JOBS_DETAIL_GET_OFFER_DETAIL,
  ACTION_JOBS_DETAIL_GET_ETAB_DETAIL,
  ACTION_JOBS_DETAIL_GET_SIMILAR_OFFERS,
  ACTION_JOBS_DETAIL_GET_ETAB_OFFERS,
  GETTER_JOBS_DETAIL_GET_CURRENT_ETAB,
  GETTER_JOBS_DETAIL_GET_CURRENT_OFFER,
  GETTER_JOBS_DETAIL_GET_SIMILAR_OFFERS,
  GETTER_JOBS_DETAIL_GET_ETAB_OFFERS,
  MUTATION_JOBS_DETAIL_SET_OFFER_DETAIL,
  MUTATION_JOBS_DETAIL_SET_ETAB_DETAIL,
  MUTATION_JOBS_DETAIL_GET_SIMILAR_OFFERS,
  MUTATION_JOBS_DETAIL_GET_ETAB_OFFERS,
} from "@jobs/store/types";

function initialState() {
  return {
    currentOffer: {},
    currentEtab: {},
    similarOffers: {},
    etabOffers: {},
  };
}

export const state = {
  initialState,
  ...initialState(),
};

export const actions = {
  async [ACTION_JOBS_DETAIL_GET_OFFER_DETAIL]({ commit }, id) {
    const result = await JobsService.getOfferDetail(id);

    commit(MUTATION_JOBS_DETAIL_SET_OFFER_DETAIL, result);
  },
  async [ACTION_JOBS_DETAIL_GET_ETAB_DETAIL]({ commit }, slug) {
    const result = await JobsService.getEtablissementDetailById(slug);

    commit(MUTATION_JOBS_DETAIL_SET_ETAB_DETAIL, result);
  },
  async [ACTION_JOBS_DETAIL_GET_SIMILAR_OFFERS]({ commit }, slug) {
    const result = await JobsService.getSimilarOffers(slug);
    commit(MUTATION_JOBS_DETAIL_GET_SIMILAR_OFFERS, result);
  },
  async [ACTION_JOBS_DETAIL_GET_ETAB_OFFERS]({ commit }, slug) {
    const result = await JobsService.searchEtabJobs(slug);
    commit(MUTATION_JOBS_DETAIL_GET_ETAB_OFFERS, result);
  },
};

export const mutations = {
  [MUTATION_JOBS_DETAIL_SET_OFFER_DETAIL](_state, result) {
    _state.currentOffer = result ?? {};
  },
  [MUTATION_JOBS_DETAIL_SET_ETAB_DETAIL](_state, result) {
    _state.currentEtab = result ?? {};
  },
  [MUTATION_JOBS_DETAIL_GET_SIMILAR_OFFERS](_state, result) {
    _state.similarOffers = result ?? {};
  },
  [MUTATION_JOBS_DETAIL_GET_ETAB_OFFERS](_state, result) {
    _state.etabOffers = result ?? {};
  },
};

export const getters = {
  [GETTER_JOBS_DETAIL_GET_CURRENT_OFFER]: (_state) => new JobOfferDetailModel(_state.currentOffer),
  [GETTER_JOBS_DETAIL_GET_CURRENT_ETAB]: (_state) => new JobEtabDetailModel(_state.currentEtab),
  [GETTER_JOBS_DETAIL_GET_SIMILAR_OFFERS]: (_state) => {
    const similarsOffers = new JobsSimilarOffersModel(_state.similarOffers);
    return similarsOffers.getSimilarOffersList();
  },
  [GETTER_JOBS_DETAIL_GET_ETAB_OFFERS]: (_state) => {
    const etabOffers = new JobsEtabOffersModel(_state.etabOffers);
    return etabOffers.getEtabOffersList();
  },
};
export default function createModule() {
  return {
    namespaced: false,
    state: () => ({ ...initialState() }),
    actions,
    mutations,
    getters,
  };
}
