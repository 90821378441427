import { ETAB } from "@core/constants";

export const ISignup = Object.freeze({
  address: "adresse",
  categoryId: "id_categorie",
  city: "ville",
  description: "presentation",
  email: "email",
  etabName: "nom_etab",
  facebook: "facebook",
  familyId: "id_family",
  firstname: "prenom",
  img: "img",
  isGroup: "is_group",
  isLarge: "is_large",
  lastname: "nom",
  metro: "metro",
  originInscription: "origin_inscription",
  password: "password",
  phone: "telephone",
  postalCode: "code_postal",
  seats: "couverts",
  segment: "segment",
  website: "web",
});

export class SignupModel {
  constructor(data = {}) {
    this[ISignup.address] = data[ISignup.address];
    this[ISignup.categoryId] = data[ISignup.categoryId];
    this[ISignup.city] = data[ISignup.city];
    this[ISignup.description] = data[ISignup.description];
    this[ISignup.email] = data[ISignup.email];
    this[ISignup.etabName] = data[ISignup.etabName];
    this[ISignup.facebook] = data[ISignup.facebook];
    this[ISignup.familyId] = data[ISignup.familyId];
    this[ISignup.firstname] = data[ISignup.firstname];
    this[ISignup.img] = data[ISignup.img];
    this[ISignup.isGroup] = data[ISignup.isGroup];
    this[ISignup.isLarge] = data[ISignup.isLarge];
    this[ISignup.lastname] = data[ISignup.lastname];
    this[ISignup.originInscription] = data[ISignup.originInscription];
    this[ISignup.metro] = data[ISignup.metro];
    this[ISignup.password] = data[ISignup.password];
    this[ISignup.phone] = data[ISignup.phone];
    this[ISignup.postalCode] = data[ISignup.postalCode];
    this[ISignup.seats] = data[ISignup.seats];
    this[ISignup.segment] = data[ISignup.segment];
    this[ISignup.website] = data[ISignup.website];
  }

  getEmail() {
    return this[ISignup.email];
  }

  hasEmail() {
    return this.getEmail() !== undefined;
  }

  getSegment() {
    return this[ISignup.segment];
  }

  isCHR() {
    return this.getSegment() === ETAB.SEGMENT.CHR;
  }

  isCaterer() {
    return this.getSegment() === ETAB.SEGMENT.CATERER;
  }

  isLargeAccount() {
    return this.getSegment() === ETAB.SEGMENT.GRAND_COMPTE;
  }

  hasOriginInscription() {
    return this[ISignup.originInscription] !== undefined;
  }
}
