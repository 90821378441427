import { JavascriptHelpers } from "@core/helpers";
import { DEMAND_TYPES } from "@core/constants";

export const IReview = Object.freeze({
  comment: "comment",
  demandId: "id",
  demandType: "type",
  end: "fin",
  extraId: "id_extra",
  firstname: "prenom",
  lastname: "nom",
  note: "note",
  photo: "photo",
  qualification: "qualification",
  recruited: "recruited",
  start: "debut",
});

export class ReviewModel {
  constructor(data = {}) {
    this[IReview.comment] = data[IReview.comment];
    this[IReview.demandId] = data[IReview.demandId];
    this[IReview.demandType] = data[IReview.demandType];
    this[IReview.end] = data[IReview.end];
    this[IReview.extraId] = data[IReview.extraId];
    this[IReview.firstname] = data[IReview.firstname];
    this[IReview.lastname] = data[IReview.lastname];
    this[IReview.note] = data[IReview.note];
    this[IReview.photo] = data[IReview.photo];
    this[IReview.qualification] = data[IReview.qualification];
    this[IReview.recruited] = data[IReview.recruited];
    this[IReview.start] = data[IReview.start];
  }

  getUniqueId() {
    return String(this.getDemandId()) + String(this.getExtraId());
  }

  getDemandId() {
    return this[IReview.demandId];
  }

  getQualification() {
    return this[IReview.qualification];
  }

  getDemandType() {
    return this[IReview.demandType] || "";
  }

  getDemandTypeDisplay() {
    if (this.isSeason()) {
      return JavascriptHelpers.capitalizeFirstLetter(DEMAND_TYPES.SEASON);
    }
    return this.getDemandType().toUpperCase();
  }

  getNote() {
    return this[IReview.note];
  }

  getComment() {
    return this[IReview.comment] || "";
  }

  isMER() {
    return this.getDemandType().toLowerCase() === DEMAND_TYPES.MER.toLowerCase();
  }

  isAE() {
    return this.getDemandType().toLowerCase() === DEMAND_TYPES.AE.toLowerCase();
  }

  isCDI() {
    return this.getDemandType().toLowerCase() === DEMAND_TYPES.CDI.toLowerCase();
  }

  isSeason() {
    return this.getDemandType().toLowerCase() === DEMAND_TYPES.SEASON.toLowerCase();
  }

  setComment(comment) {
    this[IReview.comment] = comment;
  }

  setNote(note) {
    this[IReview.note] = note;
  }
}
