import { DatesHelpers } from "@core/helpers";
import { BillableShiftModel } from "@core/models/back-office/billable-shifts/billable-shifts.model";
import { BillingSessionAmountModel } from "./billing-session-amount.model";

export const IBillingSession = Object.freeze({
  id: "id",
  comment: "comment",
  createdDate: "created_at",
  billingDate: "billing_date",
  paymentDate: "payment_date",
  extra: "is_extra",
  etab: "is_etab",
  userId: "user_id",
  amountEtab: "amount_etab",
  amountExtra: "amount_extra",
  shifts: "billable_shifts",
  shiftsTotal: "billable_shifts_total",
});

export class BillingSessionModel {
  constructor(data = {}) {
    this[IBillingSession.id] = data[IBillingSession.id];
    this[IBillingSession.userId] = data[IBillingSession.userId];
    this[IBillingSession.comment] = data[IBillingSession.comment];
    this[IBillingSession.billingDate] = data[IBillingSession.billingDate];
    this[IBillingSession.paymentDate] = data[IBillingSession.paymentDate];
    this[IBillingSession.createdDate] = data[IBillingSession.createdDate];
    this[IBillingSession.extra] = data[IBillingSession.extra];
    this[IBillingSession.etab] = data[IBillingSession.etab];
    this[IBillingSession.amountEtab] = data[IBillingSession.amountEtab];
    this[IBillingSession.amountExtra] = data[IBillingSession.amountExtra];
    this[IBillingSession.shifts] = data[IBillingSession.shifts] || [];
    this[IBillingSession.shiftsTotal] = data[IBillingSession.shiftsTotal];
  }

  getId() {
    return this[IBillingSession.id];
  }

  getComment() {
    return this[IBillingSession.comment] || "";
  }

  getBillingDate() {
    return DatesHelpers.getDayjs(this[IBillingSession.billingDate]);
  }

  getFormattedDate(format = "L") {
    return this.getBillingDate().format(format);
  }

  getPaymentDate() {
    return this[IBillingSession.paymentDate] && DatesHelpers.getDayjs(this[IBillingSession.paymentDate]);
  }

  getFormattedPaymentDate(format = "L") {
    return this.getPaymentDate()?.format(format);
  }

  isTypeEtab() {
    return this[IBillingSession.etab] === true;
  }

  isTypeExtra() {
    return this[IBillingSession.extra] === true;
  }

  isTypeBoth() {
    return this.isTypeEtab() && this.isTypeExtra();
  }

  getAmountEtab() {
    return new BillingSessionAmountModel(this[IBillingSession.amountEtab]);
  }

  getAmountExtra() {
    return new BillingSessionAmountModel(this[IBillingSession.amountExtra]);
  }

  getShifts() {
    return this[IBillingSession.shifts].map((s) => new BillableShiftModel(s));
  }
}
