import { JobOfferModel } from "./jobs-offer.model";

export const IJobsOfferListItem = Object.freeze({
  url: "url",
});

export class JobsOfferListItem extends JobOfferModel {
  constructor(data = {}) {
    super(data);
    this[IJobsOfferListItem.url] = data[IJobsOfferListItem.url];
  }

  getUrl() {
    return this[IJobsOfferListItem.url];
  }
}
