export const IBasicOption = Object.freeze({
  code: "code",
  label: "label",
  id: "id",
  description: "description",
  name: "name",
});

export class BasicOptionModel {
  constructor(data = {}) {
    this[IBasicOption.code] = data[IBasicOption.code];
    this[IBasicOption.name] = data[IBasicOption.name] || data[IBasicOption.label];
    this[IBasicOption.id] = data[IBasicOption.id];
    this[IBasicOption.description] = data[IBasicOption.description];
  }

  getCode() {
    return this[IBasicOption.code];
  }

  getId() {
    return this[IBasicOption.id];
  }

  getLabel() {
    return this[IBasicOption.name];
  }

  getName() {
    return this[IBasicOption.name];
  }

  getDescription() {
    return this[IBasicOption.description];
  }
}
