export const ICreationStepDates = Object.freeze({
  startDate: "start",
  endDate: "end",
});

export class CreationStepDatesModel {
  constructor(data = {}) {
    this[ICreationStepDates.startDate] = data[ICreationStepDates.startDate];
    this[ICreationStepDates.endDate] = data[ICreationStepDates.endDate];
  }

  getStartDate() {
    return this[ICreationStepDates.startDate];
  }

  getEndDate() {
    return this[ICreationStepDates.endDate];
  }
}
