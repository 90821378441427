export const ICandidateAeFolderFile = Object.freeze({
  identityDocumentType: "doc_type",
  filename: "filename",
});

export class CandidateAeFolderFileModel {
  constructor(data = {}) {
    this[ICandidateAeFolderFile.identityDocumentType] = data[ICandidateAeFolderFile.identityDocumentType];
    this[ICandidateAeFolderFile.filename] = data[ICandidateAeFolderFile.filename];
  }

  getFileName() {
    return this[ICandidateAeFolderFile.filename];
  }

  getDocumentType() {
    return this[ICandidateAeFolderFile.identityDocumentType];
  }
}
