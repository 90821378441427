// HOME
export const ACTION_JOBS_GET_POPULAR_SEARCHES = "home/ACTION_JOBS_GET_POPULAR_SEARCHES";
export const ACTION_JOBS_GET_LOCALIZATION_SEARCHES = "home/ACTION_JOBS_GET_LOCALIZATION_SEARCHES";
export const ACTION_JOBS_GET_DYNAMICS_PAGES = "home/ACTION_JOBS_GET_DYNAMICS_PAGES";
export const ACTION_JOBS_GET_JOB_OFFERS = "home/ACTION_JOBS_GET_JOB_OFFERS";

// QUALIFICATIONS
export const ACTION_JOBS_QUALIFICATIONS_GET_ALL = "search/ACTION_JOBS_QUALIFICATIONS_GET_ALL";
export const ACTION_JOBS_CONTRACTS_GET_ALL = "search/ACTION_JOBS_CONTRACTS_GET_ALL";
export const ACTION_JOBS_SEARCH_ACTION = "search/ACTION_JOBS_SEARCH_ACTION";
export const ACTION_JOBS_RESET_SEARCH = "search/ACTION_JOBS_RESET_SEARCH";
// DYNAMIC
export const ACTION_JOBS_DYNAMIC_GET_CONTENT_BY_ID = "dynamic/ACTION_JOBS_DYNAMIC_GET_CONTENT_BY_ID";
// DETAIL
export const ACTION_JOBS_DETAIL_GET_OFFER_DETAIL = "detail/ACTION_JOBS_DETAIL_GET_OFFER_DETAIL";
export const ACTION_JOBS_DETAIL_GET_ETAB_DETAIL = "detail/ACTION_JOBS_DETAIL_GET_ETAB_DETAIL";
export const ACTION_JOBS_DETAIL_GET_SIMILAR_OFFERS = "detail/ACTION_JOBS_DETAIL_GET_SIMILAR_OFFERS";
export const ACTION_JOBS_DETAIL_GET_ETAB_OFFERS = "detail/ACTION_JOBS_DETAIL_GET_ETAB_OFFERS";
// SEO
export const ACTION_JOBS_GET_METADATA_BY_ROUTENAME = "metadata/ACTION_JOBS_GET_METADATA_BY_ROUTENAME";
// STORE
export const ACTION_RESET_STORE = "store/ACTION_RESET_STORE";
