export const ICandidateOfferAddress = Object.freeze({
  street: "street",
  postalCode: "zipcode",
  city: "city",
});

export class CandidateOfferAddressModel {
  constructor(data = {}) {
    this[ICandidateOfferAddress.street] = data[ICandidateOfferAddress.street];
    this[ICandidateOfferAddress.postalCode] = data[ICandidateOfferAddress.postalCode];
    this[ICandidateOfferAddress.city] = data[ICandidateOfferAddress.city];
  }

  getStreet() {
    return this[ICandidateOfferAddress.street];
  }

  getPostalCode() {
    return this[ICandidateOfferAddress.postalCode];
  }

  getCity() {
    return this[ICandidateOfferAddress.city];
  }

  getFormattedAdress() {
    return `${this.getStreet()} ${this.getPostalCode()} ${this.getCity()}`;
  }
}
