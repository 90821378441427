export const ICandidateLocationFilter = Object.freeze({
  city: "city",
  zipcode: "zipcode",
  department: "department",
});

export class LocationOptionModel {
  constructor(data = {}) {
    this[ICandidateLocationFilter.city] = data[ICandidateLocationFilter.city];
    this[ICandidateLocationFilter.zipcode] = data[ICandidateLocationFilter.zipcode];
    this[ICandidateLocationFilter.department] = data[ICandidateLocationFilter.department];
  }

  getLabel() {
    return `${this[ICandidateLocationFilter.city]} (${this[ICandidateLocationFilter.zipcode]})`;
  }

  getCity() {
    return this[ICandidateLocationFilter.city];
  }

  getCode() {
    return this[ICandidateLocationFilter.zipcode];
  }

  getDepartment() {
    return this[ICandidateLocationFilter.department];
  }
}
