export const IBoAeFolderDocument = Object.freeze({
  identityDocumentType: "doc_type",
  filename: "remote_filename",
  uploadedDate: "upload_date",
  archivedDate: "archived_date",
  archivedBy: "archived_by",
  expirationDate: "expire_date",
  alertCode: "expire_code",
});

export class BoAeFolderDocumentModel {
  constructor(data = {}) {
    this[IBoAeFolderDocument.identityDocumentType] = data[IBoAeFolderDocument.identityDocumentType];
    this[IBoAeFolderDocument.filename] = data[IBoAeFolderDocument.filename];
    this[IBoAeFolderDocument.uploadedDate] = data[IBoAeFolderDocument.uploadedDate];
    this[IBoAeFolderDocument.expirationDate] = data[IBoAeFolderDocument.expirationDate];
    this[IBoAeFolderDocument.archivedDate] = data[IBoAeFolderDocument.archivedDate];
    this[IBoAeFolderDocument.archivedBy] = data[IBoAeFolderDocument.archivedBy];
    this[IBoAeFolderDocument.alertCode] = data[IBoAeFolderDocument.alertCode];
  }

  getFilename() {
    return this[IBoAeFolderDocument.filename];
  }

  getArchivedDate() {
    return this[IBoAeFolderDocument.archivedDate];
  }

  getArchivedBy() {
    return this[IBoAeFolderDocument.archivedBy];
  }

  getAlertCode() {
    return this[IBoAeFolderDocument.alertCode];
  }

  getExpirationDate() {
    return this[IBoAeFolderDocument.expirationDate];
  }

  getDocumentType() {
    return this[IBoAeFolderDocument.identityDocumentType] || "";
  }

  setDocumentType(identityDocumentType) {
    this[IBoAeFolderDocument.identityDocumentType] = identityDocumentType;
  }

  isArchivedDocument() {
    return this.getArchivedDate() && this.getArchivedBy();
  }
}
