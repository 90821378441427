export const IBillableShiftsFrequency = Object.freeze({
  code: "code",
  label: "label",
});

export class BillableShiftsFrequencyModel {
  constructor(data = {}) {
    this[IBillableShiftsFrequency.code] = data[IBillableShiftsFrequency.code];
    this[IBillableShiftsFrequency.label] = data[IBillableShiftsFrequency.label];
  }

  getCode() {
    return this[IBillableShiftsFrequency.code];
  }

  getLabel() {
    return this[IBillableShiftsFrequency.label];
  }
}
