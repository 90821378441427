// HOME
export const MUTATION_JOBS_GET_POPULAR_SEARCHES = "home/MUTATION_JOBS_GET_POPULAR_SEARCHES";
export const MUTATION_JOBS_GET_LOCALIZATION_SEARCHES = "home/MUTATION_JOBS_GET_LOCALIZATION_SEARCHES";
export const MUTATION_JOBS_GET_DYNAMICS_PAGES = "home/MUTATION_JOBS_GET_DYNAMICS_PAGES";
export const MUTATION_JOBS_GET_JOB_OFFERS = "home/MUTATION_JOBS_GET_JOB_OFFERS";

// QUALIFICATIONS
export const MUTATION_JOBS_QUALIFICATIONS_SET_ALL = "search/MUTATION_JOBS_QUALIFICATIONS_SET_ALL";
export const MUTATION_JOBS_CONTRACTS_SET_ALL = "search/MUTATION_JOBS_CONTRACTS_SET_ALL";
export const MUTATION_JOBS_SET_SEARCH_RESULTS = "search/MUTATION_JOBS_SET_SEARCH_RESULTS";
// DYNAMIC
export const MUTATION_JOBS_DYNAMIC_SET_CURRENT_PAGE_CONTENT = "dynamic/MUTATION_JOBS_DYNAMIC_SET_CURRENT_PAGE_CONTENT";
export const MUTATION_JOBS_DYNAMIC_SET_CURRENT_PAGE_METADATA = "dynamic/MUTATION_JOBS_DYNAMIC_SET_CURRENT_PAGE_METADATA";
// DETAIL
export const MUTATION_JOBS_DETAIL_SET_OFFER_DETAIL = "detail/MUTATION_JOBS_DETAIL_SET_OFFER_DETAIL";
export const MUTATION_JOBS_DETAIL_SET_ETAB_DETAIL = "detail/MUTATION_JOBS_DETAIL_SET_ETAB_DETAIL";
export const MUTATION_JOBS_DETAIL_GET_SIMILAR_OFFERS = "detail/MUTATION_JOBS_DETAIL_GET_SIMILAR_OFFERS";
export const MUTATION_JOBS_DETAIL_GET_ETAB_OFFERS = "detail/MUTATION_JOBS_DETAIL_GET_ETAB_OFFERS";

export const MUTATION_JOBS_DETAIL_SET_SEO = "detail/MUTATION_JOBS_DETAIL_SET_SEO";
// SEO
export const MUTATION_JOBS_CURRENT_METADATA = "metadata/MUTATION_JOBS_CURRENT_METADATA";
export const MUTATION_JOBS_SET_DEFAULT_METADATA = "metadata/MUTATION_JOBS_SET_DEFAULT_METADAT";
