export const ICandidateOfferOutfit = Object.freeze({
  img: "img",
  outfitLabel: "outfit_label",
  colorLabel: "label_color",
  colorCode: "code",
});

export class CandidateOfferOutfitModel {
  constructor(data = {}) {
    this[ICandidateOfferOutfit.img] = data[ICandidateOfferOutfit.img];
    this[ICandidateOfferOutfit.outfitLabel] = data[ICandidateOfferOutfit.outfitLabel];
    this[ICandidateOfferOutfit.colorLabel] = data[ICandidateOfferOutfit.colorLabel];
    this[ICandidateOfferOutfit.colorCode] = data[ICandidateOfferOutfit.colorCode];
  }

  getImg() {
    return this[ICandidateOfferOutfit.img];
  }

  getOutfitLabel() {
    return this[ICandidateOfferOutfit.outfitLabel] || "";
  }

  getColorLabel() {
    return this[ICandidateOfferOutfit.colorLabel] || "";
  }

  getColorCode() {
    return this[ICandidateOfferOutfit.colorCode] || "";
  }
}
