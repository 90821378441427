import { Preferences } from "@capacitor/preferences";

/**
 * Wrapper around Capacitor plugin storage
 * https://capacitor.ionicframework.com/docs/apis/storage/
 */
export class Storage {
  /**
   * Returns the Capacitor plugin instance
   * @readonly
   * @static
   */
  static get plugin() {
    return Preferences;
  }
}
