import { Logger } from "@core/helpers/logger.helpers";
import { JOBS_ROUTES } from "@jobs/config";
import { RouterView } from "vue-router";

export default function ({ store, ssrContext }) {
  return [
    {
      path: "/",
      component: RouterView,
      children: [
        {
          path: JOBS_ROUTES.public.vendeurSpecialise.path,
          name: JOBS_ROUTES.public.vendeurSpecialise.name,
          component: () => import("../pages/landing/VendeursSpecialises.page.vue"),
        },
        {
          path: JOBS_ROUTES.public.hidfLyon.path,
          name: JOBS_ROUTES.public.hidfLyon.name,
          component: () => import("../pages/landing/HidfLyon.page.vue"),
        },
        // #region PUBLIC
        {
          path: JOBS_ROUTES.public.root.path,
          name: JOBS_ROUTES.public.root.name,
          component: () => import("../pages/public/Public.root.vue"),
          redirect: { name: JOBS_ROUTES.public.home.name },
          children: [
            {
              path: JOBS_ROUTES.public.home.path,
              name: JOBS_ROUTES.public.home.name,
              component: () => import("../pages/public/home/Home.page.vue"),
            },
            {
              path: JOBS_ROUTES.public.search.path,
              name: JOBS_ROUTES.public.search.name,
              component: () => import("../pages/public/search/Search.page.vue"),
            },
            {
              path: JOBS_ROUTES.public.etablissement.path,
              name: JOBS_ROUTES.public.etablissement.name,
              component: () => import("../pages/public/etab/JobsEtab.page.vue"),
            },
            {
              path: JOBS_ROUTES.public.offer.root.path,
              name: JOBS_ROUTES.public.offer.root.name,
              component: RouterView,
              // we have a offer root in order to manage redirection in the absence of a slug
              redirect: { name: JOBS_ROUTES.public.search.name },
              children: [
                {
                  path: JOBS_ROUTES.public.offer.detail.path,
                  name: JOBS_ROUTES.public.offer.detail.name,
                  component: () => import("../pages/public/job-offer/JobOfferDetail.page.vue"),
                  async beforeEnter(to, from, next) {
                    try {
                      next();
                    } catch (error) {
                      Logger.error("Jobs - couldn't get offer page", error);
                      next({ name: JOBS_ROUTES.public.notFound.name });
                      // fail silently in order to keep SSR alive
                    }
                  },
                },
              ],
            },
            {
              // soft 500 for explicit redirections
              path: JOBS_ROUTES.public.error500.path,
              name: JOBS_ROUTES.public.error500.name,
              component: () => import("../pages/Error500.vue"),
            },
            {
              // soft 404 for explicit redirections
              path: JOBS_ROUTES.public.notFound.path,
              name: JOBS_ROUTES.public.notFound.name,
              component: () => import("../pages/Error404.vue"),
            },
            // ATTENTION //
            // setup dynamic routing second to last
            // in order to avoid url conflict with existing routes
            {
              path: JOBS_ROUTES.public.dynamic.path,
              name: JOBS_ROUTES.public.dynamic.name,
              component: () => import("../pages/public/dynamic/Dynamic.page.vue"),
            },
            // ATTENTION //
            // setup the navigation 404 fallback AFTER the dynamic routes
            // in order to catch all the other uri
            {
              path: "*",
              component: () => import("../pages/Error404.vue"),
            },
            {
              path: "/:catchAll(.*)",
              component: () => import("../pages/Error404.vue"),
            },
          ],
        },
        // #endregion PUBLIC
      ],
    },
  ];
}
