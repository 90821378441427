import sanitizeHtml from "sanitize-html";

export class SanitationHelper {
  static sanitize(dirty) {
    return sanitizeHtml(dirty, {
      allowedTags: sanitizeHtml.defaults.allowedTags.concat([
        "u",
        "sup",
        "span",
        "img",
        "h1",
        "h2",
        "h3",
        "h4",
        "h5",
        "h6",
        "a",
        "ul",
        "li",
        "ol",
        "code",
        "blockquote",
        "nl",
        "b",
        "i",
        "strong",
        "em",
        "strike",
        "hr",
        "br",
        "div",
        "table",
        "thead",
        "caption",
        "tbody",
        "tr",
        "th",
        "td",
        "pre",
      ]),
      allowedAttributes: {
        "*": [
          "border",
          "cellspacing",
          "cellpadding",
          "width",
          "height",
          "valign",
          "href",
          "align",
          "class",
          "src",
          "alt",
          "style",
          "target",
          "center",
          "data-*",
          "bgcolor",
        ],
      },
      allowedSchemes: ["http", "https", "mailto"],
      selfClosing: ["img", "br", "hr", "area", "base", "basefont", "input", "link", "meta"],
    });
  }

  /**
   * Will sanitize string adding capital letter on first letter if necessary
   * @static
   * @param {String} city
   */
  static sanitizeCityName(city) {
    const separators = [",", " ", "'", "-"];
    const wordsToSkip = ["le", "la", "les", "lès", "sur", "aux", "au", "d", "du", "de", "en", "et", "l"];
    const cityToLowerCase = city.toLowerCase().trim();
    let formattedString = "";
    // if city is not an empty string ...
    if (cityToLowerCase.length) {
      // ... split string into array of words using our separators
      const splittedCityToLowerCase = cityToLowerCase.split(/[, '-]+/);
      splittedCityToLowerCase.forEach((word, index) => {
        const shouldWordBeUppercased = !wordsToSkip.includes(word) || index === 0;
        // Capitalize word if necessary ...
        formattedString = shouldWordBeUppercased ? formattedString + word[0].toUpperCase() + word.slice(1) : formattedString + word;

        const isSeperatorCharacter = separators.includes(cityToLowerCase.charAt(formattedString.length));
        // and add separator character
        if (isSeperatorCharacter) {
          formattedString += cityToLowerCase.charAt(formattedString.length);
        }
      });
    }

    return formattedString;
  }

  /**
   * Will sanitize string adding capital letter on first letter
   * @static
   * @param {String} label
   */
  static capitalizeFirstLetter(label) {
    const stringToLowerCase = label.toLowerCase().trim();
    if (stringToLowerCase.length > 1) {
      return stringToLowerCase[0].toUpperCase() + stringToLowerCase.slice(1);
    }
    return label;
  }

  /**
   * Escape html
   * @static
   * @param {string} string
   * @returns string
   */
  static escapeHtml(string) {
    return string.replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;").replace(/"/g, "&quot;").replace(/'/g, "&#039;");
  }
}
