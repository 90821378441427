export const IJobsSearchCriterias = Object.freeze({
  qualifications: "qualifications",
  contracts: "contracts",
  postalCodes: "postal_code",
});

export class JobsSearchCriteriasModel {
  constructor(data = {}) {
    this[IJobsSearchCriterias.qualifications] = data[IJobsSearchCriterias.qualifications];
    this[IJobsSearchCriterias.contracts] = data[IJobsSearchCriterias.contracts];
    this[IJobsSearchCriterias.postalCodes] = data[IJobsSearchCriterias.postalCodes];
  }

  getQualificationsIds() {
    return this[IJobsSearchCriterias.qualifications] || [];
  }

  getContractsCodes() {
    return this[IJobsSearchCriterias.contracts] || [];
  }

  getPostalCodes() {
    return this[IJobsSearchCriterias.postalCodes] || [];
  }
}
