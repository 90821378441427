import { PasswordHelpers } from "@core/helpers";

export const ICandidateCredentials = Object.freeze({
  email: "email",
  password: "password",
  confirmPassword: "confirmPassword",
  code: "code",
  currentPassword: "current_password",
});

export class CandidateCredentialsModel {
  constructor(data = {}) {
    this[ICandidateCredentials.currentPassword] = data[ICandidateCredentials.currentPassword];
    this[ICandidateCredentials.code] = data[ICandidateCredentials.code];
    this[ICandidateCredentials.email] = data[ICandidateCredentials.email];
    this[ICandidateCredentials.password] = data[ICandidateCredentials.password];
    this[ICandidateCredentials.confirmPassword] = data[ICandidateCredentials.confirmPassword];
  }

  getEmail() {
    return this[ICandidateCredentials.email];
  }

  getPassword() {
    return this[ICandidateCredentials.password];
  }

  getCode() {
    return this[ICandidateCredentials.code];
  }

  getCurrentPassword() {
    return this[ICandidateCredentials.currentPassword];
  }

  getConfirmPassword() {
    return this[ICandidateCredentials.confirmPassword];
  }

  passwordHasNumericCaract() {
    return PasswordHelpers.hasNumeric(this.getPassword());
  }

  passwordHasEightCaract() {
    return PasswordHelpers.hasEightCaract(this.getPassword());
  }

  passwordHasMajCaract() {
    return PasswordHelpers.hasMajCaract(this.getPassword());
  }

  passwordHasMinCaract() {
    return PasswordHelpers.hasMinCaract(this.getPassword());
  }

  passwordHasSpecialCaract() {
    return PasswordHelpers.hasSpecialCaract(this.getPassword());
  }
}
