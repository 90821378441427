import { ADMIN_FOLDER_STATUS, ADMIN_FOLDER_DOCUMENTS, ADMIN_FOLDER_ALERT, REFUSAL_REASON_OTHER } from "@core/constants";
import { BoAeFolderDocumentModel } from "./ae-folder-document.model";
import { RefusalReasonModel } from "./refusal-reason.model";

export const IBoAeFolder = Object.freeze({
  status: "status",
  alert: "alert",
  comment: "comment",
  refusalReason: "reason_of_refusal",
  companyName: "company_name",
  isCompany: "is_company",
  vat: "tva",
  iban: "iban",
  siret: "siret",
  swift: "swift",
  aeDocuments: "docs_insee",
  ribDocuments: "docs_rib",
  identityDocuments: "docs_identite",
  identityDocumentsVerso: "docs_identite_verso",
});

export class BoAeFolderModel {
  constructor(data = {}) {
    this[IBoAeFolder.status] = data[IBoAeFolder.status];
    this[IBoAeFolder.alert] = data[IBoAeFolder.alert];
    this[IBoAeFolder.comment] = data[IBoAeFolder.comment];
    this[IBoAeFolder.refusalReason] = data[IBoAeFolder.refusalReason];
    this[IBoAeFolder.companyName] = data[IBoAeFolder.companyName];
    this[IBoAeFolder.isCompany] = data[IBoAeFolder.isCompany];
    this[IBoAeFolder.vat] = data[IBoAeFolder.vat];
    this[IBoAeFolder.iban] = data[IBoAeFolder.iban];
    this[IBoAeFolder.siret] = data[IBoAeFolder.siret];
    this[IBoAeFolder.swift] = data[IBoAeFolder.swift];
    this[IBoAeFolder.aeDocuments] = data[IBoAeFolder.aeDocuments];
    this[IBoAeFolder.identityDocuments] = data[IBoAeFolder.identityDocuments];
    this[IBoAeFolder.ribDocuments] = data[IBoAeFolder.ribDocuments];
    this[IBoAeFolder.identityDocumentsVerso] = data[IBoAeFolder.identityDocumentsVerso];
  }

  getVat() {
    return this[IBoAeFolder.vat] || "";
  }

  getSiret() {
    return this[IBoAeFolder.siret] || "";
  }

  getIban() {
    return this[IBoAeFolder.iban];
  }

  getSwift() {
    return this[IBoAeFolder.swift];
  }

  getCompanyName() {
    return this[IBoAeFolder.companyName] || "";
  }

  isCompany() {
    return this[IBoAeFolder.isCompany] || false;
  }

  getComment() {
    return this[IBoAeFolder.comment] || "";
  }

  getRefusalReason() {
    return new RefusalReasonModel(this[IBoAeFolder.refusalReason]);
  }

  isOtherRefusalReasonSelected() {
    return this.getRefusalReason().getCode() === REFUSAL_REASON_OTHER;
  }

  getStatus() {
    return this[IBoAeFolder.status];
  }

  getAlert() {
    return this[IBoAeFolder.alert];
  }

  getAeDocuments() {
    return (this[IBoAeFolder.aeDocuments] || []).map((document) => new BoAeFolderDocumentModel(document));
  }

  getLatestAeDocument() {
    return this.getAeDocuments()[0];
  }

  getIdentityDocuments() {
    return (this[IBoAeFolder.identityDocuments] || []).map((document) => new BoAeFolderDocumentModel(document));
  }

  getIdentityVersoDocuments() {
    return (this[IBoAeFolder.identityDocumentsVerso] || []).map((document) => new BoAeFolderDocumentModel(document));
  }

  getLatestIdentityDocument() {
    return this.getIdentityDocuments()[0];
  }

  getLatestIdentityVersoDocument() {
    return this.getIdentityVersoDocuments()[0];
  }

  getRibDocuments() {
    return (this[IBoAeFolder.ribDocuments] || []).map((document) => new BoAeFolderDocumentModel(document));
  }

  getLatestRibDocument() {
    return this.getRibDocuments()[0];
  }

  getStatusColor() {
    switch (this.getStatus()) {
      case ADMIN_FOLDER_STATUS.PENDING_INTERNAL_VERIFICATION.code:
        return ADMIN_FOLDER_STATUS.PENDING_INTERNAL_VERIFICATION.color;
      case ADMIN_FOLDER_STATUS.PENDING_INTERNAL_RE_VERIFICATION.code:
        return ADMIN_FOLDER_STATUS.PENDING_INTERNAL_RE_VERIFICATION.color;
      case ADMIN_FOLDER_STATUS.PENDING.code:
        return ADMIN_FOLDER_STATUS.PENDING.color;
      case ADMIN_FOLDER_STATUS.VERIFIED.code:
        return ADMIN_FOLDER_STATUS.VERIFIED.color;
      case ADMIN_FOLDER_STATUS.REFUSED.code:
        return ADMIN_FOLDER_STATUS.REFUSED.color;
      default:
        return ADMIN_FOLDER_STATUS.PENDING_INTERNAL_VERIFICATION.color;
    }
  }

  getAlertColor() {
    switch (this.getAlert()) {
      case ADMIN_FOLDER_ALERT.WILL_EXPIRE_SOON.code:
        return ADMIN_FOLDER_ALERT.WILL_EXPIRE_SOON.color;
      case ADMIN_FOLDER_ALERT.EXPIRED.code:
        return ADMIN_FOLDER_ALERT.EXPIRED.color;
      default:
        return "primary";
    }
  }

  isPendingFolder() {
    return this.getStatus() === ADMIN_FOLDER_STATUS.PENDING.code;
  }

  requiredDocumentsUploaded() {
    const latestDocuments = [];
    if (this.getLatestAeDocument()) {
      latestDocuments.push(this.getLatestAeDocument());
    }
    if (this.getLatestIdentityDocument()) {
      latestDocuments.push(this.getLatestIdentityDocument());
    }
    if (this.getLatestRibDocument()) {
      latestDocuments.push(this.getLatestRibDocument());
    }
    const foundArchivedDocument = latestDocuments.find((document) => !!document.getArchivedDate());
    return latestDocuments.length === 3 && !foundArchivedDocument;
  }
}

export class BoAeFolderEditableModel extends BoAeFolderModel {
  constructor(data = {}) {
    super(data);
    this[ADMIN_FOLDER_DOCUMENTS.IDENTITY] = data[ADMIN_FOLDER_DOCUMENTS.IDENTITY];
    this[ADMIN_FOLDER_DOCUMENTS.AE] = data[ADMIN_FOLDER_DOCUMENTS.AE];
  }

  getIdentityDocument() {
    return this[ADMIN_FOLDER_DOCUMENTS.IDENTITY];
  }

  getAeDocument() {
    return this[ADMIN_FOLDER_DOCUMENTS.AE];
  }
}
