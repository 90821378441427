export const JOBS_ROUTES = {
  /**
   * WARNING
   * Any change on this file should be checked with the server
   * Especially about the canonical urls sent via api/public/metadata/
   * WARNING
   */
  public: {
    root: { path: "", name: "jobs-public" },
    hidfLyon: { path: "recrutement-lyon", name: "hidf-lyon" },
    vendeurSpecialise: { path: "vendeurs-specialises-en-ile-de-france", name: "vendeurs-specialises" },
    dynamic: { path: ":slug", name: "jobs-dynamic-slug" },
    etablissement: { path: "etablissement/:slug", name: "job-etablissement" },
    home: { path: "/", name: "jobs-home" },
    notFound: { path: "404", name: "jobs-404" },
    error500: { path: "500", name: "jobs-500" },
    offer: {
      root: { path: "emploi", name: "job-offer" },
      detail: { path: ":slug", name: "job-offer-detail" },
    },
    search: { path: "recherche", name: "jobs-search" },
  },
};
