import { CANDIDATES_STATUS, PERMANENT_CANDIDATES_STATUS } from "@core/constants";

export const ICandidateDashboard = Object.freeze({
  picture: "picture",
  firstname: "firstname",
  status: "status",
});

export class CandidateDashboardModel {
  constructor(data = {}) {
    this[ICandidateDashboard.picture] = data[ICandidateDashboard.picture];
    this[ICandidateDashboard.firstname] = data[ICandidateDashboard.firstname];
    this[ICandidateDashboard.status] = data[ICandidateDashboard.status];
  }

  getPicture() {
    return this[ICandidateDashboard.picture];
  }

  getFirstname() {
    return this[ICandidateDashboard.firstname];
  }

  getStatus() {
    return this[ICandidateDashboard.status] || "";
  }

  isCancelledStatus() {
    return this.getStatus() === CANDIDATES_STATUS.CANCELED_BY_EXTRA;
  }

  isContactedStatus() {
    return this.getStatus() === PERMANENT_CANDIDATES_STATUS.CONTACTED;
  }

  isAppliedStatus() {
    return this.getStatus() === PERMANENT_CANDIDATES_STATUS.APPLIED;
  }

  isSelectedStatus() {
    return this.getStatus() === CANDIDATES_STATUS.SELECTED;
  }
}
