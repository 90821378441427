import { JobsDynamicPageModel } from "./jobs-dynamic-page.model";

export const IJobsDynamicPages = Object.freeze({
  title: "title",
  description: "description",
  pages: "thematic_pages",
});

export class JobsDynamicPagesModel {
  constructor(data = {}) {
    this[IJobsDynamicPages.title] = data[IJobsDynamicPages.title];
    this[IJobsDynamicPages.description] = data[IJobsDynamicPages.description];
    this[IJobsDynamicPages.pages] = data[IJobsDynamicPages.pages];
  }

  getTitle() {
    return this[IJobsDynamicPages.title];
  }

  getDescription() {
    return this[IJobsDynamicPages.description];
  }

  getPages() {
    return (this[IJobsDynamicPages.pages] || []).map((page) => new JobsDynamicPageModel(page));
  }
}
