import { RecapStepModel } from "./recap-step.model";

export const ICreationStepRecap = Object.freeze({
  steps: "steps",
  isValid: "is_valid",
  contractType: "contract_type",
});

export class CreationStepRecapModel {
  constructor(data = {}) {
    this[ICreationStepRecap.steps] = data[ICreationStepRecap.steps] || [];
    this[ICreationStepRecap.isValid] = data[ICreationStepRecap.isValid];
    this[ICreationStepRecap.contractType] = data[ICreationStepRecap.contractType];
  }

  getSteps() {
    return this[ICreationStepRecap.steps].map((step) => new RecapStepModel(step));
  }

  isValid() {
    return !!this[ICreationStepRecap.isValid];
  }

  getContractType() {
    return this[ICreationStepRecap.contractType];
  }

  isPermanent() {
    return this.getContractType() === "permanent";
  }

  isTemporary() {
    return this.getContractType() === "temporary";
  }
}
