import { CandidateAccountModel, ICandidateAccount } from "./candidate-account.model";

export class CandidateSignupModel extends CandidateAccountModel {
  constructor(data = {}) {
    super(data);
    this[ICandidateAccount.optoutNewsletter] = data[ICandidateAccount.optoutNewsletter] || false;
    this[ICandidateAccount.phoneNumber] = data[ICandidateAccount.phoneNumber];
  }

  isOptinNewsletter() {
    return this[ICandidateAccount.optoutNewsletter] === false;
  }

  getPhoneNumber() {
    return this[ICandidateAccount.phoneNumber] || "";
  }
}
