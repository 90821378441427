/**
 * THIS FILE IS GENERATED AUTOMATICALLY.
 * DO NOT EDIT.
 *
 * You are probably looking on adding startup/initialization code.
 * Use "quasar new boot <name>" and add it there.
 * One boot file per concern. Then reference the file(s) in quasar.config.js > boot:
 * boot: ['file', ...] // do not add ".js" extension to it.
 *
 * Boot files are your "main.js"
 **/

import lang from 'quasar/lang/fr.js'

import iconSet from 'quasar/icon-set/material-icons.js'



import {QAvatar,QBanner,QBreadcrumbs,QBtn,QBtnToggle,QCard,QCardActions,QCardSection,QCheckbox,QChip,QDialog,QExpansionItem,QIcon,QItem,QItemLabel,QItemSection,QList,QSelect,QSeparator,QStep,QStepper,QStepperNavigation,QSkeleton,QToggle,Ripple,ClosePopup,TouchPan,Notify,Dialog,Cookies,Meta,BottomSheet} from 'quasar'



export default { config: {},lang,iconSet,components: {QAvatar,QBanner,QBreadcrumbs,QBtn,QBtnToggle,QCard,QCardActions,QCardSection,QCheckbox,QChip,QDialog,QExpansionItem,QIcon,QItem,QItemLabel,QItemSection,QList,QSelect,QSeparator,QStep,QStepper,QStepperNavigation,QSkeleton,QToggle},directives: {Ripple,ClosePopup,TouchPan},plugins: {Notify,Dialog,Cookies,Meta,BottomSheet} }

