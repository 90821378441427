import { CandidateOfferOutfitModel } from "./candidate-offer-outfit.model";

export const ICandidateOfferClothing = Object.freeze({
  outfits: "outfits",
  comment: "comment",
  provided: "provided",
});

export class CandidateOfferClothingModel {
  constructor(data = {}) {
    this[ICandidateOfferClothing.outfits] = data[ICandidateOfferClothing.outfits];
    this[ICandidateOfferClothing.comment] = data[ICandidateOfferClothing.comment];
    this[ICandidateOfferClothing.provided] = data[ICandidateOfferClothing.provided];
  }

  getOutfits() {
    return (this[ICandidateOfferClothing.outfits] || []).map((outfit) => new CandidateOfferOutfitModel(outfit));
  }

  getComment() {
    return this[ICandidateOfferClothing.comment] || "";
  }

  isProvided() {
    return this[ICandidateOfferClothing.provided] === true;
  }

  isSpecified() {
    return (
      this.getOutfits().length > 0 || //
      this.getComment().length > 0 ||
      this.isProvided()
    );
  }
}
