export const ICreationStep = Object.freeze({
  data: "data",
  assets: "assets",
  totalSteps: "total_steps",
  currentStep: "current_step",
  stepTitle: "title",
  stepSubtitle: "subtitle",
});

export class CreationStepModel {
  constructor(data = {}) {
    this[ICreationStep.assets] = data[ICreationStep.assets];
    this[ICreationStep.data] = data[ICreationStep.data];
    this[ICreationStep.totalSteps] = data[ICreationStep.totalSteps];
    this[ICreationStep.currentStep] = data[ICreationStep.currentStep];
    this[ICreationStep.stepTitle] = data[ICreationStep.stepTitle];
    this[ICreationStep.stepSubtitle] = data[ICreationStep.stepSubtitle];
  }

  getAssets() {
    return this[ICreationStep.assets];
  }

  getData() {
    return this[ICreationStep.data] instanceof Array ? {} : this[ICreationStep.data];
  }

  getTotalSteps() {
    return this[ICreationStep.totalSteps] || 0;
  }

  getCurrentStep() {
    return this[ICreationStep.currentStep] || 0;
  }

  getStepTitle() {
    return this[ICreationStep.stepTitle];
  }

  getStepSubtitle() {
    return this[ICreationStep.stepSubtitle];
  }
}
