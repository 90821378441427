import { EtabProfileModel } from "@core/models/etab/etab-info/etab-profile.model";

export const IMissionAddress = Object.freeze({
  postalCode: "zipcode",
  street: "street",
  city: "city",
});

export class MissionAddressModel {
  constructor(data = {}) {
    this[IMissionAddress.postalCode] = data[IMissionAddress.postalCode];
    this[IMissionAddress.street] = data[IMissionAddress.street];
    this[IMissionAddress.city] = data[IMissionAddress.city];
  }

  getPostalCode() {
    return this[IMissionAddress.postalCode];
  }

  getStreet() {
    return this[IMissionAddress.street];
  }

  getCity() {
    return this[IMissionAddress.city];
  }

  getFormattedAddress() {
    return `${this[IMissionAddress.street] || ""} ${this[IMissionAddress.postalCode] || ""} ${this[IMissionAddress.city] || ""}`;
  }

  getMissionAddress(value) {
    if (this[IMissionAddress.street]) {
      return this.getFormattedAddress();
    }
    return new EtabProfileModel(value).getFormattedAddress();
  }
}
