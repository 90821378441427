import { Logger } from "@core/helpers/logger.helpers";

let typeformEmbed;

// third party library is not SSR SERVER compatible (usage of window)
// https://github.com/Typeform/embed/tree/main/packages/embed
if (!process.env.SERVER) {
  Logger.log("Typeform - load script @typeform/embed");
  // eslint-disable-next-line global-require
  typeformEmbed = require("@typeform/embed");
  // eslint-disable-next-line global-require
  require("@typeform/embed/build/css/popup.css");
  // eslint-disable-next-line global-require
  require("@core/styles/plugins/typeform.overrides.scss");
}

const defaultOptions = {
  open: "load",
  size: 90,
  autoClose: 3,
  autoResize: true,
  onSubmit() {
    Logger.log("Typeform - form successfully submitted");
  },
  onReady() {
    Logger.log("Typeform - form ready");
  },
  onClose() {
    Logger.log("Typeform - form closed");
  },
};

export const typeformMixins = {
  methods: {
    $_typeformOpenPopup(typeformUrl = "", options = {}) {
      const config = Object.assign({}, defaultOptions, options);

      if (typeformUrl !== "" && typeformEmbed !== undefined) {
        typeformEmbed.createPopup(typeformUrl, config);
      }
    },
  },
};
