import { CardModel } from "./card.model";

export const ICards = Object.freeze({
  cards: "cards",
});
export class CardsCollectionModel {
  constructor(data = {}) {
    this[ICards.cards] = data[ICards.cards];
  }

  getCards() {
    return (this[ICards.cards] || []).map((card) => new CardModel(card));
  }

  getDefaultCard() {
    const defaultCard = this.getCards().find((card) => card.isCardDefault());
    return defaultCard || this.getFirstCard();
  }

  getFirstCard() {
    return this.getCards()[0];
  }

  hasCards() {
    return this.getCards().length > 0;
  }
}
