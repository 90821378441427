export class MetaHelpers {
  static setAutoMeta(metaTags) {
    const metaObj = { meta: {}, link: {} };
    if (!metaTags) {
      return metaObj;
    }
    if (metaTags.title) {
      metaObj.title = metaTags.title;
      metaObj.meta.title = { name: "title", content: metaTags.title };
      metaObj.meta.ogTitle = { property: "og:title", content: metaTags.title };
      metaObj.meta.twitterTitle = { property: "twitter:title", content: metaTags.title };
    }
    if (metaTags.description) {
      metaObj.meta.description = { name: "description", content: metaTags.description };

      metaObj.meta.ogType = { property: "og:type", content: metaTags.type || "website" };
      metaObj.meta.ogDescription = { property: "og:description", content: metaTags.description };

      metaObj.meta.twitterCard = { property: "twitter:card", content: "summary_large_image" };
      metaObj.meta.twitterDescription = { property: "twitter:description", content: metaTags.description };
    }
    if (metaTags.url) {
      metaObj.meta.ogUrl = { property: "og:url", content: metaTags.url };
      metaObj.meta.twitterUrl = { property: "twitter:url", content: metaTags.url };
    }
    if (metaTags.canonical) {
      metaObj.link.canonical = { rel: "canonical", href: metaTags.canonical };
    }
    if (metaTags.image) {
      metaObj.meta.ogImage = { property: "og:image", content: metaTags.image };
      metaObj.meta.twitterImage = { property: "twitter:image", content: metaTags.image };
    }
    // shortcut for noIndex
    if (metaTags.noIndex && !metaTags.robots) {
      metaObj.meta.robots = { name: "robots", content: "noindex, follow" };
    }
    if (metaTags.robots) {
      metaObj.meta.robots = { name: "robots", content: metaTags.robots };
    }

    return metaObj;
  }
}
