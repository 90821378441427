import { DEMAND_TYPES } from "@core/constants";
import { DatesHelpers } from "@core/helpers";
import { BookingExpressCandidateModel } from "@core/models/extra/booking-express-candidate.model";
import { PlanningDayModel } from "./planning-day.model";
import { PlanningModel } from "./planning.model";
import { WeeklyHoursModel } from "./weekly-hours.model";
import { SalaryModel } from "./salary.model";
import { MissionDurationModel } from "./mission-duration.model";
import { ClothingModel } from "./clothing.model";
import { MissionAddressModel } from "./mission-address.model";

export const DEMAND_DEFAULT_EXPIRATION_TIME = 10;

export const ICreationDemand = Object.freeze({
  clothing: "clothing",
  comment: "comment",
  date: "date",
  daysToExpiration: "daysToExpiration",
  familyId: "familyId",
  familyIdSnakeCase: "family_id",
  id: "id",
  missionDuration: "mapping_nb_jours_prix",
  multiExtra: "multiExtra",
  numberOfExtra: "numberOfExtra",
  numberOfExtraSnakeCase: "number_of_extra",
  planning: "planning",
  published: "published",
  qualificationId: "qualificationId",
  qualificationIdSnakeCase: "qualification_id",
  salary: "salary",
  type: "type",
  typicalSchedule: "typicalSchedule",
  weeklyHours: "weeklyHours",
  missionAddress: "address",
  preselectedCandidate: "candidate",
  bookingFavoriteSearch: "search",
  novicesAllowed: "is_open_to_novices",
  duplicatedFrom: "duplicated_from",
  boostPercentage: "percent_boosted_price",
});

export class CreationDemandModel {
  constructor(data = {}) {
    this[ICreationDemand.clothing] = data[ICreationDemand.clothing];
    this[ICreationDemand.comment] = data[ICreationDemand.comment];
    this[ICreationDemand.date] = data[ICreationDemand.date];
    this[ICreationDemand.daysToExpiration] = data[ICreationDemand.daysToExpiration];
    this[ICreationDemand.familyId] = data[ICreationDemand.familyId] || data[ICreationDemand.familyIdSnakeCase];
    this[ICreationDemand.id] = data[ICreationDemand.id];
    this[ICreationDemand.missionDuration] = data[ICreationDemand.missionDuration];
    this[ICreationDemand.multiExtra] = data[ICreationDemand.multiExtra];
    this[ICreationDemand.numberOfExtra] = data[ICreationDemand.numberOfExtra] || data[ICreationDemand.numberOfExtraSnakeCase];
    this[ICreationDemand.planning] = data[ICreationDemand.planning];
    this[ICreationDemand.published] = data[ICreationDemand.published];
    this[ICreationDemand.qualificationId] = data[ICreationDemand.qualificationId] || data[ICreationDemand.qualificationIdSnakeCase];
    this[ICreationDemand.salary] = data[ICreationDemand.salary];
    this[ICreationDemand.type] = data[ICreationDemand.type];
    this[ICreationDemand.typicalSchedule] = data[ICreationDemand.typicalSchedule];
    this[ICreationDemand.weeklyHours] = data[ICreationDemand.weeklyHours];
    this[ICreationDemand.missionAddress] = data[ICreationDemand.missionAddress];
    this[ICreationDemand.preselectedCandidate] = data[ICreationDemand.preselectedCandidate];
    this[ICreationDemand.bookingFavoriteSearch] = data[ICreationDemand.bookingFavoriteSearch];
    this[ICreationDemand.missionAddress] = data[ICreationDemand.missionAddress];
    this[ICreationDemand.novicesAllowed] = data[ICreationDemand.novicesAllowed];
    this[ICreationDemand.duplicatedFrom] = data[ICreationDemand.duplicatedFrom];
  }

  getId() {
    return this[ICreationDemand.id];
  }

  getFamilyId() {
    return this[ICreationDemand.familyId];
  }

  getQualificationId() {
    return this[ICreationDemand.qualificationId];
  }

  getNumberOfExtra() {
    return this[ICreationDemand.numberOfExtra] || 1;
  }

  getDuplicatedFrom() {
    return this[ICreationDemand.duplicatedFrom];
  }

  getDate() {
    return this[ICreationDemand.date];
  }

  getDaysLength() {
    const start = this.getDate().start;
    const end = this.getDate().end;
    return DatesHelpers.numberOfDaysBetweenDates({ start, end });
  }

  getTypicalSchedule() {
    return new PlanningDayModel(this[ICreationDemand.typicalSchedule]);
  }

  getMissionAddress() {
    return new MissionAddressModel(this[ICreationDemand.missionAddress]);
  }

  getType() {
    return this[ICreationDemand.type];
  }

  getPlanning() {
    return new PlanningModel(this[ICreationDemand.planning]);
  }

  getWeeklyHours() {
    return new WeeklyHoursModel(this[ICreationDemand.weeklyHours]);
  }

  getSalary() {
    return new SalaryModel(this[ICreationDemand.salary]);
  }

  isMultiExtra() {
    return this[ICreationDemand.multiExtra] === true;
  }

  getMissionDuration() {
    return new MissionDurationModel(this[ICreationDemand.missionDuration]);
  }

  getComment() {
    return this[ICreationDemand.comment] || "";
  }

  getClothing() {
    return new ClothingModel(this[ICreationDemand.clothing]);
  }

  getFavorite() {
    return this[ICreationDemand.preselectedCandidate];
  }

  setSelectedFavorite(value) {
    this[ICreationDemand.preselectedCandidate] = value ? new BookingExpressCandidateModel(value) : undefined;
  }

  setBookingFavoriteSearch(value) {
    this[ICreationDemand.bookingFavoriteSearch] = value;
  }

  getBookingFavoriteSearch() {
    return this[ICreationDemand.bookingFavoriteSearch];
  }

  resetBookingFavoriteSearch() {
    this[ICreationDemand.bookingFavoriteSearch] = undefined;
  }

  getDaysToExpiration() {
    return this[ICreationDemand.daysToExpiration] || DEMAND_DEFAULT_EXPIRATION_TIME;
  }

  isPublished() {
    return this[ICreationDemand.published] === true;
  }

  isValid() {
    return (
      this.isTypeValid() &&
      this.isQualificationValid() &&
      this.isDateValid() &&
      // we validate planning only if it's specified
      ((!this.isAE() && !this.isPlanningSpecified()) || this.isPlanningValid()) &&
      // for MER, we force the validity of typicalSchedule
      (!this.isMER() || (this.getTypicalSchedule().isSelected() && this.getTypicalSchedule().isValid()))
    );
  }

  isTypeValid() {
    return this.getType() !== undefined;
  }

  isQualificationValid() {
    return this.getQualificationId() !== undefined;
  }

  isDateValid() {
    return !!this.getDate();
  }

  isPlanningSpecified() {
    return this.getPlanning().isSpecified();
  }

  isPlanningValid() {
    return this.getPlanning().isValid();
  }

  hasSelectedFavorite() {
    return this.getFavorite().getId() !== undefined;
  }

  isMissionDurationValid() {
    return this.getMissionDuration().isValid();
  }

  isTypicalScheduleValid() {
    const schedule = this.getTypicalSchedule();
    return schedule.isSelected() && schedule.isValid();
  }

  isSalaryValid() {
    return this.getSalary().isValid();
  }

  isCDI() {
    return this.getType() === DEMAND_TYPES.CDI;
  }

  isSeason() {
    return this.getType() === DEMAND_TYPES.SEASON;
  }

  isAE() {
    return this.getType() === DEMAND_TYPES.AE;
  }

  isInterim() {
    return this.getType() === DEMAND_TYPES.INTERIM;
  }

  isMER() {
    return this.getType() === DEMAND_TYPES.MER;
  }

  isPermanent() {
    return this.isCDI() || this.isSeason();
  }

  isTemporary() {
    return this.isAE() || this.isMER() || this.isInterim();
  }

  setPlanning() {
    const type = this.getType();
    const planning = this.getPlanning();

    const planningModel = new PlanningModel(planning);
    planningModel.setPlanningDays();
    // for CDI and SEASON, the planning is a simple week
    if (!planningModel.isSpecified() && (type === DEMAND_TYPES.CDI || type === DEMAND_TYPES.SEASON)) {
      planningModel.initFromWeek();
    }

    this[ICreationDemand.planning] = planningModel;
  }

  setDateInstance() {
    if (this.isDateValid()) {
      const data = this.getDate();
      if (data && !(data.start || data.end)) {
        this[ICreationDemand.date] = new Date(data);
      } else {
        const { start, end } = data;
        if (start) {
          this[ICreationDemand.date].start = new Date(start);
        }
        if (end) {
          this[ICreationDemand.date].end = new Date(end);
        }
      }
    }
  }

  setPublished() {
    this[ICreationDemand.published] = true;
  }

  isNovicesAllowed() {
    return this[ICreationDemand.novicesAllowed] === true;
  }
}
