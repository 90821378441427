import { DEVICE_OS, MIME_TYPES, NAMESPACE_URI } from "@core/constants";

/**
 * Simple noop function
 */
export const noop = () => {};
export const PHONE_REGEX = /^(?:(?:\+|00)33|0)[1-9](?:[.-]*\d{2}){4}$/;

export class JavascriptHelpers {
  /**
   * Open link
   * @param {String} url
   * @param {*} options
   * @returns {Window} instance of window
   */
  static openUrl(url, ...options) {
    return window.open(url, ...options);
  }

  /**
   * Open link
   * @param {String} blob
   * @returns {Window} instance of window
   */
  static openBlobFile(blob) {
    const fileURL = URL.createObjectURL(blob);
    return window.open(fileURL);
  }

  /**
   *
   * @param {String} base64
   */
  static openBase64File(base64, type) {
    const tabs = window.open();
    const isImg = [MIME_TYPES.PNG, MIME_TYPES.JPEG, MIME_TYPES.JPG];
    const imgStyles = `
      background-color: #222;
      -o-object-fit: contain;
      object-fit: contain;
      width: -webkit-fill-available;
      width: -moz-available;
      width: fill-available;
      height: -webkit-fill-available;
      height: -moz-available;
      height: fill-available;
    `;
    tabs.document.body.style.margin = 0;
    if (type === MIME_TYPES.PDF) {
      tabs.document.body.style.overflow = "hidden";
      tabs.document.body.innerHTML = `<iframe width='100%' height='100%' src='${base64}'></iframe>`;
    }
    if (isImg.includes(type)) {
      tabs.document.body.innerHTML = `
        <img src="${base64}" style="${imgStyles}" />
      `;
    }
  }

  /**
   *
   * Get the value of a parameter from the url provided, undefined if absent
   * @param {Object} { url, parameter }
   * @property {String} url
   * @property {String} parameter
   * @example
   *  // returns "foo"
   *  JavascriptHelpers.getUrlParameter({ url: "https://my-url.com?my_param=foo", parameter: "my_param" });
   * @returns {String} value of the given parameter
   */
  static getUrlParameter({ url, parameter } = {}) {
    if (!url || !parameter) {
      return undefined;
    }

    const urlParameters = {};
    url.replace(/[?&]+([^=&]+)=([^&]*)/gi, (m, key, value) => {
      urlParameters[key] = value;
    });

    return urlParameters[parameter];
  }

  /**
   * Reload the whole document (thus restarting the app)
   * @static
   */
  static reloadWindow() {
    window.location.href = "/";
  }

  /**
   * Capitalize the first letter of the provided string
   * @static
   * @param {String} [string=""]
   * @returns {String}
   */
  static capitalizeFirstLetter(value = "") {
    const string = String(value);
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  /**
   * Prevent an inelegant linebreak before a double punctation sign
   * @static
   * @param {String} [value=""]
   * @returns {String}
   */
  static insertWhitespaceBeforePunctuation(value = "") {
    const string = String(value);
    return string.replace(" :", "\xa0:").replace(" ?", "\xa0?").replace(" !", "\xa0!").replace(" -", "\xa0-");
  }

  /**
   * Add the right article prefix when word starts with aeiouyh
   * @static
   * @param {String} [value=""]
   * @param {String} [shortPrefix="d'"]
   * @param {String} [longPrefix="de "]
   * @returns {String}
   */
  static addCorrectArticlePrefix(value = "", shortPrefix = "d'", longPrefix = "de ") {
    const string = String(value);
    const firstLetter = string.charAt(0).toLocaleLowerCase();

    if (/[aeiouyh]/.test(firstLetter)) {
      return `${shortPrefix}${string}`;
    }
    return `${longPrefix}${string}`;
  }

  /**
   * Lowercase and remove the * from form labels
   * Usefull when we want to display validation error with the same string as the field label
   * @static
   * @param {String} [string=""]
   * @returns {String}
   * @example
   * // returns "nom d'utilisateur"
   * JavascriptHelpers.formatFormLabelValidation("Nom d'utilisateur *")
   */
  static formatFormLabelValidation(value = "") {
    return value.toLocaleLowerCase().replace("*", "").trim();
  }

  /**
   * Display a valid phone number with spaces as XX XX XX XX XX
   * @param {String} phone
   * @returns
   */
  static formatPhoneNumber(phone = "") {
    return String(phone).replace(/(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, "$1 $2 $3 $4 $5");
  }

  /**
   * Validate a string as a french phone number
   * @param {String} number
   * @returns
   */
  static isValidPhoneNumber(number) {
    return PHONE_REGEX.test(number);
  }

  /**
   * Returns true if provided string contains string between carrets
   * @example:
   *  JavascriptHelpers.containsHTML("test") // false
   *  JavascriptHelpers.containsHTML("test --> result") // false
   *  JavascriptHelpers.containsHTML("test <b>result</b>") // true
   * @static
   * @param {string} [string=""]
   * @returns {Boolean}
   */
  static containsHTML(string = "") {
    const regex = /<(.*?)>/;
    return regex.test(string);
  }

  /**
   * Returns the nearest int from provided value
   * @static
   * @param {Number} value
   * @param {Number} roundToNearest
   * @returns {Number} nearest int
   */
  static getRoundedNearest(value, roundToNearest = 15) {
    const float = parseFloat(value);
    const nearest = parseInt(roundToNearest, 10);
    return Math.round(float / nearest) * nearest;
  }

  /**
   * Ensure the provided parameter is a string or return empty quotes
   * @param {Any} str
   */
  static ensureStringOrEmpty(str) {
    if (typeof str !== "string") {
      return "";
    }
    return str;
  }

  /**
   * Detect weither or not a script is present in the document
   * @static
   * @param {*} uri
   * @returns {Boolean}
   */
  static hasScript(uri) {
    return Array.from(document.getElementsByTagName("script")).some((script) => script.src.includes(uri));
  }

  /**
   * Load the provided script with facultative callback
   * @static
   * @param {*} [{ uri, callback }]
   */
  static loadScript({ uri, callback = () => {} } = {}) {
    if (!uri) {
      return;
    }

    const script = document.createElement("script");

    script.async = true;
    script.onload = callback;
    script.src = uri;

    document.body.appendChild(script);
  }

  /**
   * Load script into the head
   * @static
   * @param {*} file
   */
  static loadScriptToHead(file) {
    const head = document.head || document.getElementsByTagName("head")[0];
    const script = document.createElementNS(NAMESPACE_URI, "script");
    script.type = "text/javascript";
    script.textContent = file;
    script.async = true;
    head.appendChild(script);
  }

  /**
   * Returns data formatted for url based on a reference object
   * @example
   * input:
   *  {
   *    data: { single_param: "foo", array_param: ["bar", "baz"], not_relevant_param: "should_disappear" },
   *    reference: { single_param: "single_param", array_param: "array_param"}
   *  }
   * output: "single_param=foo&array_param=bar,baz"
   * @returns {String} urlFilters
   */
  static getUrlFiltersFromReference({ data, reference }) {
    const urlFilters = [];
    Object.values(reference).forEach((objValue) => {
      const value = JavascriptHelpers.getEncodedValueIfDefined(data[objValue]);
      if (value !== undefined) {
        urlFilters.push(`${objValue}=${value}`);
      }
    });
    return urlFilters.join("&");
  }

  /**
   * Returns data formatted for url
   * @example
   * input: { single_param: "foo", array_param: ["bar", "baz"], array_empty_param: [], empty_param: "" }
   * output: "single_param=foo&array_param=bar,baz"
   * @returns {String} urlFilters
   */
  static getUrlFilters(data = {}) {
    const urlFilters = [];
    Object.keys(data).forEach((key) => {
      const value = JavascriptHelpers.getEncodedValueIfDefined(data[key]);
      if (value !== undefined) {
        urlFilters.push(`${key}=${value}`);
      }
    });
    return urlFilters.join("&");
  }

  static getEncodedValueIfDefined(valueToEncode) {
    if (Array.isArray(valueToEncode)) {
      if (valueToEncode.length > 0) {
        return encodeURI(valueToEncode);
      }
    } else if (valueToEncode !== undefined && valueToEncode !== null && valueToEncode !== "") {
      return encodeURI(valueToEncode);
    }
    return undefined;
  }

  /**
   * Determine the mobile operating system.
   * This function returns one of 'iOS', 'Android' or 'unknown'.
   *
   * @returns {String}
   */
  static getMobileOperatingSystem() {
    if (!process.env.SERVER) {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      // Windows Phone must come first because its UA also contains "Android"
      if (/android/i.test(userAgent)) {
        return DEVICE_OS.ANDROID;
      }
      // iOS detection from: http://stackoverflow.com/a/9039885/177710
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return DEVICE_OS.IOS;
      }
      return DEVICE_OS.UNKNOWN;
    }
    // in case of SSR
    return DEVICE_OS.SERVER;
  }

  /**
   * Remove accents from string
   * Taken from: https://stackoverflow.com/questions/990904/remove-accents-diacritics-in-a-string-in-javascript
   * @param {String} value
   * @returns {String}
   */
  static removeDiacritics(value = "") {
    return value.normalize("NFD").replace(/\p{Diacritic}/gu, "");
  }

  static openGoogleMapLink(location) {
    return `https://maps.google.com/maps?q=${location}`;
  }

  /**
   * Check url for an internal redirection
   * Internal urls must be prefixed with '#'
   * @param {String} url
   * @returns {String}
   */
  static redirectInternalUrl(url = "") {
    if (url && url.length && Array.from(url)[0] !== "#") {
      return `#${url}`;
    }
    return url;
  }

  /**
   * Check url for an internal redirection
   * Internal urls must be prefixed with '#'
   * @param {Object} data
   * @returns {Boolean}
   */
  static isEmptyObject(data) {
    return Object.keys(data).length === 0 && data.constructor === Object;
  }

  static getFileSizeFromBase64(base64) {
    // get file size from base64 string in Bytes
    // https://stackoverflow.com/questions/29939635/how-to-get-file-size-of-newly-created-image-if-src-is-base64-string
    const stringLength = base64.length - "data:image/jpeg;base64,".length;
    const sizeInBytes = 4 * Math.ceil(stringLength / 3) * 0.5624896334383812;
    return sizeInBytes;
  }

  /**
   *
   * @param {string} string
   * @param {number} limit
   * @returns string
   */
  static trimString(string, limit) {
    const dots = string.length > limit ? " ..." : "";
    return `${string.substring(0, limit)} ${dots}`;
  }
}
