export const IReferenceContact = Object.freeze({
  name: "contact_name",
  position: "contact_job",
  phoneNumber: "phone_number",
  mail: "email",
  experienceId: "experience",
  sharingMessage: "sharing_message",
  managerFirstname: "manager_first_name",
  managerLastname: "manager_last_name",
  managerEmail: "manager_email",
  managerPhoneNumber: "manager_phone_number",
  managerComment: "manager_comment",
  managerValidationDate: "manager_validation_date",
  rating: "rating",
  date: "date",
  contactDetails: "contact_details",
  managerDetails: "manager_details",
});

export class ReferenceContactModel {
  constructor(data = {}) {
    this[IReferenceContact.name] = data[IReferenceContact.name];
    this[IReferenceContact.position] = data[IReferenceContact.position];
    this[IReferenceContact.phoneNumber] = data[IReferenceContact.phoneNumber];
    this[IReferenceContact.mail] = data[IReferenceContact.mail];
    this[IReferenceContact.experienceId] = data[IReferenceContact.experienceId];
    this[IReferenceContact.sharingMessage] = data[IReferenceContact.sharingMessage];
  }

  getContactName() {
    return this[IReferenceContact.name] || "";
  }

  getContactPosition() {
    return this[IReferenceContact.position] || "";
  }

  getContactPhoneNumber() {
    return this[IReferenceContact.phoneNumber] || "";
  }

  getContactEmail() {
    return this[IReferenceContact.mail] || "";
  }

  getSharingMessage() {
    return this[IReferenceContact.sharingMessage];
  }

  shouldPhoneNumberBeRequired() {
    return this[IReferenceContact.name] && !this[IReferenceContact.mail];
  }

  shouldMailBeRequired() {
    return this[IReferenceContact.name] && !this[IReferenceContact.phoneNumber];
  }

  isSet() {
    return (
      this[IReferenceContact.name] !== undefined || //
      this[IReferenceContact.phoneNumber] !== undefined ||
      this[IReferenceContact.mail] !== undefined
    );
  }
}
