import { CANDIDATES_STATUS } from "@core/constants";
import { JavascriptHelpers } from "@core/helpers";
import { PermanentRecommendationModel } from "./Permanent-recommendation.model";
import { CandidateReportsModel } from "../common";

export const IPermanentCandidate = Object.freeze({
  candidateReports: "candidate_reports",
  averageNote: "average_note",
  email: "extra_mail",
  experiencesAmount: "nb_experiences",
  firstname: "firstname",
  id: "extra_id",
  ignored: "ignored",
  isFavorite: "is_favorite",
  lastname: "lastname",
  notesAmount: "nb_notes",
  novice: "is_novice",
  phone: "extra_phone_number",
  picture: "picture",
  postalCode: "postal_code",
  recommendation: "best_recommendation",
  recruited: "recruited",
  selected: "selected",
  status: "extra_status",
  fromJoPool: "is_from_jo_pool",
});

export class PermanentCandidateModel {
  constructor(data = {}) {
    this[IPermanentCandidate.candidateReports] = data[IPermanentCandidate.candidateReports];
    this[IPermanentCandidate.averageNote] = data[IPermanentCandidate.averageNote];
    this[IPermanentCandidate.email] = data[IPermanentCandidate.email];
    this[IPermanentCandidate.experiencesAmount] = data[IPermanentCandidate.experiencesAmount];
    this[IPermanentCandidate.firstname] = data[IPermanentCandidate.firstname];
    this[IPermanentCandidate.id] = data[IPermanentCandidate.id];
    this[IPermanentCandidate.ignored] = data[IPermanentCandidate.ignored];
    this[IPermanentCandidate.isFavorite] = data[IPermanentCandidate.isFavorite];
    this[IPermanentCandidate.lastname] = data[IPermanentCandidate.lastname];
    this[IPermanentCandidate.notesAmount] = data[IPermanentCandidate.notesAmount];
    this[IPermanentCandidate.novice] = data[IPermanentCandidate.novice];
    this[IPermanentCandidate.phone] = data[IPermanentCandidate.phone];
    this[IPermanentCandidate.picture] = data[IPermanentCandidate.picture];
    this[IPermanentCandidate.postalCode] = data[IPermanentCandidate.postalCode];
    this[IPermanentCandidate.recommendation] = data[IPermanentCandidate.recommendation];
    this[IPermanentCandidate.selected] = data[IPermanentCandidate.selected];
    this[IPermanentCandidate.recruited] = data[IPermanentCandidate.recruited];
    this[IPermanentCandidate.fromJoPool] = data[IPermanentCandidate.fromJoPool];
  }

  isRecruited() {
    return this[IPermanentCandidate.recruited];
  }

  getId() {
    return this[IPermanentCandidate.id];
  }

  getFirstname() {
    return this[IPermanentCandidate.firstname];
  }

  getLastname() {
    return this[IPermanentCandidate.lastname];
  }

  getCandidateReports() {
    return new CandidateReportsModel(this[IPermanentCandidate.candidateReports]);
  }

  getPostalCode() {
    return this[IPermanentCandidate.postalCode];
  }

  getPicture() {
    return this[IPermanentCandidate.picture];
  }

  getRecommendation() {
    return new PermanentRecommendationModel(this[IPermanentCandidate.recommendation]);
  }

  isFavorite() {
    return this[IPermanentCandidate.isFavorite] === true;
  }

  isFromJoPool() {
    return this[IPermanentCandidate.fromJoPool] === true;
  }

  isSelected() {
    return this[IPermanentCandidate.selected] === true;
  }

  isNovice() {
    return this[IPermanentCandidate.novice] === true;
  }

  getExperiencesAmount() {
    return this[IPermanentCandidate.experiencesAmount];
  }

  getAverageNote() {
    return this[IPermanentCandidate.averageNote];
  }

  getNotesAmount() {
    return this[IPermanentCandidate.notesAmount];
  }

  getStatus() {
    return this[IPermanentCandidate.status];
  }

  getIdentity() {
    const phone = JavascriptHelpers.isValidPhoneNumber(this[IPermanentCandidate.phone]) ? this[IPermanentCandidate.phone] : undefined;
    return {
      firstname: this[IPermanentCandidate.firstname],
      lastname: this[IPermanentCandidate.lastname],
      phone,
      email: this[IPermanentCandidate.email],
      postalCode: this[IPermanentCandidate.postalCode],
    };
  }

  hasRefused() {
    return this.getStatus() === CANDIDATES_STATUS.EXTRA_REFUSE;
  }

  isIgnored() {
    return !!this[IPermanentCandidate.ignored];
  }
}

export class PermanentCandidateContactedModel extends PermanentCandidateModel {
  constructor(data = {}) {
    super({ ...data, [IPermanentCandidate.selected]: true });
  }
}

export class PermanentCandidateIgnoredModel extends PermanentCandidateModel {
  constructor(data = {}) {
    super({ ...data, [IPermanentCandidate.ignored]: true });
  }
}

export class PermanentCandidateRecruitedModel extends PermanentCandidateModel {
  constructor(data = {}) {
    super({ ...data, [IPermanentCandidate.recruited]: true });
  }
}
