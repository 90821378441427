export const IReviewAmount = Object.freeze({
  notes: "nb_notes",
  schedules: "nb_releves",
});

export class ReviewAmountModel {
  constructor(data = {}) {
    this[IReviewAmount.notes] = data[IReviewAmount.notes];
    this[IReviewAmount.schedules] = data[IReviewAmount.schedules];
  }

  getNotesTodo() {
    return this[IReviewAmount.notes] || 0;
  }

  getSchedulesTodo() {
    return this[IReviewAmount.schedules] || 0;
  }

  hasTodo() {
    return this.getNotesTodo() > 0 || this.getSchedulesTodo() > 0;
  }
}
