import { JobsOfferListItem } from "./jobs-offer-list-item.model";

export const IJobsSimilarOffers = Object.freeze({
  total: "total",
  similarOffers: "similarsOffers",
});

export class JobsSimilarOffersModel {
  constructor(data = {}) {
    this[IJobsSimilarOffers.total] = data[IJobsSimilarOffers.total];
    this[IJobsSimilarOffers.similarOffers] = data[IJobsSimilarOffers.similarOffers];
  }

  getTotal() {
    return this[IJobsSimilarOffers.total];
  }

  getSimilarOffersList() {
    return (this[IJobsSimilarOffers.similarOffers] || []).map((offer) => new JobsOfferListItem(offer));
  }
}
