export const ICreationStepNovices = Object.freeze({
  novicesAllowed: "is_open_to_novices",
});

export class CreationStepNovicesModel {
  constructor(data = {}) {
    this[ICreationStepNovices.novicesAllowed] = data[ICreationStepNovices.novicesAllowed];
  }

  getNovicesAllowed() {
    return this[ICreationStepNovices.novicesAllowed] === true;
  }
}
