export const IBookingExpressCandidate = Object.freeze({
  id: "extra_id",
  firstname: "firstname",
  lastname: "lastname",
  missionNumber: "total_missions",
  available: "available",
  picture: "picture",
  rating: "rating",
});

export class BookingExpressCandidateModel {
  constructor(data = {}) {
    this[IBookingExpressCandidate.id] = data[IBookingExpressCandidate.id];
    this[IBookingExpressCandidate.firstname] = data[IBookingExpressCandidate.firstname];
    this[IBookingExpressCandidate.lastname] = data[IBookingExpressCandidate.lastname];
    this[IBookingExpressCandidate.missionNumber] = data[IBookingExpressCandidate.missionNumber];
    this[IBookingExpressCandidate.available] = data[IBookingExpressCandidate.available];
    this[IBookingExpressCandidate.picture] = data[IBookingExpressCandidate.picture];
    this[IBookingExpressCandidate.rating] = data[IBookingExpressCandidate.rating];
  }

  getId() {
    return this[IBookingExpressCandidate.id];
  }

  getFirstName() {
    return this[IBookingExpressCandidate.firstname];
  }

  getLastName() {
    return this[IBookingExpressCandidate.lastname];
  }

  getFullName() {
    return `${this[IBookingExpressCandidate.firstname]} ${this[IBookingExpressCandidate.lastname]}`;
  }

  getMissionNumber() {
    return this[IBookingExpressCandidate.missionNumber];
  }

  isAvailable() {
    return this[IBookingExpressCandidate.available] === true;
  }

  getPicture() {
    return this[IBookingExpressCandidate.picture];
  }

  getRating() {
    return this[IBookingExpressCandidate.rating];
  }
}
