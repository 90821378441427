import { BillableShiftModel } from "./billable-shifts.model";

export const IBillableShiftsList = Object.freeze({
  total: "total",
  billableShifts: "billable_shifts",
});

export class BillableShiftsListModel {
  constructor(data = {}) {
    this[IBillableShiftsList.total] = data[IBillableShiftsList.total];
    this[IBillableShiftsList.billableShifts] = data[IBillableShiftsList.billableShifts];
  }

  getTotal() {
    return this[IBillableShiftsList.total];
  }

  getBillableShiftsList() {
    return (this[IBillableShiftsList.billableShifts] || []).map((row) => new BillableShiftModel(row));
  }
}
