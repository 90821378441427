export const ISegment = Object.freeze({
  code: "code",
  label: "label",
});

export class SegmentModel {
  constructor(data = {}) {
    this[ISegment.code] = data[ISegment.code];
    this[ISegment.label] = data[ISegment.label];
  }

  getCode() {
    return this[ISegment.code];
  }

  getLabel() {
    return this[ISegment.label];
  }
}
