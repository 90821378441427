import { IRecruitmentMER } from "./recruitment.interface";
import { Recruitment } from "./recruitment";

export class RecruitmentMERModel extends Recruitment {
  constructor(data = {}) {
    super(data);

    this[IRecruitmentMER.canBeCanceled] = data[IRecruitmentMER.canBeCanceled];
    this[IRecruitmentMER.duration] = data[IRecruitmentMER.duration];
    this[IRecruitmentMER.isFilled] = data[IRecruitmentMER.isFilled];
    this[IRecruitmentMER.linkedId] = data[IRecruitmentMER.linkedId];
    this[IRecruitmentMER.merEnded] = data[IRecruitmentMER.merEnded];
    this[IRecruitmentMER.merStarted] = data[IRecruitmentMER.merStarted];
    this[IRecruitmentMER.multi] = data[IRecruitmentMER.multi];
    this[IRecruitmentMER.startDate] = data[IRecruitmentMER.startDate];
  }

  getLinkedId() {
    return this[IRecruitmentMER.linkedId];
  }

  isFilled() {
    return this[IRecruitmentMER.isFilled] === true;
  }

  isMultiCandidates() {
    return this[IRecruitmentMER.multi] === "1";
  }

  hasStarted() {
    return this[IRecruitmentMER.merStarted] === true;
  }

  hasEnded() {
    return this[IRecruitmentMER.merEnded] === true;
  }

  canBeCanceled() {
    return this[IRecruitmentMER.canBeCanceled] === true;
  }

  getDuration() {
    return this[IRecruitmentMER.duration];
  }

  getStartDate() {
    return this[IRecruitmentMER.startDate];
  }
}
