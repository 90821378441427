import { SanitationHelper } from "@core/helpers";

export const ISubscriptionModel = Object.freeze({
  id: "id",
  title: "title",
  subtitle: "subtitle",
  img: "img",
  sellingPoints: "selling_points",
  subtext: "sub_text",
  okBtnLabel: "ok_btn_label",
  nokBtnLabel: "nok_btn_label",
  mandateUrl: "direct_debit_mandate_url",
  message: "message",
});

export class SubscriptionModel {
  constructor(data = {}) {
    this[ISubscriptionModel.id] = data[ISubscriptionModel.id];
    this[ISubscriptionModel.title] = data[ISubscriptionModel.title];
    this[ISubscriptionModel.img] = data[ISubscriptionModel.img];
    this[ISubscriptionModel.sellingPoints] = data[ISubscriptionModel.sellingPoints];
    this[ISubscriptionModel.subtitle] = data[ISubscriptionModel.subtitle];
    this[ISubscriptionModel.message] = data[ISubscriptionModel.message];
    this[ISubscriptionModel.subtext] = data[ISubscriptionModel.subtext];
    this[ISubscriptionModel.okBtnLabel] = data[ISubscriptionModel.okBtnLabel];
    this[ISubscriptionModel.nokBtnLabel] = data[ISubscriptionModel.nokBtnLabel];
    this[ISubscriptionModel.mandateUrl] = data[ISubscriptionModel.mandateUrl];
  }

  isEmpty() {
    return this.getId() === undefined;
  }

  getId() {
    return this[ISubscriptionModel.id];
  }

  getTitle() {
    return this[ISubscriptionModel.title] ?? "";
  }

  getSellingPoints() {
    const sanitizedSellingPoints = (this[ISubscriptionModel.sellingPoints] ?? []).map((sp) => SanitationHelper.sanitize(sp));
    return sanitizedSellingPoints;
  }

  getSubtitle() {
    return this[ISubscriptionModel.subtitle] ?? "";
  }

  getMandateUrl() {
    return this[ISubscriptionModel.mandateUrl];
  }

  hasMandateUrl() {
    return this.getMandateUrl() !== undefined;
  }

  getImg() {
    return this[ISubscriptionModel.img];
  }

  getSanitizedMessage() {
    return SanitationHelper.sanitize(this[ISubscriptionModel.message] ?? "");
  }

  getSanitizedSubtext() {
    return SanitationHelper.sanitize(this[ISubscriptionModel.subtext] ?? "");
  }

  hasOkBtn() {
    return this.getOkBtnLabel() !== "";
  }

  getOkBtnLabel() {
    return this[ISubscriptionModel.okBtnLabel] ?? "";
  }

  hasNokBtn() {
    return this.getNokBtnLabel() !== "";
  }

  getNokBtnLabel() {
    return this[ISubscriptionModel.nokBtnLabel] ?? "";
  }
}
