export const IBillableShiftsAmount = Object.freeze({
  unitAmount: "unit_amount",
  tax: "tax",
  totalTTC: "total_amount_t_t_c",
  totalHT: "total_amount_h_t",
  totalTax: "total_amount_tax",
});

export class BillableShiftAmountModel {
  constructor(data = {}) {
    this[IBillableShiftsAmount.unitAmount] = data[IBillableShiftsAmount.unitAmount];
    this[IBillableShiftsAmount.tax] = data[IBillableShiftsAmount.tax];
    this[IBillableShiftsAmount.totalTTC] = data[IBillableShiftsAmount.totalTTC];
    this[IBillableShiftsAmount.totalHT] = data[IBillableShiftsAmount.totalHT];
    this[IBillableShiftsAmount.totalTax] = data[IBillableShiftsAmount.totalTax];
  }

  getUnitAmount() {
    return this[IBillableShiftsAmount.unitAmount];
  }

  getTax() {
    return this[IBillableShiftsAmount.tax];
  }

  getTotalTTC() {
    return this[IBillableShiftsAmount.totalTTC];
  }

  getTotalHT() {
    return this[IBillableShiftsAmount.totalHT];
  }

  getTotalTax() {
    return this[IBillableShiftsAmount.totalTax];
  }
}
