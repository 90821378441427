import { SHIFT_STATUS } from "@core/constants";
import { DatesHelpers } from "@core/helpers";

export const ICalendarShiftSummary = Object.freeze({
  date: "date",
  status: "status",
});

export class CalendarShiftSummaryModel {
  constructor(data = {}) {
    this[ICalendarShiftSummary.date] = data[ICalendarShiftSummary.date];
    this[ICalendarShiftSummary.status] = data[ICalendarShiftSummary.status];
  }

  getDate() {
    return DatesHelpers.getDayjs(this[ICalendarShiftSummary.date]).toDate();
  }

  getStatus() {
    return this[ICalendarShiftSummary.status] || [];
  }

  hasBooked() {
    return this.getStatus().includes(SHIFT_STATUS.BOOKED);
  }

  hasCanceled() {
    return this.getStatus().includes(SHIFT_STATUS.EXTRA_CANCELED);
  }
}
