export const IBillingSessionCreationResponse = Object.freeze({
  id: "id_billing_session",
  message: "message",
});

export class BillingSessionCreationResponseModel {
  constructor(data = {}) {
    this[IBillingSessionCreationResponse.id] = data[IBillingSessionCreationResponse.id];
    this[IBillingSessionCreationResponse.message] = data[IBillingSessionCreationResponse.message];
  }

  getId() {
    return this[IBillingSessionCreationResponse.id];
  }

  getMessage() {
    return this[IBillingSessionCreationResponse.message];
  }
}
