import { DatesHelpers, ArrayHelpers } from "@core/helpers";
import { PlanningShiftModel } from "./planning-shift.model";

export const IPlanningShiftsGroup = Object.freeze({
  date: "date",
  nbExtraRequired: "nb_extras_desired",
  nbExtraBooked: "nb_extras_booked",
  shiftsBooked: "shifts_booked",
  shiftsCanceled: "shifts_cancelled",
});

export class PlanningShiftsGroupModel {
  constructor(data = {}) {
    this[IPlanningShiftsGroup.date] = data[IPlanningShiftsGroup.date];
    this[IPlanningShiftsGroup.nbExtraRequired] = data[IPlanningShiftsGroup.nbExtraRequired];
    this[IPlanningShiftsGroup.nbExtraBooked] = data[IPlanningShiftsGroup.nbExtraBooked];
    this[IPlanningShiftsGroup.shiftsBooked] = data[IPlanningShiftsGroup.shiftsBooked];
    this[IPlanningShiftsGroup.shiftsCanceled] = data[IPlanningShiftsGroup.shiftsCanceled];
  }

  getDate() {
    return this[IPlanningShiftsGroup.date];
  }

  getFormattedDate() {
    return DatesHelpers.getDayjs(this.getDate()).format("dddd Do MMMM");
  }

  getShiftsSorted(shifts) {
    const shiftsModels = shifts.map((s) => new PlanningShiftModel(s));
    // we sort alphabetically from extra firstname
    return shiftsModels.sort((a, b) => {
      const afirstName = a.getExtraBooked().getIdentity().firstname;
      const bfirstName = b.getExtraBooked().getIdentity().firstname;
      return ArrayHelpers.alphabeticallySort(afirstName, bfirstName);
    });
  }

  getShiftsBooked() {
    return this.getShiftsSorted(this[IPlanningShiftsGroup.shiftsBooked] || []);
  }

  getShiftsCanceled() {
    return this.getShiftsSorted(this[IPlanningShiftsGroup.shiftsCanceled] || []);
  }

  getNbExtraRequired() {
    return this[IPlanningShiftsGroup.nbExtraRequired];
  }

  getNbExtraBooked() {
    return this[IPlanningShiftsGroup.nbExtraBooked];
  }
}
