import { Recruitment } from "./recruitment";
import { IRecruitmentPermanent } from "./recruitment.interface";

export class RecruitmentPermanentModel extends Recruitment {
  constructor(data = {}) {
    super(data);

    this[IRecruitmentPermanent.unlimitedContact] = data[IRecruitmentPermanent.unlimitedContact];
    this[IRecruitmentPermanent.upfront] = data[IRecruitmentPermanent.upfront];
  }

  isUnlocked() {
    return this[IRecruitmentPermanent.unlimitedContact] === true;
  }

  isUpfront() {
    return this[IRecruitmentPermanent.upfront] === true;
  }

  canBeActivated() {
    return (
      !this.isUnlocked() && //
      !this.isExpired() &&
      !this.isArchived()
    );
  }
}
