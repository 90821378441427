export const IRelaunchDemand = Object.freeze({
  type: "type",
  date: "created_at",
});

export const RELAUNCH_TYPE = {
  AUTO: "auto_relaunch",
  MANUAL: "manual_relaunch",
};

export class RelaunchDemandModel {
  constructor(data = {}) {
    this[IRelaunchDemand.type] = data[IRelaunchDemand.type];
    this[IRelaunchDemand.date] = data[IRelaunchDemand.date];
  }

  getType() {
    return this[IRelaunchDemand.type];
  }

  getDate() {
    return this[IRelaunchDemand.date];
  }

  isAuto() {
    return this.getType() === RELAUNCH_TYPE.AUTO;
  }
}
