import { JobsDynamicPageModel } from "@core/models";
import { JobsService } from "@core/services/jobs";
import {
  ACTION_JOBS_DYNAMIC_GET_CONTENT_BY_ID,
  GETTER_JOBS_DYNAMIC_GET_CURRENT_PAGE_CONTENT,
  MUTATION_JOBS_DYNAMIC_SET_CURRENT_PAGE_CONTENT,
} from "@jobs/store/types";

function initialState() {
  return {
    currentPageContent: {},
  };
}

export const state = {
  initialState,
  ...initialState(),
};

export const actions = {
  async [ACTION_JOBS_DYNAMIC_GET_CONTENT_BY_ID]({ commit }, slug) {
    const result = await JobsService.getJobsDynamicContentById(slug);
    commit(MUTATION_JOBS_DYNAMIC_SET_CURRENT_PAGE_CONTENT, result);
  },
};

export const mutations = {
  [MUTATION_JOBS_DYNAMIC_SET_CURRENT_PAGE_CONTENT](_state, content) {
    _state.currentPageContent = content;
  },
};

export const getters = {
  [GETTER_JOBS_DYNAMIC_GET_CURRENT_PAGE_CONTENT]: (_state) => new JobsDynamicPageModel(_state.currentPageContent),
};

export default function createModule() {
  return {
    namespaced: false,
    state: () => ({ ...initialState() }),
    actions,
    mutations,
    getters,
  };
}
