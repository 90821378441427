import { ArrayHelpers } from "@core/helpers";
import { ExperienceWarningModel } from "./experience-warning.model";

export const IReferenceDetails = Object.freeze({
  duplicatedPhoneNumber: "phone_number_duplicate",
});

export class ReferenceDetailsModel {
  constructor(data = {}) {
    this[IReferenceDetails.duplicatedPhoneNumber] = data[IReferenceDetails.duplicatedPhoneNumber];
  }

  getDuplicatedPhoneNumber() {
    const duplicatedPhoneNumber = ArrayHelpers.ensureArray(this[IReferenceDetails.duplicatedPhoneNumber]);
    return duplicatedPhoneNumber.map((duplicate) => new ExperienceWarningModel(duplicate));
  }
}
