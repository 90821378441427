import { NumericHelpers } from "@core/helpers";
import { SubscriptionActionButtonModel } from "./subscription-action-button.model";

export const ISubscribedOfferInformations = Object.freeze({
  id: "id",
  label: "name",
  description: "description",
  orderDesktop: "order_desktop",
  mobileOrder: "order_mobile",
  monthsDuration: "months_duration",
  subText: "sub_text",
  img: "img",
  amountHt: "amount_ht",
  amountTtc: "amount_ttc",
  buttons: "buttons",
  amountLabel: "amount_label",
});

export class SubscribedOfferInformationsModel {
  constructor(data = {}) {
    this[ISubscribedOfferInformations.id] = data[ISubscribedOfferInformations.id];
    this[ISubscribedOfferInformations.label] = data[ISubscribedOfferInformations.label];
    this[ISubscribedOfferInformations.description] = data[ISubscribedOfferInformations.description];
    this[ISubscribedOfferInformations.orderDesktop] = data[ISubscribedOfferInformations.orderDesktop];
    this[ISubscribedOfferInformations.mobileOrder] = data[ISubscribedOfferInformations.mobileOrder];
    this[ISubscribedOfferInformations.monthsDuration] = data[ISubscribedOfferInformations.monthsDuration];
    this[ISubscribedOfferInformations.subText] = data[ISubscribedOfferInformations.subText];
    this[ISubscribedOfferInformations.img] = data[ISubscribedOfferInformations.img];
    this[ISubscribedOfferInformations.amountHt] = data[ISubscribedOfferInformations.amountHt];
    this[ISubscribedOfferInformations.amountTtc] = data[ISubscribedOfferInformations.amountTtc];
    this[ISubscribedOfferInformations.buttons] = data[ISubscribedOfferInformations.buttons];
    this[ISubscribedOfferInformations.amountLabel] = data[ISubscribedOfferInformations.amountLabel];
  }

  getId() {
    return this[ISubscribedOfferInformations.id];
  }

  getLabel() {
    return this[ISubscribedOfferInformations.label];
  }

  getDescription() {
    return this[ISubscribedOfferInformations.description];
  }

  getDesktopOrder() {
    return this[ISubscribedOfferInformations.orderDesktop];
  }

  getMobileOrder() {
    return this[ISubscribedOfferInformations.mobileOrder];
  }

  getMonthDuration() {
    return this[ISubscribedOfferInformations.monthsDuration];
  }

  getSubtext() {
    return this[ISubscribedOfferInformations.subText];
  }

  getImg() {
    return this[ISubscribedOfferInformations.img];
  }

  getAmountHt() {
    return NumericHelpers.formatNumberAsCurrency({ number: this[ISubscribedOfferInformations.amountHt] });
  }

  getAmountTtc() {
    return NumericHelpers.formatNumberAsCurrency({ number: this[ISubscribedOfferInformations.amountTtc] });
  }

  getActionButtons() {
    return (this[ISubscribedOfferInformations.buttons] || []).map((button) => new SubscriptionActionButtonModel(button));
  }

  getAmountLabel() {
    return this[ISubscribedOfferInformations.amountLabel];
  }
}
