import { JavascriptHelpers } from "@core/helpers";

export const IActionButton = Object.freeze({
  label: "label",
  link: "link",
});

export class ActionButtonModel {
  constructor(data = {}) {
    this[IActionButton.label] = data[IActionButton.label];
    this[IActionButton.link] = data[IActionButton.link];
  }

  getLabel() {
    return this[IActionButton.label];
  }

  getLink() {
    return JavascriptHelpers.redirectInternalUrl(this[IActionButton.link]);
  }
}
