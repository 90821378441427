import { DatesHelpers } from "@core/helpers";
import { SubscribedOfferInformationsModel } from "./subscribed-offer-informations.model";

export const ISubscribedInformations = Object.freeze({
  etab: "etab",
  offer: "offer",
  startDate: "start_subscription_date",
  endDate: "end_subscription_date",
  nextPaymentDate: "next_monthly_payment_date",
});

export class SubscribedInformationsModel {
  constructor(data = {}) {
    this[ISubscribedInformations.etab] = data[ISubscribedInformations.etab];
    this[ISubscribedInformations.offer] = data[ISubscribedInformations.offer];
    this[ISubscribedInformations.startDate] = data[ISubscribedInformations.startDate];
    this[ISubscribedInformations.endDate] = data[ISubscribedInformations.endDate];
    this[ISubscribedInformations.nextPaymentDate] = data[ISubscribedInformations.nextPaymentDate];
  }

  getOffer() {
    return new SubscribedOfferInformationsModel(this[ISubscribedInformations.offer]);
  }

  getStartSubscriptionDate() {
    return this[ISubscribedInformations.startDate];
  }

  getFormattedEndDate() {
    if (this[ISubscribedInformations.endDate]) {
      return DatesHelpers.getDayjs(this[ISubscribedInformations.endDate]).format("LL");
    }
    return undefined;
  }

  getFormattedNextPaymentDate() {
    if (this[ISubscribedInformations.nextPaymentDate]) {
      return DatesHelpers.getDayjs(this[ISubscribedInformations.nextPaymentDate]).format("LL");
    }
    return "-";
  }
}
