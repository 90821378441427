import mitt from "mitt";

const emitter = mitt();

// doc https://github.com/developit/mitt/tree/main
export class EventManager {
  /**
   * @param {*} event
   * @param {*} callback
   * will be used to register events and listen to them
   */
  static on(event, callback) {
    emitter.on(event, callback);
  }

  /**
   * @param {*} event
   * @param {*} callback
   * will be used to remove specific event
   */
  static off(event, callback) {
    emitter.off(event, callback);
  }

  /**
   * @param {*} event
   * @param {*} data
   * will be used to emit events
   */
  static emit(event, data) {
    emitter.emit(event, data);
  }

  /**
   * @param {*} events
   * @example of events:
   * [
   * {name: CANDIDATE_EVENTS.OFFERS_PERMANENT.OPEN_REVIEW_MODAL, action: this.openReviewModal},
   * {name: CANDIDATE_EVENTS.OFFERS_PERMANENT.OPEN_CANCEL_MISSION_MODAL, action: this.openCancelMissionModal}
   * ]
   */
  static registerAllEvents(events) {
    events.forEach((event) => {
      emitter.on(event.name, event.action);
    });
  }

  /**
   * @param {*} events
   * @example of events:
   * [
   * {name: CANDIDATE_EVENTS.OFFERS_PERMANENT.OPEN_REVIEW_MODAL, action: this.openReviewModal},
   * {name: CANDIDATE_EVENTS.OFFERS_PERMANENT.OPEN_CANCEL_MISSION_MODAL, action: this.openCancelMissionModal}
   * ]
   */
  static unregisterAllEvents(events) {
    events.forEach((event) => {
      emitter.off(event.name, event.action);
    });
  }

  /**
   * will be used to clear all events
   */
  static clearAllEvents() {
    emitter.all.clear();
  }
}
