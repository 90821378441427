import { SELF_URL, JOBS_CONTRACTS } from "@core/constants";
import api from "@core/plugins/api";
import { JavascriptHelpers } from "@core/helpers";

const jobsSearchUrl = (query) => `/joboffer/public/search-offers?${query}`;
const getJobsQualificationsUrl = () => `/joboffer/public/qualifications`;
const jobsEtabOffersUrl = (slug) => `/joboffer/public/etab-offers/${slug}`;
const getOfferDetailUrl = (slug) => `/joboffer/public/offer/${slug}`;
const jobsDetailMetaDataById = (slug) => `/seo/public/metadata/jobs/offer/${slug}`;
const getSimilarOffersUrl = (slug) => `/joboffer/public/similar-offers/${slug}`;
const jobsDynamicPagesUrl = () => `/joboffer/public/thematics/sectors`;
const jobsPopularPagesUrl = () => `/joboffer/public/thematics/popular`;
const jobsDynamicPageRegionUrl = () => `/joboffer/public/thematics/regions`;
const jobsDynamicPageByIdUrl = (slug) => `/joboffer/public/thematic/${slug}`;
const jobsDynamicPageMetadataByIdUrl = (slug) => `/seo/public/metadata/jobs/thematic/${slug}`;
const jobsHomePageMetaDataUrl = () => `/seo/public/metadata/jobs/page/hp`;
const jobsEtabPageMetaDataUrl = (slug) => `/seo/public/metadata/jobs/etab/${slug}`;
const jobsSearchPageMetaDataUrl = () => `/seo/public/metadata/jobs/page/search`;
const JobsDefaultPageMetaDataUrl = () => `/seo/public/metadata/jobs/page/default`;
const jobsEtablissementDetailUrl = (slug) => `/joboffer/public/etab/${slug}`;

export class JobsService {
  static getMetaDataFallback() {
    return {
      title: "Extracadabra - Les meilleurs jobs en hôtellerie-restauration et vente",
      description:
        "Extracadabra est la solution qui t'accompagne gratuitement dans tes recherches d'emplois. En extra ou CDI, trouve le meilleur job en hôtellerie-restauration ou vente-logistique !",
      url: SELF_URL,
    };
  }

  static async getJobsQualifications() {
    try {
      const { data } = await api.public.get(getJobsQualificationsUrl());
      return data;
    } catch (error) {
      throw error;
    }
  }

  static async searchJobs({ page = 1, rowsPerPage = 0, qualification, contract, postalCode, etablissement } = {}) {
    try {
      const query = JavascriptHelpers.getUrlFilters({ page, nb: rowsPerPage, q: qualification, c: contract, cp: postalCode, e: etablissement });
      const { data } = await api.public.get(jobsSearchUrl(query));
      return data;
    } catch (error) {
      throw error;
    }
  }

  static async searchEtabJobs(slug) {
    try {
      const { data } = await api.public.get(jobsEtabOffersUrl(slug));
      return data;
    } catch (error) {
      throw error;
    }
  }

  static async getJobsContracts() {
    try {
      return JOBS_CONTRACTS;
    } catch (error) {
      throw error;
    }
  }

  static async getJobsDynamicPages() {
    try {
      const { data } = await api.public.get(jobsDynamicPagesUrl());
      return data;
    } catch (error) {
      throw error;
    }
  }

  static async getJobsLocalisation() {
    try {
      const { data } = await api.public.get(jobsDynamicPageRegionUrl());
      return data;
    } catch (error) {
      throw error;
    }
  }

  static async getJobsPopularPages() {
    try {
      const { data } = await api.public.get(jobsPopularPagesUrl());
      return data;
    } catch (error) {
      throw error;
    }
  }

  static async getJobsDynamicContentById(slug) {
    try {
      const { data } = await api.public.get(jobsDynamicPageByIdUrl(slug));
      return data;
    } catch (error) {
      throw error;
    }
  }

  static async getJobsDynamicMetadataById(slug) {
    try {
      const { data } = await api.public.get(jobsDynamicPageMetadataByIdUrl(slug));
      return data;
    } catch (error) {
      throw error;
    }
  }

  static async getOfferDetail(slug) {
    try {
      const { data } = await api.public.get(getOfferDetailUrl(slug));
      return data;
    } catch (error) {
      throw error;
    }
  }

  static async getJobsDetailMetaDataById(slug) {
    try {
      const { data } = await api.public.get(jobsDetailMetaDataById(slug));
      return data;
    } catch (error) {
      throw error;
    }
  }

  static async getJobsHomePageMetaData() {
    try {
      const { data } = await api.public.get(jobsHomePageMetaDataUrl());
      return data;
    } catch (error) {
      throw error;
    }
  }

  static async getJobsEtabPageMetaData(slug) {
    try {
      const { data } = await api.public.get(jobsEtabPageMetaDataUrl(slug));
      return data;
    } catch (error) {
      throw error;
    }
  }

  static async getJobsSearchPageMetaData() {
    try {
      const { data } = await api.public.get(jobsSearchPageMetaDataUrl());
      return data;
    } catch (error) {
      throw error;
    }
  }

  static async getJobsDefaultPageMetaData() {
    try {
      const { data } = await api.public.get(JobsDefaultPageMetaDataUrl());
      return data;
    } catch (error) {
      throw error;
    }
  }

  static async getSimilarOffers(slug) {
    try {
      const { data } = await api.public.get(getSimilarOffersUrl(slug));
      return data;
    } catch (error) {
      throw error;
    }
  }

  static async getEtablissementDetailById(slug) {
    try {
      const { data } = await api.public.get(jobsEtablissementDetailUrl(slug));
      return data;
    } catch (error) {
      throw error;
    }
  }
}
