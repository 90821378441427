import {
  APP_VERSION,
  X_APP_VERSION,
  X_CHANNEL_EXTRACADABRA,
  X_CHANNEL_OS,
  X_CHANNEL_VALUE_WEB,
  X_CHANNEL_VALUE_APP,
  DEVICE_OS,
} from "@core/constants";
import { isWeb, Device } from "@core/plugins/mobile";

/**
 * Intercept all requests to add the app version token
 * @param {*} config - axios request config
 * @param {Boolean} [config.disableVersion] - if set to true, do not add version to headers
 * @returns initial call config
 */
export async function appHeadersInterceptor(config) {
  if (config) {
    if (config.disableVersion !== true) {
      config.headers[X_APP_VERSION] = APP_VERSION;
    }
    if (config.disableChannel !== true) {
      // will differentiate from mobile web (isWeb:true) and mobile app
      config.headers[X_CHANNEL_EXTRACADABRA] = isWeb ? X_CHANNEL_VALUE_WEB : X_CHANNEL_VALUE_APP;
    }
    if (config.disableOS !== true) {
      if (process.env.SERVER) {
        // specific case for SSR
        config.headers[X_CHANNEL_OS] = DEVICE_OS.SERVER;
      } else {
        const [operatingSystem, osVersion] = await Promise.all([Device.getOperatingSystem(), Device.getOperatingSystemVersion()]);
        config.headers[X_CHANNEL_OS] = `${operatingSystem}/${osVersion}`;
      }
    }
  }
  return config;
}
