import { JavascriptHelpers } from "@core/helpers";

export const IFeedbackCandidate = Object.freeze({
  averageNote: "average_note",
  email: "mail",
  experiencesAmount: "nb_experiences",
  firstname: "firstname",
  id: "extra_id",
  isFavorite: "is_favorite",
  lastname: "lastname",
  notesAmount: "nb_notes",
  phone: "phone",
  picture: "picture",
  postalCode: "postal_code",
  recruited: "recruited",
});

export class FeedbackCandidateModel {
  constructor(data = {}) {
    this[IFeedbackCandidate.averageNote] = data[IFeedbackCandidate.averageNote];
    this[IFeedbackCandidate.email] = data[IFeedbackCandidate.email];
    this[IFeedbackCandidate.experiencesAmount] = data[IFeedbackCandidate.experiencesAmount];
    this[IFeedbackCandidate.firstname] = data[IFeedbackCandidate.firstname];
    this[IFeedbackCandidate.id] = data[IFeedbackCandidate.id];
    this[IFeedbackCandidate.isFavorite] = data[IFeedbackCandidate.isFavorite];
    this[IFeedbackCandidate.lastname] = data[IFeedbackCandidate.lastname];
    this[IFeedbackCandidate.notesAmount] = data[IFeedbackCandidate.notesAmount];
    this[IFeedbackCandidate.phone] = data[IFeedbackCandidate.phone];
    this[IFeedbackCandidate.picture] = data[IFeedbackCandidate.picture];
    this[IFeedbackCandidate.postalCode] = data[IFeedbackCandidate.postalCode];
    this[IFeedbackCandidate.recruited] = data[IFeedbackCandidate.recruited];
  }

  isRecruited() {
    return this[IFeedbackCandidate.recruited];
  }

  getId() {
    return this[IFeedbackCandidate.id];
  }

  getFirstname() {
    return this[IFeedbackCandidate.firstname];
  }

  getLastname() {
    return this[IFeedbackCandidate.lastname];
  }

  getLastnameInitials() {
    return `${this[IFeedbackCandidate.lastname][0]}.`;
  }

  getPostalCode() {
    return this[IFeedbackCandidate.postalCode];
  }

  getPicture() {
    return this[IFeedbackCandidate.picture];
  }

  isFavorite() {
    return this[IFeedbackCandidate.isFavorite] === true;
  }

  getExperiencesAmount() {
    return this[IFeedbackCandidate.experiencesAmount];
  }

  getAverageNote() {
    return this[IFeedbackCandidate.averageNote];
  }

  getNotesAmount() {
    return this[IFeedbackCandidate.notesAmount];
  }

  getEmail() {
    return this[IFeedbackCandidate.email];
  }

  getPhone() {
    return JavascriptHelpers.isValidPhoneNumber(this[IFeedbackCandidate.phone]) ? this[IFeedbackCandidate.phone] : undefined;
  }
}
