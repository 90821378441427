import { DatesHelpers } from "@core/helpers";
import { IPlanningDay, PlanningDayModel } from "./planning-day.model";

export const IPlanning = Object.freeze({
  days: "days",
  specified: "specified",
});

export class PlanningModel {
  constructor(data = {}) {
    this[IPlanning.days] = data[IPlanning.days] || [];
    this[IPlanning.specified] = data[IPlanning.specified];
  }

  getDays() {
    return this[IPlanning.days];
  }

  setPlanningDays() {
    const planningDaysModel = this.getDays().map((d) => new PlanningDayModel(d));
    this[IPlanning.days] = planningDaysModel;
  }

  isSpecified() {
    return this[IPlanning.specified] === true;
  }

  setSpecified() {
    this[IPlanning.specified] = true;
  }

  selectedDays() {
    return this[IPlanning.days].filter((d) => d.isSelected()) || [];
  }

  selectedDaysCount() {
    return this.selectedDays().length;
  }

  initFromWeek() {
    this[IPlanning.days].length = 0; // reset the array
    DatesHelpers.weekdays.forEach((weekday) => {
      const day = {
        [IPlanningDay.id]: weekday.id,
        [IPlanningDay.selected]: false,
      };
      this[IPlanning.days].push(new PlanningDayModel(day));
    });
  }

  initFromDate({ start, end, defaultSchedule = {} }) {
    this[IPlanning.days].length = 0; // reset the array
    const number = DatesHelpers.numberOfDaysBetweenDates({ start, end });
    for (let i = 0; i < number; i++) {
      const day = {
        ...defaultSchedule, // set default schedule if any
        [IPlanningDay.id]: i,
        [IPlanningDay.selected]: true, // selected by default
        [IPlanningDay.date]: DatesHelpers.getDayjs(start)
          .startOf("days") // we want to start from 00:00
          .add(i, "days"),
      };
      this[IPlanning.days].push(new PlanningDayModel(day));
    }
  }

  toggleDay(dayId) {
    const day = this.getDay(dayId);
    if (day.isSelected()) {
      day.unselect();
    } else {
      day.select();
    }
  }

  isDaySelected(dayId) {
    const day = this.getDay(dayId);
    return day.isSelected();
  }

  scheduleDay({ dayId, property, value }) {
    this.getDay(dayId)[property] = value;
  }

  getDay(dayId) {
    return this[IPlanning.days].find((d) => d.getId() === dayId) || new PlanningDayModel();
  }

  noDaySelected() {
    return this[IPlanning.days].every((d) => d.selected === false);
  }

  isValid() {
    return this[IPlanning.days].every((d) => d.isValid()) && !this.noDaySelected();
  }

  getTotalDurationInMinutes() {
    const allDays = this.getDays();
    let duration = 0;
    allDays.forEach((day) => {
      duration += day.getTotalDuration() - day.getBreakDuration();
    });

    return duration;
  }
}
