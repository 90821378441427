import { CANDIDATES_APPLY_STATUS_TYPES } from "@core/constants";
import { CandidateApplyStatusBlockModel } from "./candidate-apply-status-block.model";

export const ICandidateApplyStatus = Object.freeze({
  type: "type",
  title: "title",
  content: "content",
  isAE: "show_ae_block",
  blocks: "blocks",
});

const successIcon = require("@core/assets/img/apply_success.svg");
const warningIcon = require("@core/assets/img/folder_warning.svg");

export class CandidateApplyStatusModel {
  constructor(data = {}) {
    this[ICandidateApplyStatus.type] = data[ICandidateApplyStatus.type];
    this[ICandidateApplyStatus.title] = data[ICandidateApplyStatus.title];
    this[ICandidateApplyStatus.content] = data[ICandidateApplyStatus.content];
    this[ICandidateApplyStatus.isAE] = data[ICandidateApplyStatus.isAE];
    this[ICandidateApplyStatus.blocks] = data[ICandidateApplyStatus.blocks];
  }

  getType() {
    return this[ICandidateApplyStatus.type];
  }

  getTitle() {
    return this[ICandidateApplyStatus.title];
  }

  getContent() {
    return this[ICandidateApplyStatus.content];
  }

  isAE() {
    return this[ICandidateApplyStatus.isAE] === true;
  }

  getIcon() {
    switch (this.getType()) {
      case CANDIDATES_APPLY_STATUS_TYPES.SUCCESS:
        return successIcon;
      case CANDIDATES_APPLY_STATUS_TYPES.WARNING:
        return warningIcon;
      default:
        return successIcon;
    }
  }

  getColor() {
    switch (this.getType()) {
      case CANDIDATES_APPLY_STATUS_TYPES.SUCCESS:
        return "positive";
      case CANDIDATES_APPLY_STATUS_TYPES.WARNING:
        return "warning";
      default:
        return "secondary";
    }
  }

  getBlocks() {
    return (this[ICandidateApplyStatus.blocks] || []).map((block) => new CandidateApplyStatusBlockModel(block));
  }
}
