import { DatesHelpers } from "@core/helpers";
import { i18n } from "@core/plugins/i18n";

export const IPermanentRecommendation = {
  comment: "comment",
  commentBy: "comment_by",
  end: "end_experience",
  etabCategory: "etab_category",
  etabName: "etab_name",
  note: "note",
  qualificationName: "job_name",
  start: "start_experience",
};

export class PermanentRecommendationModel {
  constructor(data = {}) {
    this[IPermanentRecommendation.comment] = data[IPermanentRecommendation.comment];
    this[IPermanentRecommendation.commentBy] = data[IPermanentRecommendation.commentBy];
    this[IPermanentRecommendation.end] = data[IPermanentRecommendation.end];
    this[IPermanentRecommendation.etabCategory] = data[IPermanentRecommendation.etabCategory];
    this[IPermanentRecommendation.etabName] = data[IPermanentRecommendation.etabName];
    this[IPermanentRecommendation.note] = data[IPermanentRecommendation.note];
    this[IPermanentRecommendation.qualificationName] = data[IPermanentRecommendation.qualificationName];
    this[IPermanentRecommendation.start] = data[IPermanentRecommendation.start];
  }

  getCommentLength() {
    return (this[IPermanentRecommendation.comment] || "").length;
  }

  getComment() {
    return this[IPermanentRecommendation.comment];
  }

  getCommenter() {
    return this[IPermanentRecommendation.commentBy];
  }

  getQualificationName() {
    return this[IPermanentRecommendation.qualificationName];
  }

  getEtabIdentity() {
    return `${this[IPermanentRecommendation.etabName]}, ${this[IPermanentRecommendation.etabCategory]}`;
  }

  getDatesDisplay() {
    const start = this[IPermanentRecommendation.start] ? DatesHelpers.getDayjs(this[IPermanentRecommendation.start]) : null;
    const end = this[IPermanentRecommendation.end] ? DatesHelpers.getDayjs(this[IPermanentRecommendation.end]) : null;
    let display = "";
    if (start) {
      display += start.format("MMM YYYY");
      if (end) {
        // if start and end are not in the same month, we display the end date
        if (!start.isSame(end, "month")) {
          display += ` ${i18n.global.t("CORE.MODELS.RECOMMENDATION.@TOP_RECO_END_DATE", { date: end.format("MMM YYYY") })}`;
        }
      } else {
        // if there's no end date, it means it's still in progress
        display += ` ${i18n.global.t("CORE.MODELS.RECOMMENDATION.@TOP_RECO_IN_PROGRESS")}`;
      }
    }

    return display;
  }
}
