import { CandidateOfferTypicalScheduleModel } from "./candidate-offer-typical-schedule.model";
import { CandidateOfferModel } from "./candidate-offer.model";

export const ICandidateOfferPermanent = Object.freeze({
  typicalSchedule: "typical_schedule",
  requestedContact: "requested_contact",
});

export class CandidateOfferPermanentModel extends CandidateOfferModel {
  constructor(data = {}) {
    super(data);
    this[ICandidateOfferPermanent.typicalSchedule] = data[ICandidateOfferPermanent.typicalSchedule];
    this[ICandidateOfferPermanent.requestedContact] = data[ICandidateOfferPermanent.requestedContact];
  }

  getTypicalSchedule() {
    return new CandidateOfferTypicalScheduleModel(this[ICandidateOfferPermanent.typicalSchedule]);
  }

  isScheduleUnfilled() {
    return this.getTypicalSchedule().getDays().length === 0;
  }

  requestedContact() {
    return this[ICandidateOfferPermanent.requestedContact];
  }
}
