<template>
  <LoadingSpinner />
  <router-view />
</template>

<script>
  import { createMetaMixin } from "quasar";
  import { typeformMixins } from "@core/mixins/typeform.mixins";
  import { EventManager } from "@core/managers/event.manager";
  import { MetaHelpers } from "@core/helpers";
  import { JobsService } from "@core/services/jobs";
  import { JOBS_EVENTS } from "@jobs/config";
  import { ACTION_JOBS_CONTRACTS_GET_ALL, ACTION_JOBS_QUALIFICATIONS_GET_ALL } from "@jobs/store/types";

  const seoFallback = JobsService.getMetaDataFallback();

  export default {
    mixins: [
      typeformMixins,
      createMetaMixin(function () {
        return MetaHelpers.setAutoMeta({
          title: seoFallback.title,
          description: seoFallback.description,
          url: seoFallback.url + this.$route.fullPath,
        });
      }),
    ],
    preFetch({ store, currentRoute, previousRoute, redirect, ssrContext, urlPath, publicPath }) {
      return Promise.all([store.dispatch(ACTION_JOBS_QUALIFICATIONS_GET_ALL), store.dispatch(ACTION_JOBS_CONTRACTS_GET_ALL)]);
    },
    created() {
      EventManager.on(JOBS_EVENTS.OPEN_TYPEFORM, this.openTypeform);
    },
    beforeUnmount() {
      EventManager.off(JOBS_EVENTS.OPEN_TYPEFORM, this.openTypeform);
    },
    methods: {
      openTypeform(typeformUrl, options = {}) {
        this.$_typeformOpenPopup(typeformUrl, options);
      },
      handleClick(e) {
        const target = e.currentTarget;
        const href = target.getAttribute("href");

        if (!target || !href) {
          return;
        }

        if (target.classList.contains("secondary")) {
          this.openModal(href);
        } else {
          window.location.href = href;
        }
      },
    },
  };
</script>
