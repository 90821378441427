import { EtabCategoryModel } from "@core/models/common/etab-category.model";
import { EtabFamilyModel } from "./etab-family.model";

export const IEtablissement = Object.freeze({
  id: "id",
  etabName: "nom_etablissement",
  address: "adresse",
  postalCode: "code_postal",
  city: "ville",
  phone: "telephone",
  email: "email",
  managerName: "nom",
  urlReleveHeure: "url_releveHeure",
  category: "category",
  categoryGroup: "category_group",
});

export class EtablissementModel {
  constructor(data = {}) {
    this[IEtablissement.id] = data[IEtablissement.id];
    this[IEtablissement.etabName] = data[IEtablissement.etabName];
    this[IEtablissement.address] = data[IEtablissement.address];
    this[IEtablissement.postalCode] = data[IEtablissement.postalCode];
    this[IEtablissement.city] = data[IEtablissement.city];
    this[IEtablissement.phone] = data[IEtablissement.phone];
    this[IEtablissement.email] = data[IEtablissement.email];
    this[IEtablissement.managerName] = data[IEtablissement.managerName];
    this[IEtablissement.urlReleveHeure] = data[IEtablissement.urlReleveHeure];
    this[IEtablissement.category] = data[IEtablissement.category];
    this[IEtablissement.categoryGroup] = data[IEtablissement.categoryGroup];
  }

  getId() {
    return this[IEtablissement.id];
  }

  getAddress() {
    return this[IEtablissement.address];
  }

  getCity() {
    return this[IEtablissement.city];
  }

  getPostalCode() {
    return this[IEtablissement.postalCode];
  }

  getFormattedAddress() {
    return `${this[IEtablissement.address] || ""} ${this[IEtablissement.postalCode] || ""} ${this[IEtablissement.city] || ""}`;
  }

  getCategory() {
    return new EtabCategoryModel(this[IEtablissement.category]);
  }

  getCategoryGroup() {
    return new EtabFamilyModel(this[IEtablissement.categoryGroup]);
  }
}
