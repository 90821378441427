import { BasicOptionModel } from "@core/models/common/basic-option.model";
import { ArrayHelpers } from "@core/helpers/array.helpers.js";
import { LocationOptionModel, ICandidateLocationFilter } from "@core/models/common/candidate-filters-location.model";

export const IRecruitmentCandidatesFilters = Object.freeze({
  locations: "location",
  languages: "language",
  workExperiences: "nb_exp",
  alreadyWorkedIn: "already_worked_in",
  hasRecommendation: "has_recommendation",
  hasVehicle: "has_vehicle",
  status: "status",
  cities: "cities",
  postalCode: "cp",
});

export class RecruitmentCandidatesFiltersModel {
  constructor(data = {}) {
    this[IRecruitmentCandidatesFilters.locations] = data[IRecruitmentCandidatesFilters.locations];
    this[IRecruitmentCandidatesFilters.languages] = data[IRecruitmentCandidatesFilters.languages];
    this[IRecruitmentCandidatesFilters.workExperiences] = data[IRecruitmentCandidatesFilters.workExperiences];
    this[IRecruitmentCandidatesFilters.alreadyWorkedIn] = data[IRecruitmentCandidatesFilters.alreadyWorkedIn];
    this[IRecruitmentCandidatesFilters.hasRecommendation] = data[IRecruitmentCandidatesFilters.hasRecommendation];
    this[IRecruitmentCandidatesFilters.hasVehicle] = data[IRecruitmentCandidatesFilters.hasVehicle];
    this[IRecruitmentCandidatesFilters.status] = data[IRecruitmentCandidatesFilters.status];
    this[IRecruitmentCandidatesFilters.cities] = data[IRecruitmentCandidatesFilters.cities];
    this[IRecruitmentCandidatesFilters.postalCode] = data[IRecruitmentCandidatesFilters.postalCode];
  }

  getLocations() {
    return (this[IRecruitmentCandidatesFilters.locations] || []).map((location) => new LocationOptionModel(location));
  }

  getLanguages() {
    return (this[IRecruitmentCandidatesFilters.languages] || []).map((language) => new BasicOptionModel(language));
  }

  getExperiences() {
    return (this[IRecruitmentCandidatesFilters.workExperiences] || []).map((xp) => new BasicOptionModel(xp));
  }

  hasRecommendation() {
    return this[IRecruitmentCandidatesFilters.hasRecommendation] === 1;
  }

  hasVehicle() {
    return this[IRecruitmentCandidatesFilters.hasVehicle] === 1;
  }

  hasAlreadyWorkedIn() {
    return this[IRecruitmentCandidatesFilters.alreadyWorkedIn] === 1;
  }

  resetRecommendationFilter() {
    this[IRecruitmentCandidatesFilters.hasRecommendation] = 0;
  }

  resetVehicleFilter() {
    this[IRecruitmentCandidatesFilters.hasVehicle] = 0;
  }

  resetWorkedInFilter() {
    this[IRecruitmentCandidatesFilters.alreadyWorkedIn] = 0;
  }

  initFilters({ query, languageOptions, experienceOptions }) {
    this.initLocations(query);
    this.initLanguages(query, languageOptions);
    this.initExperiences(query, experienceOptions);
    this.initToggles(query);
  }

  initToggles(query = {}) {
    this[IRecruitmentCandidatesFilters.hasRecommendation] = Number(query[IRecruitmentCandidatesFilters.hasRecommendation] || 0);
    this[IRecruitmentCandidatesFilters.hasVehicle] = Number(query[IRecruitmentCandidatesFilters.hasVehicle] || 0);
    this[IRecruitmentCandidatesFilters.alreadyWorkedIn] = Number(query[IRecruitmentCandidatesFilters.alreadyWorkedIn] || 0);
  }

  initLocations(query) {
    // here we force array to have the same data when we retreive data from query and when we set data in form
    const cities = ArrayHelpers.forceArray(query[IRecruitmentCandidatesFilters.cities]);
    const cp = ArrayHelpers.forceArray(query[IRecruitmentCandidatesFilters.postalCode]);
    const locations = [];
    for (let index = 0; index < cities.length; index++) {
      const location = {
        [ICandidateLocationFilter.city]: cities[index],
        [ICandidateLocationFilter.zipcode]: cp[index],
      };
      locations.push(location);
    }
    this[IRecruitmentCandidatesFilters.locations] = locations.map((c) => new LocationOptionModel(c));
  }

  initLanguages(query, languageOptions) {
    // we retreive language from url after refresh to apply it on filters
    if (query[IRecruitmentCandidatesFilters.languages]) {
      this[IRecruitmentCandidatesFilters.languages] = languageOptions.filter((language) =>
        query[IRecruitmentCandidatesFilters.languages].includes(language.getCode()),
      );
    }
  }

  initExperiences(query, experienceOptions) {
    // we retreive experience from url after refresh to apply it on filters
    if (query[IRecruitmentCandidatesFilters.workExperiences]) {
      this[IRecruitmentCandidatesFilters.workExperiences] = experienceOptions.filter((xp) =>
        query[IRecruitmentCandidatesFilters.workExperiences].includes(xp.getCode()),
      );
    }
  }

  getQuery() {
    const cities = this.getLocations().map((location) => location.getCity());
    const cp = this.getLocations().map((location) => location.getCode());
    const selectedLanguages = this.getLanguages().map((language) => language.getCode());
    const selectedExperiences = this.getExperiences().map((xp) => xp.getCode());
    const hasRecommendation = this.hasRecommendation() ? 1 : undefined;
    const alreadyWorkedIn = this.hasAlreadyWorkedIn() ? 1 : undefined;
    const hasVehicle = this.hasVehicle() ? 1 : undefined;
    return {
      [IRecruitmentCandidatesFilters.cities]: cities,
      [IRecruitmentCandidatesFilters.postalCode]: cp,
      [IRecruitmentCandidatesFilters.workExperiences]: selectedExperiences,
      [IRecruitmentCandidatesFilters.languages]: selectedLanguages,
      [IRecruitmentCandidatesFilters.hasRecommendation]: hasRecommendation,
      [IRecruitmentCandidatesFilters.alreadyWorkedIn]: alreadyWorkedIn,
      [IRecruitmentCandidatesFilters.hasVehicle]: hasVehicle,
    };
  }

  hasFiltersActive() {
    return (
      this.getLocations().length > 0 ||
      this.getExperiences().length > 0 ||
      this.getLanguages().length > 0 ||
      this.hasRecommendation() ||
      this.hasVehicle() ||
      this.hasAlreadyWorkedIn()
    );
  }

  removeFilters(selected, filters) {
    const index = filters.findIndex((filter) => filter.getCode() === selected.getCode());
    if (index !== -1) {
      filters.splice(index, 1);
    }
  }

  resetToggleFilters(toggle) {
    switch (toggle) {
      case IRecruitmentCandidatesFilters.hasRecommendation:
        this.resetRecommendationFilter();
        break;
      case IRecruitmentCandidatesFilters.hasVehicle:
        this.resetVehicleFilter();
        break;
      case IRecruitmentCandidatesFilters.alreadyWorkedIn:
        this.resetWorkedInFilter();
        break;
      default:
        break;
    }
  }
}
