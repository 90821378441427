import { DatesHelpers, ScheduleHelpers } from "@core/helpers";
import { i18n } from "@core/plugins/i18n";

export const IJobPlanningDays = Object.freeze({
  id: "id",
  label: "label",
  startHour: "start_hour",
  endHour: "end_hour",
  breakDuration: "break_duration",
  date: "date",
});

export class JobPlanningDaysModel {
  constructor(data = {}) {
    this[IJobPlanningDays.id] = data[IJobPlanningDays.id];
    this[IJobPlanningDays.label] = data[IJobPlanningDays.label];
    this[IJobPlanningDays.startHour] = data[IJobPlanningDays.startHour];
    this[IJobPlanningDays.endHour] = data[IJobPlanningDays.endHour];
    this[IJobPlanningDays.date] = data[IJobPlanningDays.date];
    this[IJobPlanningDays.breakDuration] = data[IJobPlanningDays.breakDuration];
  }

  getId() {
    return this[IJobPlanningDays.id];
  }

  getLabel() {
    return this[IJobPlanningDays.label];
  }

  getStartHour() {
    return this[IJobPlanningDays.startHour];
  }

  getEndHour() {
    return this[IJobPlanningDays.endHour];
  }

  getBreakDuration() {
    return this[IJobPlanningDays.breakDuration];
  }

  getDate() {
    return this[IJobPlanningDays.date];
  }

  getDateDisplay(format = "LL") {
    return DatesHelpers.getDayjs(this.getDate()).format(format);
  }

  getScheduleDisplay() {
    const start = this.getFormattedTimeDisplay(this.getStartHour());
    const end = this.getFormattedTimeDisplay(this.getEndHour());
    return `${start} à ${end}`;
  }

  getFormattedTimeDisplay(durationInMinutes) {
    const formattedTime = DatesHelpers.formatMinsToHrsMins(durationInMinutes % ScheduleHelpers.DAY_DURATION_MINUTES);
    const nextDay = durationInMinutes >= ScheduleHelpers.DAY_DURATION_MINUTES;
    const formattedNextDay = nextDay ? ` ${i18n.global.t("CORE.MODELS.SCHEDULE.@NEXT_DAY")}` : "";
    return `${formattedTime}${formattedNextDay}`;
  }

  getBreakDisplay() {
    if (this.getBreakDuration() >= 60) {
      return DatesHelpers.formatMinsToHrsMins(this.getBreakDuration());
    }
    return `${this.getBreakDuration()}min`;
  }

  hasSpecificHours() {
    return this.getEndHour() !== undefined && this.getStartHour() !== undefined;
  }
}
