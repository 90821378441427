import { DatesHelpers, JavascriptHelpers, ScheduleHelpers } from "@core/helpers";
import { CandidateOfferScheduleDayModel } from "./candidate-offer-schedule-day.model";

export const ICandidateOfferTypicalSchedule = Object.freeze({
  days: "days",
  fluctuating: "fluctuating",
  schedule: "schedule",
});

export class CandidateOfferTypicalScheduleModel {
  constructor(data = {}) {
    this[ICandidateOfferTypicalSchedule.days] = data[ICandidateOfferTypicalSchedule.days];
    this[ICandidateOfferTypicalSchedule.fluctuating] = data[ICandidateOfferTypicalSchedule.fluctuating];
    this[ICandidateOfferTypicalSchedule.schedule] = data[ICandidateOfferTypicalSchedule.schedule];
  }

  getDays() {
    return (this[ICandidateOfferTypicalSchedule.days] || []).map((day) => new CandidateOfferScheduleDayModel(day));
  }

  getSchedule() {
    return this[ICandidateOfferTypicalSchedule.schedule];
  }

  isFluctuating() {
    return this[ICandidateOfferTypicalSchedule.fluctuating] === true;
  }

  hasSpecificHours() {
    return this.getDays().find((day) => day.hasSpecificHours()) !== undefined;
  }

  getFormattedSchedule() {
    const formattedSchedule = [];
    DatesHelpers.weekdays.forEach((weekday, index) => {
      const foundDay = this.getDays().find((day) => day.label.toLowerCase() === weekday.label.toLowerCase());
      formattedSchedule.push({
        key: index,
        name: JavascriptHelpers.capitalizeFirstLetter(weekday.label),
        schedule: foundDay ? ScheduleHelpers.formatDaySchedule({ daySchedule: foundDay }) : null, // {start, end, break}
      });
    });
    return formattedSchedule;
  }
}
