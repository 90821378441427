import { DatesHelpers } from "@core/helpers";
import { i18n } from "@core/plugins/i18n";

export const ITimesheetShift = Object.freeze({
  date: "start_date",
  duration: "nb_hours_total",
  breakDuration: "nb_hours_break",
});

export class TimesheetShiftModel {
  constructor(data = {}) {
    this[ITimesheetShift.date] = data[ITimesheetShift.date];
    this[ITimesheetShift.duration] = data[ITimesheetShift.duration];
    this[ITimesheetShift.breakDuration] = data[ITimesheetShift.breakDuration];
  }

  isFilled() {
    return this[ITimesheetShift.date] !== undefined;
  }

  getDate() {
    return DatesHelpers.getDayjs(this[ITimesheetShift.date]);
  }

  getDuration() {
    return this[ITimesheetShift.duration];
  }

  getBreakDuration() {
    return this[ITimesheetShift.breakDuration];
  }

  getStartTimeInMinutes() {
    const minutes = this.getDate().minute();
    const hours = this.getDate().hour();
    return hours * 60 + minutes;
  }

  getEndTimeInMinutes() {
    const startTime = this.getStartTimeInMinutes();
    return startTime + (this.getDuration() || 0) * 60;
  }

  getBreakDurationInMinutes() {
    return (this.getBreakDuration() || 0) * 60;
  }

  getFormattedDate(format = "L") {
    return this.getDate().format(format);
  }

  getFormattedStartTime() {
    return this.getFormattedTime(this.getStartTimeInMinutes());
  }

  getFormattedEndTime() {
    return this.getFormattedTime(this.getEndTimeInMinutes());
  }

  getFormattedTime(durationInMinutes) {
    const formattedTime = DatesHelpers.formatMinsToHrsMins(durationInMinutes % DatesHelpers.DAY_DURATION_MINUTES);
    const nextDay = durationInMinutes >= DatesHelpers.DAY_DURATION_MINUTES;
    const formattedNextDay = nextDay ? ` ${i18n.global.t("CORE.MODELS.SCHEDULE.@NEXT_DAY")}` : "";
    return `${formattedTime}${formattedNextDay}`;
  }

  getFormattedBreakDuration() {
    const breakDuration = this.getBreakDurationInMinutes();
    return breakDuration > 0 ? DatesHelpers.formatMinsToHrsMins(breakDuration) : "";
  }

  formatShiftTime() {
    const start = this.getFormattedStartTime();
    const end = this.getFormattedEndTime();

    const breakDuration = this.getFormattedBreakDuration();
    const formattedTime = i18n.global.t("CORE.MODELS.SHIFT.@SHIFT_TIME", { start, end });
    const formattedBreak = breakDuration ? ` ${i18n.global.t("CORE.MODELS.SHIFT.@SHIFT_TIME_BREAK", { breakDuration })}` : "";

    return `${formattedTime}${formattedBreak}`;
  }
}
