import { CANDIDATES_STATUS } from "@core/constants";
import { JavascriptHelpers } from "@core/helpers";
import { QualificationModel } from "@core/models/common/qualification.model";
import { CandidateTagModel } from "./candidate-tag.model";
import { TopRecommendationModel } from "./top-recommendation.model";
import { CandidateReportsModel } from "../common";

export const ICandidate = Object.freeze({
  candidateReports: "candidate_reports",
  birthDate: "naissance",
  canceledComment: "raison_annulation",
  canceledExtra: "extra_annule",
  description: "description",
  email: "email",
  experiencesAmount: "nb_experiences",
  favorite: "favori",
  firstname: "prenom",
  id: "id",
  ignored: "ignored",
  lastname: "nom",
  linkedId: "id_demande_fille",
  note: "note",
  notesAmount: "nb_job_noted",
  phone: "telephone",
  photo: "photo",
  postalCode: "code_postal",
  qualifications: "qualifications",
  selected: "selected",
  status: "status",
  tags: "tags",
  topRecommendation: "top_recommandation",
  urlPhoto: "url_photo",
  warning: "warning",
  fromJoPool: "is_from_jo_pool",
});

export class CandidateModel {
  constructor(data = {}) {
    this[ICandidate.birthDate] = data[ICandidate.birthDate];
    this[ICandidate.canceledComment] = data[ICandidate.canceledComment];
    this[ICandidate.canceledExtra] = data[ICandidate.canceledExtra];
    this[ICandidate.candidateReports] = data[ICandidate.candidateReports];
    this[ICandidate.description] = data[ICandidate.description];
    this[ICandidate.email] = data[ICandidate.email];
    this[ICandidate.experiencesAmount] = data[ICandidate.experiencesAmount];
    this[ICandidate.favorite] = data[ICandidate.favorite];
    this[ICandidate.firstname] = data[ICandidate.firstname];
    this[ICandidate.id] = data[ICandidate.id];
    this[ICandidate.lastname] = data[ICandidate.lastname];
    this[ICandidate.linkedId] = data[ICandidate.linkedId];
    this[ICandidate.note] = data[ICandidate.note];
    this[ICandidate.notesAmount] = data[ICandidate.notesAmount];
    this[ICandidate.phone] = data[ICandidate.phone];
    this[ICandidate.photo] = data[ICandidate.photo];
    this[ICandidate.postalCode] = data[ICandidate.postalCode];
    this[ICandidate.qualifications] = data[ICandidate.qualifications];
    this[ICandidate.selected] = data[ICandidate.selected];
    this[ICandidate.ignored] = data[ICandidate.ignored];
    this[ICandidate.status] = data[ICandidate.status];
    this[ICandidate.topRecommendation] = data[ICandidate.topRecommendation];
    this[ICandidate.urlPhoto] = data[ICandidate.urlPhoto];
    this[ICandidate.tags] = data[ICandidate.tags];
    this[ICandidate.warning] = data[ICandidate.warning] === 1;
    this[ICandidate.fromJoPool] = data[ICandidate.fromJoPool];
  }

  getCandidateReports() {
    return new CandidateReportsModel(this[ICandidate.candidateReports]);
  }

  getId() {
    return this[ICandidate.id] && String(this[ICandidate.id]);
  }

  getFirstname() {
    return this[ICandidate.firstname];
  }

  getStatus() {
    return this[ICandidate.status];
  }

  getPicture() {
    return this[ICandidate.urlPhoto] || this[ICandidate.photo];
  }

  getTags() {
    return new CandidateTagModel(this[ICandidate.tags]);
  }

  getIdentity() {
    const phone = JavascriptHelpers.isValidPhoneNumber(this[ICandidate.phone]) ? this[ICandidate.phone] : undefined;
    return {
      firstname: this[ICandidate.firstname],
      lastname: this[ICandidate.lastname],
      phone,
      email: this[ICandidate.email],
      postalCode: this[ICandidate.postalCode],
    };
  }

  getQualification() {
    return (this[ICandidate.qualifications] || []).map((qualification) => new QualificationModel(qualification));
  }

  getDetails() {
    const postalCode = this[ICandidate.postalCode];
    if (postalCode) {
      return `${this[ICandidate.firstname]} (${this[ICandidate.postalCode]})`;
    }
    return this[ICandidate.firstname];
  }

  isCanceledByExtra() {
    return this[ICandidate.canceledExtra] === true;
  }

  getCanceledComment() {
    return this[ICandidate.canceledComment] || "";
  }

  isSelected() {
    return this[ICandidate.selected] === true;
  }

  isFavorite() {
    return this[ICandidate.favorite] === true;
  }

  isFromJoPool() {
    return this[ICandidate.fromJoPool] === true;
  }

  getRecommendation() {
    return new TopRecommendationModel(this[ICandidate.topRecommendation]);
  }

  getLinkedId() {
    return this[ICandidate.linkedId];
  }

  hasRefused() {
    return this.getStatus() === CANDIDATES_STATUS.EXTRA_REFUSE;
  }

  getPostalCode() {
    return this[ICandidate.postalCode];
  }

  getExperienceAmount() {
    return this[ICandidate.experiencesAmount];
  }

  getNote() {
    return this[ICandidate.note];
  }

  getNotesAmount() {
    return this[ICandidate.notesAmount];
  }

  isNovice() {
    return this[ICandidate.novice] === 1;
  }
}

export class CandidateSelectedModel extends CandidateModel {
  constructor(data = {}) {
    super({ ...data, [ICandidate.selected]: true });
  }
}

export class CandidateCanceledModel extends CandidateModel {
  constructor(data = {}) {
    super({ ...data, [ICandidate.canceledExtra]: true });
  }
}

export class CandidateIgnoredModel extends CandidateModel {
  constructor(data = {}) {
    super({ ...data, [ICandidate.ignored]: true });
  }
}
