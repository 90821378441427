export const IBillingSessionAmount = Object.freeze({
  totalTTC: "total_amount_t_t_c",
  totalHT: "total_amount_h_t",
  totalTax: "total_amount_tax",
});

export class BillingSessionAmountModel {
  constructor(data = {}) {
    this[IBillingSessionAmount.totalTTC] = data[IBillingSessionAmount.totalTTC];
    this[IBillingSessionAmount.totalHT] = data[IBillingSessionAmount.totalHT];
    this[IBillingSessionAmount.totalTax] = data[IBillingSessionAmount.totalTax];
  }

  hasTotal() {
    return this.getTotalTTC() > 0;
  }

  getTotalTTC() {
    return this[IBillingSessionAmount.totalTTC];
  }

  getTotalHT() {
    return this[IBillingSessionAmount.totalHT];
  }

  getTotalTax() {
    return this[IBillingSessionAmount.totalTax];
  }
}
