import { CalendarShiftSummaryModel } from "./calendar-shift-summary.model";

export const ICalendarShiftsSummaryList = Object.freeze({
  shifts: "shifts",
});

export class CalendarShiftsSummaryListModel {
  constructor(data = {}) {
    this[ICalendarShiftsSummaryList.shifts] = data[ICalendarShiftsSummaryList.shifts];
  }

  getShiftsSummary() {
    return (this[ICalendarShiftsSummaryList.shifts] || []).map((s) => new CalendarShiftSummaryModel(s));
  }

  getBookedDates() {
    return this.getShiftsSummary().filter((summary) => summary.hasBooked());
  }

  getCanceledDates() {
    return this.getShiftsSummary().filter((summary) => summary.hasCanceled());
  }

  isEmpty() {
    return this.getShiftsSummary().length === 0;
  }
}
