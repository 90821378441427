export const ICreationStepWeeklyHours = Object.freeze({
  hours: "hours",
  fluctuating: "fluctuating",
});

export class CreationStepWeeklyHoursModel {
  constructor(data = {}) {
    this[ICreationStepWeeklyHours.hours] = data[ICreationStepWeeklyHours.hours];
    this[ICreationStepWeeklyHours.fluctuating] = data[ICreationStepWeeklyHours.fluctuating];
  }

  getHours() {
    return this[ICreationStepWeeklyHours.hours];
  }

  isFluctuating() {
    return this[ICreationStepWeeklyHours.fluctuating] === true;
  }
}
