import { DatesHelpers, NumericHelpers } from "@core/helpers";
import { SubscriptionActionButtonModel } from "./subscription-action-button.model";

export const ISubscriptionOffers = Object.freeze({
  id: "id",
  label: "name",
  description: "description",
  orderDesktop: "order_desktop",
  mobileOrder: "order_mobile",
  monthsDuration: "months_duration",
  subText: "sub_text",
  img: "img",
  selectedByDefault: "selected_by_default",
  amountHt: "amount_ht",
  amountTtc: "amount_ttc",
  buttons: "buttons",
});

export class SubscriptionOffersModel {
  constructor(data = {}) {
    this[ISubscriptionOffers.id] = data[ISubscriptionOffers.id];
    this[ISubscriptionOffers.label] = data[ISubscriptionOffers.label];
    this[ISubscriptionOffers.description] = data[ISubscriptionOffers.description];
    this[ISubscriptionOffers.orderDesktop] = data[ISubscriptionOffers.orderDesktop];
    this[ISubscriptionOffers.mobileOrder] = data[ISubscriptionOffers.mobileOrder];
    this[ISubscriptionOffers.monthsDuration] = data[ISubscriptionOffers.monthsDuration];
    this[ISubscriptionOffers.subText] = data[ISubscriptionOffers.subText];
    this[ISubscriptionOffers.img] = data[ISubscriptionOffers.img];
    this[ISubscriptionOffers.selectedByDefault] = data[ISubscriptionOffers.selectedByDefault];
    this[ISubscriptionOffers.amountHt] = data[ISubscriptionOffers.amountHt];
    this[ISubscriptionOffers.amountTtc] = data[ISubscriptionOffers.amountTtc];
    this[ISubscriptionOffers.buttons] = data[ISubscriptionOffers.buttons];
  }

  getId() {
    return this[ISubscriptionOffers.id];
  }

  getLabel() {
    return this[ISubscriptionOffers.label];
  }

  getDescription() {
    return this[ISubscriptionOffers.description];
  }

  getDesktopOrder() {
    return this[ISubscriptionOffers.orderDesktop];
  }

  getMobileOrder() {
    return this[ISubscriptionOffers.mobileOrder];
  }

  getMonthDuration() {
    return this[ISubscriptionOffers.monthsDuration];
  }

  getSubtext() {
    return this[ISubscriptionOffers.subText];
  }

  getImg() {
    return this[ISubscriptionOffers.img];
  }

  isSelectedByDefaut() {
    return this[ISubscriptionOffers.selectedByDefault] === true;
  }

  getAmountHt() {
    return NumericHelpers.formatNumberAsCurrency({ number: this[ISubscriptionOffers.amountHt] });
  }

  getAmountTtc() {
    return NumericHelpers.formatNumberAsCurrency({ number: this[ISubscriptionOffers.amountTtc] });
  }

  getActionButtons() {
    return (this[ISubscriptionOffers.buttons] || []).map((button) => new SubscriptionActionButtonModel(button));
  }

  getFormattedEndDate() {
    const formattedDateNow = DatesHelpers.getDayjs();
    return DatesHelpers.getDayjs(formattedDateNow).add(this.getMonthDuration(), "M").format("DD/MM/YYYY");
  }
}
