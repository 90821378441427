export const IPaymentSession = Object.freeze({
  code: "code",
  label: "libelle",
});

export class PaymentSessionModel {
  constructor(data = {}) {
    this[IPaymentSession.code] = data[IPaymentSession.code];
    this[IPaymentSession.label] = data[IPaymentSession.label];
  }

  getCode() {
    return this[IPaymentSession.code];
  }

  getLabel() {
    return this[IPaymentSession.label];
  }
}
