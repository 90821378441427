import { ArrayHelpers } from "@core/helpers";
import { DEMAND_TYPES, RECRUITMENT_TYPES } from "@core/constants";
import { ICandidate } from "@core/models/extra";
import { EtablissementModel } from "@core/models/etab/etab-info/etablissement.model";
import { IRecruitment } from "./recruitment.interface";

export class Recruitment {
  constructor(data = {}) {
    this[IRecruitment.id] = data[IRecruitment.id];
    this[IRecruitment.type] = data[IRecruitment.type];
    this[IRecruitment.demandType] = data[IRecruitment.demandType];
    this[IRecruitment.candidates] = data[IRecruitment.candidates];
    this[IRecruitment.isBookingExpress] = data[IRecruitment.isBookingExpress];
    this[IRecruitment.secondsToExpiration] = data[IRecruitment.secondsToExpiration];
    this[IRecruitment.etablissement] = data[IRecruitment.etablissement];
    this[IRecruitment.isArchived] = data[IRecruitment.isArchived];
    this[IRecruitment.selectedCandidates] = data[IRecruitment.selectedCandidates];
    this[IRecruitment.currentCandidates] = data[IRecruitment.currentCandidates];
    this[IRecruitment.ignoredCandidates] = data[IRecruitment.ignoredCandidates];
    this[IRecruitment.canceledCandidates] = data[IRecruitment.canceledCandidates];
    this[IRecruitment.qualificationLabel] = data[IRecruitment.qualificationLabel];
    this[IRecruitment.isFilled] = data[IRecruitment.isFilled];
    this[IRecruitment.precisions] = data[IRecruitment.precisions];
    this[IRecruitment.appliedCandidatesAmount] = data[IRecruitment.appliedCandidatesAmount];
    this[IRecruitment.selectedCandidatesAmount] = data[IRecruitment.selectedCandidatesAmount];
    this[IRecruitment.ignoredCandidatesAmount] = data[IRecruitment.ignoredCandidatesAmount];
    this[IRecruitment.isNovicesAllowed] = data[IRecruitment.isNovicesAllowed];
  }

  getDemandId() {
    return this[IRecruitment.id];
  }

  getType() {
    return this[IRecruitment.type] || "";
  }

  getDemandType() {
    return this[IRecruitment.demandType] || "";
  }

  isBookingExpress() {
    return this[IRecruitment.isBookingExpress] === true;
  }

  getMissionAddress() {
    return this[IRecruitment.missionAddress];
  }

  getCity() {
    return this[IRecruitment.city];
  }

  getPostalCode() {
    return this[IRecruitment.postalCode];
  }

  isCDI() {
    return this.getDemandType().toLowerCase() === DEMAND_TYPES.CDI.toLowerCase();
  }

  isSeason() {
    return this.getDemandType().toLowerCase() === DEMAND_TYPES.SEASON.toLowerCase();
  }

  isAE() {
    return this.getDemandType().toLowerCase() === DEMAND_TYPES.AE.toLowerCase();
  }

  isMER() {
    return this.getType().toLowerCase() === DEMAND_TYPES.MER.toLowerCase();
  }

  isPermanent() {
    const demandTypes = RECRUITMENT_TYPES.PERMANENT.demandTypes;
    return demandTypes.includes(this.getDemandType());
  }

  isTemporary() {
    const demandTypes = RECRUITMENT_TYPES.TEMPORARY.demandTypes;
    return demandTypes.includes(this.getDemandType());
  }

  isArchived() {
    return this[IRecruitment.isArchived] === true;
  }

  isExpired() {
    return this[IRecruitment.secondsToExpiration] === 0;
  }

  getQualificationLabel() {
    return this[IRecruitment.qualificationLabel];
  }

  getPrecisions() {
    return this[IRecruitment.precisions];
  }

  getSecondsToExpiration() {
    return parseInt(this[IRecruitment.secondsToExpiration], 10) || 0;
  }

  isNovicesAllowed() {
    return this[IRecruitment.isNovicesAllowed] === true;
  }

  getAllCandidates() {
    const allCandidates = [
      ...this.getCanceledCandidates(),
      ...this.getSelectedCandidates(),
      ...this.getCurrentCandidates(),
      ...this.getIgnoredCandidates(),
    ];
    return ArrayHelpers.deduplicateByProperty(allCandidates, [ICandidate.id]);
  }

  getCandidateById(id) {
    return this.getAllCandidates().find((candidate) => candidate.getId() === String(id));
  }

  getSelectedCandidates() {
    return this[IRecruitment.selectedCandidates] || [];
  }

  getCurrentCandidates() {
    return this[IRecruitment.currentCandidates] || [];
  }

  getIgnoredCandidates() {
    return this[IRecruitment.ignoredCandidates] || [];
  }

  getCanceledCandidates() {
    return this[IRecruitment.canceledCandidates] || [];
  }

  getCurrentEtablissement() {
    return new EtablissementModel(this[IRecruitment.etablissement]);
  }

  getEtabFamilyGroupName() {
    return this.getCurrentEtablissement().getCategoryGroup().getName();
  }

  getAppliedCandidatesAmount() {
    return this[IRecruitment.appliedCandidatesAmount];
  }

  getSelectedCandidatesAmount() {
    return this[IRecruitment.selectedCandidatesAmount];
  }

  getIgnoredCandidatesAmount() {
    return this[IRecruitment.ignoredCandidatesAmount] || undefined;
  }

  /**
   * Method to be overriden
   */
  isFilled() {
    return false;
  }

  /**
   * Method to be overriden
   */
  isUnlocked() {
    return false;
  }

  /**
   * Method to be overriden
   */
  hasStarted() {
    return false;
  }

  /**
   * Method to be overriden
   */
  hasEnded() {
    return false;
  }

  /**
   * Method to be overriden
   */
  canBeCanceled() {
    return false;
  }
}
