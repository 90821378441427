export const ETAB = {
  SEGMENT: {
    CHR: "CHR",
    GRAND_COMPTE: "Grands comptes",
    CATERER: "Traiteurs/Events",
  },
};

export const QUALIFICATION_FAMILY = {
  BAR: "BAR",
  HEALTH: "SANTE",
  HOSTEL: "SALLE",
  KITCHEN: "CUISINE",
  RETAIL: "RETAIL",
  AUTRE: "AUTRE",
};
export const QUALIFICATION_FAMILY_LABEL = {
  HOSTEL: "Hôtel",
  BAR: "Salle/Bar",
  KITCHEN: "Cuisine",
  RETAIL: "Vente / Livraison",
  HEALTH: "Santé",
  OTHER: "Autres",
};

export const ETAB_FAMILY = {
  CAFE_REST: "CAFE_REST",
  BAR_CLUB: "BAR_CLUB",
  HOTEL: "HOTEL",
  F_AUTRE: "F_AUTRE",
  A_EMPORTER: "A_EMPORTER",
  MAGASIN: "MAGASIN",
  SANTE: "SANTE",
  COLLECTIVE: "COLLECTIVE",
  EVENT: "EVENEMENT",
};

export const BILLING_STATUS = {
  AE: "auto_e",
};

export const FORM_ORIGIN_INPUT_VALUES = [
  {
    label: "ETAB.FORMS.LABELS.ORIGINS.@FRIEND",
    value: "friend",
  },
  {
    label: "ETAB.FORMS.LABELS.ORIGINS.@EMPLOYEE",
    value: "employee",
  },
  {
    label: "ETAB.FORMS.LABELS.ORIGINS.@CLIENT",
    value: "client",
  },
  {
    label: "ETAB.FORMS.LABELS.ORIGINS.@GOOGLE",
    value: "google",
  },
  {
    label: "ETAB.FORMS.LABELS.ORIGINS.@SOCIAL_MEDIAS",
    value: "social_medias",
  },
  {
    label: "ETAB.FORMS.LABELS.ORIGINS.@PRESS",
    value: "press",
  },
  {
    label: "ETAB.FORMS.LABELS.ORIGINS.@EXTRACADABRA",
    value: "extracadabra",
  },
  {
    label: "ETAB.FORMS.LABELS.ORIGINS.@STORES",
    value: "stores",
  },
  {
    label: "ETAB.FORMS.LABELS.ORIGINS.@OTHER_RADIO",
    value: "other",
  },
];

export const ROLE = {
  MASTER: "ROLE_MULTIACCOUNT_SUPERVISOR_ETAB",
};

export const FEATURE_FLAGS = {
  EPCT: "FEATURE_EPCT",
};

export const ETAB_SUBSCRIPTION_STATUS = {
  ACTIVATED: "SUBSCRIPTION_ACTIVATED",
  CAN_SUBSCRIBE: "NO_SUBSCRIPTION_CAN_SUBSCRIBE",
  CAN_NOT_SUBSCRIBE: "NO_SUBSCRIPTION_CAN_NOT_SUBSCRIBE",
  FREE_OFFER: "NO_SUBSCRIPTION_FREE_OFFER",
};

export const SUBSCRIPTION_ACTION_BUTTON = {
  OK: "ok",
  NOT_OK: "notOk",
};

export const CREATION_ACTIVE_CONTRACT = {
  MER: {
    name: "mer",
    icon: "icon-extra-extra",
    label: "ETAB.PAGES.SEARCH_DASHBOARD.@EXTRA",
  },
  BOOKING_FAVORI: {
    name: "booking_favori",
    icon: "icon-extra-booking-express",
    label: "ETAB.PAGES.SEARCH_DASHBOARD.@BOOKING_EXPRESS",
  },
  MISSION_AUTOE: {
    name: "auto_e",
    icon: "icon-extra-extra",
    label: "ETAB.PAGES.SEARCH_DASHBOARD.@EXTRA",
  },
  INTERIM: {
    name: "interim",
    icon: "icon-extra-extra",
    label: "ETAB.PAGES.SEARCH_DASHBOARD.@INTERIM",
  },
  CDI: {
    name: "cdi",
    icon: "icon-extra-cdi",
    label: "ETAB.PAGES.SEARCH_DASHBOARD.@CDI",
  },
  SAISONNIER: {
    name: "saisonnier",
    icon: "icon-extra-saison",
    label: "ETAB.PAGES.SEARCH_DASHBOARD.@SEASON",
  },
};

export const CREATION_DATES_TYPES = {
  SINGLE: "single",
  MULTIPLE: "multiple",
  BOTH: "both",
};

export const TEMPLATE_CREATION_STEPS = {
  EXTRA_AMOUNT: "extra-amount",
  QUALIFICATION: "qualification",
  PLANNING: "planning",
  QUOTE: "quote",
  SHIFT: "shift",
  COMMENT: "comment",
  ADDRESS: "address",
  OUTFIT: "outfit",
  NOVICE: "novice",
  SALARY: "salary",
  WEEK: "week",
  SUMMARIZE: "summarize",
};
