export const ICategoryFamilyQualif = Object.freeze({
  categoryName: "category_name",
  categoryID: "category_id",
  groupQualifLinks: "group_qualif_links",
});

export const ICategoryFamilyQualifColumns = Object.freeze({
  categoryName: "category_name",
  groupQualifLinks: "group_qualif_links",
  status: "status",
});

export class CategoryFamilyQualifModel {
  constructor(data = {}) {
    this[ICategoryFamilyQualif.categoryName] = data[ICategoryFamilyQualif.categoryName];
    this[ICategoryFamilyQualif.categoryID] = data[ICategoryFamilyQualif.categoryID];
    this[ICategoryFamilyQualif.groupQualifLinks] = data[ICategoryFamilyQualif.groupQualifLinks];
  }

  getCategoryID() {
    return this[ICategoryFamilyQualif.categoryID];
  }

  getCategoryName() {
    return this[ICategoryFamilyQualif.categoryName];
  }

  getGroupQualifLinks() {
    return this[ICategoryFamilyQualif.groupQualifLinks] || [];
  }

  setGroupQualifLinks(groupQualifLinks) {
    this[ICategoryFamilyQualif.groupQualifLinks] = groupQualifLinks;
  }
}
