import { JobsOfferListItem } from "./jobs-offer-list-item.model";

export const IJobsSearchList = Object.freeze({
  total: "total",
  jobsOffers: "jobsOffers",
});

export class JobsSearchListModel {
  constructor(data = {}) {
    this[IJobsSearchList.total] = data[IJobsSearchList.total];
    this[IJobsSearchList.jobsOffers] = data[IJobsSearchList.jobsOffers];
  }

  getTotal() {
    return parseInt(this[IJobsSearchList.total], 10);
  }

  getJobsOffersList() {
    return (this[IJobsSearchList.jobsOffers] || []).map((offer) => new JobsOfferListItem(offer));
  }
}
