export const IMultiAccountEtab = Object.freeze({
  id: "id",
  name: "name",
  picture: "picture",
  isSelected: "isSelected",
});

export class MultiAccountEtabModel {
  constructor(data = {}) {
    this[IMultiAccountEtab.id] = data[IMultiAccountEtab.id];
    this[IMultiAccountEtab.name] = data[IMultiAccountEtab.name];
    this[IMultiAccountEtab.picture] = data[IMultiAccountEtab.picture];
    this[IMultiAccountEtab.isSelected] = data[IMultiAccountEtab.isSelected];
  }

  getId() {
    return this[IMultiAccountEtab.id];
  }

  getName() {
    return this[IMultiAccountEtab.name];
  }

  getPicture() {
    return this[IMultiAccountEtab.picture];
  }
}
