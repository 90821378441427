import { TIMESHEET_STATUS, TIMESHEET_DETAILED_STATUS } from "@core/constants";
import { JavascriptHelpers } from "@core/helpers";
import { TimesheetShiftModel } from "./timesheet-shift.model";
import { TimesheetScheduleModel, ITimesheetSchedule } from "./timesheet-schedule.model";

export const ITimesheet = Object.freeze({
  id: "id",
  etabId: "etab_id",
  etabName: "etab_label",
  segment: "etab_segment",
  extraId: "extra_id",
  extraFullname: "extra_label",
  extraFirstname: "extra_firstname",
  extraLastname: "extra_lastname",
  extraQualif: "extra_qualif_name",
  missionId: "mission_id",
  status: "status",
  detailedStatus: "detailed_status",
  planned: "timesheet_planned",
  extra: "timesheet_extra",
  etab: "timesheet_etab",
  billable: "timesheet_billable",
  shiftId: "shift_id",
  etabEncodedId: "etab_encoded_id",
  extraEncodedId: "extra_encoded_id",
});

export const ITimesheetColumns = Object.freeze({
  date: "date",
  shift: "shift",
  etab: "etab",
  extra: "extra",
  filledStatus: "filledStatus",
  segment: "segment",
  status: "status",
  detailedStatus: "detailedStatus",
});

export class TimesheetModel {
  constructor(data = {}) {
    this[ITimesheet.id] = data[ITimesheet.id];
    this[ITimesheet.etabId] = data[ITimesheet.etabId];
    this[ITimesheet.etabName] = data[ITimesheet.etabName];
    this[ITimesheet.segment] = data[ITimesheet.segment];
    this[ITimesheet.extraId] = data[ITimesheet.extraId];
    this[ITimesheet.extraFullname] = data[ITimesheet.extraFullname];
    this[ITimesheet.extraFirstname] = data[ITimesheet.extraFirstname];
    this[ITimesheet.extraLastname] = data[ITimesheet.extraLastname];
    this[ITimesheet.extraQualif] = data[ITimesheet.extraQualif];
    this[ITimesheet.missionId] = data[ITimesheet.missionId];
    this[ITimesheet.status] = data[ITimesheet.status];
    this[ITimesheet.detailedStatus] = data[ITimesheet.detailedStatus];
    this[ITimesheet.planned] = data[ITimesheet.planned];
    this[ITimesheet.extra] = data[ITimesheet.extra];
    this[ITimesheet.etab] = data[ITimesheet.etab];
    this[ITimesheet.billable] = data[ITimesheet.billable];
    this[ITimesheet.shiftId] = data[ITimesheet.shiftId];
    this[ITimesheet.etabEncodedId] = data[ITimesheet.etabEncodedId];
    this[ITimesheet.extraEncodedId] = data[ITimesheet.extraEncodedId];
  }

  getId() {
    return this[ITimesheet.id];
  }

  getEtabId() {
    return this[ITimesheet.etabId];
  }

  getEtabName() {
    return this[ITimesheet.etabName];
  }

  getExtraId() {
    return this[ITimesheet.extraId];
  }

  getExtraFullname() {
    return `${this.getExtraFirstname()} ${this.getExtraLastname()}`;
  }

  getExtraShortname() {
    if (this.getExtraFullname().length > 20) {
      return `${this.getExtraFirstname()} ${this.getExtraLastname().charAt(0).toUpperCase()}.`;
    }
    return this.getExtraFullname();
  }

  getExtraFirstname() {
    return this[ITimesheet.extraFirstname] || "";
  }

  getExtraLastname() {
    return this[ITimesheet.extraLastname] || "";
  }

  getExtraQualif() {
    return this[ITimesheet.extraQualif] || "";
  }

  getSegment() {
    return this[ITimesheet.segment];
  }

  getMissionId() {
    return this[ITimesheet.missionId];
  }

  getShiftId() {
    return this[ITimesheet.shiftId];
  }

  getStatus() {
    return this[ITimesheet.status];
  }

  getDetailedStatus() {
    return this[ITimesheet.detailedStatus];
  }

  hasConflict() {
    return this.getDetailedStatus() === TIMESHEET_DETAILED_STATUS.CONFLICTED;
  }

  isBillable() {
    return this.getStatus() === TIMESHEET_STATUS.BILLABLE;
  }

  isExtraMissing() {
    return this.getDetailedStatus() === TIMESHEET_DETAILED_STATUS.EXTRA_NO_SHOW;
  }

  getEtabEncodedId() {
    return this[ITimesheet.etabEncodedId];
  }

  getExtraEncodedId() {
    return this[ITimesheet.extraEncodedId];
  }

  getPlannedTimesheet() {
    return new TimesheetShiftModel(this[ITimesheet.planned]);
  }

  getExtraTimesheet() {
    return new TimesheetShiftModel(this[ITimesheet.extra]);
  }

  getEtabTimesheet() {
    return new TimesheetShiftModel(this[ITimesheet.etab]);
  }

  getBillableTimesheet() {
    return new TimesheetShiftModel(this[ITimesheet.billable]);
  }

  getFormattedDate() {
    return this.getPlannedTimesheet().getFormattedDate();
  }

  getScheduleData() {
    const billable = this.getBillableTimesheet();
    const planned = this.getPlannedTimesheet();

    const timesheetReference = billable.isFilled() ? billable : planned;

    return new TimesheetScheduleModel({
      [ITimesheetSchedule.date]: planned.getDate(),
      [ITimesheetSchedule.start]: JavascriptHelpers.getRoundedNearest(timesheetReference.getStartTimeInMinutes()),
      [ITimesheetSchedule.end]: JavascriptHelpers.getRoundedNearest(timesheetReference.getEndTimeInMinutes()),
      [ITimesheetSchedule.breakDuration]: JavascriptHelpers.getRoundedNearest(timesheetReference.getBreakDurationInMinutes()),
    });
  }
}
