export const IBillingSessionFilters = Object.freeze({
  page: "page",
  take: "nb",
});

export class BillingSessionFiltersModel {
  constructor(data = {}) {
    this[IBillingSessionFilters.page] = data[IBillingSessionFilters.page];
    this[IBillingSessionFilters.take] = data[IBillingSessionFilters.take];
  }
}
