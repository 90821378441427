export const ISEOMetadata = Object.freeze({
  canonical: "canonical",
  description: "description",
  img: "og_image",
  title: "title",
  type: "og_type",
});

export class SEOMetadataModel {
  constructor(data = {}) {
    this[ISEOMetadata.canonical] = data[ISEOMetadata.canonical];
    this[ISEOMetadata.description] = data[ISEOMetadata.description];
    this[ISEOMetadata.img] = data[ISEOMetadata.img];
    this[ISEOMetadata.title] = data[ISEOMetadata.title];
    this[ISEOMetadata.type] = data[ISEOMetadata.type];
  }

  getTitle() {
    return this[ISEOMetadata.title];
  }

  getDescription() {
    return this[ISEOMetadata.description];
  }

  getImg() {
    return this[ISEOMetadata.img];
  }

  getType() {
    return this[ISEOMetadata.type];
  }

  getCanonical() {
    return this[ISEOMetadata.canonical];
  }
}
