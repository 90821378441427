import { NumericHelpers, DatesHelpers } from "@core/helpers";

export const ICreationStepPricingBoost = Object.freeze({
  totalExtra: "total_extra",
  qualification: "qualification",
  hourlyRate: "hourly_rate",
  duration: "duration",
  boostPercentages: "percent_boosted_price",
  boostPercentage: "boost_percentage",
});

export class CreationStepPricingBoostModel {
  constructor(data = {}) {
    this[ICreationStepPricingBoost.totalExtra] = data[ICreationStepPricingBoost.totalExtra];
    this[ICreationStepPricingBoost.qualification] = data[ICreationStepPricingBoost.qualification];
    this[ICreationStepPricingBoost.hourlyRate] = data[ICreationStepPricingBoost.hourlyRate];
    this[ICreationStepPricingBoost.duration] = data[ICreationStepPricingBoost.duration];
    this[ICreationStepPricingBoost.boostPercentages] = data[ICreationStepPricingBoost.boostPercentages];
    this[ICreationStepPricingBoost.boostPercentage] = data[ICreationStepPricingBoost.boostPercentage];
  }

  getTotalExtra() {
    return this[ICreationStepPricingBoost.totalExtra] || 0;
  }

  getQualification() {
    return this[ICreationStepPricingBoost.qualification] || "";
  }

  getHourlyRate() {
    return this[ICreationStepPricingBoost.hourlyRate] || 0;
  }

  getDuration() {
    return this[ICreationStepPricingBoost.duration] || 0;
  }

  getDurationDisplay() {
    return DatesHelpers.formatMinsToHrsMins(this.getDuration() * 60);
  }

  getPriceDisplay() {
    return NumericHelpers.formatNumberAsCurrency({ number: this.getDuration() * this.getHourlyRate(), forceTrailingZeros: true });
  }

  getHourlyRateDisplay() {
    // we round it to 2 decimals
    return NumericHelpers.formatNumberAsCurrency({ number: this.getHourlyRate(), forceTrailingZeros: true });
  }

  getBoostPercentage() {
    return this[ICreationStepPricingBoost.boostPercentage] || 0;
  }

  getBoostPercentagesList() {
    return this[ICreationStepPricingBoost.boostPercentages] || [];
  }

  isPricingBoostActivated() {
    return this.getBoostPercentagesList().length > 0;
  }
}
