import { BasicOptionModel } from "@core/models/common/basic-option.model";

export const ICandidateLanguage = Object.freeze({
  id: "id",
  lang: "language",
  level: "level",
});

export class CandidateLanguageModel {
  constructor(data = {}) {
    this[ICandidateLanguage.id] = data[ICandidateLanguage.id];
    this[ICandidateLanguage.lang] = data[ICandidateLanguage.lang];
    this[ICandidateLanguage.level] = data[ICandidateLanguage.level];
  }

  getId() {
    return this[ICandidateLanguage.id];
  }

  getLang() {
    return new BasicOptionModel(this[ICandidateLanguage.lang]);
  }

  getLevel() {
    return new BasicOptionModel(this[ICandidateLanguage.level]);
  }
}
