export const ICreationStepComment = Object.freeze({
  comment: "comment",
});

export class CreationStepCommentModel {
  constructor(data = {}) {
    this[ICreationStepComment.comment] = data[ICreationStepComment.comment];
  }

  getComment() {
    return this[ICreationStepComment.comment];
  }
}
