import { CORE_EVENTS } from "@core/constants";
import { Logger } from "@core/helpers/logger.helpers";
import { EventManager } from "@core/managers/event.manager";
import { i18n } from "@core/plugins/i18n";

export const errorType = {
  SERVER_FAILED: "SERVER_FAILED",
  COULDNT_REACH: "COULDNT_REACH",
  REQUEST_FAILED: "REQUEST_FAILED",
};

/**
 * Qualifies the various HTTP failed response
 * We can disable error handling in the call configuration
 * @example
 * api.get(url, { handleError: false })
 * @param {*} error - axios response error
 * @param {Object} error.config - axios request config
 * @param {Boolean} error.config.handleError - if set to false, do not handle error and return the falty response
 * @returns {Promise} rejected promise
 */
export function errorHandlerInterceptor(error) {
  const config = error.config || {};
  if (config.handleError === false) {
    error.handled = false;
  } else {
    error.handled = true;
    if (error.response) {
      error.errorType = handleServerFailed(error);
    } else if (error.request) {
      error.errorType = handleCouldntReach(error);
    } else {
      error.errorType = handleRequestFailed(error);
    }
  }
  // Whatever happens, we return falty promise
  return Promise.reject(error);
}

/**
 * The request was made and the server responded with a status code that falls out of the range of 2xx
 * @param {*} error - axios response error
 * @param {Object} error.config - axios request config
 * @returns {string} error type SERVER_FAILED
 */
export function handleServerFailed(error = { response: {} }) {
  try {
    const { data = {}, status, headers } = error.response;
    const errorCode = data.code;
    switch (status) {
      case 401: {
        EventManager.emit(CORE_EVENTS.ERROR_401, { error, data, status, headers });
        break;
      }
      case 403: {
        // if there's a code, it means the api wants to say something to the user
        if (errorCode) {
          const message = i18n.global.t(`ERRORS.HTTP.@${errorCode}`);
          EventManager.emit(CORE_EVENTS.ERROR_403, { error, data, status, headers, message });
        }
        // otherwise we fail silently, each call will decide what to do
        break;
      }
      case 426: {
        // upgrade required
        EventManager.emit(CORE_EVENTS.FORCE_UPDATE, { error, data, status, headers });
        break;
      }
      default:
        break;
    }

    Logger.warn(errorCode, data, status, headers, error.config);
  } catch (err) {
    // fail silently
  }

  return errorType.SERVER_FAILED;
}

/**
 * The request was made but no response was received
 * @param {*} error - axios response error
 * @param {Object} error.request - instance of XMLHttpRequest
 * @param {Object} error.config - axios request config
 * @returns {string} error type COULDNT_REACH
 */
export function handleCouldntReach(error) {
  const { request } = error;
  const message = "The request was made but no response was received";
  Logger.warn(message, request, error.config);
  return errorType.COULDNT_REACH;
}

/**
 * Something happened in setting up the request that triggered an Error
 * @param {*} error - axios response error
 * @param {Object} error.config - axios request config
 * @returns {string} error type REQUEST_FAILED
 */
export function handleRequestFailed(error) {
  const { message } = error;
  Logger.warn(message, error.config);
  return errorType.REQUEST_FAILED;
}
