export const IRefusalReason = Object.freeze({
  active: "active",
  code: "code",
  text: "text",
  description: "description",
});

export class RefusalReasonModel {
  constructor(data = {}) {
    this[IRefusalReason.active] = data[IRefusalReason.active];
    this[IRefusalReason.code] = data[IRefusalReason.code];
    this[IRefusalReason.text] = data[IRefusalReason.text];
    this[IRefusalReason.description] = data[IRefusalReason.description];
  }

  isActive() {
    return this[IRefusalReason.active];
  }

  getCode() {
    return this[IRefusalReason.code];
  }

  getText() {
    return this[IRefusalReason.text] || "";
  }

  getDescription() {
    return this[IRefusalReason.description] || "";
  }
}
