export const ICandidateTimesheet = Object.freeze({
  hasIncompleteTimesheet: "has_incomplete_timesheet",
  timesheetLink: "candidate_activity_report_uri",
});

export class CandidateTimesheetModel {
  constructor(data = {}) {
    this[ICandidateTimesheet.hasIncompleteTimesheet] = data[ICandidateTimesheet.hasIncompleteTimesheet];
    this[ICandidateTimesheet.timesheetLink] = data[ICandidateTimesheet.timesheetLink];
  }

  hasIncompleteTimesheet() {
    return this[ICandidateTimesheet.hasIncompleteTimesheet];
  }

  getTimesheetLink() {
    return this[ICandidateTimesheet.timesheetLink];
  }
}
