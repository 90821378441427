import { DatesHelpers } from "@core/helpers";
import { QualificationModel } from "@core/models/common/qualification.model";
import { CANDIDATE_EXPERIENCE_CONTRACTS } from "@core/constants";

const DISPLAY_DATE_FORMAT_SHORT = "DD/MM/YYYY";

export const ICandidateReference = Object.freeze({
  birthdate: "birthdate",
  firstname: "firstname",
  lastname: "lastname",
  picture: "picture",
  contract: "contract",
  endDate: "end_date",
  etabName: "etab_name",
  expId: "exp",
  id: "id",
  postalCode: "postal_code",
  qualification: "qualification",
  startDate: "start_date",
});

export class CandidateReferenceModel {
  constructor(data = {}) {
    this[ICandidateReference.birthdate] = data[ICandidateReference.birthdate];
    this[ICandidateReference.firstname] = data[ICandidateReference.firstname];
    this[ICandidateReference.lastname] = data[ICandidateReference.lastname];
    this[ICandidateReference.picture] = data[ICandidateReference.picture];
    this[ICandidateReference.contract] = data[ICandidateReference.contract];
    this[ICandidateReference.endDate] = data[ICandidateReference.endDate];
    this[ICandidateReference.etabName] = data[ICandidateReference.etabName];
    this[ICandidateReference.expId] = data[ICandidateReference.expId];
    this[ICandidateReference.id] = data[ICandidateReference.id];
    this[ICandidateReference.postalCode] = data[ICandidateReference.postalCode];
    this[ICandidateReference.qualification] = data[ICandidateReference.qualification];
    this[ICandidateReference.startDate] = data[ICandidateReference.startDate];
  }

  getAge() {
    const birthdate = DatesHelpers.getDateMomentIfExist(this[ICandidateReference.birthdate]);
    return birthdate ? DatesHelpers.getDayjs().diff(birthdate, "year") : undefined;
  }

  getFullName() {
    if (this.getFirstname()) {
      return `${this.getFirstname()} ${this.getLastname()}`;
    }
    return "";
  }

  getFirstname() {
    return this[ICandidateReference.firstname];
  }

  getLastname() {
    return this[ICandidateReference.lastname];
  }

  getPicture() {
    return this[ICandidateReference.picture];
  }

  getContract() {
    return this[ICandidateReference.contract];
  }

  getDisplayedContract() {
    const contract = CANDIDATE_EXPERIENCE_CONTRACTS.find((c) => c.code === this.getContract());
    return contract.label;
  }

  getEtabName() {
    return this[ICandidateReference.etabName];
  }

  getPostalCode() {
    return this[ICandidateReference.postalCode];
  }

  getQualification() {
    return new QualificationModel(this[ICandidateReference.qualification]);
  }

  getStartDateDisplay() {
    return DatesHelpers.getDateMomentIfExist(this[ICandidateReference.startDate])?.format(DISPLAY_DATE_FORMAT_SHORT);
  }

  getEndDateDisplay() {
    return DatesHelpers.getDateMomentIfExist(this[ICandidateReference.endDate])?.format(DISPLAY_DATE_FORMAT_SHORT);
  }
}
