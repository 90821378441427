import { CARD_TYPES } from "@core/constants";

export const ICard = Object.freeze({
  brand: "display_brand",
  expMonth: "exp_month",
  expYear: "exp_year",
  id: "id",
  isDefault: "is_default",
  last4: "last_four",
  name: "name",
});
export class CardModel {
  constructor(data = {}) {
    this[ICard.brand] = data[ICard.brand];
    this[ICard.expMonth] = data[ICard.expMonth];
    this[ICard.expYear] = data[ICard.expYear];
    this[ICard.id] = data[ICard.id];
    this[ICard.isDefault] = data[ICard.isDefault];
    this[ICard.last4] = data[ICard.last4];
    this[ICard.name] = data[ICard.name];
  }

  getId() {
    return this[ICard.id];
  }

  getLast4() {
    return this[ICard.last4];
  }

  getCardDisplayMask() {
    return `XXXX XXXX XXXX ${this.getLast4()}`;
  }

  isCardDefault() {
    return this[ICard.isDefault] === true;
  }

  getBrand() {
    return this[ICard.brand] || "";
  }

  isVisa() {
    return this.getBrand().toLowerCase() === CARD_TYPES.VISA.toLowerCase();
  }

  isMastercard() {
    return this.getBrand().toLowerCase() === CARD_TYPES.MASTERCARD.toLowerCase();
  }
}
