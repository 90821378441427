import { SanitationHelper } from "@core/helpers";

export const ICandidateApplyStatusBlock = Object.freeze({
  number: "number",
  title: "title",
  content: "content",
  isAppBlock: "is_app_block",
  actionLink: "cta_link",
  actionLabel: "cta_label",
});

export class CandidateApplyStatusBlockModel {
  constructor(data = {}) {
    this[ICandidateApplyStatusBlock.number] = data[ICandidateApplyStatusBlock.number];
    this[ICandidateApplyStatusBlock.title] = data[ICandidateApplyStatusBlock.title];
    this[ICandidateApplyStatusBlock.content] = data[ICandidateApplyStatusBlock.content];
    this[ICandidateApplyStatusBlock.isAppBlock] = data[ICandidateApplyStatusBlock.isAppBlock];
    this[ICandidateApplyStatusBlock.actionLink] = data[ICandidateApplyStatusBlock.actionLink];
    this[ICandidateApplyStatusBlock.actionLabel] = data[ICandidateApplyStatusBlock.actionLabel];
  }

  getNumber() {
    return this[ICandidateApplyStatusBlock.number];
  }

  getTitle() {
    return this[ICandidateApplyStatusBlock.title];
  }

  getContent() {
    return SanitationHelper.sanitize(this[ICandidateApplyStatusBlock.content] || "");
  }

  isAppBlock() {
    return this[ICandidateApplyStatusBlock.isAppBlock] === true;
  }

  getActionLink() {
    return this[ICandidateApplyStatusBlock.actionLink];
  }

  getActionLabel() {
    return this[ICandidateApplyStatusBlock.actionLabel];
  }

  hasCTA() {
    return this.getActionLink() && this.getActionLabel();
  }
}
