import { WalletLinesModel } from "./wallet-lines.model";

export const IWallet = Object.freeze({
  indentifierId: "identifier_id",
  indentifierType: "identifier_type",
  amount: "total",
  walletLines: "wallet_lines",
});

export class WalletModel {
  constructor(data = {}) {
    this[IWallet.indentifierId] = data[IWallet.indentifierId];
    this[IWallet.indentifierType] = data[IWallet.indentifierType];
    this[IWallet.amount] = data[IWallet.amount];
    this[IWallet.walletLines] = data[IWallet.walletLines];
  }

  getIdentifierId() {
    return this[IWallet.indentifierId];
  }

  getIdentifierType() {
    return this[IWallet.indentifierType];
  }

  getAmount() {
    return this[IWallet.amount];
  }

  getWalletLines() {
    return this[IWallet.walletLines]?.map((walletLine) => new WalletLinesModel(walletLine));
  }
}
