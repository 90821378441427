import { SHIFT_STATUS } from "@core/constants";
import { DatesHelpers } from "@core/helpers";
import { QualificationModel } from "@core/models/common/qualification.model";
import { ExtraModel } from "@core/models/extra/extra.model";

export const IPlanningShift = Object.freeze({
  breakDuration: "nb_heures_pause",
  missionId: "id_mission",
  duration: "nb_heures",
  extraBooked: "extra_booked",
  hourlyWage: "prix_horaire",
  jobId: "id_job",
  qualification: "qualification",
  startingDateTime: "date_heure_debut_shift",
  status: "status",
});

export class PlanningShiftModel {
  constructor(data = {}) {
    this[IPlanningShift.breakDuration] = data[IPlanningShift.breakDuration];
    this[IPlanningShift.missionId] = data[IPlanningShift.missionId];
    this[IPlanningShift.duration] = data[IPlanningShift.duration];
    this[IPlanningShift.extraBooked] = data[IPlanningShift.extraBooked];
    this[IPlanningShift.hourlyWage] = data[IPlanningShift.hourlyWage];
    this[IPlanningShift.jobId] = data[IPlanningShift.jobId];
    this[IPlanningShift.qualification] = data[IPlanningShift.qualification];
    this[IPlanningShift.startingDateTime] = data[IPlanningShift.startingDateTime];
    this[IPlanningShift.status] = data[IPlanningShift.status];
  }

  getJobId() {
    return this[IPlanningShift.jobId];
  }

  getMissionId() {
    return this[IPlanningShift.missionId];
  }

  getDate() {
    return DatesHelpers.getDayjs(this[IPlanningShift.startingDateTime]);
  }

  getExtraBooked() {
    return new ExtraModel(this[IPlanningShift.extraBooked]);
  }

  getQualification() {
    return new QualificationModel(this[IPlanningShift.qualification]);
  }

  isCanceledByExtra() {
    return this[IPlanningShift.status] === SHIFT_STATUS.EXTRA_CANCELED;
  }

  isExtraMissing() {
    return this[IPlanningShift.status] === SHIFT_STATUS.EXTRA_MISSING;
  }

  isBooked() {
    return this[IPlanningShift.status] === SHIFT_STATUS.BOOKED;
  }

  getStartingDateTime() {
    return this[IPlanningShift.startingDateTime];
  }

  getSchedule() {
    const start = this.getDate();
    const end = this.getDate().add(this[IPlanningShift.duration], "hours");

    return {
      start,
      end,
    };
  }

  getBreakDuration() {
    return this[IPlanningShift.breakDuration] || 0;
  }

  getBreakDisplay() {
    const breakDuration = this.getBreakDuration() * 60;
    if (breakDuration === 0) {
      return "";
    }
    if (breakDuration > 60) {
      return DatesHelpers.formatMinsToHrsMins(breakDuration);
    }
    return `${breakDuration}min`;
  }

  getStartTimeDisplay(format = "HH:mm") {
    const { start } = this.getSchedule();
    return start.format(format);
  }

  getEndTimeDisplay(format = "HH:mm") {
    const { end } = this.getSchedule();
    return end.format(format);
  }
}
