export const IUserEtab = Object.freeze({
  email: "email",
  firstConnexion: "first_connexion",
  name: "nom",
  role: "role",
});

export class UserEtabModel {
  constructor(data = {}) {
    this[IUserEtab.email] = data[IUserEtab.email];
    this[IUserEtab.firstConnexion] = data[IUserEtab.firstConnexion];
    this[IUserEtab.name] = data[IUserEtab.name];
    this[IUserEtab.role] = data[IUserEtab.role];
  }
}
