import { TimesheetModel } from "./timesheet.model";

export const ITimesheetsList = Object.freeze({
  total: "total",
  timesheets: "timesheets",
});

export class TimesheetsListModel {
  constructor(data = {}) {
    this[ITimesheetsList.total] = data[ITimesheetsList.total];
    this[ITimesheetsList.timesheets] = data[ITimesheetsList.timesheets];
  }

  getTotal() {
    return this[ITimesheetsList.total];
  }

  getTimesheetList() {
    return (this[ITimesheetsList.timesheets] || []).map((row) => new TimesheetModel(row));
  }
}
