import { DatesHelpers } from "@core/helpers";

export const ITimesheetFilters = Object.freeze({
  minDate: "dateMin",
  maxDate: "dateMax",
  etabId: "etab_id",
  extraId: "extra_id",
  missionId: "mission_id",
  segments: "segments",
  locations: "etab_location",
  status: "status",
  page: "page",
  take: "nb",
});

export class TimesheetFiltersModel {
  constructor(data = {}) {
    this[ITimesheetFilters.minDate] = data[ITimesheetFilters.minDate];
    this[ITimesheetFilters.maxDate] = data[ITimesheetFilters.maxDate];
    this[ITimesheetFilters.etabId] = data[ITimesheetFilters.etabId];
    this[ITimesheetFilters.extraId] = data[ITimesheetFilters.extraId];
    this[ITimesheetFilters.missionId] = data[ITimesheetFilters.missionId];
    this[ITimesheetFilters.segments] = data[ITimesheetFilters.segments];
    this[ITimesheetFilters.locations] = data[ITimesheetFilters.locations];
    this[ITimesheetFilters.status] = data[ITimesheetFilters.status];
    this[ITimesheetFilters.page] = data[ITimesheetFilters.page];
    this[ITimesheetFilters.take] = data[ITimesheetFilters.take];
  }

  hasDates() {
    const date = this[ITimesheetFilters.minDate];
    return date !== undefined && date !== null;
  }

  getFormattedDates(format = "L") {
    return {
      min: this[ITimesheetFilters.minDate] ? DatesHelpers.getDayjs(this[ITimesheetFilters.minDate]).format(format) : undefined,
      max: this[ITimesheetFilters.maxDate] ? DatesHelpers.getDayjs(this[ITimesheetFilters.maxDate]).format(format) : undefined,
    };
  }

  getStatus() {
    const status = this[ITimesheetFilters.status];
    return Array.isArray(status) && status.length > 0 ? status : undefined;
  }

  getSegments() {
    const segments = this[ITimesheetFilters.segments];
    return Array.isArray(segments) && segments.length > 0 ? segments : undefined;
  }

  getLocations() {
    const locations = this[ITimesheetFilters.locations];
    return Array.isArray(locations) && locations.length > 0 ? locations : undefined;
  }
}
