import { DatesHelpers, JavascriptHelpers } from "@core/helpers";
import { SHIFT_STATUS } from "@core/constants";
import { QualificationModel } from "@core/models/common/qualification.model";

export const IShift = Object.freeze({
  breakDuration: "nb_heures_pause",
  duration: "nb_heures",
  extraBooked: "extra_booked",
  hourlyWage: "prix_horaire",
  id: "id_job",
  qualification: "qualification",
  startingDateTime: "date_heure_debut_shift",
  status: "status",
});

export class ShiftModel {
  constructor(data = {}) {
    this[IShift.breakDuration] = data[IShift.breakDuration];
    this[IShift.duration] = data[IShift.duration];
    this[IShift.extraBooked] = data[IShift.extraBooked];
    this[IShift.hourlyWage] = data[IShift.hourlyWage];
    this[IShift.id] = data[IShift.id];
    this[IShift.qualification] = data[IShift.qualification];
    this[IShift.startingDateTime] = data[IShift.startingDateTime];
    this[IShift.status] = data[IShift.status];
  }

  getId() {
    return this[IShift.id];
  }

  hasExtraBooked() {
    return this.getExtraBooked() !== undefined;
  }

  getExtraBooked() {
    return this[IShift.extraBooked];
  }

  getQualification() {
    return new QualificationModel(this[IShift.qualification]);
  }

  isCanceledByExtra() {
    return this[IShift.status] === SHIFT_STATUS.EXTRA_CANCELED;
  }

  isExtraMissing() {
    return this[IShift.status] === SHIFT_STATUS.EXTRA_MISSING;
  }

  isBooked() {
    return this[IShift.status] === SHIFT_STATUS.BOOKED;
  }

  getStartingDateTime() {
    return this[IShift.startingDateTime];
  }

  getSchedule() {
    const start = DatesHelpers.getDayjs(this[IShift.startingDateTime]);
    const end = DatesHelpers.getDayjs(this[IShift.startingDateTime]).add(this[IShift.duration], "hours");

    return {
      start,
      end,
    };
  }

  hasStarted() {
    const now = DatesHelpers.getDayjs();
    return this.getSchedule().start.isBefore(now);
  }

  getDayDisplay() {
    const display = this[IShift.startingDateTime] ? DatesHelpers.getDayjs(this[IShift.startingDateTime]).format("dddd DD/MM") : "Shift";
    return JavascriptHelpers.capitalizeFirstLetter(display);
  }

  getBreakDuration() {
    return this[IShift.breakDuration] || 0;
  }

  getBreakDisplay() {
    const breakDuration = this.getBreakDuration() * 60;
    if (breakDuration === 0) {
      return "";
    }
    if (breakDuration > 60) {
      return DatesHelpers.formatMinsToHrsMins(breakDuration);
    }
    return `${breakDuration}min`;
  }

  getStartTimeDisplay(format = "HH:mm") {
    const { start } = this.getSchedule();
    return start.format(format);
  }

  getEndTimeDisplay(format = "HH:mm") {
    const { end } = this.getSchedule();
    return end.format(format);
  }
}
