import { SanitationHelper } from "@core/helpers";

export const IJobEtab = Object.freeze({
  name: "name",
  category: "category",
  upfront: "upfront",
  presentation: "presentation",
  etabLink: "etab_link",
});

export class JobEtabModel {
  constructor(data = {}) {
    this[IJobEtab.name] = data[IJobEtab.name];
    this[IJobEtab.category] = data[IJobEtab.category];
    this[IJobEtab.upfront] = data[IJobEtab.upfront];
    this[IJobEtab.presentation] = data[IJobEtab.presentation];
    this[IJobEtab.etabLink] = data[IJobEtab.etabLink];
  }

  getName() {
    return this[IJobEtab.name];
  }

  getCategory() {
    return this[IJobEtab.category] || "";
  }

  getPresentation() {
    return (
      SanitationHelper.sanitize(this[IJobEtab.presentation] || "")
        // add newlines on html linebreak
        .replace(/(?:\r\n|\r|\n|↵|&#8629;)/g, "<br />")
    );
  }

  isUpFront() {
    return this[IJobEtab.upfront] === true;
  }

  getEtabLink() {
    return this[IJobEtab.etabLink];
  }
}
