export const ICandidateReports = Object.freeze({
  noShowCount: "no_show_count",
  cancelation24hCount: "cancelation_24h_count",
  badBehaviourCount: "bad_behaviour_count",
});

export class CandidateReportsModel {
  constructor(data = {}) {
    this[ICandidateReports.noShowCount] = data[ICandidateReports.noShowCount];
    this[ICandidateReports.cancelation24hCount] = data[ICandidateReports.cancelation24hCount];
    this[ICandidateReports.badBehaviourCount] = data[ICandidateReports.badBehaviourCount];
  }

  getNoShowCount() {
    return this[ICandidateReports.noShowCount] || 0;
  }

  getCancelation24hCount() {
    return this[ICandidateReports.cancelation24hCount] || 0;
  }

  getBadBehaviourCount() {
    return this[ICandidateReports.badBehaviourCount] || 0;
  }

  getTotalReports() {
    return this.getNoShowCount() + this.getCancelation24hCount() + this.getBadBehaviourCount();
  }
}
