export const IBoAddress = Object.freeze({
  street: "street",
  postalCode: "zipcode",
  legacyPostalCode: "postal_code",
  city: "city",
});

export class BoAddressModel {
  constructor(data = {}) {
    this[IBoAddress.street] = data[IBoAddress.street];
    this[IBoAddress.postalCode] = data[IBoAddress.postalCode] || data[IBoAddress.legacyPostalCode];
    this[IBoAddress.city] = data[IBoAddress.city];
  }

  getFormattedAddress() {
    return `${this[IBoAddress.street] ?? ""} ${this[IBoAddress.postalCode] ?? ""} ${this[IBoAddress.city] ?? ""}`;
  }
}
