import { Capacitor } from "@capacitor/core";

export const PLATFORMS = {
  ANDROID: "android",
  IOS: "ios",
  WEB: "web",
};

export const isWeb = Capacitor.platform.toLowerCase() === PLATFORMS.WEB.toLowerCase();
export const isAndroid = Capacitor.platform.toLowerCase() === PLATFORMS.ANDROID.toLowerCase();
export const isIOS = Capacitor.platform.toLowerCase() === PLATFORMS.IOS.toLowerCase();
export const isMobile = isAndroid || isIOS;
export const isNative = Capacitor.isNative;
