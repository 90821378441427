import { DatesHelpers } from "@core/helpers";

export const IWalletLines = Object.freeze({
  amount: "amount",
  comment: "comment",
  createdAt: "created_at",
  createdBy: "created_by",
  identifierId: "identifier_id",
  identifierType: "identifier_type",
});

export class WalletLinesModel {
  constructor(data = {}) {
    this[IWalletLines.identifierId] = data[IWalletLines.identifierId];
    this[IWalletLines.identifierType] = data[IWalletLines.identifierType];
    this[IWalletLines.amount] = data[IWalletLines.amount];
    this[IWalletLines.comment] = data[IWalletLines.comment];
    this[IWalletLines.createdAt] = data[IWalletLines.createdAt];
    this[IWalletLines.createdBy] = data[IWalletLines.createdBy];
  }

  getIdentifierId() {
    return this[IWalletLines.identifierId];
  }

  getIdentifierType() {
    return this[IWalletLines.identifierType];
  }

  getAmount() {
    return this[IWalletLines.amount];
  }

  getComment() {
    return this[IWalletLines.comment];
  }

  getCreationDate() {
    return DatesHelpers.getDayjs(this[IWalletLines.createdAt]);
  }

  getFormattedDate(format = "DD/MM/YYYY HH:mm") {
    return this.getCreationDate().format(format);
  }

  getCreatorId() {
    return this[IWalletLines.createdBy];
  }
}
