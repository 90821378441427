import { IReferenceContact, ReferenceContactModel } from "@core/models/common/reference-contact.model";
import { DatesHelpers } from "@core/helpers";
import { ReferenceDetailsModel } from "@core/models/back-office/experiences/reference-details.model";

export class BoReferenceContactModel extends ReferenceContactModel {
  constructor(data = {}) {
    super(data);
    this[IReferenceContact.date] = data[IReferenceContact.date];
    this[IReferenceContact.managerComment] = data[IReferenceContact.managerComment];
    this[IReferenceContact.managerFirstname] = data[IReferenceContact.managerFirstname];
    this[IReferenceContact.managerLastname] = data[IReferenceContact.managerLastname];
    this[IReferenceContact.managerPhoneNumber] = data[IReferenceContact.managerPhoneNumber];
    this[IReferenceContact.managerEmail] = data[IReferenceContact.managerEmail];
    this[IReferenceContact.managerValidationDate] = data[IReferenceContact.managerValidationDate];
    this[IReferenceContact.rating] = data[IReferenceContact.rating];
    this[IReferenceContact.managerDetails] = data[IReferenceContact.managerDetails];
    this[IReferenceContact.contactDetails] = data[IReferenceContact.contactDetails];
  }

  getManagerFirstname() {
    return this[IReferenceContact.managerFirstname] || "";
  }

  getManagerLastname() {
    return this[IReferenceContact.managerLastname] || "";
  }

  getFormattedManagerName() {
    return `${this.getManagerFirstname()} ${this.getManagerLastname()}`;
  }

  getManagerPhoneNumber() {
    return this[IReferenceContact.managerPhoneNumber] || "";
  }

  getManagerEmail() {
    return this[IReferenceContact.managerEmail] || "";
  }

  getManagerComment() {
    return this[IReferenceContact.managerComment] || "";
  }

  getRating() {
    return this[IReferenceContact.rating] || 0;
  }

  getManagerValidationDate() {
    return this[IReferenceContact.managerValidationDate];
  }

  getFormattedManagerValidationDate() {
    return DatesHelpers.getDayjs(this.getManagerValidationDate()).format("DD/MM/YYYY HH:mm");
  }

  getDate() {
    return this[IReferenceContact.date];
  }

  getFormattedDate() {
    return DatesHelpers.getDayjs(this.getDate()).format("DD/MM/YYYY HH:mm");
  }

  getManagerDetails() {
    return new ReferenceDetailsModel(this[IReferenceContact.managerDetails]);
  }

  getContactDetails() {
    return new ReferenceDetailsModel(this[IReferenceContact.contactDetails]);
  }
}
