import { NumericHelpers } from "@core/helpers";
import { CardModel } from "@core/models/etab/payment/card.model";
import { DEMAND_TYPES } from "@core/constants";

export const IDemandRecap = Object.freeze({
  card: "carte",
  contract: "contrat",
  creditAvailable: "canPayDemandeWithAvoir",
  creditTTC: "avoirTtc",
  favorite: "favori",
  mode: "mode",
  multiDays: "multiJours",
  offerCDI: "offre_complete_c_d_i",
  priceHT: "prix",
  priceTTC: "prixNet",
  subscriptionActive: "abonnementActif",
  demandType: "demand_type",
  demandQualification: "demand_qualification",
});

export class DemandRecapModel {
  constructor(data = {}) {
    this[IDemandRecap.card] = data[IDemandRecap.card];
    this[IDemandRecap.contract] = data[IDemandRecap.contract];
    this[IDemandRecap.creditAvailable] = data[IDemandRecap.creditAvailable];
    this[IDemandRecap.creditTTC] = data[IDemandRecap.creditTTC];
    this[IDemandRecap.favorite] = data[IDemandRecap.favorite];
    this[IDemandRecap.mode] = data[IDemandRecap.mode];
    this[IDemandRecap.multiDays] = data[IDemandRecap.multiDays];
    this[IDemandRecap.offerCDI] = data[IDemandRecap.offerCDI];
    this[IDemandRecap.priceHT] = data[IDemandRecap.priceHT];
    this[IDemandRecap.priceTTC] = data[IDemandRecap.priceTTC];
    this[IDemandRecap.subscriptionActive] = data[IDemandRecap.subscriptionActive];
    this[IDemandRecap.demandType] = data[IDemandRecap.demandType];
    this[IDemandRecap.demandQualification] = data[IDemandRecap.demandQualification];
  }

  getPriceTTC() {
    return this[IDemandRecap.priceTTC]
      ? NumericHelpers.formatNumberAsCurrency({ number: this[IDemandRecap.priceTTC], forceTrailingZeros: true })
      : "-";
  }

  getPriceHT() {
    return this[IDemandRecap.priceHT] ? NumericHelpers.formatNumberAsCurrency({ number: this[IDemandRecap.priceHT], forceTrailingZeros: true }) : "-";
  }

  getCredit() {
    return this[IDemandRecap.creditTTC]
      ? NumericHelpers.formatNumberAsCurrency({ number: this[IDemandRecap.creditTTC], forceTrailingZeros: true })
      : "-";
  }

  canUseCredit() {
    return this[IDemandRecap.creditAvailable] === true;
  }

  isFavorite() {
    return this[IDemandRecap.favorite] === true;
  }

  canUseSubscription() {
    return this[IDemandRecap.subscriptionActive] === true;
  }

  getCard() {
    return new CardModel(this[IDemandRecap.card]);
  }

  getDemandType() {
    return this[IDemandRecap.demandType];
  }

  getDemandQualification() {
    return this[IDemandRecap.demandQualification];
  }

  isCDI() {
    return this.getDemandType() === DEMAND_TYPES.CDI;
  }

  isSeason() {
    return this.getDemandType() === DEMAND_TYPES.SEASON;
  }
}
