import { IRecruitment, IRecruitmentAE } from "./recruitment.interface";
import { Recruitment } from "./recruitment";

export class RecruitmentAEModel extends Recruitment {
  constructor(data = {}) {
    super(data);

    this[IRecruitmentAE.canBeCanceled] = data[IRecruitmentAE.canBeCanceled];
    this[IRecruitmentAE.endDate] = data[IRecruitmentAE.endDate];
    this[IRecruitmentAE.isFilled] = data[IRecruitmentAE.isFilled];
    this[IRecruitmentAE.missionEnded] = data[IRecruitmentAE.missionEnded];
    this[IRecruitmentAE.missionStarted] = data[IRecruitmentAE.missionStarted];
    this[IRecruitmentAE.nbExtraRequired] = data[IRecruitmentAE.nbExtraRequired];
    this[IRecruitmentAE.nbExtrasBooked] = data[IRecruitmentAE.nbExtrasBooked];
    this[IRecruitmentAE.startDate] = data[IRecruitmentAE.startDate];
    this[IRecruitmentAE.startingDateTime] = data[IRecruitmentAE.startingDateTime];
  }

  getMissionId() {
    return this[IRecruitment.id];
  }

  isFilled() {
    return this[IRecruitmentAE.isFilled];
  }

  hasStarted() {
    return this[IRecruitmentAE.missionStarted];
  }

  hasEnded() {
    return this[IRecruitmentAE.missionEnded];
  }

  canBeCanceled() {
    return this[IRecruitmentAE.canBeCanceled] === true;
  }

  getShifts() {
    return this[IRecruitmentAE.shifts];
  }

  getExtraRequiredAmount() {
    return this[IRecruitmentAE.nbExtraRequired];
  }

  getExtraBookedAmount() {
    return this[IRecruitmentAE.nbExtrasBooked];
  }

  getExtraToRecruitAmount() {
    if (this.isFilled()) {
      return 0;
    }
    return this.getExtraRequiredAmount() - this.getExtraBookedAmount() - this.getCanceledCandidates().length;
  }

  getStartingDateTime() {
    return this[IRecruitmentAE.startingDateTime];
  }

  getStartDate() {
    return this[IRecruitmentAE.startDate];
  }

  getEndDate() {
    return this[IRecruitmentAE.endDate];
  }

  isOneDay() {
    return this.getStartDate().isSame(this.getEndDate(), "day");
  }
}
