import { DatesHelpers, JavascriptHelpers, ScheduleHelpers } from "@core/helpers";
import { JobPlanningDaysModel } from "./jobs-planning-days.model";

export const IJobsPlanning = Object.freeze({
  days: "days",
  fluctuating: "fluctuating",
  schedule: "schedule",
});

export class JobsPlanningModel {
  constructor(data = {}) {
    this[IJobsPlanning.days] = data[IJobsPlanning.days];
    this[IJobsPlanning.fluctuating] = data[IJobsPlanning.fluctuating];
    this[IJobsPlanning.schedule] = data[IJobsPlanning.schedule];
  }

  getDays() {
    return (this[IJobsPlanning.days] || []).map((day) => new JobPlanningDaysModel(day));
  }

  getSchedule() {
    return this[IJobsPlanning.schedule];
  }

  isFluctuating() {
    return this[IJobsPlanning.fluctuating] === true;
  }

  hasSpecificHours() {
    return this.getDays().find((day) => day.hasSpecificHours()) !== undefined;
  }

  getFormattedSchedule() {
    const formattedSchedule = [];
    DatesHelpers.weekdays.forEach((weekday, index) => {
      const foundDay = this.getDays().find((day) => day.label.toLowerCase() === weekday.label.toLowerCase());
      formattedSchedule.push({
        key: index,
        name: JavascriptHelpers.capitalizeFirstLetter(weekday.label),
        schedule: foundDay ? ScheduleHelpers.formatDaySchedule({ daySchedule: foundDay }) : null, // {start, end, break}
      });
    });
    return formattedSchedule;
  }
}
