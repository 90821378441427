export const ICreationStepCandidate = Object.freeze({
  candidate: "candidate",
});

export class CreationStepCandidateModel {
  constructor(data = {}) {
    this[ICreationStepCandidate.candidate] = data[ICreationStepCandidate.candidate];
  }

  getCandidate() {
    return this[ICreationStepCandidate.candidate];
  }
}
