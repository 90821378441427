import { OutfitSelectedModel } from "./outfit-selected.model";
import { IOutfits } from "./outfit.model";

export const IClothing = Object.freeze({
  comment: "comment",
  clothing: "clothing",
  provided: "provided",
});

export class ClothingModel {
  constructor(data = {}) {
    this[IClothing.comment] = data[IClothing.comment];
    this[IClothing.clothing] = data[IClothing.clothing];
    this[IClothing.provided] = data[IClothing.provided];
  }

  getSelectedOutfits() {
    return (this[IClothing.clothing] || []).map((outfit) => new OutfitSelectedModel(outfit));
  }

  getSelectedOutfit(id) {
    return this.getSelectedOutfits().find((outfit) => outfit.getId() === id);
  }

  isOutfitSelected(id) {
    return this.getSelectedOutfit(id) !== undefined;
  }

  selectOutfit(outfit, color) {
    if (!this.isOutfitSelected(outfit.getId())) {
      const selectedOutfits = this[IClothing.clothing] || [];
      if (color) {
        outfit[IOutfits.color] = color;
        outfit[IOutfits.colorId] = color.id;
      }
      selectedOutfits.push(new OutfitSelectedModel(outfit));
      this[IClothing.clothing] = [...selectedOutfits];
    }
  }

  unselectOutfit(outfit) {
    const index = this.getSelectedOutfits().findIndex((outfitSelected) => outfitSelected.getId() === outfit.getId());
    if (index !== -1) {
      this[IClothing.clothing].splice(index, 1);
    }
  }

  getOutfitColor(outfit) {
    const selectedOutfit = this.getSelectedOutfit(outfit.getId());
    const outfitColors = outfit.getAvailableColors();
    let foundOutfitColor = null;
    if (selectedOutfit?.getId() && outfitColors?.length) {
      foundOutfitColor = outfitColors.find((color) => color.getId() === selectedOutfit.getColor());
    }
    return foundOutfitColor?.getCode();
  }

  getOutfitColorCode(outfit) {
    return this.getOutfitColor(outfit);
  }

  setProvided(value) {
    this[IClothing.provided] = value;
  }

  getComment() {
    return this[IClothing.comment];
  }

  setComment(value) {
    this[IClothing.comment] = value;
  }

  setSpecified(value) {
    this[IClothing.specified] = value;
  }

  isSpecified() {
    return this[IClothing.specified] === true;
  }

  isDefined() {
    return this.getSelectedOutfits().length > 0 || this.getComment();
  }

  isProvided() {
    return this[IClothing.provided] === true;
  }
}
