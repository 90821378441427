/**
 * Manage the NODE Environment variables
 *
 * @export
 * @class EnvironmentManager
 */
export class EnvironmentManager {
  static isProduction() {
    return process.env.NODE_ENV === "production";
  }

  static isDevelopment() {
    return process.env.NODE_ENV === "development";
  }

  static isTest() {
    return process.env.NODE_ENV === "test";
  }
}
