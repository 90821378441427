export const IRecruitment = Object.freeze({
  canceledCandidates: "canceledCandidates",
  candidates: "candidates",
  currentCandidates: "currentCandidates",
  isBookingExpress: "booking_express_extra",
  demandType: "details_type",
  etablissement: "etablissement",
  secondsToExpiration: "expiration_time",
  id: "id",
  ignoredCandidates: "ignoredCandidates",
  ignoredCandidatesAmount: "total_ignored_candidates",
  isArchived: "is_archived",
  qualificationLabel: "qualificationLabel",
  MERJob: "mer_work",
  permanentJob: "permanent_work",
  selectedCandidates: "selectedCandidates",
  selectedCandidatesAmount: "total_selected_candidates",
  temporaryJob: "temporary_work",
  isFilled: "pourvue",
  precisions: "precisions",
  hasStarted: "hasStarted",
  type: "type",
  missionAddress: "adresse",
  city: "ville",
  postalCode: "code_postal",
  appliedCandidatesAmount: "total_applied_candidates",
  isNovicesAllowed: "open_to_novices",
});

export const IRecruitmentAE = Object.freeze({
  canBeCanceled: "annulable",
  endDate: "date_fin",
  hourlyWage: "prix_horaire",
  isFilled: "pourvue",
  missionEnded: "mission_ended",
  missionPrice: "prix_mission_extra_booked",
  missionStarted: "mission_started",
  nbExtraRequired: "nb_extras_voulus",
  nbExtrasBooked: "nb_extras_booked",
  nbExtrasWarning: "nb_extras_warning",
  precisions: "precisions",
  qualification: "qualification",
  shifts: "shifts",
  startDate: "date_debut",
  theoricalMissionPrice: "prix_theorique_mission",
  startingDateTime: "startingDateTime",
  endingDateTime: "endingDateTime",
});

export const IRecruitmentMER = Object.freeze({
  canBeCanceled: "annulable",
  candidates: "all_candidate",
  duration: "nombre_jour",
  isFilled: "pourvue",
  linkedId: "id_demande_fille",
  merEnded: "mer_ended",
  merStarted: "mer_started",
  multi: "multi",
  newCandidatesAmount: "nombre_nouveau_candidat",
  precisions: "precision",
  qualification: "qualification",
  startDate: "date_debut",
});

export const IRecruitmentPermanent = Object.freeze({
  precisions: "precisions",
  qualification: "qualification",
  statusByExtra: "status_by_extra",
  contact: "contact",
  recruited: "recruté",
  new: "nouveau",
  ignored: "refus",
  extraRefused: "refus_extra",
  unlimitedContact: "contact_illimite_valide",
  upfront: "upfront",
});
