import { NumericHelpers } from "@core/helpers";

export const ICandidateOfferSalary = Object.freeze({
  forecast: "forecast",
  periodicity: "periodicity",
  fluctuating: "fluctuating",
  amount: "amount",
  min: "min",
  max: "max",
});

export class CandidateOfferSalaryModel {
  constructor(data = {}) {
    this[ICandidateOfferSalary.forecast] = data[ICandidateOfferSalary.forecast];
    this[ICandidateOfferSalary.periodicity] = data[ICandidateOfferSalary.periodicity];
    this[ICandidateOfferSalary.fluctuating] = data[ICandidateOfferSalary.fluctuating];
    this[ICandidateOfferSalary.amount] = data[ICandidateOfferSalary.amount];
    this[ICandidateOfferSalary.min] = data[ICandidateOfferSalary.min];
    this[ICandidateOfferSalary.max] = data[ICandidateOfferSalary.max];
  }

  getAmount() {
    return this[ICandidateOfferSalary.amount];
  }

  getAmountDisplay() {
    return NumericHelpers.formatNumberAsCurrency({ number: this.getAmount() });
  }

  getMin() {
    return this[ICandidateOfferSalary.min];
  }

  getMinDisplay() {
    return NumericHelpers.formatNumberAsCurrency({ number: this.getMin() });
  }

  getMax() {
    return this[ICandidateOfferSalary.max];
  }

  getMaxDisplay() {
    return NumericHelpers.formatNumberAsCurrency({ number: this.getMax() });
  }

  getPeriodicity() {
    return this[ICandidateOfferSalary.periodicity];
  }

  isFluctuating() {
    return this[ICandidateOfferSalary.fluctuating] === true;
  }

  getDisplay() {
    const hasRange = this.getMin() && this.getMax();
    if (this.isFluctuating() && !hasRange) {
      return "CORE.COMPONENTS.OFFERS.@SALARY_FLUCTUATING";
    }
    if (hasRange) {
      return `${this.getMinDisplay()} à ${this.getMaxDisplay()}`;
    }
    return `${this.getAmountDisplay()}`;
  }

  getForecast() {
    return this[ICandidateOfferSalary.forecast];
  }

  getForecastDisplay() {
    const forecast = NumericHelpers.formatNumberAsCurrency({ number: this.getForecast() });
    return `${forecast}`;
  }
}
