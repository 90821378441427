import { BillableShiftModel } from "@core/models/back-office/billable-shifts/billable-shifts.model";

export const IBillingSessionCreation = Object.freeze({
  comment: "comment",
  date: "date",
  datePayment: "payment_date",
  isEtabSelected: "isEtabSelected",
  isExtraSelected: "isExtraSelected",
  shifts: "shifts",
  submitted: "submitted",
});

export class BillingSessionCreationModel {
  constructor(data = {}) {
    this[IBillingSessionCreation.comment] = data[IBillingSessionCreation.comment] || "";
    this[IBillingSessionCreation.date] = data[IBillingSessionCreation.date] || null;
    this[IBillingSessionCreation.datePayment] = data[IBillingSessionCreation.datePayment] || null;
    this[IBillingSessionCreation.isEtabSelected] = data[IBillingSessionCreation.isEtabSelected] === true;
    this[IBillingSessionCreation.isExtraSelected] = data[IBillingSessionCreation.isExtraSelected] === true;
    this[IBillingSessionCreation.shifts] = data[IBillingSessionCreation.shifts] || [];
    this[IBillingSessionCreation.submitted] = data[IBillingSessionCreation.submitted] === true;
  }

  getCommentLength() {
    return 255;
  }

  selectionIsValid() {
    return this[IBillingSessionCreation.isEtabSelected] || this[IBillingSessionCreation.isExtraSelected];
  }

  dateIsValid() {
    return this[IBillingSessionCreation.date] !== null;
  }

  datePaymentIsValid() {
    return this[IBillingSessionCreation.datePayment] !== null;
  }

  commentIsValid() {
    return this[IBillingSessionCreation.comment]?.length <= this.getCommentLength();
  }

  isValid() {
    return this.selectionIsValid() && this.dateIsValid() && this.datePaymentIsValid() && this.commentIsValid();
  }

  getShifts() {
    return this[IBillingSessionCreation.shifts].map((s) => new BillableShiftModel(s));
  }

  setShifts(shifts = []) {
    this[IBillingSessionCreation.shifts] = [...shifts];
  }

  setSubmitted() {
    this[IBillingSessionCreation.submitted] = true;
  }

  isSubmitted() {
    return this[IBillingSessionCreation.submitted] === true;
  }
}
