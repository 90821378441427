export const IMultiAccount = Object.freeze({
  link: "request_link",
});

export class MultiAccountModel {
  constructor(data = {}) {
    this[IMultiAccount.link] = data[IMultiAccount.link];
  }

  getLink() {
    return this[IMultiAccount.link];
  }
}
