import { i18n } from "@core/plugins/i18n";

export const IEducation = Object.freeze({
  date: "date",
  detail: "detail",
  id: "id",
  inProgress: "en_cours",
  label: "nom",
});

export class EducationModel {
  constructor(data = {}) {
    this[IEducation.date] = data[IEducation.date];
    this[IEducation.detail] = data[IEducation.detail];
    this[IEducation.id] = data[IEducation.id];
    this[IEducation.inProgress] = data[IEducation.inProgress];
    this[IEducation.label] = data[IEducation.label];
  }

  getPeriod() {
    if (this[IEducation.inProgress] === 1) {
      return i18n.global.t("CORE.MODELS.EDUCATION.@IN_PROGRESS");
    }
    return this[IEducation.date];
  }

  getTitle() {
    return this[IEducation.detail];
  }

  getLabel() {
    return this[IEducation.label];
  }

  getId() {
    return this[IEducation.id];
  }
}
