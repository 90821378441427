import { NumericHelpers } from "@core/helpers";

export const IJobSalary = Object.freeze({
  amount: "amount",
  fluctuating: "fluctuating",
  forecast: "forecast",
  periodicity: "periodicity",
  periodUnit: "period_unit",
  range: "range",
});

export const IJobSalaryRange = Object.freeze({
  min: "min",
  max: "max",
});

export class JobSalaryModel {
  constructor(data = {}) {
    this[IJobSalary.amount] = data[IJobSalary.amount];
    this[IJobSalary.fluctuating] = data[IJobSalary.fluctuating];
    this[IJobSalary.forecast] = data[IJobSalary.forecast];
    this[IJobSalary.periodicity] = data[IJobSalary.periodicity];
    this[IJobSalary.periodUnit] = data[IJobSalary.periodUnit];
    this[IJobSalary.range] = data[IJobSalary.range];
  }

  getAmount() {
    return parseFloat(this[IJobSalary.amount], 10);
  }

  getAmountDisplay() {
    if (this.hasRange()) {
      const min = NumericHelpers.formatNumberAsCurrency({ number: this.getRangeMin() });
      const max = NumericHelpers.formatNumberAsCurrency({ number: this.getRangeMax() });

      return `${min} - ${max}`;
    }

    const amount = this.getAmount();
    if (!amount) {
      return undefined;
    }
    return NumericHelpers.formatNumberAsCurrency({ number: amount });
  }

  hasRange() {
    return this.getRangeMin() && this.getRangeMax();
  }

  getRange() {
    return this[IJobSalary.range] || {};
  }

  getRangeMin() {
    return this.getRange()[IJobSalaryRange.min];
  }

  getRangeMax() {
    return this.getRange()[IJobSalaryRange.max];
  }

  getForecast() {
    return this[IJobSalary.forecast];
  }

  getPeriodUnit() {
    return this[IJobSalary.periodUnit];
  }

  getForecastDisplay() {
    const forecast = this.getForecast();
    if (!forecast) {
      return " - ";
    }
    return NumericHelpers.formatNumberAsCurrency({ number: forecast });
  }

  getPeriodicity() {
    return this[IJobSalary.periodicity];
  }

  isFluctuating() {
    return this[IJobSalary.fluctuating] === true;
  }
}
