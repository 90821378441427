import { BasicOptionModel } from "@core/models/common/basic-option.model";
import { LocationOptionModel } from "@core/models/common/candidate-filters-location.model";

export const ICandidateFilters = Object.freeze({
  locations: "location",
  languages: "language",
  workExperiences: "nb_exp",
  alreadyWorkedIn: "already_worked_in",
  hasRecommendation: "has_recommendation",
  hasVehicle: "has_vehicle",
  status: "status",
});

export class CandidateFiltersModel {
  constructor(data = {}) {
    this[ICandidateFilters.locations] = data[ICandidateFilters.locations];
    this[ICandidateFilters.languages] = data[ICandidateFilters.languages];
    this[ICandidateFilters.workExperiences] = data[ICandidateFilters.workExperiences];
    this[ICandidateFilters.alreadyWorkedIn] = data[ICandidateFilters.alreadyWorkedIn];
    this[ICandidateFilters.hasRecommendation] = data[ICandidateFilters.hasRecommendation];
    this[ICandidateFilters.hasVehicle] = data[ICandidateFilters.hasVehicle];
    this[ICandidateFilters.status] = data[ICandidateFilters.status];
  }

  getLocations() {
    return (this[ICandidateFilters.locations] || []).map((location) => new LocationOptionModel(location));
  }

  getLanguages() {
    return (this[ICandidateFilters.languages] || []).map((language) => new BasicOptionModel(language));
  }

  getExperiences() {
    return (this[ICandidateFilters.workExperiences] || []).map((xp) => new BasicOptionModel(xp));
  }

  hasRecommendation() {
    return this[ICandidateFilters.hasRecommendation] === 1;
  }

  hasVehicle() {
    return this[ICandidateFilters.hasVehicle] === 1;
  }

  hasAlreadyWorkedIn() {
    return this[ICandidateFilters.alreadyWorkedIn] === 1;
  }

  resetRecommendationFilter() {
    this[ICandidateFilters.hasRecommendation] = 0;
  }

  resetVehicleFilter() {
    this[ICandidateFilters.hasVehicle] = 0;
  }

  resetWorkedInFilter() {
    this[ICandidateFilters.alreadyWorkedIn] = 0;
  }

  hasFiltersActive() {
    return (
      this.getLocations().length > 0 ||
      this.getExperiences().length > 0 ||
      this.getLanguages().length > 0 ||
      this.hasRecommendation() ||
      this.hasVehicle() ||
      this.hasAlreadyWorkedIn()
    );
  }
}
