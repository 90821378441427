import { MIME_TYPES } from "@core/constants";

export const IBoFile = Object.freeze({
  file: "file",
  type: "type",
  blob: "blob",
});

export class BoFileModel {
  constructor(data = {}) {
    this[IBoFile.file] = data[IBoFile.file];
    this[IBoFile.type] = data[IBoFile.type];
    this[IBoFile.blob] = data[IBoFile.blob];
  }

  getFile() {
    return this[IBoFile.file];
  }

  getType() {
    return this[IBoFile.type];
  }

  getBlob() {
    return this[IBoFile.blob];
  }

  isPicture() {
    return this[IBoFile.type] === MIME_TYPES.PNG || this[IBoFile.type] === MIME_TYPES.JPEG || this[IBoFile.type] === MIME_TYPES.JPG;
  }

  isPdf() {
    return this[IBoFile.type] === MIME_TYPES.PDF;
  }
}
