export const DEFAULT_BREAK_DURATION = undefined;

export const DEMAND_TYPES = {
  CDI: "CDI",
  SEASON: "SAISONNIER",
  AE: "AE",
  EXTRA: "EXTRA", // @Deprecated: voir avec Romain s'il y a encore des Etabs MER
  MER: "MER",
  INTERIM: "INTERIM",
};

export const RECRUITMENT_TYPES = {
  PERMANENT: {
    label: "permanent",
    demandTypes: [DEMAND_TYPES.CDI, DEMAND_TYPES.SEASON],
  },
  TEMPORARY: {
    label: "temporary",
    shortLabel: "temp",
    demandTypes: [DEMAND_TYPES.AE, DEMAND_TYPES.EXTRA, DEMAND_TYPES.MER, DEMAND_TYPES.INTERIM],
  },
};

export const CANDIDATES_STATUS = {
  NEW: "nouveau",
  CONTACTED: "contact",
  IGNORED: "refus",
  SELECTED: "selected",
  CANCELED_BY_EXTRA: "cancelled",
  CANCELED_BY_ETAB: "ignored",
  CURRENT: "current",
  EXTRA_REFUSE: "extra_refuse",
};

export const PERMANENT_CANDIDATES_STATUS = {
  CONTACTED: "contacted",
  RECRUITED: "recruited",
  REPORTED: "reported",
  APPLIED: "applied",
  IGNORED: "ignored",
};

export const EXTRA_STATUS = {
  CANDIDATE: "current",
  CANCELED: "cancelled",
  SELECTED: "selected",
  IGNORED: "ignored",
};

export const SHIFT_STATUS = {
  EXTRA_CANCELED: "annule_extra",
  EXTRA_MISSING: "extra_absent",
  BOOKED: "booked",
};

export const DEFAULT_HOURLY_SALARY = 12;
export const DEFAULT_MONTHLY_SALARY = 1850;
