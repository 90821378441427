import { i18n } from "@core/plugins/i18n";

export const IOutfitColor = Object.freeze({
  id: "id",
  label: "label",
  code: "code",
  autoselected: "selected",
});

export class OutfitColorModel {
  constructor(data = {}) {
    this[IOutfitColor.label] = data[IOutfitColor.label];
    this[IOutfitColor.code] = data[IOutfitColor.code];
    this[IOutfitColor.autoselected] = data[IOutfitColor.autoselected];
    this[IOutfitColor.id] = data[IOutfitColor.id];
  }

  getId() {
    return this[IOutfitColor.id];
  }

  getCode() {
    return this[IOutfitColor.code];
  }

  getLabel() {
    return this[IOutfitColor.label] ? i18n.global.t(this[IOutfitColor.label]) : "";
  }

  isAutoSelected() {
    return this[IOutfitColor.autoselected] === true;
  }
}
