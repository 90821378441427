// https://github.com/axios/axios
import axios from "axios";
import { throttleAdapterEnhancer } from "axios-extensions";
import { API_URL_PUBLIC, API_URL_V2, API_URL, API_URL_COMET } from "@core/constants";
import { errorHandlerInterceptor } from "@core/interceptors/error-handler.interceptor";
import { appHeadersInterceptor } from "@core/interceptors/app-headers.interceptor";

const TIMEOUT = 30000;

/**
 * two separate api for public and private (ie. behind an auth-wall) routes
 */
const api = {
  public: createHttpInstance({ baseURL: API_URL_PUBLIC || API_URL || API_URL_COMET }),
  private: createHttpInstance({ baseURL: API_URL }),
  v2: createHttpInstance({ baseURL: API_URL_V2 || API_URL }),
  comet: createHttpInstance({ baseURL: API_URL_COMET }),
  // simple httpInstance in order to not have the interceptors, base URL etc.
  default: axios.create(),
};

/**
 * Create public httpInstance with basic configuration and interceptors
 * @returns {AxiosInstance} httpInstance
 */
function createHttpInstance({ baseURL = "" }) {
  const httpInstance = axios.create({
    baseURL,
    timeout: TIMEOUT,
    adapter: throttleAdapterEnhancer(axios.defaults.adapter, { threshold: 250 }),
  });
  // register request interceptors
  httpInstance.interceptors.request.use(appHeadersInterceptor);
  // register response error interceptors
  httpInstance.interceptors.response.use((response) => response, errorHandlerInterceptor);

  return httpInstance;
}

export default api;
