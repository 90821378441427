export const ILanguage = Object.freeze({
  id: "langue_source_id",
  level: "niveau",
  label: "nom",
});

export class LanguageModel {
  constructor(data = {}) {
    this[ILanguage.id] = data[ILanguage.id];
    this[ILanguage.level] = data[ILanguage.level];
    this[ILanguage.label] = data[ILanguage.label];
  }

  getId() {
    return this[ILanguage.id];
  }

  getLevel() {
    return this[ILanguage.level];
  }

  getLabel() {
    return this[ILanguage.label];
  }
}
