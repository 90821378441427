export const IAuth = Object.freeze({
  login: "login",
  password: "password",
});

export class AuthModel {
  constructor(data = {}) {
    this[IAuth.login] = data[IAuth.login];
    this[IAuth.password] = data[IAuth.password];
  }

  getLogin() {
    return this[IAuth.login];
  }

  getPassword() {
    return this[IAuth.password];
  }
}
