import { ReferenceContactModel } from "@core/models/common/reference-contact.model";
import { CandidateExperienceModel, ICandidateExperience } from "@core/models/common/candidate-experience.model";
import { CANDIDATE_EXPERIENCE_STATUSES } from "@core/constants";

export class CandidateExtracadabraExperienceModel extends CandidateExperienceModel {
  constructor(data = {}) {
    super(data);
  }

  isExtracadabraExperience() {
    return true;
  }
}

export class CandidatePersonalExperienceModel extends CandidateExperienceModel {
  constructor(data = {}) {
    super(data);
    this[ICandidateExperience.status] = data[ICandidateExperience.status];
    this[ICandidateExperience.reference] = data[ICandidateExperience.reference];
  }

  getStatus() {
    return this[ICandidateExperience.status];
  }

  getReference() {
    return new ReferenceContactModel(this[ICandidateExperience.reference]);
  }

  isPending() {
    return (
      this[ICandidateExperience.status] === CANDIDATE_EXPERIENCE_STATUSES.PENDING.code ||
      this[ICandidateExperience.status] === CANDIDATE_EXPERIENCE_STATUSES.PENDING_INTERNAL_VERIFICATION.code
    );
  }

  isVerified() {
    return this[ICandidateExperience.status] === CANDIDATE_EXPERIENCE_STATUSES.VERIFIED.code;
  }

  isUnverified() {
    return this[ICandidateExperience.status] === CANDIDATE_EXPERIENCE_STATUSES.UNVERIFIED.code;
  }

  isPersonalExperience() {
    return true;
  }
}
