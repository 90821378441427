import { ETAB_FAMILY } from "@core/constants";
import { EtabCategoryModel } from "@core/models/common/etab-category.model.js";

export const IEtabFamily = Object.freeze({
  categories: "categories",
  code: "code",
  id: "id",
  label: "nom",
  order: "ordre",
  name: "name",
});

export class EtabFamilyModel {
  constructor(data = {}) {
    this[IEtabFamily.categories] = data[IEtabFamily.categories];
    this[IEtabFamily.code] = data[IEtabFamily.code];
    this[IEtabFamily.id] = data[IEtabFamily.id];
    this[IEtabFamily.label] = data[IEtabFamily.label];
    this[IEtabFamily.order] = data[IEtabFamily.order];
    this[IEtabFamily.name] = data[IEtabFamily.name];
  }

  getId() {
    return this[IEtabFamily.id];
  }

  getCode() {
    return this[IEtabFamily.code];
  }

  getLabel() {
    return this[IEtabFamily.label];
  }

  getOrder() {
    return this[IEtabFamily.order];
  }

  getName() {
    return this[IEtabFamily.name];
  }

  getIcon() {
    switch (this.getCode()) {
      case ETAB_FAMILY.CAFE_REST:
        return "icon-extra-room";
      case ETAB_FAMILY.BAR_CLUB:
        return "icon-extra-bar";
      case ETAB_FAMILY.HOTEL:
        return "icon-extra-hostel";
      case ETAB_FAMILY.F_AUTRE:
        return "icon-extra-others";
      case ETAB_FAMILY.A_EMPORTER:
        return "icon-extra-sandwich";
      case ETAB_FAMILY.MAGASIN:
        return "icon-extra-shopping-bag";
      case ETAB_FAMILY.SANTE:
        return "icon-extra-health";
      case ETAB_FAMILY.COLLECTIVE:
        return "icon-extra-cafeteria";
      case ETAB_FAMILY.EVENT:
        return "icon-extra-event";
      default:
        return "icon-extra-restaurant";
    }
  }

  getCategories() {
    return (this[IEtabFamily.categories] || []).map((category) => new EtabCategoryModel(category));
  }
}
