import { ActionButtonModel } from "../../common/action-button.model";

export const ICandidateApplyFailure = Object.freeze({
  type: "type",
  title: "title",
  message: "message",
  actions: "actions",
});

export class CandidateApplyFailureModel {
  constructor(data = {}) {
    this[ICandidateApplyFailure.type] = data[ICandidateApplyFailure.type];
    this[ICandidateApplyFailure.title] = data[ICandidateApplyFailure.title];
    this[ICandidateApplyFailure.message] = data[ICandidateApplyFailure.message];
    this[ICandidateApplyFailure.actions] = data[ICandidateApplyFailure.actions];
  }

  getType() {
    return this[ICandidateApplyFailure.type];
  }

  getTitle() {
    return this[ICandidateApplyFailure.title];
  }

  getMessage() {
    return this[ICandidateApplyFailure.message];
  }

  getActions() {
    return (this[ICandidateApplyFailure.actions] || []).map((action) => new ActionButtonModel(action));
  }
}
