export const ISupervisedEtab = Object.freeze({
  masterEtabId: "master_id",
  supervisedEtabId: "sub_etab",
});

export class SupervisedEtabModel {
  constructor(data = {}) {
    this[ISupervisedEtab.masterEtabId] = data[ISupervisedEtab.masterEtabId];
    this[ISupervisedEtab.supervisedEtabId] = data[ISupervisedEtab.supervisedEtabId];
  }

  getMasterEtabId() {
    return this[ISupervisedEtab.masterEtabId];
  }

  getSupervisedEtabId() {
    return this[ISupervisedEtab.supervisedEtabId];
  }

  isValid() {
    return this.getMasterEtabId() && this.getSupervisedEtabId();
  }
}
