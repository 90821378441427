export const DEFAULT_LANGUAGE = "fr";
export const EXTRA_APP_ID_ANDROID = "com.extracadabra.android.extra";
export const EXTRA_APP_ID_IOS = "1056999788";
export const ETAB_APP_ID_ANDROID = "com.extracadabra.android.etab";
export const ETAB_APP_ID_IOS = "1056907780";
export const TEMPORARY_TOKEN_URL_PARAMETER = "temporary_token";
export const MULTIVERSE_STORAGE_KEY = "candidate_multiverse_key";
export const ETAB_ID_URL_PARAMETER = "id_etab";
export const USER_BO_ID_URL_PARAMETER = "id_user_bo";
export const INITIAL_LOADING_DELAY = 1000;
export const VISUAL_LOADING_DELAY = 350;
export const STARTUP_DELAY = 2000;
export const PING_INTERVAL_DELAY = 5000;
export const NOTIFICATION_DELAY = 3000;
export const PUSH_NOTIFICATION_DELAY = 15000;
export const X_APP_VERSION = "X-APP-VERSION";
export const X_CHANNEL_EXTRACADABRA = "X-CHANNEL-EXTRACADABRA";
export const X_CHANNEL_VALUE_WEB = "web";
export const X_CHANNEL_VALUE_APP = "app";
export const X_CHANNEL_OS = "X-CHANNEL-OS";
export const X_AUTH_TOKEN = "X-AUTH-TOKEN";
export const X_AUTH_ETAB_SUPERVISED = "X-AUTH-ETAB-SUPERVISED";
export const X_AUTH_USER_SUPERVISOR = "X-AUTH-USER-SUPERVISOR";
export const X_AUTH_SUB_ETAB = "X-AUTH-SUB-ETAB";
export const STRIPE_URL = "https://js.stripe.com/v3/";
export const API_DATE_FORMAT_FULL = "YYYY-MM-DD HH:mm:ss";
export const API_DATE_FORMAT_SHORT = "YYYY-MM-DD";
export const DISPLAY_DATE_FORMAT_SHORT = "DD/MM/YYYY";
export const DISPLAY_SHORT_DATE = "ddd DD/MM";
export const USER_CLOSED_APP_BANNER_COOKIE = "extracadabra_app_banner";
export const RECRUITMENT_COOKIE_NAME = "recrutement-logged-in";
export const RECRUITMENT_COOKIE_VALUE = "1";
export const EXTRACADABRA_DOMAIN = ".extracadabra.com";
export const GTM_ENABLED_BY_DEFAULT = true;
export const GTM_CONSENT_GROUP = "GTM_CONSENT_GROUP";
export const APP_RATING = 4;
export const PASSWORD_SECURITY_SPECIAL_CHARS = "@$!%*?&";
export const APP_CANDIDATE = "app-candidate";
export const APP_ETAB = "app-etab";
export const IBAN_MASK = "AAXX XXXX XXXX XXXX XXXX XXXX XXXX XXXX XXXX";
export const SWIFT_MASK = "XXXXXXXXXXX";
export const SIRET_MASK = "X## ### ### #####";

export const LOGGER_LEVELS = {
  NONE: "none",
  INFO: "info",
  VERBOSE: "verbose",
};

export const DEVICE_OS = {
  ANDROID: "Android",
  IOS: "IOS",
  SERVER: "server",
  UNKNOWN: "unknown",
};

export const NOTIFICATION_TYPES = {
  ERROR: "error",
  SUCCESS: "success",
  INFO: "info",
  WARNING: "warning",
};

export const SOCIAL_NETWORKS = [
  {
    iconName: "facebook",
    iconAlt: "CORE.COMPONENTS.SOCIAL_NETWORK.@FACEBOOK_ALT",
    link: "https://www.facebook.com/extracadabra",
  },
  {
    iconName: "instagram",
    iconAlt: "CORE.COMPONENTS.SOCIAL_NETWORK.@INSTAGRAM_ALT",
    link: "https://www.instagram.com/extracadabra",
  },
  {
    iconName: "linkedin",
    iconAlt: "CORE.COMPONENTS.SOCIAL_NETWORK.@LINKEDIN_ALT",
    link: "https://fr.linkedin.com/company/extracadabra",
  },
  {
    iconName: "twitter",
    iconAlt: "CORE.COMPONENTS.SOCIAL_NETWORK.@TWITTER_ALT",
    link: "https://twitter.com/extracadabra",
  },
  {
    iconName: "tiktok",
    iconAlt: "CORE.COMPONENTS.SOCIAL_NETWORK.@TIKTOK_ALT",
    link: "https://www.tiktok.com/@extracadabra",
  },
];

export const STORES = {
  APPLE: {
    name: "appstore",
    alt: "CORE.COMPONENTS.STORES.@APPSTORE_ALT",
  },
  GOOGLE: {
    name: "playstore",
    alt: "CORE.COMPONENTS.STORES.@PLAYSTORE_ALT",
  },
};
