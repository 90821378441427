import { Logger } from "./logger.helpers";

export class NumericHelpers {
  /**
   * Returns a string of the value rounded to the nearest decimal
   * @static
   * @param {number} [value=0]
   * @param {number} [decimals=2]
   * @returns {String} usage of toFixed() returns a String
   */
  static toFixedRounded(value = 0, decimals = 2) {
    try {
      const float = parseFloat(value, 10);
      const roundingSecurityOne = 10 ** (decimals + 1);
      const roundingSecurityTwo = 10 ** decimals;
      if (!Number.isNaN(float) && typeof float === "number") {
        // safe rounding JS
        // https://stackoverflow.com/questions/1726630/formatting-a-number-with-exactly-two-decimals-in-javascript
        const safeRounded = Math.round((float * roundingSecurityOne) / 10) / roundingSecurityTwo;
        const fixedRounded = safeRounded.toFixed(decimals);
        // toFixed returns ECMA 262 with . as decimal separator
        // https://stackoverflow.com/questions/2865719/javascript-tofixed-localized
        const localizedFixedRounded = parseFloat(fixedRounded, 10).toLocaleString(undefined, {
          minimumFractionDigits: decimals,
          maximumFractionDigits: decimals,
        });
        return localizedFixedRounded;
      }
      return undefined;
    } catch (error) {
      return undefined;
    }
  }

  /**
   * Returns a string of the value rounded to the nearest decimal with no trailing zero
   * @static
   * @param {number} [value=0]
   * @param {number} [decimals=2]
   * @returns {String} usage of toFixedRoundedWithNoTrailingZero() returns a String
   */
  static toFixedRoundedWithNoTrailingZero(value = 0, decimals = 2) {
    if (value - Math.floor(value) !== 0) {
      return NumericHelpers.toFixedRounded(value, decimals);
    }
    return parseInt(value, 10).toLocaleString(undefined, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  }

  /**
   *
   * @param {number} value
   *
   */
  static ensureNumber({ value }) {
    const parsedValue = parseFloat(value);
    return !Number.isNaN(parsedValue);
  }

  /**
   * Format an amount based on a locale and a currency
   * @static
   * @param {number} number
   * @param {string} locale
   * @param {string} currency
   * @param {number} maximumFractionDigits
   * @param {number} minimumFractionDigits
   * @param {boolean} forceTrailingZeros - by default we don't show ###,00 but ### in case of round numbers
   *
   */
  static formatNumberAsCurrency({
    number,
    locale = "fr-FR",
    currency = "EUR",
    minimumFractionDigits = 2,
    maximumFractionDigits = 2,
    forceTrailingZeros = false,
  }) {
    // security check
    if (minimumFractionDigits > maximumFractionDigits) {
      Logger.error(
        `NumericHelpers.formatNumberAsCurrency error - minimumFractionDigits (${minimumFractionDigits}) is bigger than maximumFractionDigits (${maximumFractionDigits})`,
      );
      minimumFractionDigits = maximumFractionDigits;
    }
    // if number has trailing zeros and we do not force them, update minimumFractionDigits
    if (number - Math.floor(number) === 0 && !forceTrailingZeros) {
      minimumFractionDigits = 0;
    }
    return new Intl.NumberFormat(locale, { style: "currency", currency, minimumFractionDigits, maximumFractionDigits }).format(number);
  }

  /**
   * Format a number based on a specified locale
   * @static
   * @param {number} number
   * @param {string} locale
   * @param {number} minimumFractionDigits
   * @param {number} maximumFractionDigits
   *
   */
  static formatToLocaleNumber({ number, locale = "fr-FR", maximumFractionDigits = 2, minimumFractionDigits = 2 }) {
    // security check
    if (minimumFractionDigits > maximumFractionDigits) {
      Logger.error(
        `NumericHelpers.formatToLocaleNumber error - minimumFractionDigits (${minimumFractionDigits}) is bigger than maximumFractionDigits (${maximumFractionDigits})`,
      );
      minimumFractionDigits = maximumFractionDigits;
    }
    return new Intl.NumberFormat(locale, { minimumFractionDigits, maximumFractionDigits }).format(number);
  }

  static applyPercentage({ amount = 0, percentage = 0 }) {
    return amount * (1 + percentage / 100);
  }
}
