export const IBillableShiftsStatus = Object.freeze({
  code: "code",
  label: "label",
  criticity: "criticity", // color
});

export class BillableShiftsStatusModel {
  constructor(data = {}) {
    this[IBillableShiftsStatus.code] = data[IBillableShiftsStatus.code];
    this[IBillableShiftsStatus.label] = data[IBillableShiftsStatus.label];
    this[IBillableShiftsStatus.criticity] = data[IBillableShiftsStatus.criticity];
  }

  getCode() {
    return this[IBillableShiftsStatus.code];
  }

  getLabel() {
    return this[IBillableShiftsStatus.label];
  }

  getCriticity() {
    return this[IBillableShiftsStatus.criticity];
  }
}
