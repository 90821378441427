import { DatesHelpers, JavascriptHelpers } from "@core/helpers";
import { QualificationModel } from "@core/models/common/qualification.model";

export const IExperience = Object.freeze({
  address: "adresse",
  autorisationVerif: "autorisation_verif",
  canBeReferenced: "referencable",
  category: "categorie",
  city: "ville",
  comment: "commentaire",
  contactName: "nom_contact",
  contactPhone: "numero_contact",
  contactPosition: "poste_contact",
  contract: "contrat",
  createdAt: "created_at",
  deletedAt: "deleted_at",
  doneWithExtracadabra: "done_with_extracadabra",
  durationOfJob: "annee_job",
  end: "fin",
  etab: "etablissement",
  etabName: "nom",
  etabType: "type",
  id: "id",
  internalComment: "commentaire_interne",
  jobsAmount: "count_job",
  note: "note",
  photo: "photo",
  postalCode: "code_postal",
  punition: "punition",
  qualification: "qualification",
  seats: "couverts",
  start: "debut",
  updatedAt: "updated_at",
});

export class ExperienceModel {
  constructor(data = {}) {
    this[IExperience.address] = data[IExperience.address];
    this[IExperience.autorisationVerif] = data[IExperience.autorisationVerif];
    this[IExperience.canBeReferenced] = data[IExperience.canBeReferenced];
    this[IExperience.category] = data[IExperience.category];
    this[IExperience.city] = data[IExperience.city];
    this[IExperience.comment] = data[IExperience.comment];
    this[IExperience.contactName] = data[IExperience.contactName];
    this[IExperience.contactPhone] = data[IExperience.contactPhone];
    this[IExperience.contactPosition] = data[IExperience.contactPosition];
    this[IExperience.contract] = data[IExperience.contract];
    this[IExperience.createdAt] = data[IExperience.createdAt];
    this[IExperience.deletedAt] = data[IExperience.deletedAt];
    this[IExperience.doneWithExtracadabra] = data[IExperience.doneWithExtracadabra];
    this[IExperience.durationOfJob] = data[IExperience.durationOfJob];
    this[IExperience.end] = data[IExperience.end];
    this[IExperience.etab] = data[IExperience.etab];
    this[IExperience.etabName] = data[IExperience.etabName];
    this[IExperience.etabType] = data[IExperience.etabType];
    this[IExperience.id] = data[IExperience.id];
    this[IExperience.internalComment] = data[IExperience.internalComment];
    this[IExperience.jobsAmount] = data[IExperience.jobsAmount];
    this[IExperience.note] = data[IExperience.note];
    this[IExperience.photo] = data[IExperience.photo];
    this[IExperience.postalCode] = data[IExperience.postalCode];
    this[IExperience.punition] = data[IExperience.punition];
    this[IExperience.qualification] = data[IExperience.qualification];
    this[IExperience.seats] = data[IExperience.seats];
    this[IExperience.start] = data[IExperience.start];
    this[IExperience.updatedAt] = data[IExperience.updatedAt];
  }

  getQualification() {
    return new QualificationModel(this[IExperience.qualification]);
  }

  getQualificationLabel() {
    return this.getQualification().getLabel();
  }

  getNote() {
    return this[IExperience.note];
  }

  getEtab() {
    return {
      name: JavascriptHelpers.capitalizeFirstLetter(this[IExperience.etabName]),
      type: this[IExperience.etabType],
    };
  }

  getContract() {
    const start = this[IExperience.start];
    const startDisplay = start ? DatesHelpers.getDayjs(start).format("MMM YYYY") : undefined;
    const end = start && this[IExperience.end];
    const endDisplay = end ? DatesHelpers.getDayjs(end).format("MMM YYYY") : undefined;
    let contract = this[IExperience.contract] || "";

    if (contract.length === 3) {
      // if CDI, CDD then uppercase
      contract = contract.toUpperCase();
    } else {
      contract = JavascriptHelpers.capitalizeFirstLetter(contract);
    }

    return {
      contract,
      start: startDisplay,
      end: endDisplay && startDisplay !== endDisplay ? endDisplay : undefined,
    };
  }
}
