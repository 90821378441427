export const ICandidateReferenceProspect = Object.freeze({
  firstname: "manager_firstname",
  lastname: "manager_lastname",
  email: "manager_email",
  comment: "manager_comment",
  rating: "manager_rating",
  phoneNumber: "manager_phone_number",
});

export class CandidateReferenceProspectModel {
  constructor(data = {}) {
    this[ICandidateReferenceProspect.firstname] = data[ICandidateReferenceProspect.firstname];
    this[ICandidateReferenceProspect.lastname] = data[ICandidateReferenceProspect.lastname];
    this[ICandidateReferenceProspect.email] = data[ICandidateReferenceProspect.email];
    this[ICandidateReferenceProspect.comment] = data[ICandidateReferenceProspect.comment];
    this[ICandidateReferenceProspect.rating] = data[ICandidateReferenceProspect.rating];
    this[ICandidateReferenceProspect.phoneNumber] = data[ICandidateReferenceProspect.phoneNumber];
  }
}
