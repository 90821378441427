import { ScheduleHelpers } from "@core/helpers";

export const ICandidateOfferScheduleDay = Object.freeze({
  id: "id",
  label: "label",
  startHour: "start_hour",
  endHour: "end_hour",
  breakDuration: "break_duration",
  date: "date",
});

export class CandidateOfferScheduleDayModel {
  constructor(data = {}) {
    this[ICandidateOfferScheduleDay.id] = data[ICandidateOfferScheduleDay.id];
    this[ICandidateOfferScheduleDay.label] = data[ICandidateOfferScheduleDay.label];
    this[ICandidateOfferScheduleDay.startHour] = data[ICandidateOfferScheduleDay.startHour];
    this[ICandidateOfferScheduleDay.endHour] = data[ICandidateOfferScheduleDay.endHour];
    this[ICandidateOfferScheduleDay.date] = data[ICandidateOfferScheduleDay.date];
    this[ICandidateOfferScheduleDay.breakDuration] = data[ICandidateOfferScheduleDay.breakDuration];
  }

  getId() {
    return this[ICandidateOfferScheduleDay.id];
  }

  getLabel() {
    return this[ICandidateOfferScheduleDay.label];
  }

  getStartHour() {
    return this[ICandidateOfferScheduleDay.startHour];
  }

  getEndHour() {
    return this[ICandidateOfferScheduleDay.endHour];
  }

  getBreakDuration() {
    return this[ICandidateOfferScheduleDay.breakDuration];
  }

  getDate() {
    return this[ICandidateOfferScheduleDay.date];
  }

  getFormattedSchedule() {
    const daySchedule = ScheduleHelpers.formatDaySchedule({ daySchedule: this });
    const { start, end } = daySchedule;
    return `${start} - ${end}`;
  }
}
