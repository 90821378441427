export const IStripeMandate = Object.freeze({
  clientSecret: "client_secret",
});

export class StripeMandateModel {
  constructor(data = {}) {
    this[IStripeMandate.clientSecret] = data[IStripeMandate.clientSecret];
  }

  getClientSecret() {
    return this[IStripeMandate.clientSecret];
  }
}
