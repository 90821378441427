import { NumericHelpers } from "@core/helpers";

export const ICreationStepSalary = Object.freeze({
  amount: "amount",
  range: "range",
  periodicity: "salary_type",
  fluctuating: "fluctuating",
});

export class CreationStepSalaryModel {
  constructor(data = {}) {
    this[ICreationStepSalary.amount] = data[ICreationStepSalary.amount];
    this[ICreationStepSalary.range] = data[ICreationStepSalary.range];
    this[ICreationStepSalary.periodicity] = data[ICreationStepSalary.periodicity];
    this[ICreationStepSalary.fluctuating] = data[ICreationStepSalary.fluctuating];
  }

  getAmount() {
    return this[ICreationStepSalary.amount];
  }

  getAmountFormatted() {
    return NumericHelpers.formatNumberAsCurrency({ number: this.getAmount() });
  }

  getRange() {
    return this[ICreationStepSalary.range] || null;
  }

  getRangeFormatted() {
    if (this.getRange()) {
      const { min, max } = this.getRange();
      return {
        min: NumericHelpers.formatNumberAsCurrency({ number: min }),
        max: NumericHelpers.formatNumberAsCurrency({ number: max }),
      };
    }
    return { min: "- €", max: "- €" };
  }

  getPeriodicity() {
    return this[ICreationStepSalary.periodicity];
  }

  isFluctuating() {
    return this[ICreationStepSalary.fluctuating] === true;
  }

  isValid() {
    return (
      this[ICreationStepSalary.fluctuating] ||
      (this[ICreationStepSalary.amount] !== undefined && this[ICreationStepSalary.periodicity] !== undefined) ||
      this[ICreationStepSalary.range] !== undefined
    );
  }

  isRangeValid() {
    return (
      this[ICreationStepSalary.range]?.min >= 0 &&
      this[ICreationStepSalary.range].min !== null &&
      this[ICreationStepSalary.range]?.max > 0 &&
      this[ICreationStepSalary.range].max !== null &&
      this[ICreationStepSalary.range].max > this[ICreationStepSalary.range].min
    );
  }
}
