import { SanitationHelper } from "@core/helpers";

export const IJobEtabDetail = Object.freeze({
  name: "etab_name",
  category: "etab_category",
  description: "etab_description",
  city: "city",
  postalCode: "post_code",
  picture: "etab_picture",
});

export class JobEtabDetailModel {
  constructor(data = {}) {
    this[IJobEtabDetail.name] = data[IJobEtabDetail.name];
    this[IJobEtabDetail.category] = data[IJobEtabDetail.category];
    this[IJobEtabDetail.description] = data[IJobEtabDetail.description];
    this[IJobEtabDetail.city] = data[IJobEtabDetail.city];
    this[IJobEtabDetail.postalCode] = data[IJobEtabDetail.postalCode];
    this[IJobEtabDetail.picture] = data[IJobEtabDetail.picture];
  }

  getName() {
    return this[IJobEtabDetail.name];
  }

  getCategory() {
    return this[IJobEtabDetail.category] || "";
  }

  getDescription() {
    return this[IJobEtabDetail.description];
  }

  getCity() {
    return this[IJobEtabDetail.city];
  }

  getPostalCode() {
    return this[IJobEtabDetail.postalCode];
  }

  getPicture() {
    return this[IJobEtabDetail.picture];
  }

  getPresentation() {
    return (
      SanitationHelper.sanitize(this[IJobEtabDetail.presentation] || "")
        // add newlines on html linebreak
        .replace(/(?:\r\n|\r|\n|↵|&#8629;)/g, "<br />")
    );
  }
}
