// from webpack define
export const APP_VERSION = process.env.APP_VERSION;
export const ENVIRONMENT = process.env.NODE_ENV;

// from .env
export const API_URL = process.env.VUE_APP_API_URL;
export const API_URL_V2 = process.env.VUE_APP_API_URL_V2;
export const API_URL_PUBLIC = process.env.VUE_APP_API_URL_PUBLIC;
export const API_URL_COMET = process.env.VUE_APP_API_URL_COMET;
export const BACKOFFICE_LEGACY_URL = process.env.VUE_APP_BACKOFFICE_LEGACY_URL;
export const BLOG_URL = process.env.VUE_APP_BLOG_URL;
export const BLOG_QUESTIONS_URL = process.env.VUE_APP_BLOG_QUESTIONS_URL;
export const BLOG_PHOTO_ADVICES_URL = process.env.VUE_APP_BLOG_PHOTO_ADVICES_URL;
export const CALL_ME_BACK_URL = process.env.VUE_APP_CALL_ME_BACK_URL;
export const CGU_URL = process.env.VUE_APP_CGU_URL;
export const CONFIDENTIALITY_URL = process.env.VUE_APP_CONFIDENTIALITY_URL;
export const DEVICE_TOKEN_STORAGE_KEY = process.env.VUE_APP_DEVICE_TOKEN_STORAGE_KEY;
export const EMAIL_CANDIDATES_SUPPORT = process.env.VUE_APP_EMAIL_CANDIDATES_SUPPORT;
export const EMAIL_CONTACT_EXTRACADABRA = process.env.VUE_APP_EMAIL_CONTACT_EXTRACADABRA;
export const EMAIL_EXTRACADABRA_CRA = process.env.VUE_APP_EMAIL_EXTRACADABRA_CRA;
export const EMAIL_EXTRACADABRA_SUPPORT = process.env.VUE_APP_EMAIL_EXTRACADABRA_SUPPORT;
export const EMAIL_EXTRACADABRA_DPO = process.env.VUE_APP_EMAIL_EXTRACADABRA_DPO;
export const EXTRACADABRA_URL = process.env.VUE_APP_EXTRACADABRA_URL;
export const EXTRACADABRA_ABOUT_URL = process.env.VUE_APP_EXTRACADABRA_ABOUT_URL;
export const FAQ_CANDIDATES_URL = process.env.VUE_APP_FAQ_CANDIDATES_URL;
export const FAQ_ETAB_URL = process.env.VUE_APP_FAQ_ETAB_URL;
export const LP_LYON_URL = process.env.VUE_APP_LP_LYON_URL;
export const LP_SELLER_URL = process.env.VUE_APP_LP_SELLER_URL;
export const NEW_FAQ_ETAB_URL = process.env.VUE_APP_NEW_FAQ_ETAB_URL;
export const FIREBASE_APP_ID = process.env.VUE_APP_FIREBASE_APP_ID;
export const FIREBASE_AUTH_DOMAIN = process.env.VUE_APP_FIREBASE_AUTH_DOMAIN;
export const FIREBASE_DATABASE_URL = process.env.VUE_APP_FIREBASE_DATABASE_URL;
export const FIREBASE_MESSAGING_SENDER_ID = process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID;
export const FIREBASE_PROJECT_ID = process.env.VUE_APP_FIREBASE_PROJECT_ID;
export const FIREBASE_STORAGE_BUCKET = process.env.VUE_APP_FIREBASE_STORAGE_BUCKET;
export const GO_CARDLESS = process.env.VUE_APP_GO_CARDLESS;
export const GO_CARDLESS_SUBSCRIPTION = process.env.VUE_APP_GO_CARDLESS_SUBSCRIPTION;
export const PURCHASELY_API_KEY = process.env.VUE_APP_PURCHASELY_API_KEY;
export const PURCHASELY_MENU_PLACEMENT_ID = process.env.VUE_APP_PURCHASELY_MENU_PLACEMENT_ID;
export const PURCHASELY_DEMAND_PLACEMENT_ID = process.env.VUE_APP_PURCHASELY_DEMAND_PLACEMENT_ID;
export const COOKIES_DOMAIN = process.env.VUE_APP_COOKIES_DOMAIN;
export const COOKIES_CONSENT_KEY = process.env.VUE_APP_COOKIES_CONSENT_KEY;
export const GTM_ID = process.env.VUE_APP_GTM_ID;
export const JOBS_URL = process.env.VUE_APP_JOBS_URL;
export const JOIN_TEAM_URL = process.env.VUE_APP_JOIN_TEAM_URL;
export const SHOWCASE_RECRUITMENT_URL = process.env.VUE_APP_SHOWCASE_RECRUITMENT_URL;
export const CANDIDATES_URL = process.env.VUE_APP_CANDIDATES_URL;
export const LANDING_ETAB_RESTAURATION_URL = process.env.VUE_APP_LANDING_ETAB_RESTAURATION_URL;
export const LANDING_ETAB_RETAIL_URL = process.env.VUE_APP_LANDING_ETAB_RETAIL_URL;
export const PUSH_NOTIFICATION_ACCEPTATION_STORAGE_KEY = process.env.VUE_APP_PUSH_NOTIFICATION_ACCEPTATION_STORAGE_KEY;
export const QUESTION_ETAB_URL = process.env.VUE_APP_QUESTION_ETAB_URL;
export const SELF_URL = process.env.VUE_APP_SELF_URL;
export const STRIPE_PK = process.env.VUE_APP_STRIPE_PK;
export const SUPERVISOR_STORAGE_KEY = process.env.VUE_APP_SUPERVISOR_STORAGE_KEY;
export const SURVEY_FAQ_URL = process.env.VUE_APP_SURVEY_FAQ_URL;
export const TOKEN_STORAGE_KEY = process.env.VUE_APP_TOKEN_STORAGE_KEY;
export const REFRESH_TOKEN_STORAGE_KEY = process.env.VUE_APP_REFRESH_TOKEN_STORAGE_KEY;
export const UNLIMITED_RECRUITMENT_URL = process.env.VUE_APP_UNLIMITED_RECRUITMENT_URL;
export const TYPEFORM_LOGIN_URL = process.env.VUE_APP_TYPEFORM_LOGIN_URL;
export const USER_DATA_STORAGE_KEY = process.env.VUE_APP_USER_DATA_STORAGE_KEY;
export const VAPID_PUBLIC_KEY = process.env.VUE_APP_VAPID_PUBLIC_KEY;
export const WWW_ETAB_URL = process.env.VUE_APP_WWW_ETAB_URL;
export const WWW_ETAB_LANDING_URL = process.env.VUE_APP_WWW_ETAB_LANDING_URL;
export const WWW_JOBS_URL = process.env.VUE_APP_WWW_JOBS_URL;
export const LANDING_RESTOCO_URL = process.env.VUE_APP_LANDING_RESTOCO;
export const TYPEFORM_AE_STEPS_URL = process.env.VUE_APP_TYPEFORM_AE_STEPS_URL;
export const TYPEFORM_COIFFEUR_URL = process.env.VUE_APP_COIFFEUR_TYPEFORM_URL;
export const TYPEFORM_SANTE_URL = process.env.VUE_APP_SANTE_TYPEFORM_URL;
export const TYPEFORM_OTHER_URL = process.env.VUE_APP_OTHER_TYPEFORM_URL;
export const TYPEFORM_PROPRETE_URL = process.env.VUE_APP_PROPRETE_TYPEFORM_URL;
export const CANDIDATE_AE_TYPEFORM_URL = process.env.VUE_APP_CANDIDATE_AE_TYPEFORM_URL;
export const CANDIDATE_GOOD_CONDUCT_URL = process.env.VUE_APP_GOOD_CONDUCT_EXTRACADABRA;
export const BATCH_ANDROID_API_KEY = process.env.VUE_APP_BATCH_ANDROID_API_KEY;
export const BATCH_IOS_API_KEY = process.env.VUE_APP_BATCH_IOS_API_KEY;
export const CUSTOM_URL_SCHEME = process.env.VUE_APP_CUSTOM_URL_SCHEME;
export const NAMESPACE_URI = process.env.VUE_APP_NAMESPACE_URI;
export const BATCH_INBOX_KEY = process.env.VUE_APP_INBOX_KEY;
export const TYPEFORM_COMPLAIN_ETAB_URL = process.env.VUE_APP_TYPEFORM_COMPLAIN_ETAB_URL;
export const TYPEFORM_ISSUE_WITH_ETAB_URL = process.env.VUE_APP_TYPEFORM_ISSUE_WITH_ETAB_URL;
export const POSTHOG_API_KEY = process.env.VUE_APP_POSTHOG_API_KEY;
export const POSTHOG_API_HOST = process.env.VUE_APP_POSTHOG_API_HOST;
export const UTM_TRACKER = process.env.VUE_APP_UTM_TRACKER;
export const FIREBASE_API_KEY = process.env.VUE_APP_FIREBASE_API_KEY;
export const FIREBASE_MEASUREMENT_ID = process.env.VUE_APP_FIREBASE_MEASUREMENT_ID;
