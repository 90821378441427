import { DatesHelpers } from "@core/helpers";

export const IBillingSessionAdditionalLine = Object.freeze({
  additionalLineId: "additional_line_id",
  billingSessionId: "billing_session_id",
  identifierId: "identifier_id",
  identifierType: "identifier_type",
  totalAmountHt: "total_amount_h_t",
  totalAmountTtc: "total_amount_t_t_c",
  amountHt: "ht_amount",
  tax: "tax",
  totalTax: "total_amount_tax",
  nbUnit: "nb_unit",
  unitAmount: "unit_amount",
  comment: "comment",
  createdAt: "created_at",
  createdBy: "created_by",
  shiftId: "shift_id",
  name: "name",
});

export const IBillingSessionLineColumns = Object.freeze({
  tax: "tax",
  date: "created_at",
  comment: "comment",
  name: "name",
  shift: "shift",
  total: "total",
  deleteAction: "deleteAction",
});

export class BillingSessionAdditionalLineModel {
  constructor(data = {}) {
    this[IBillingSessionAdditionalLine.additionalLineId] = data[IBillingSessionAdditionalLine.additionalLineId];
    this[IBillingSessionAdditionalLine.billingSessionId] = data[IBillingSessionAdditionalLine.billingSessionId];
    this[IBillingSessionAdditionalLine.identifierId] = data[IBillingSessionAdditionalLine.identifierId];
    this[IBillingSessionAdditionalLine.identifierType] = data[IBillingSessionAdditionalLine.identifierType];
    this[IBillingSessionAdditionalLine.totalAmountHt] = data[IBillingSessionAdditionalLine.totalAmountHt];
    this[IBillingSessionAdditionalLine.totalAmountTtc] = data[IBillingSessionAdditionalLine.totalAmountTtc];
    this[IBillingSessionAdditionalLine.tax] = data[IBillingSessionAdditionalLine.tax];
    this[IBillingSessionAdditionalLine.totalTax] = data[IBillingSessionAdditionalLine.totalTax];
    this[IBillingSessionAdditionalLine.nbUnit] = data[IBillingSessionAdditionalLine.nbUnit];
    this[IBillingSessionAdditionalLine.unitAmount] = data[IBillingSessionAdditionalLine.unitAmount];
    this[IBillingSessionAdditionalLine.comment] = data[IBillingSessionAdditionalLine.comment];
    this[IBillingSessionAdditionalLine.createdAt] = data[IBillingSessionAdditionalLine.createdAt];
    this[IBillingSessionAdditionalLine.createdBy] = data[IBillingSessionAdditionalLine.createdBy];
    this[IBillingSessionAdditionalLine.shiftId] = data[IBillingSessionAdditionalLine.shiftId];
    this[IBillingSessionAdditionalLine.name] = data[IBillingSessionAdditionalLine.name];
    this[IBillingSessionAdditionalLine.amountHt] = data[IBillingSessionAdditionalLine.amountHt];
  }

  getId() {
    return this[IBillingSessionAdditionalLine.additionalLineId];
  }

  getDate() {
    return DatesHelpers.getDayjs(this[IBillingSessionAdditionalLine.createdAt]);
  }

  getFormattedDate(format = "DD/MM/YYYY HH:mm") {
    return this.getDate().format(format);
  }

  getIdentifierType() {
    return this[IBillingSessionAdditionalLine.identifierType];
  }

  getComment() {
    return this[IBillingSessionAdditionalLine.comment];
  }

  getShiftId() {
    return this[IBillingSessionAdditionalLine.shiftId];
  }

  getAmountTtc() {
    return this[IBillingSessionAdditionalLine.totalAmountTtc];
  }

  getAmountHt() {
    return this[IBillingSessionAdditionalLine.totalAmountHt];
  }

  getTaxAmount() {
    return this[IBillingSessionAdditionalLine.tax];
  }

  getName() {
    return this[IBillingSessionAdditionalLine.name];
  }
}
