import { LOGGER_LEVELS } from "@core/constants";
import { EnvironmentManager } from "@core/managers/environment.manager";

/**
 * Log stuff
 * Logger is not referenced in helpers/index.js for unit testing purpose
 * The units tests have the bad habits of breaking when they import a massive index file
 */
export class Logger {
  /**
   * Returns the LOGGER_LEVEL
   * @readonly
   * @static
   */
  static get level() {
    // on SSR the process.env likes to surround stuff with quotes, let's remove them to be sure
    return process.env.LOGGER_LEVEL?.replace(/"/g, "");
  }

  static get shouldPrint() {
    return !EnvironmentManager.isTest();
  }

  static isVerbose() {
    return Logger.level === LOGGER_LEVELS.VERBOSE;
  }

  static isInfo() {
    return Logger.level === LOGGER_LEVELS.INFO;
  }

  /**
   * Will console.warn no matter the logger level
   * @static
   * @param {Object} message - the message to log
   * @param {Array} [values] - the optional values to log
   */
  static warn(message, ...values) {
    if (Logger.shouldPrint) {
      console.warn(message, ...values);
    }
  }

  /**
   * Will console.error no matter the logger level
   * @static
   * @param {Object} message - the message to log
   * @param {Array} [values] - the optional values to log
   */
  static error(message, ...values) {
    if (Logger.shouldPrint) {
      console.error(message, ...values);
    }
  }

  /**
   * Will log if logger level is set accordingly
   * @static
   * @param {Object} message - the message to log
   * @param {Array} [values] - the optional values to log
   */
  static info(message, ...values) {
    if (Logger.shouldPrint && (Logger.isVerbose() || Logger.isInfo())) {
      console.info(message, ...values);
    }
  }

  /**
   * Will log if logger level is set accordingly
   * @static
   * @param {Object} message - the message to log
   * @param {Array} [values] - the optional values to log
   */
  static log(message, ...values) {
    if (Logger.shouldPrint && Logger.isVerbose()) {
      console.log(message, ...values);
    }
  }
}
