import { PASSWORD_SECURITY_SPECIAL_CHARS } from "@core/constants";

export const PASSWORD_REGEX = new RegExp(
  `^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[${PASSWORD_SECURITY_SPECIAL_CHARS}])[A-Za-z\\d${PASSWORD_SECURITY_SPECIAL_CHARS}]{8,}$`,
);

export class PasswordHelpers {
  static hasEightCaract(string) {
    const regex = new RegExp(`[a-zA-Z\\d${PASSWORD_SECURITY_SPECIAL_CHARS}]{8,}`);
    return regex.test(string);
  }

  static hasMajCaract(string) {
    const regex = /[A-Z]/;
    return regex.test(string);
  }

  static hasMinCaract(string) {
    const regex = /[a-z]/;
    return regex.test(string);
  }

  static hasNumeric(string) {
    const regex = /[\d]/;
    return regex.test(string);
  }

  static hasSpecialCaract(string) {
    const regex = new RegExp(`[${PASSWORD_SECURITY_SPECIAL_CHARS}]`);
    // if string has a special character, check unautorized charaters
    if (regex.test(string)) {
      const autorizedCharacters = new RegExp(`^[A-Za-z\\d${PASSWORD_SECURITY_SPECIAL_CHARS}]*$`);
      return autorizedCharacters.test(string);
    }
    return false;
  }

  static hasAllCriterias(string) {
    return (
      this.hasEightCaract(string) &&
      this.hasMajCaract(string) &&
      this.hasMinCaract(string) &&
      this.hasNumeric(string) &&
      this.hasSpecialCaract(string)
    );
  }
}
