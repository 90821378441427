import { DatesHelpers, JavascriptHelpers, ScheduleHelpers } from "@core/helpers";
import { JobOfferModel } from "./jobs-offer.model";
import { JobClothingModel } from "./jobs-clothing.model";
import { JobPlanningDaysModel } from "./jobs-planning-days.model";

export const IJobOfferDetail = Object.freeze({
  comment: "comment",
  clothing: "clothing",
  shifts: "shifts",
  isExpired: "is_expired",
  enrichedDescription: "enriched_description",
});

export class JobOfferDetailModel extends JobOfferModel {
  constructor(data = {}) {
    super(data);
    this[IJobOfferDetail.comment] = data[IJobOfferDetail.comment];
    this[IJobOfferDetail.clothing] = data[IJobOfferDetail.clothing];
    this[IJobOfferDetail.shifts] = data[IJobOfferDetail.shifts];
    this[IJobOfferDetail.isExpired] = data[IJobOfferDetail.isExpired];
    this[IJobOfferDetail.enrichedDescription] = data[IJobOfferDetail.enrichedDescription];
  }

  getComment() {
    return this[IJobOfferDetail.comment];
  }

  getClothing() {
    return new JobClothingModel(this[IJobOfferDetail.clothing]);
  }

  getShifts() {
    return (this[IJobOfferDetail.shifts] || []).map((shift) => new JobPlanningDaysModel(shift));
  }

  isExpired() {
    return this[IJobOfferDetail.isExpired] === true;
  }

  getEnrichedDescription() {
    return this[IJobOfferDetail.enrichedDescription];
  }

  getTemporarySchedule({ startDate }) {
    const displayDateFormat = "ddd DD MMM";
    const formattedShifts = [];
    const shifts = this.getShifts();
    if (shifts.length > 0) {
      const lastShift = shifts[shifts.length - 1];
      const endDate = lastShift.getDate();
      let dayjsStartDate = DatesHelpers.getDayjs(startDate);
      const numberOfDays = DatesHelpers.numberOfDaysBetweenDates({ start: startDate, end: endDate });
      for (let i = 0; i < numberOfDays; i++) {
        const formattedStartDate = dayjsStartDate.format("YYYY-MM-DD");
        const foundShift = shifts.find((shift) => DatesHelpers.getDayjs(shift.getDate()).format("YYYY-MM-DD") === formattedStartDate);
        formattedShifts.push({
          key: i,
          name: JavascriptHelpers.capitalizeFirstLetter(dayjsStartDate.format(displayDateFormat)),
          schedule: foundShift ? ScheduleHelpers.formatDaySchedule({ daySchedule: foundShift }) : null,
        });
        dayjsStartDate = dayjsStartDate.add(1, "days");
      }
    }

    return formattedShifts;
  }
}
