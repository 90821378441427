export const IAccount = Object.freeze({
  email: "email",
  firstname: "prenom",
  lastname: "nom",
  phone: "telephone",
});

export class AccountModel {
  constructor(data = {}) {
    this[IAccount.email] = data[IAccount.email];
    this[IAccount.firstname] = data[IAccount.firstname];
    this[IAccount.lastname] = data[IAccount.lastname];
    this[IAccount.phone] = data[IAccount.phone];
  }

  getEmail() {
    return this[IAccount.email];
  }
}
