import { DEMAND_TYPES } from "@core/constants";
import { DatesHelpers } from "@core/helpers";
import { ReviewExtraModel } from "./review-extra.model";
import { IReview } from "./review.model";

export const IScheduleExtra = Object.freeze({
  breakDuration: "pause",
  demandId: "id_mission",
  end: "fin",
  extraId: "id_extra",
  firstname: "prenom",
  isLast: "is_last",
  lastname: "nom",
  photo: "photo",
  qualification: "qualification",
  reviewed: "reviewed",
  shiftId: "id",
  start: "debut",
  updatedSchedule: "updatedSchedule",
  plannedStart: "debut_previsionnel",
  plannedEnd: "fin_previsionnel",
  plannedBreakDuration: "pause_previsionnel",
  extraStart: "debut_extra",
  extraEnd: "fin_extra",
  extraBreakDuration: "pause_extra",
});

export class ScheduleExtraModel {
  constructor(data = {}) {
    this[IScheduleExtra.breakDuration] = data[IScheduleExtra.breakDuration];
    this[IScheduleExtra.demandId] = data[IScheduleExtra.demandId];
    this[IScheduleExtra.end] = data[IScheduleExtra.end];
    this[IScheduleExtra.extraId] = data[IScheduleExtra.extraId];
    this[IScheduleExtra.firstname] = data[IScheduleExtra.firstname];
    this[IScheduleExtra.isLast] = data[IScheduleExtra.isLast];
    this[IScheduleExtra.lastname] = data[IScheduleExtra.lastname];
    this[IScheduleExtra.photo] = data[IScheduleExtra.photo];
    this[IScheduleExtra.qualification] = data[IScheduleExtra.qualification];
    this[IScheduleExtra.reviewed] = data[IScheduleExtra.reviewed];
    this[IScheduleExtra.shiftId] = data[IScheduleExtra.shiftId];
    this[IScheduleExtra.start] = data[IScheduleExtra.start];
    this[IScheduleExtra.updatedSchedule] = data[IScheduleExtra.updatedSchedule];
    this[IScheduleExtra.plannedStart] = data[IScheduleExtra.plannedStart];
    this[IScheduleExtra.plannedEnd] = data[IScheduleExtra.plannedEnd];
    this[IScheduleExtra.plannedBreakDuration] = data[IScheduleExtra.plannedBreakDuration];
    this[IScheduleExtra.extraStart] = data[IScheduleExtra.extraStart];
    this[IScheduleExtra.extraEnd] = data[IScheduleExtra.extraEnd];
    this[IScheduleExtra.extraBreakDuration] = data[IScheduleExtra.extraBreakDuration];
  }

  getDemandId() {
    return this[IScheduleExtra.demandId];
  }

  getExtraId() {
    return this[IScheduleExtra.extraId];
  }

  getShiftId() {
    return this[IScheduleExtra.shiftId];
  }

  getQualification() {
    return this[IScheduleExtra.qualification];
  }

  getPicture() {
    return this[IScheduleExtra.photo];
  }

  getExtraFirstname() {
    return this[IScheduleExtra.firstname];
  }

  getExtraLastname() {
    return this[IScheduleExtra.lastname];
  }

  getDatesDayjs() {
    return {
      start: DatesHelpers.getDayjs(this[IScheduleExtra.start]),
      end: DatesHelpers.getDayjs(this[IScheduleExtra.end]),
    };
  }

  isSameDay() {
    const { start, end } = this.getDatesDayjs();
    return start.isSame(end, "day");
  }

  getDatesDisplay() {
    const dates = this.getDatesDayjs();
    return dates.start.format("dddd DD/MM");
  }

  getStartTime() {
    const { start } = this.getDatesDayjs();
    return start.hour() * 60 + start.minute();
  }

  getEndTime() {
    const { end } = this.getDatesDayjs();
    const offset = this.isSameDay() ? 0 : 24;
    return (offset + end.hour()) * 60 + end.minute();
  }

  getBreakDuration() {
    return parseFloat(this[IScheduleExtra.breakDuration], 10) * 60;
  }

  setUpdatedSchedule({ start, end, breakDuration } = {}) {
    this[IScheduleExtra.updatedSchedule] = { start, end, breakDuration };
  }

  getUpdatedSchedule() {
    return this[IScheduleExtra.updatedSchedule] || {};
  }

  getUpdatedBreakDuration() {
    return this.getUpdatedSchedule().breakDuration;
  }

  getPlannedStartTime() {
    return DatesHelpers.getDayjs(this[IScheduleExtra.plannedStart]).format("HH:mm");
  }

  getPlannedEndTime() {
    return DatesHelpers.getDayjs(this[IScheduleExtra.plannedEnd]).format("HH:mm");
  }

  getPlannedBreakDuration() {
    const duration = this[IScheduleExtra.plannedBreakDuration];
    return duration > 0 ? DatesHelpers.formatMinsToHrsMins(duration * 60) : undefined;
  }

  getExtraStart() {
    return this[IScheduleExtra.extraStart] ? DatesHelpers.getDayjs(this[IScheduleExtra.extraStart]).format("HH:mm") : null;
  }

  getExtraEnd() {
    return this[IScheduleExtra.extraEnd] ? DatesHelpers.getDayjs(this[IScheduleExtra.extraEnd]).format("HH:mm") : null;
  }

  getExtraBreakDuration() {
    const duration = this[IScheduleExtra.extraBreakDuration];
    return duration > 0 ? DatesHelpers.formatMinsToHrsMins(duration * 60) : undefined;
  }

  isLastShift() {
    return this[IScheduleExtra.isLast] === true;
  }

  isAlreadyReviewed() {
    return this[IScheduleExtra.reviewed] === true;
  }

  getReviewFromSchedule() {
    return new ReviewExtraModel({
      [IReview.demandId]: this[IScheduleExtra.demandId],
      [IReview.demandType]: DEMAND_TYPES.AE, // schedule are only for AE
      [IReview.end]: this[IScheduleExtra.end],
      [IReview.extraId]: this[IScheduleExtra.extraId],
      [IReview.firstname]: this[IScheduleExtra.firstname],
      [IReview.lastname]: this[IScheduleExtra.lastname],
      [IReview.photo]: this[IScheduleExtra.photo],
      [IReview.qualification]: this[IScheduleExtra.qualification],
      [IReview.start]: this[IScheduleExtra.start],
      [IReview.plannedStart]: this[IScheduleExtra.plannedStart],
      [IReview.plannedEnd]: this[IScheduleExtra.plannedEnd],
      [IReview.plannedBreakDuration]: this[IScheduleExtra.plannedBreakDuration],
      [IReview.extraStart]: this[IScheduleExtra.extraStart],
      [IReview.extraEnd]: this[IScheduleExtra.extraEnd],
      [IReview.extraBreakDuration]: this[IScheduleExtra.extraBreakDuration],
    });
  }
}
