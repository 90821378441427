import { PasswordHelpers } from "@core/helpers";

export const IAccountPassword = Object.freeze({
  currentPassword: "current_password",
  newPassword: "new_password",
  newPasswordConfirm: "new_password_confirm",
});

export class AccountPasswordModel {
  constructor(data = {}) {
    this[IAccountPassword.currentPassword] = data[IAccountPassword.currentPassword] || "";
    this[IAccountPassword.newPassword] = data[IAccountPassword.newPassword] || "";
    this[IAccountPassword.newPasswordConfirm] = data[IAccountPassword.newPasswordConfirm] || "";
  }

  getCurrentPassword() {
    return this[IAccountPassword.currentPassword];
  }

  getNewPassword() {
    return this[IAccountPassword.newPassword];
  }

  getNewConfirmPassword() {
    return this[IAccountPassword.newPasswordConfirm];
  }

  passwordHasNumericCaract() {
    return PasswordHelpers.hasNumeric(this.getNewPassword());
  }

  passwordHasEightCaract() {
    return PasswordHelpers.hasEightCaract(this.getNewPassword());
  }

  passwordHasMajCaract() {
    return PasswordHelpers.hasMajCaract(this.getNewPassword());
  }

  passwordHasMinCaract() {
    return PasswordHelpers.hasMinCaract(this.getNewPassword());
  }

  passwordHasSpecialCaract() {
    return PasswordHelpers.hasSpecialCaract(this.getNewPassword());
  }
}
