export const IIban = Object.freeze({
  ibanLastDigits: "iban_last_digits",
  iban: "iban",
  owner: "owner",
});

export class IbanModel {
  constructor(data = {}) {
    this[IIban.ibanLastDigits] = data[IIban.ibanLastDigits];
    this[IIban.iban] = data[IIban.iban];
    this[IIban.owner] = data[IIban.owner];
  }

  getIbanLastDigits() {
    return this[IIban.ibanLastDigits] ? `XXXX XXXX XXXX XXXX XXXX XXXX ${this[IIban.ibanLastDigits]}` : "";
  }

  getIban() {
    return this[IIban.iban];
  }

  getOwner() {
    return this[IIban.owner];
  }
}
