export const ICreationStepAddress = Object.freeze({
  street: "street",
  city: "city",
  zipcode: "zipcode",
});

export class CreationStepAddressModel {
  constructor(data = {}) {
    this[ICreationStepAddress.street] = data[ICreationStepAddress.street];
    this[ICreationStepAddress.city] = data[ICreationStepAddress.city];
    this[ICreationStepAddress.zipcode] = data[ICreationStepAddress.zipcode];
  }

  getStreet() {
    return this[ICreationStepAddress.street];
  }

  getCity() {
    return this[ICreationStepAddress.city];
  }

  getZipcode() {
    return this[ICreationStepAddress.zipcode];
  }
}
