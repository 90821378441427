import { BILLING_STATUS, FEATURE_FLAGS } from "@core/constants";
import { DatesHelpers } from "@core/helpers";
import { EtabCategoryModel } from "@core/models/common/etab-category.model.js";

export const IEtabProfile = Object.freeze({
  address: "adresse",
  allowedAE: "autorise_ae",
  credit: "avoir",
  billing: "facturation",
  billingStatus: "status_facturation",
  category: "categorie",
  city: "ville",
  description: "presentation",
  etabName: "nom_etablissement",
  facebook: "facebook",
  id: "id",
  photo: "photo",
  postalCode: "code_postal",
  seats: "couverts",
  segment: "segment",
  subscriptionActive: "abonnement_actif",
  type: "type",
  website: "web",
  supportedZone: "zone_supported",
  hasFavorites: "has_favorites",
  firstName: "prenom",
  phone: "telephone",
  createdAt: "created_at",
  featureFlags: "feature_flags",
});

export class EtabProfileModel {
  constructor(data = {}) {
    this[IEtabProfile.address] = data[IEtabProfile.address];
    this[IEtabProfile.allowedAE] = data[IEtabProfile.allowedAE];
    this[IEtabProfile.credit] = data[IEtabProfile.credit];
    this[IEtabProfile.billing] = data[IEtabProfile.billing];
    this[IEtabProfile.billingStatus] = data[IEtabProfile.billingStatus];
    this[IEtabProfile.category] = data[IEtabProfile.category];
    this[IEtabProfile.city] = data[IEtabProfile.city];
    this[IEtabProfile.country] = data[IEtabProfile.country];
    this[IEtabProfile.description] = data[IEtabProfile.description];
    this[IEtabProfile.etabName] = data[IEtabProfile.etabName];
    this[IEtabProfile.facebook] = data[IEtabProfile.facebook];
    this[IEtabProfile.id] = data[IEtabProfile.id];
    this[IEtabProfile.photo] = data[IEtabProfile.photo];
    this[IEtabProfile.postalCode] = data[IEtabProfile.postalCode];
    this[IEtabProfile.seats] = data[IEtabProfile.seats];
    this[IEtabProfile.segment] = data[IEtabProfile.segment];
    this[IEtabProfile.subscriptionActive] = data[IEtabProfile.subscriptionActive];
    this[IEtabProfile.type] = data[IEtabProfile.type];
    this[IEtabProfile.website] = data[IEtabProfile.website];
    this[IEtabProfile.supportedZone] = data[IEtabProfile.supportedZone];
    this[IEtabProfile.hasFavorites] = data[IEtabProfile.hasFavorites];
    this[IEtabProfile.firstName] = data[IEtabProfile.firstName];
    this[IEtabProfile.phone] = data[IEtabProfile.phone];
    this[IEtabProfile.createdAt] = data[IEtabProfile.createdAt];
    this[IEtabProfile.featureFlags] = data[IEtabProfile.featureFlags];
  }

  getId() {
    return this[IEtabProfile.id];
  }

  isProfileLoaded() {
    return this.getId() !== undefined;
  }

  getCategoryEtab() {
    return new EtabCategoryModel(this[IEtabProfile.category]);
  }

  getCategoryLabel() {
    return this.getCategoryEtab().getLabel();
  }

  hasActiveSubscription() {
    return this[IEtabProfile.subscriptionActive] === true;
  }

  isBillingAE() {
    return this[IEtabProfile.billing] === 1 && this[IEtabProfile.billingStatus] === BILLING_STATUS.AE;
  }

  isAllowedAE() {
    return this[IEtabProfile.allowedAE] === true;
  }

  hasIban() {
    return this.isAllowedAE();
  }

  getEtabName() {
    return this[IEtabProfile.etabName];
  }

  getPhoto() {
    return this[IEtabProfile.photo];
  }

  getCredit() {
    return this[IEtabProfile.credit] || 0;
  }

  getFormattedAddress() {
    return `${this[IEtabProfile.address] || ""} ${this[IEtabProfile.postalCode] || ""} ${this[IEtabProfile.city] || ""}`;
  }

  hasFavorites() {
    return this[IEtabProfile.hasFavorites];
  }

  getFirstName() {
    return this[IEtabProfile.firstName];
  }

  getPostalCode() {
    return this[IEtabProfile.postalCode];
  }

  getPhoneNumber() {
    return this[IEtabProfile.phone];
  }

  getCreationDate() {
    return DatesHelpers.getDayjs(this[IEtabProfile.createdAt]).format("D MMM YYYY");
  }

  getFeatureFlags() {
    return this[IEtabProfile.featureFlags];
  }

  hasEpctFeatureFlag() {
    return this.getFeatureFlags() && !!this.getFeatureFlags()[FEATURE_FLAGS.EPCT];
  }
}
