export const CONTRACTS = {
  CDI: "cdi",
  SEASON: "saisonnier",
  AE: "extra",
  INTERIM: "interim",
};

export const CONTRACTS_PERMANENT = [
  {
    code: CONTRACTS.CDI,
    label: "CORE.MODELS.CONTRACTS.@CDI",
    universeColor: "primary",
    themeColor: "primary",
  },
  {
    code: CONTRACTS.SEASON,
    label: "CORE.MODELS.CONTRACTS.@SEASON",
    universeColor: "primary",
    themeColor: "season-orange",
  },
];
export const CONTRACTS_TEMPORARY = [
  {
    code: CONTRACTS.AE,
    label: "CORE.MODELS.CONTRACTS.@AE",
    universeColor: "secondary",
    themeColor: "secondary",
  },
  {
    code: CONTRACTS.INTERIM,
    label: "CORE.MODELS.CONTRACTS.@INTERIM",
    universeColor: "interim-green",
    themeColor: "interim-green",
  },
];

export const CONTRACTS_ALL = [
  //
  ...CONTRACTS_PERMANENT,
  ...CONTRACTS_TEMPORARY,
];
