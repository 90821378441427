import { BILLABLE_SHIFTS_STATUS } from "@core/constants";
import { DatesHelpers } from "@core/helpers";
import { BillableShiftAmountModel } from "./billable-shifts-amount.model";

export const IBillableShifts = Object.freeze({
  billingSessionId: "billing_session_id",
  billingSessionIdExtra: "extra_billing_session_id",
  billingSessionIdEtab: "etab_billing_session_id",
  etabAmount: "etab_amount",
  etabId: "etab_id",
  etabName: "etab_label",
  extraAmount: "extra_amount",
  extraFirstname: "extra_firstname",
  extraId: "extra_id",
  extraLastname: "extra_lastname",
  extraQualif: "extra_qualif_name",
  id: "id",
  shiftId: "shift_id",
  startDate: "start_date",
  status: "status",
  timesheetId: "timesheet_id",
  workingHours: "nb_working_hours",
});

export const IBillableShiftsColumns = Object.freeze({
  amount: "amount",
  date: "date",
  etab: "etab",
  extra: "extra",
  hours: "hours",
  select: "select",
  billingSession: "billingSession",
  status: "status",
});

export class BillableShiftModel {
  constructor(data = {}) {
    this[IBillableShifts.id] = data[IBillableShifts.id];
    this[IBillableShifts.etabId] = data[IBillableShifts.etabId];
    this[IBillableShifts.etabName] = data[IBillableShifts.etabName];
    this[IBillableShifts.extraId] = data[IBillableShifts.extraId];
    this[IBillableShifts.extraFirstname] = data[IBillableShifts.extraFirstname];
    this[IBillableShifts.extraLastname] = data[IBillableShifts.extraLastname];
    this[IBillableShifts.extraQualif] = data[IBillableShifts.extraQualif];
    this[IBillableShifts.shiftId] = data[IBillableShifts.shiftId];
    this[IBillableShifts.timesheetId] = data[IBillableShifts.timesheetId];
    this[IBillableShifts.billingSessionId] = data[IBillableShifts.billingSessionId];
    this[IBillableShifts.billingSessionIdExtra] = data[IBillableShifts.billingSessionIdExtra];
    this[IBillableShifts.billingSessionIdEtab] = data[IBillableShifts.billingSessionIdEtab];
    this[IBillableShifts.status] = data[IBillableShifts.status];
    this[IBillableShifts.startDate] = data[IBillableShifts.startDate];
    this[IBillableShifts.workingHours] = data[IBillableShifts.workingHours];
    this[IBillableShifts.etabAmount] = data[IBillableShifts.etabAmount];
    this[IBillableShifts.extraAmount] = data[IBillableShifts.extraAmount];
  }

  getAmountEtab() {
    return new BillableShiftAmountModel(this[IBillableShifts.etabAmount]);
  }

  getAmountExtra() {
    return new BillableShiftAmountModel(this[IBillableShifts.extraAmount]);
  }

  getId() {
    return this[IBillableShifts.id];
  }

  getTimesheetId() {
    return this[IBillableShifts.timesheetId];
  }

  getEtabId() {
    return this[IBillableShifts.etabId];
  }

  getEtabName() {
    return this[IBillableShifts.etabName];
  }

  getExtraId() {
    return this[IBillableShifts.extraId];
  }

  getExtraFullname() {
    return `${this.getExtraFirstname()} ${this.getExtraLastname()}`;
  }

  getExtraShortname() {
    if (this.getExtraFullname().length > 20) {
      return `${this.getExtraFirstname()} ${this.getExtraLastname().charAt(0).toUpperCase()}.`;
    }
    return this.getExtraFullname();
  }

  getExtraFirstname() {
    return this[IBillableShifts.extraFirstname] || "";
  }

  getExtraLastname() {
    return this[IBillableShifts.extraLastname] || "";
  }

  getExtraQualif() {
    return this[IBillableShifts.extraQualif] || "";
  }

  getShiftId() {
    return this[IBillableShifts.shiftId];
  }

  getStatus() {
    return this[IBillableShifts.status];
  }

  isBilled() {
    return this.getStatus() === BILLABLE_SHIFTS_STATUS.BILLED;
  }

  getWorkingHours() {
    return this[IBillableShifts.workingHours];
  }

  getFormattedWorkingHours() {
    return DatesHelpers.formatMinsToHrsMins(this.getWorkingHours() * 60);
  }

  getBillingSessionIdExtra() {
    return this[IBillableShifts.billingSessionIdExtra];
  }

  getBillingSessionIdEtab() {
    return this[IBillableShifts.billingSessionIdEtab];
  }

  getDate() {
    return DatesHelpers.getDayjs(this[IBillableShifts.startDate]);
  }

  getFormattedDate(format = "DD/MM/YYYY HH:mm") {
    return this.getDate().format(format);
  }
}
