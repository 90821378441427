export const IContract = Object.freeze({
  code: "code",
  label: "label",
});

export class ContractModel {
  constructor(data = {}) {
    this[IContract.code] = data[IContract.code];
    this[IContract.label] = data[IContract.label];
  }

  getCode() {
    return this[IContract.code];
  }

  getLabel() {
    return this[IContract.label];
  }
}
