export const ITimesheetStatus = Object.freeze({
  code: "code",
  label: "label",
  criticity: "criticity", // color
});

export class TimesheetStatusModel {
  constructor(data = {}) {
    this[ITimesheetStatus.code] = data[ITimesheetStatus.code];
    this[ITimesheetStatus.label] = data[ITimesheetStatus.label];
    this[ITimesheetStatus.criticity] = data[ITimesheetStatus.criticity];
  }

  getCode() {
    return this[ITimesheetStatus.code];
  }

  getLabel() {
    return this[ITimesheetStatus.label];
  }

  getCriticity() {
    return this[ITimesheetStatus.criticity];
  }
}
