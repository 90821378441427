import { Device as DevicePlugin } from "@capacitor/device";

/**
 * Wrapper around Capacitor plugin device
 * https://capacitorjs.com/docs/apis/device
 */
export class Device {
  /**
   * Returns the Capacitor plugin instance
   * @readonly
   */
  static get plugin() {
    return DevicePlugin;
  }

  /**
   * Get the device's info
   * @returns {Promise<DeviceInfo>} DeviceInfo
   */
  static getInfo() {
    return Device.plugin.getInfo();
  }

  /**
   * Get the device's Operating System
   * @returns {String} OS
   */
  static async getOperatingSystem() {
    const deviceInfo = await Device.getInfo();
    return deviceInfo.operatingSystem;
  }

  /**
   * Get the device's Operating System Version
   * @returns {String} OS Version
   */
  static async getOperatingSystemVersion() {
    const deviceInfo = await Device.getInfo();
    return deviceInfo.osVersion;
  }
}
