import { JOBS_APPLICATION_STATUS } from "@core/constants";

export const IJobsOffersStatus = Object.freeze({
  status: "status",
  link: "link",
});

export class JobsOffersStatusModel {
  constructor(data = {}) {
    this[IJobsOffersStatus.status] = data[IJobsOffersStatus.status];
    this[IJobsOffersStatus.link] = data[IJobsOffersStatus.link];
  }

  getStatus() {
    return this[IJobsOffersStatus.status];
  }

  getLink() {
    return this[IJobsOffersStatus.link];
  }

  isOpenApply() {
    return this[IJobsOffersStatus.status] === JOBS_APPLICATION_STATUS.OPEN_APPLY;
  }

  isCandidateApply() {
    return this[IJobsOffersStatus.status] === JOBS_APPLICATION_STATUS.APPLY;
  }

  isDownload() {
    return this[IJobsOffersStatus.status] === JOBS_APPLICATION_STATUS.DOWNLOAD;
  }
}
