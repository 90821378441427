import { IbanModel } from "./payment";

export const IEtabAdminFolder = Object.freeze({
  address: "address",
  city: "city",
  socialReason: "social_reason",
  etabName: "etab_name",
  id: "id",
  postalCode: "zipcode",
  email: "email",
  country: "country",
  vat: "vat_number",
  siret: "siret",
  paymentMean: "payment_method",
  hasAdminInfo: "has_admin_info",
  hasCard: "has_card",
  cardsCount: "cards_count",
  registeredIban: "registered_iban",
  ibanDetails: "iban_details",
  anomaly: "anomaly",
  isAuthorizedAE: "is_authorized_ae",
});

export class EtabAdminFolderModel {
  constructor(data = {}) {
    this[IEtabAdminFolder.id] = data[IEtabAdminFolder.id];
    this[IEtabAdminFolder.address] = data[IEtabAdminFolder.address];
    this[IEtabAdminFolder.city] = data[IEtabAdminFolder.city];
    this[IEtabAdminFolder.country] = data[IEtabAdminFolder.country];
    this[IEtabAdminFolder.email] = data[IEtabAdminFolder.email];
    this[IEtabAdminFolder.etabName] = data[IEtabAdminFolder.etabName];
    this[IEtabAdminFolder.postalCode] = data[IEtabAdminFolder.postalCode];
    this[IEtabAdminFolder.siret] = data[IEtabAdminFolder.siret];
    this[IEtabAdminFolder.socialReason] = data[IEtabAdminFolder.socialReason];
    this[IEtabAdminFolder.vat] = data[IEtabAdminFolder.vat];
    this[IEtabAdminFolder.hasAdminInfo] = data[IEtabAdminFolder.hasAdminInfo];
    this[IEtabAdminFolder.hasCard] = data[IEtabAdminFolder.hasCard];
    this[IEtabAdminFolder.cardsCount] = data[IEtabAdminFolder.cardsCount];
    this[IEtabAdminFolder.registeredIban] = data[IEtabAdminFolder.registeredIban];
    this[IEtabAdminFolder.ibanDetails] = data[IEtabAdminFolder.ibanDetails];
    this[IEtabAdminFolder.paymentMean] = data[IEtabAdminFolder.paymentMean];
    this[IEtabAdminFolder.anomaly] = data[IEtabAdminFolder.anomaly];
    this[IEtabAdminFolder.isAuthorizedAE] = data[IEtabAdminFolder.isAuthorizedAE];
  }

  getId() {
    return this[IEtabAdminFolder.id];
  }

  getSiret() {
    return this[IEtabAdminFolder.siret] || "";
  }

  getVat() {
    return this[IEtabAdminFolder.vat];
  }

  getEtabName() {
    return this[IEtabAdminFolder.etabName] || "";
  }

  getPostalCode() {
    return this[IEtabAdminFolder.postalCode];
  }

  getAddress() {
    return this[IEtabAdminFolder.address];
  }

  getCity() {
    return this[IEtabAdminFolder.city];
  }

  getCountry() {
    return this[IEtabAdminFolder.country];
  }

  getEmail() {
    return this[IEtabAdminFolder.email];
  }

  getPaymentMean() {
    return this[IEtabAdminFolder.paymentMean];
  }

  getHasAdminInfo() {
    return this[IEtabAdminFolder.hasAdminInfo] || false;
  }

  getHasCard() {
    return this[IEtabAdminFolder.hasCard] || false;
  }

  getCardsCount() {
    return this[IEtabAdminFolder.cardsCount] || 0;
  }

  getRegisteredIban() {
    return this[IEtabAdminFolder.registeredIban] || false;
  }

  getIbanDetails() {
    return new IbanModel(this[IEtabAdminFolder.ibanDetails]);
  }

  getSocialReason() {
    return this[IEtabAdminFolder.socialReason];
  }

  hasAnomaly() {
    return this[IEtabAdminFolder.anomaly];
  }

  isAuthorizedAE() {
    return this[IEtabAdminFolder.isAuthorizedAE];
  }
}
