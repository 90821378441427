import { DatesHelpers } from "@core/helpers";

export const IBillableShiftsFilters = Object.freeze({
  etabId: "etab_id",
  extraId: "extra_id",
  frequencies: "frequencies",
  maxDate: "dateMax",
  billingExternalRef: "billingExternalRef",
  page: "page",
  status: "status",
  take: "nb",
});

export class BillableShiftsFiltersModel {
  constructor(data = {}) {
    this[IBillableShiftsFilters.etabId] = data[IBillableShiftsFilters.etabId];
    this[IBillableShiftsFilters.extraId] = data[IBillableShiftsFilters.extraId];
    this[IBillableShiftsFilters.frequencies] = data[IBillableShiftsFilters.frequencies];
    this[IBillableShiftsFilters.maxDate] = data[IBillableShiftsFilters.maxDate];
    this[IBillableShiftsFilters.billingExternalRef] = data[IBillableShiftsFilters.billingExternalRef];
    this[IBillableShiftsFilters.page] = data[IBillableShiftsFilters.page];
    this[IBillableShiftsFilters.status] = data[IBillableShiftsFilters.status];
    this[IBillableShiftsFilters.take] = data[IBillableShiftsFilters.take];
  }

  getStatus() {
    const status = this[IBillableShiftsFilters.status];
    return Array.isArray(status) && status.length > 0 ? status : undefined;
  }

  getFrequencies() {
    const frequencies = this[IBillableShiftsFilters.frequencies];
    return Array.isArray(frequencies) && frequencies.length > 0 ? frequencies : undefined;
  }

  getFormattedDate(format = "L") {
    return this[IBillableShiftsFilters.maxDate] ? DatesHelpers.getDayjs(this[IBillableShiftsFilters.maxDate]).format(format) : undefined;
  }
}
