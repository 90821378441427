import { ContractModel } from "@core/models/jobs/contract.model";
import { QualificationModel } from "@core/models/common/qualification.model";
import { EtabCategoryModel } from "@core/models/common/etab-category.model";
import { DatesHelpers } from "@core/helpers";
import { CANDIDATE_EXPERIENCE_CONTRACTS, DISPLAY_DATE_FORMAT_SHORT } from "@core/constants";

export const ICandidateExperience = Object.freeze({
  id: "id",
  extra: "extra",
  extraId: "extra_id",
  city: "city",
  contract: "contract",
  duration: "duration",
  endDate: "end_date",
  etabCategory: "category",
  etabName: "etab_name",
  isCurrentPosition: "is_current_job",
  postalCode: "postal_code",
  qualification: "qualification",
  rating: "rating",
  reference: "reference",
  startDate: "start_date",
  status: "status",
  allowVerification: "is_allow_verification",
  isSelfVerified: "is_self_verified",
  internalComment: "internal_comment",
});

export class CandidateExperienceModel {
  constructor(data = {}) {
    this[ICandidateExperience.id] = data[ICandidateExperience.id];
    this[ICandidateExperience.qualification] = data[ICandidateExperience.qualification];
    this[ICandidateExperience.etabName] = data[ICandidateExperience.etabName];
    this[ICandidateExperience.contract] = data[ICandidateExperience.contract];
    this[ICandidateExperience.postalCode] = data[ICandidateExperience.postalCode];
    this[ICandidateExperience.city] = data[ICandidateExperience.city];
    this[ICandidateExperience.duration] = data[ICandidateExperience.duration];
    this[ICandidateExperience.startDate] = data[ICandidateExperience.startDate];
    this[ICandidateExperience.endDate] = data[ICandidateExperience.endDate];
    this[ICandidateExperience.isCurrentPosition] = data[ICandidateExperience.isCurrentPosition] || false;
    this[ICandidateExperience.rating] = data[ICandidateExperience.rating];
    this[ICandidateExperience.etabCategory] = data[ICandidateExperience.etabCategory];
  }

  getId() {
    return this[ICandidateExperience.id];
  }

  getQualification() {
    return new QualificationModel(this[ICandidateExperience.qualification]);
  }

  getQualificationName() {
    return this.getQualification().getLabel();
  }

  getEtabName() {
    return this[ICandidateExperience.etabName];
  }

  getCity() {
    return this[ICandidateExperience.city] || "";
  }

  getPostalCode() {
    return this[ICandidateExperience.postalCode] || "";
  }

  getFormattedAddress() {
    return `${this.getCity()}, ${this.getPostalCode()}`;
  }

  getContractObject() {
    return this[ICandidateExperience.contract];
  }

  getContract() {
    return new ContractModel(this.getContractObject());
  }

  getContractColor() {
    const contract = Object.values(CANDIDATE_EXPERIENCE_CONTRACTS).find((c) => c.code === this.getContractObject());
    return contract?.color;
  }

  getDisplayedContract() {
    const contract = CANDIDATE_EXPERIENCE_CONTRACTS.find((c) => c.code === this.getContractObject());
    return contract?.label;
  }

  isCurrentPosition() {
    return this[ICandidateExperience.isCurrentPosition] === true;
  }

  getStartDate() {
    return DatesHelpers.getDayjs(this[ICandidateExperience.startDate]).format(DISPLAY_DATE_FORMAT_SHORT);
  }

  getStartTimestamp() {
    return DatesHelpers.getDayjs(this[ICandidateExperience.startDate]).unix();
  }

  getEndDate() {
    return this[ICandidateExperience.endDate]
      ? DatesHelpers.getDayjs(this[ICandidateExperience.endDate]).format(DISPLAY_DATE_FORMAT_SHORT)
      : undefined;
  }

  getDuration() {
    const startDate = DatesHelpers.getDayjs(this[ICandidateExperience.startDate]);
    const endDate = this.getEndDate() === undefined ? DatesHelpers.getDayjs() : DatesHelpers.getDayjs(this[ICandidateExperience.endDate]);
    const millisecondsDurationOfOneDay = 24 * 3600 * 1000;
    // we add one day in order to have a duration of 1 day for same day missions
    // example: from 01/01/1970 to 01/01/1970 -> diff = 0, but we want duration = 1 day
    const duration = DatesHelpers.getDaysjsDuration(endDate, startDate) + millisecondsDurationOfOneDay;
    return DatesHelpers.humanizeDuration(duration);
  }

  getRating() {
    return this[ICandidateExperience.rating];
  }

  getCategory() {
    if (this[ICandidateExperience.etabCategory] !== null) {
      return new EtabCategoryModel(this[ICandidateExperience.etabCategory]);
    }
    return new EtabCategoryModel();
  }

  isExtracadabraExperience() {
    return false;
  }

  isPersonalExperience() {
    return false;
  }

  isVerified() {
    return false;
  }

  isUnverified() {
    return false;
  }

  isPending() {
    return false;
  }
}
