import { createI18n } from "vue-i18n";
import fr from "@extracadabra/i18n/fr-FR/common.json";
import messages from "@extracadabra/i18n/fr-FR/messages.json";
import { DEFAULT_LANGUAGE } from "@core/constants";

/**
 * Vue-i18n Instance
 * [vue-i18n]{ @link https://github.com/intlify/vue-i18n-next }
 */
export const i18n = createI18n({
  legacy: true,
  locale: DEFAULT_LANGUAGE,
  fallbackLocale: DEFAULT_LANGUAGE,
  // silent the translation missing errors
  globalInjection: true,
  silentTranslationWarn: true,
  messages: { fr },
});

/**
 * Configure the app with a pre-configured i18n instance
 * [vue-i18n]{ @link https://github.com/intlify/vue-i18n-next }
 * @param {Array} specificTranslations - merge the default translations with this one
 * @param {string} specificTranslations.locale - the locale (ex: fr)
 * @param {Array} specificTranslations.messages - all the keys and translations of this locale
 * @returns Vue-i18n instance
 */
export default function configureInternationalization(specificTranslations = []) {
  // add messages for http errors
  i18n.global.mergeLocaleMessage("fr", messages);
  // merge specific translations if any
  specificTranslations.forEach((translation) => i18n.global.mergeLocaleMessage(translation.locale, translation.messages));

  return i18n;
}
