export const CANDIDATES_SIGNUP_STATUS = {
  BECOME_AE: "BECOME_AE",
  ALREADY_AE: "ALREADY_AE",
  NOT_INTERESTED: "NOT_INTERESTED",
};

export const CANDIDATES_ONBOARDING_STEPS = {
  ONBOARDING_PERSONNAL_INFORMATIONS: "personal_informations",
  ONBOARDING_ADDRESS: "address",
  ONBOARDING_PICTURE: "picture",
  ONBOARDING_EXPERIENCE: "experience",
  ONBOARDING_ABOUT: "about",
  ONBOARDING_ORIGIN: "origin",
};

export const CANDIDATES_APPLY_ORIGINS = {
  SIGNUP: "register",
  LOGIN: "login",
  DIRECT: "direct",
};

export const CANDIDATES_OFFER_STATUS = {
  PENDING: "pending",
  APPLIED: "applied",
  SELECTED: "selected",
  HIRED: "hired",
  REFUSED: "refused",
  CANCELLED_MISSION: "cancelled_after_got_hired",
  CANCELLED_ETAB: "cancelled_by_etab",
  REVIEWABLE: "reviewable",
};

export const CANDIDATE_SHIFT_STATUS = {
  IN_PROGRESS: {
    status: "in_progress",
    label: "Job en cours",
    color: "extra-orange",
  },

  CANCELLED_BY_CANDIDATE: {
    status: "candidate_cancel",
    label: "Job annulé",
    color: "extra-dark-red",
  },
  COMING_SOON: {
    status: "coming_soon",
    label: "Job à venir",
    color: "extra-green",
  },
  CANCELLED_BY_ETAB: {
    status: "etab_cancel",
    label: "Jobs annulé par l'établissement",
    color: "extra-dark-red",
  },
};

export const CANDIDATES_APPLY_STATUS_TYPES = {
  SUCCESS: "success",
  WARNING: "warning",
};

export const CANDIDATES_GENDERS = {
  MALE: "homme",
  FEMALE: "femme",
};

export const MINIMUM_AGE = 16;
export const ORIGIN_OTHER_ID = 9;
export const ORIGIN_OTHER = "Autre";

export const CANDIDATE_UPLOAD_FILE_NAME = {
  IDENTITY: "IDENTITE",
  RIB: "RIB",
  INSEE: "INSEE",
  IDENTITY_VERSO: "IDENTITE_VERSO",
  RESIDENCE: "RESIDENCE",
};

export const IBAN_SUFFIX = {
  FR: "FR",
};

export const CANDIDATE_AE_FOLDER_STATUS = {
  STATUS_PENDING: "pending", // incomplete candidate side or a new document has add
  STATUS_PENDING_INTERNAL_VERIFICATION: "pending_internal_verification", // Candidate is waiting for the internal verification
  STATUS_VERIFIED: "verified",
  STATUS_REFUSED: "refused", // in case of internal verification failed
};

export const AE_FOLDER_IDENTITY_LIST = {
  CNI: "CNI",
  PASSEPORT_UE: "PASSEPORT_UE",
  PASSEPORT_FR: "PASSEPORT_FR",
  CNIE: "CNIE",
  RESIDENCE_PERMIT: "TITRE_SEJOUR",
  TEMPORARY_RESIDENCE_PERMIT: "TITRE_SEJOUR_TEMPORAIRE",
};

export const FOLDER_TYPE = {
  AE: "ae",
};

export const PAGINATION_MAX_OFFERS_PER_PAGE = 30;

export const CANDIDATE_STORED_DATA_MAX_TTL = 2 * 60 * 1000; // 2 min in ms: max duration before stored data in vuex must be refreshed

export const SATISFACTION_STATUS = [
  { iconSelected: "fa-solid fa-face-smile", iconUnselected: "fa-regular fa-face-smile", level: 5 },
  { iconSelected: "fa-solid fa-face-meh", iconUnselected: "fa-regular fa-face-meh", level: 3 },
  { iconSelected: "fa-solid fa-face-frown", iconUnselected: "fa-regular fa-face-frown", level: 1 },
];
