import { ADMIN_FOLDER_STATUS, ADMIN_FOLDER_ALERT } from "@core/constants/backoffice.constants";
import { BoAddressModel } from "./address.model";

export const IBoExtra = Object.freeze({
  extraId: "id",
  firstname: "firstname",
  lastname: "lastname",
  phone: "phone",
  email: "email",
  birthDate: "birthdate",
  nationality: "nationality",
  socialNumber: "numero_secu",
  nativeCountry: "naissance_pays",
  address: "address",
  status: "status",
  alert: "alert",
  recommendationsTotal: "number_valid_recommendations",
  experiencesRating: "experiences_rating",
});

export class BoExtraModel {
  constructor(data = {}) {
    this[IBoExtra.extraId] = data[IBoExtra.extraId];
    this[IBoExtra.firstname] = data[IBoExtra.firstname];
    this[IBoExtra.lastname] = data[IBoExtra.lastname];
    this[IBoExtra.phone] = data[IBoExtra.phone];
    this[IBoExtra.email] = data[IBoExtra.email];
    this[IBoExtra.birthDate] = data[IBoExtra.birthDate];
    this[IBoExtra.nationality] = data[IBoExtra.nationality];
    this[IBoExtra.socialNumber] = data[IBoExtra.socialNumber];
    this[IBoExtra.nativeCountry] = data[IBoExtra.nativeCountry];
    this[IBoExtra.address] = data[IBoExtra.address];
  }

  getId() {
    return this[IBoExtra.extraId];
  }

  getFirstname() {
    return this[IBoExtra.firstname];
  }

  getLastname() {
    return this[IBoExtra.lastname];
  }

  getFullName() {
    return `${this.getFirstname()} ${this.getLastname()}`;
  }

  getPhoneNumber() {
    return this[IBoExtra.phone];
  }

  getBirthDate() {
    return this[IBoExtra.birthDate];
  }

  getNationality() {
    return this[IBoExtra.nationality];
  }

  getSecurityNumber() {
    return this[IBoExtra.securityNumber];
  }

  getNativeCountry() {
    return this[IBoExtra.nativeCountry];
  }

  getAddress() {
    return new BoAddressModel(this[IBoExtra.address]);
  }
}

export class BoAeExtraModel extends BoExtraModel {
  constructor(data = {}) {
    super(data);
    this[IBoExtra.status] = data[IBoExtra.status];
    this[IBoExtra.alert] = data[IBoExtra.alert];
    this[IBoExtra.recommendationsTotal] = data[IBoExtra.recommendationsTotal];
    this[IBoExtra.experiencesRating] = data[IBoExtra.experiencesRating];
  }

  getRecommendationsTotal() {
    return this[IBoExtra.recommendationsTotal];
  }

  getExperiencesRating() {
    return this[IBoExtra.experiencesRating];
  }

  getStatus() {
    return this[IBoExtra.status];
  }

  getStatusLabel() {
    const statusList = Object.values(ADMIN_FOLDER_STATUS).find((status) => status.code === this.getStatus());
    return statusList?.label || "default";
  }

  getStatusColor() {
    switch (this.getStatus()) {
      case ADMIN_FOLDER_STATUS.PENDING_INTERNAL_VERIFICATION.code:
        return "primary";
      case ADMIN_FOLDER_STATUS.PENDING_INTERNAL_RE_VERIFICATION.code:
        return "grey";
      case ADMIN_FOLDER_STATUS.PENDING.code:
        return "warning";
      case ADMIN_FOLDER_STATUS.VERIFIED.code:
        return "positive";
      case ADMIN_FOLDER_STATUS.REFUSED.code:
        return "negative";
      default:
        return "primary";
    }
  }

  getAlert() {
    return this[IBoExtra.alert];
  }

  getAlertLabel() {
    switch (this.getAlert()) {
      case ADMIN_FOLDER_ALERT.WILL_EXPIRE_SOON.code:
        return ADMIN_FOLDER_ALERT.WILL_EXPIRE_SOON.label;
      case ADMIN_FOLDER_ALERT.EXPIRED.code:
        return ADMIN_FOLDER_ALERT.EXPIRED.label;
      default:
        return "primary";
    }
  }

  getAlertColor() {
    switch (this.getAlert()) {
      case ADMIN_FOLDER_ALERT.WILL_EXPIRE_SOON.code:
        return ADMIN_FOLDER_ALERT.WILL_EXPIRE_SOON.color;
      case ADMIN_FOLDER_ALERT.EXPIRED.code:
        return ADMIN_FOLDER_ALERT.EXPIRED.color;
      default:
        return "primary";
    }
  }
}
