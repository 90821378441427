import { QUALIFICATION_FAMILY, QUALIFICATION_FAMILY_LABEL } from "@core/constants";

// TODO voir si on peut use le BasicOptionModel ici
export const IQualificationFamily = Object.freeze({
  code: "code",
  id: "id",
  label: "nom",
});

export class QualificationFamilyModel {
  constructor(data = {}) {
    this[IQualificationFamily.code] = data[IQualificationFamily.code];
    this[IQualificationFamily.id] = data[IQualificationFamily.id];
    this[IQualificationFamily.label] = data[IQualificationFamily.label];
  }

  getId() {
    return this[IQualificationFamily.id];
  }

  getLabel() {
    return this[IQualificationFamily.label] || "";
  }

  getCode() {
    return this[IQualificationFamily.code];
  }

  getIcon() {
    switch (this.getCode()) {
      case QUALIFICATION_FAMILY.HOSTEL:
        return "icon-extra-hostel";
      case QUALIFICATION_FAMILY.BAR:
        return "icon-extra-room";
      case QUALIFICATION_FAMILY.KITCHEN:
        return "icon-extra-kitchen";
      case QUALIFICATION_FAMILY.RETAIL:
        return "icon-extra-takeaway";
      case QUALIFICATION_FAMILY.HEALTH:
        return "icon-extra-health";
      case QUALIFICATION_FAMILY.AUTRE:
        return "icon-extra-others";
      default:
        return "";
    }
  }

  getIconByLabel() {
    switch (this.getLabel()) {
      case QUALIFICATION_FAMILY_LABEL.HOSTEL:
        return "icon-extra-hostel";
      case QUALIFICATION_FAMILY_LABEL.BAR:
        return "icon-extra-room";
      case QUALIFICATION_FAMILY_LABEL.KITCHEN:
        return "icon-extra-kitchen";
      case QUALIFICATION_FAMILY_LABEL.RETAIL:
        return "icon-extra-takeaway";
      case QUALIFICATION_FAMILY_LABEL.HEALTH:
        return "icon-extra-health";
      case QUALIFICATION_FAMILY_LABEL.OTHER:
        return "icon-extra-others";
      default:
        return "";
    }
  }

  getSelectOptions() {
    return {
      code: this.getCode(),
      value: this.getId(),
      label: this.getLabel(),
      icon: this.getIcon(),
    };
  }
}
