export const IMissionDuration = Object.freeze({
  id: "id",
  code: "code",
  label: "libelle",
  price: "prix_ht",
  emergencyPrice: "prix_ht_urgence",
});

export class MissionDurationModel {
  constructor(data = {}) {
    this[IMissionDuration.id] = data[IMissionDuration.id];
    this[IMissionDuration.code] = data[IMissionDuration.code];
    this[IMissionDuration.label] = data[IMissionDuration.label];
    this[IMissionDuration.price] = data[IMissionDuration.price];
    this[IMissionDuration.emergencyPrice] = data[IMissionDuration.emergencyPrice];
  }

  getId() {
    return this[IMissionDuration.id];
  }

  getDurationDisplay() {
    return this[IMissionDuration.label];
  }

  isValid() {
    return this[IMissionDuration.id] !== undefined && this.getDurationDisplay() !== undefined;
  }
}
