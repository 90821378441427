import { QualificationModel } from "@core/models/common/qualification.model";
import { CANDIDATES_OFFER_STATUS, CONTRACTS, CONTRACTS_ALL } from "@core/constants";
import { DatesHelpers } from "@core/helpers";
import { CandidateEtabModel } from "./candidate-etab.model";
import { CandidateOfferAddressModel } from "./candidate-offer-address.model";
import { CandidateOfferSalaryModel } from "./candidate-offer-salary.model";
import { CandidateOfferScheduleDayModel } from "./candidate-offer-schedule-day.model";

export const ICandidateDashboardOffer = Object.freeze({
  id: "encoded_id",
  startDate: "start_date",
  publicationDate: "publication_date",
  viewed: "viewed",
  schedule: "forecast_schedule",
  qualification: "qualification",
  etab: "etab",
  firstShift: "first_shift",
  salary: "salary",
  address: "address",
  contractType: "contract_type",
  isBookingExpress: "is_booking_express",
  nbDays: "nb_days",
  status: "status",
  hasEnded: "has_ended",
  isReviewable: "is_reviewable",
  activityReportUri: "candidate_activity_report_uri",
  isOlympicsOffer: "is_jo",
});

export class CandidateDashboardOfferModel {
  constructor(data = {}) {
    this[ICandidateDashboardOffer.id] = data[ICandidateDashboardOffer.id];
    this[ICandidateDashboardOffer.startDate] = data[ICandidateDashboardOffer.startDate];
    this[ICandidateDashboardOffer.publicationDate] = data[ICandidateDashboardOffer.publicationDate];
    this[ICandidateDashboardOffer.viewed] = data[ICandidateDashboardOffer.viewed];
    this[ICandidateDashboardOffer.schedule] = data[ICandidateDashboardOffer.schedule];
    this[ICandidateDashboardOffer.qualification] = data[ICandidateDashboardOffer.qualification];
    this[ICandidateDashboardOffer.etab] = data[ICandidateDashboardOffer.etab];
    this[ICandidateDashboardOffer.firstShift] = data[ICandidateDashboardOffer.firstShift];
    this[ICandidateDashboardOffer.salary] = data[ICandidateDashboardOffer.salary];
    this[ICandidateDashboardOffer.address] = data[ICandidateDashboardOffer.address];
    this[ICandidateDashboardOffer.contractType] = data[ICandidateDashboardOffer.contractType];
    this[ICandidateDashboardOffer.isBookingExpress] = data[ICandidateDashboardOffer.isBookingExpress];
    this[ICandidateDashboardOffer.nbDays] = data[ICandidateDashboardOffer.nbDays];
    this[ICandidateDashboardOffer.status] = data[ICandidateDashboardOffer.status];
    this[ICandidateDashboardOffer.isReviewable] = data[ICandidateDashboardOffer.isReviewable];
    this[ICandidateDashboardOffer.activityReportUri] = data[ICandidateDashboardOffer.activityReportUri];
    this[ICandidateDashboardOffer.hasEnded] = data[ICandidateDashboardOffer.hasEnded];
    this[ICandidateDashboardOffer.isOlympicsOffer] = data[ICandidateDashboardOffer.isOlympicsOffer];
  }

  getId() {
    return this[ICandidateDashboardOffer.id];
  }

  isBookingExpress() {
    return this[ICandidateDashboardOffer.isBookingExpress] || false;
  }

  getContractType() {
    return (this[ICandidateDashboardOffer.contractType] || "").toLowerCase();
  }

  getContractTypeObject() {
    const contractTypeCode = this.getContractType();
    return CONTRACTS_ALL.find((c) => contractTypeCode === c.code) || {};
  }

  getContractTypeDisplay() {
    return this.getContractTypeObject().label;
  }

  getContractTypeColor() {
    return this.getContractTypeObject().themeColor;
  }

  isCDI() {
    return this.getContractType() === CONTRACTS.CDI;
  }

  isSeason() {
    return this.getContractType() === CONTRACTS.SEASON;
  }

  isAE() {
    return this.getContractType() === CONTRACTS.AE;
  }

  isInterim() {
    return this.getContractType() === CONTRACTS.INTERIM;
  }

  isPermanent() {
    return this.isCDI() || this.isSeason();
  }

  isTemporary() {
    return this.isAE() || this.isInterim();
  }

  isOlympicsOffer() {
    return this[ICandidateDashboardOffer.isOlympicsOffer];
  }

  getAddress() {
    return new CandidateOfferAddressModel(this[ICandidateDashboardOffer.address]);
  }

  getFormattedAddress() {
    return `${this.getAddress().getPostalCode()} ${this.getAddress().getCity()}`;
  }

  getEtab() {
    return new CandidateEtabModel(this[ICandidateDashboardOffer.etab]);
  }

  getEtabPicture() {
    return this.getEtab().getFirstPicture();
  }

  getEtabName() {
    return this.getEtab().getName();
  }

  getEtabCategory() {
    return this.getEtab().getCategory().getName();
  }

  getQualification() {
    return new QualificationModel(this[ICandidateDashboardOffer.qualification]);
  }

  getQualificationLabel() {
    return this.getQualification().getLabel();
  }

  getStartDate() {
    return this[ICandidateDashboardOffer.startDate];
  }

  getStartDateDisplay() {
    return DatesHelpers.getFormattedDate(this.getStartDate());
  }

  getSchedule() {
    return this[ICandidateDashboardOffer.schedule];
  }

  getPublicationDate() {
    return this[ICandidateDashboardOffer.publicationDate];
  }

  seen() {
    return this[ICandidateDashboardOffer.viewed];
  }

  getPublicationDateFromNow() {
    const date = this.getPublicationDate();
    return DatesHelpers.getDateMomentIfExist(date)?.fromNow();
  }

  getSalary() {
    return new CandidateOfferSalaryModel(this[ICandidateDashboardOffer.salary]);
  }

  getFirstShift() {
    return this[ICandidateDashboardOffer.firstShift] ? new CandidateOfferScheduleDayModel(this[ICandidateDashboardOffer.firstShift]) : undefined;
  }

  getNbDays() {
    return this[ICandidateDashboardOffer.nbDays] || 0;
  }

  getStatus() {
    return this[ICandidateDashboardOffer.status];
  }

  isPendingStatus() {
    return this.getStatus() === CANDIDATES_OFFER_STATUS.PENDING;
  }

  isAppliedStatus() {
    return this.getStatus() === CANDIDATES_OFFER_STATUS.APPLIED;
  }

  isSelectedStatus() {
    return this.getStatus() === CANDIDATES_OFFER_STATUS.SELECTED;
  }

  isHiredStatus() {
    return this.getStatus() === CANDIDATES_OFFER_STATUS.HIRED;
  }

  isRefusedStatus() {
    return this.getStatus() === CANDIDATES_OFFER_STATUS.REFUSED;
  }

  isCanceledMissionStatus() {
    return this.getStatus() === CANDIDATES_OFFER_STATUS.CANCELLED_MISSION;
  }

  isCanceledByEtabStatus() {
    return this.getStatus() === CANDIDATES_OFFER_STATUS.CANCELLED_ETAB;
  }

  isReviewable() {
    return this[ICandidateDashboardOffer.isReviewable];
  }

  getActivityReportUrl() {
    return this[ICandidateDashboardOffer.activityReportUri];
  }

  hasEnded() {
    return this[ICandidateDashboardOffer.hasEnded];
  }

  shouldDisplayTimeSheetBtns() {
    return (this.isSelectedStatus || this.isCanceledMissionStatus()) && (this.getActivityReportUrl() || this.isReviewable());
  }

  isCdiAsapOffer() {
    const offerDate = DatesHelpers.getDayjs(this.getStartDate());
    return this.isCDI() && DatesHelpers.isSameOrBefore(offerDate);
  }
}
