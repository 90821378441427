export const TIMESHEET_STATUS = {
  BILLABLE: "conflict_solve",
};

export const TIMESHEET_DETAILED_STATUS = {
  TO_COMPLETE: "to_complete",
  EXTRA_COMPLETED: "extra_completed",
  ETAB_COMPLETED: "etab_completed",
  AGREED: "agreed",
  FORCE_AGREED: "force_agreed",
  CONFLICTED: "conflicted",
  CONFLICT_RESOLVED_FOR_EXTRA: "conflict_resolved_for_extra",
  CONFLICT_RESOLVED_FOR_ETAB: "conflict_resolved_for_etab",
  CONFLICT_RESOLVED_BY_US: "conflict_resolved_by_us",
  BILLABLE: "billable",
  EXTRA_NO_SHOW: "extra_no_show",
};

export const BILLABLE_SHIFTS_STATUS = {
  BILLED: "fully_billed",
};

export const BO_DATE_FORMAT = "YYYY-MM-DD HH:mm";

export const IDENTIFIER_TYPE = {
  ETAB: "etab",
  EXTRA: "extra",
  CANDIDATE: "candidate",
};

// ADMIN FOLDER

export const ADMIN_FOLDER_STATUS = {
  PENDING_INTERNAL_VERIFICATION: {
    label: "BO.DASHBOARD.ADMIN_FOLDER.@PENDING_INTERNAL_VALIDATION",
    code: "pending_internal_verification",
    color: "primary",
  },
  PENDING_INTERNAL_RE_VERIFICATION: {
    label: "BO.DASHBOARD.ADMIN_FOLDER.@PENDING_INTERNAL_RE_VALIDATION",
    code: "pending_internal_re_verification",
    color: "grey",
  },
  PENDING: {
    label: "BO.DASHBOARD.ADMIN_FOLDER.@PENDING",
    code: "pending",
    color: "warning",
  },
  VERIFIED: {
    label: "BO.DASHBOARD.ADMIN_FOLDER.@VALIDATED",
    code: "verified",
    color: "positive",
  },
  REFUSED: {
    label: "BO.DASHBOARD.ADMIN_FOLDER.@REFUSED",
    code: "refused",
    color: "negative",
  },
};

export const ADMIN_FOLDER_ALERT = {
  EXPIRED: {
    label: "BO.DASHBOARD.ADMIN_FOLDER.@EXPIRED_DOCUMENT",
    code: "expired",
    color: "negative",
  },
  WILL_EXPIRE_SOON: {
    label: "BO.DASHBOARD.ADMIN_FOLDER.@DOCUMENT_EXPIRE_SOON",
    code: "will_expire_soon",
    color: "warning",
  },
};

export const MIME_TYPES = {
  PDF: "application/pdf",
  JPEG: "image/jpeg",
  JPG: "image/jpg",
  PNG: "image/png",
};

export const ADMIN_FOLDER_DOCUMENTS = {
  IDENTITY: "IDENTITE",
  IDENTITY_VERSO: "IDENTITE_VERSO",
  AE: "INSEE",
  RIB: "RIB",
};

export const REFUSAL_REASON_OTHER = "OTHER_REASON";
