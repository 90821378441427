import { ArrayHelpers } from "./array.helpers";

export class QueryHelpers {
  /**
   * @param {*} queryParamsValue
   * if queryParamsValue is an array we map it and filter it else we split at every ","
   * @returns
   */
  static getUsableQueryParams(queryParamsValue = "") {
    const queryParamsArray = Array.isArray(queryParamsValue) ? queryParamsValue : queryParamsValue.split(",");
    return queryParamsArray.map((value) => value.toString()).filter((item) => item !== "" && item !== undefined);
  }

  /**
   * @param {*} queryParamsValue
   * @example we merge and deduplicate querys
   * @returns
   */
  static mergeQueryParams(query1, query2) {
    const queryParams1 = QueryHelpers.getUsableQueryParams(query1);
    const queryParams2 = QueryHelpers.getUsableQueryParams(query2);
    return ArrayHelpers.deduplicate([...queryParams1, ...queryParams2]);
  }
}
