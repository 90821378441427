export const IJobOutFit = Object.freeze({
  img: "img",
  outfitLabel: "outfit_label",
  colorLabel: "label_color",
  colorCode: "code",
});

export class JobOutfitModel {
  constructor(data = {}) {
    this[IJobOutFit.img] = data[IJobOutFit.img];
    this[IJobOutFit.outfitLabel] = data[IJobOutFit.outfitLabel];
    this[IJobOutFit.colorLabel] = data[IJobOutFit.colorLabel];
    this[IJobOutFit.colorCode] = data[IJobOutFit.colorCode];
  }

  getImg() {
    return this[IJobOutFit.img];
  }

  getOutfitLabel() {
    return this[IJobOutFit.outfitLabel] || "";
  }

  getColorLabel() {
    return this[IJobOutFit.colorLabel] || "";
  }

  getColorCode() {
    return this[IJobOutFit.colorCode] || "";
  }
}
