import { JavascriptHelpers } from "@core/helpers";
import { DEVICE_OS, EXTRA_APP_ID_ANDROID, EXTRA_APP_ID_IOS, ETAB_APP_ID_ANDROID, ETAB_APP_ID_IOS } from "@core/constants";
import { isAndroid, isIOS } from "./platforms.constant";

/**
 * Allows for Store manipulations
 */
export class StoresService {
  static openCandidateRelevantStore() {
    const operatingSystem = JavascriptHelpers.getMobileOperatingSystem();
    if (operatingSystem === DEVICE_OS.ANDROID) {
      StoresService.openAndroidStoreLink(EXTRA_APP_ID_ANDROID);
      return true;
    }
    if (operatingSystem === DEVICE_OS.IOS) {
      StoresService.openAppStoreLink(EXTRA_APP_ID_IOS);
      return true;
    }
    return false;
  }

  static openEtabRelevantStore() {
    const operatingSystem = JavascriptHelpers.getMobileOperatingSystem();
    if (operatingSystem === DEVICE_OS.ANDROID) {
      StoresService.openAndroidStoreLink(ETAB_APP_ID_ANDROID);
      return true;
    }
    if (operatingSystem === DEVICE_OS.IOS) {
      StoresService.openAppStoreLink(ETAB_APP_ID_IOS);
      return true;
    }
    return false;
  }

  static getAndroidStoreLink(AppId) {
    return `https://play.google.com/store/apps/details?id=${AppId}`;
  }

  static getAppleStoreLink(AppId) {
    return `https://apps.apple.com/fr/app/extracadabra-etablissement/id${AppId}`;
  }

  /**
   * Opens Google Play Store on provided AppId
   */
  static openAndroidStore(AppId) {
    if (!AppId) {
      return;
    }

    if (isAndroid) {
      window.open(`market://details?id=${AppId}`, "_system");
    } else {
      StoresService.openAndroidStoreLink(AppId);
    }
  }

  /**
   * Opens Google Play Store link on provided AppId
   */
  static openAndroidStoreLink(AppId) {
    if (!AppId) {
      return;
    }

    const url = StoresService.getAndroidStoreLink(AppId);
    window.open(url, "_blank");
  }

  /**
   * Opens Apple Store on provided AppId
   */
  static openAppStore(AppId) {
    if (!AppId) {
      return;
    }

    if (isIOS) {
      window.open(`itms-apps://itunes.apple.com/fr/app/apple-store/id${AppId}?mt=8`);
    } else {
      StoresService.openAppStoreLink(AppId);
    }
  }

  /**
   * Opens Apple Store link on provided AppId
   */
  static openAppStoreLink(AppId) {
    if (!AppId) {
      return;
    }

    const url = StoresService.getAppleStoreLink(AppId);
    window.open(url, "_blank");
  }
}
