import { DatesHelpers, SanitationHelper } from "@core/helpers";
import { JOBS_CONTRACTS, JOBS_CONTRACTS_TYPE } from "@core/constants/jobs.constants";
import { i18n } from "@core/plugins/i18n";
import { JobSalaryModel } from "./jobs-salary.model";
import { JobEtabModel } from "./jobs-etab.model";
import { JobsPlanningModel } from "./jobs-typical-schedule.model";
import { JobsOffersStatusModel } from "./jobs-offer-status.model";

export const IJobsOffer = Object.freeze({
  id: "id",
  picture: "picture",
  city: "city",
  postalCode: "postal_code",
  contractType: "contract_type",
  duration: "duration",
  publicationDate: "publication_date",
  startDate: "start_date",
  qualification: "qualification",
  salary: "salary",
  etab: "etab",
  schedule: "schedule",
  typicalSchedule: "typical_schedule",
  precision: "precision",
  applicationStatus: "apply",
  url: "url",
  nbDays: "nb_days",
});

export class JobOfferModel {
  constructor(data = {}) {
    this[IJobsOffer.id] = data[IJobsOffer.id];
    this[IJobsOffer.picture] = data[IJobsOffer.picture];
    this[IJobsOffer.city] = data[IJobsOffer.city];
    this[IJobsOffer.postalCode] = data[IJobsOffer.postalCode];
    this[IJobsOffer.contractType] = data[IJobsOffer.contractType];
    this[IJobsOffer.duration] = data[IJobsOffer.duration];
    this[IJobsOffer.publicationDate] = data[IJobsOffer.publicationDate];
    this[IJobsOffer.startDate] = data[IJobsOffer.startDate];
    this[IJobsOffer.qualification] = data[IJobsOffer.qualification];
    this[IJobsOffer.salary] = data[IJobsOffer.salary];
    this[IJobsOffer.etab] = data[IJobsOffer.etab];
    this[IJobsOffer.typicalSchedule] = data[IJobsOffer.typicalSchedule];
    this[IJobsOffer.precision] = data[IJobsOffer.precision];
    this[IJobsOffer.applicationStatus] = data[IJobsOffer.applicationStatus];
    this[IJobsOffer.url] = data[IJobsOffer.url];
    this[IJobsOffer.nbDays] = data[IJobsOffer.nbDays];
  }

  getId() {
    return this[IJobsOffer.id];
  }

  getPicture() {
    return this[IJobsOffer.picture];
  }

  getCity() {
    return (this[IJobsOffer.city] || "").toLowerCase();
  }

  getPostalCode() {
    return this[IJobsOffer.postalCode];
  }

  getFormattedAddress() {
    return `${this.getPostalCode()} ${this.getCity()}`;
  }

  getContractType() {
    return this[IJobsOffer.contractType];
  }

  getContractTypeObject() {
    let contractTypeCode = this.getContractType();
    // ugly but necessary, as backend uses either "auto_e" or "extra" for AE missions
    if (contractTypeCode === JOBS_CONTRACTS_TYPE.AE) {
      contractTypeCode = JOBS_CONTRACTS_TYPE.EXTRA;
    }
    return JOBS_CONTRACTS.find((c) => contractTypeCode === c.code) || {};
  }

  getContractTypeDisplay() {
    return i18n.global.t(this.getContractTypeObject().label);
  }

  getContractTypeColor() {
    return this.getContractTypeObject().color;
  }

  getDuration() {
    return this[IJobsOffer.duration];
  }

  getPublicationDate() {
    return this[IJobsOffer.publicationDate];
  }

  getPrecision() {
    return (
      SanitationHelper.sanitize(this[IJobsOffer.precision] || "")
        // add newlines on html linebreak
        .replace(/(?:\r\n|\r|\n|↵|&#8629;)/g, "<br />")
    );
  }

  getPublicationDateFromNow() {
    const date = this.getPublicationDate();
    if (date) {
      return DatesHelpers.getDayjs(date).fromNow();
    }
    return undefined;
  }

  getStartDate() {
    return this[IJobsOffer.startDate];
  }

  getStartDateDisplay() {
    const date = this.getStartDate();
    if (date) {
      return DatesHelpers.getDayjs(date).format("DD/MM/YYYY");
    }
    return undefined;
  }

  getQualification() {
    return this[IJobsOffer.qualification];
  }

  isAE() {
    return this.getContractType() === JOBS_CONTRACTS_TYPE.AE;
  }

  isCDI() {
    return this.getContractType() === JOBS_CONTRACTS_TYPE.CDI;
  }

  isSeason() {
    return this.getContractType() === JOBS_CONTRACTS_TYPE.SEASON;
  }

  isInterim() {
    return this.getContractType() === JOBS_CONTRACTS_TYPE.INTERIM;
  }

  isPermanent() {
    return this.isCDI() || this.isSeason();
  }

  isTemporary() {
    return this.isAE() || this.isInterim();
  }

  getSalary() {
    return new JobSalaryModel(this[IJobsOffer.salary]);
  }

  getEtab() {
    return new JobEtabModel(this[IJobsOffer.etab]);
  }

  getSchedule() {
    return this[IJobsOffer.schedule];
  }

  getNbDays() {
    return this[IJobsOffer.nbDays];
  }

  getTypicalSchedule() {
    return new JobsPlanningModel(this[IJobsOffer.typicalSchedule]);
  }

  getUrl() {
    return this[IJobsOffer.url];
  }

  getApplicationStatus() {
    return new JobsOffersStatusModel(this[IJobsOffer.applicationStatus]);
  }

  isCdiAsapOffer() {
    const offerDate = DatesHelpers.getDayjs(this.getStartDate());
    return this.isCDI() && DatesHelpers.isSameOrBefore(offerDate);
  }
}
