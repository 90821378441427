export const ICandidateOnboardingStep = Object.freeze({
  stepsAmount: "total_flow_steps",
  stepNumber: "actual_step_position",
  stepSlug: "slug",
  stepId: "step_id",
  stepTitle: "step_title",
});

export class CandidateOnboardingStepModel {
  constructor(data = {}) {
    this[ICandidateOnboardingStep.stepsAmount] = data[ICandidateOnboardingStep.stepsAmount];
    this[ICandidateOnboardingStep.stepNumber] = data[ICandidateOnboardingStep.stepNumber];
    this[ICandidateOnboardingStep.stepSlug] = data[ICandidateOnboardingStep.stepSlug];
    this[ICandidateOnboardingStep.stepId] = data[ICandidateOnboardingStep.stepId];
    this[ICandidateOnboardingStep.stepTitle] = data[ICandidateOnboardingStep.stepTitle];
  }

  getStepsAmount() {
    return this[ICandidateOnboardingStep.stepsAmount];
  }

  getStepNumber() {
    return this[ICandidateOnboardingStep.stepNumber];
  }

  getStepSlug() {
    return this[ICandidateOnboardingStep.stepSlug];
  }

  getStepId() {
    return this[ICandidateOnboardingStep.stepId];
  }

  getStepTitle() {
    return this[ICandidateOnboardingStep.stepTitle];
  }
}
