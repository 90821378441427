import { CANDIDATE_AE_FOLDER_STATUS, AE_FOLDER_IDENTITY_LIST } from "@core/constants";
import { AEBannerModel } from "@core/models/common/ae-banner-status.model";
import { CandidateAeFolderFileModel } from "@core/models/candidates/profile/candidate-ae-folder-file.model";

export const ICandidateAEFolder = Object.freeze({
  status: "status",
  bannerData: "message",
  siret: "siret",
  tva: "tva",
  iban: "iban",
  swift: "swift",
  identity: "doc_identite",
  identityVerso: "doc_identite_verso",
  rib: "doc_rib",
  insee: "doc_insee",
  isComplete: "is_complete",
  isEmpty: "is_empty",
  identityDocumentType: "doc_type",
});

export class CandidateAEFolderModel {
  constructor(data = {}) {
    this[ICandidateAEFolder.status] = data[ICandidateAEFolder.status];
    this[ICandidateAEFolder.siret] = data[ICandidateAEFolder.siret];
    this[ICandidateAEFolder.tva] = data[ICandidateAEFolder.tva];
    this[ICandidateAEFolder.iban] = data[ICandidateAEFolder.iban];
    this[ICandidateAEFolder.swift] = data[ICandidateAEFolder.swift];
    this[ICandidateAEFolder.identity] = data[ICandidateAEFolder.identity];
    this[ICandidateAEFolder.identityVerso] = data[ICandidateAEFolder.identityVerso];
    this[ICandidateAEFolder.rib] = data[ICandidateAEFolder.rib];
    this[ICandidateAEFolder.insee] = data[ICandidateAEFolder.insee];
    this[ICandidateAEFolder.isComplete] = data[ICandidateAEFolder.isComplete];
    this[ICandidateAEFolder.isEmpty] = data[ICandidateAEFolder.isEmpty];
    this[ICandidateAEFolder.bannerData] = data[ICandidateAEFolder.bannerData];
    this[ICandidateAEFolder.identityDocumentType] = data[ICandidateAEFolder.identityDocumentType];
  }

  getIdentity() {
    return new CandidateAeFolderFileModel(this[ICandidateAEFolder.identity]);
  }

  getIdentityVerso() {
    return new CandidateAeFolderFileModel(this[ICandidateAEFolder.identityVerso]);
  }

  getRib() {
    return new CandidateAeFolderFileModel(this[ICandidateAEFolder.rib]);
  }

  getInsee() {
    return new CandidateAeFolderFileModel(this[ICandidateAEFolder.insee]);
  }

  getIban() {
    return this[ICandidateAEFolder.iban];
  }

  getTva() {
    return this[ICandidateAEFolder.tva];
  }

  getSiret() {
    return this[ICandidateAEFolder.siret];
  }

  getSwift() {
    return this[ICandidateAEFolder.swift];
  }

  getIdentityDocumentType() {
    return this.getIdentity().getDocumentType() || this.getIdentityVerso().getDocumentType();
  }

  isAdministrativeInfosFormComplete() {
    return this.getSiret() && this.getIban();
  }

  isPassportSelected() {
    return (
      this.getIdentityDocumentType() === AE_FOLDER_IDENTITY_LIST.PASSEPORT_FR ||
      this.getIdentityDocumentType() === AE_FOLDER_IDENTITY_LIST.PASSEPORT_EU
    );
  }

  docsUploadedAmount() {
    let docsCount = 0;
    if (this.getIdentity().getFileName()) {
      docsCount += 1;
    }
    if (this.getIdentityVerso().getFileName()) {
      docsCount += 1;
    }
    if (this.getInsee().getFileName()) {
      docsCount += 1;
    }
    if (this.getRib().getFileName()) {
      docsCount += 1;
    }
    return docsCount;
  }

  isFolderComplete() {
    return this[ICandidateAEFolder.isComplete];
  }

  isFolderEmpty() {
    return this[ICandidateAEFolder.isEmpty];
  }

  getStatus() {
    return this[ICandidateAEFolder.status];
  }

  isPending() {
    return this[ICandidateAEFolder.status] === CANDIDATE_AE_FOLDER_STATUS.STATUS_PENDING;
  }

  isPendingUntilVerify() {
    return this[ICandidateAEFolder.status] === CANDIDATE_AE_FOLDER_STATUS.STATUS_PENDING_INTERNAL_VERIFICATION;
  }

  isVerified() {
    return this[ICandidateAEFolder.status] === CANDIDATE_AE_FOLDER_STATUS.STATUS_VERIFIED;
  }

  getBannerData() {
    return new AEBannerModel(this[ICandidateAEFolder.bannerData]);
  }
}
