import { DatesHelpers, ScheduleHelpers } from "@core/helpers";
import { DEFAULT_BREAK_DURATION } from "@core/constants";
import { i18n } from "@core/plugins/i18n";

export const IPlanningDay = Object.freeze({
  id: "id",
  selected: "selected",
  date: "date",
  startHour: "startHour",
  startHourSnakeCase: "start_hour",
  endHour: "endHour",
  endHourSnakeCase: "end_hour",
  breakDuration: "breakDuration",
  breakDurationSnakeCase: "break_duration",
});

export class PlanningDayModel {
  constructor(data = {}) {
    this[IPlanningDay.id] = data[IPlanningDay.id];
    this[IPlanningDay.selected] = data[IPlanningDay.selected];
    this[IPlanningDay.date] = data[IPlanningDay.date];
    this[IPlanningDay.startHour] = data[IPlanningDay.startHour] ?? data[IPlanningDay.startHourSnakeCase];
    this[IPlanningDay.endHour] = data[IPlanningDay.endHour] ?? data[IPlanningDay.endHourSnakeCase];
    this[IPlanningDay.breakDuration] = data[IPlanningDay.breakDuration] ?? data[IPlanningDay.breakDurationSnakeCase] ?? DEFAULT_BREAK_DURATION;
  }

  getId() {
    return this[IPlanningDay.id];
  }

  getDate() {
    return this[IPlanningDay.date];
  }

  getStartHour() {
    return this[IPlanningDay.startHour];
  }

  getEndHour() {
    return this[IPlanningDay.endHour];
  }

  getScheduleDisplay() {
    const start = this.getFormattedTimeDisplay(this.getStartHour());
    const end = this.getFormattedTimeDisplay(this.getEndHour());
    return `${start} -> ${end}`;
  }

  getFormattedTimeDisplay(durationInMinutes) {
    const formattedTime = DatesHelpers.formatMinsToHrsMins(durationInMinutes % ScheduleHelpers.DAY_DURATION_MINUTES);
    const nextDay = durationInMinutes >= ScheduleHelpers.DAY_DURATION_MINUTES;
    const formattedNextDay = nextDay ? ` ${i18n.global.t("CORE.MODELS.SCHEDULE.@NEXT_DAY")}` : "";
    return `${formattedTime}${formattedNextDay}`;
  }

  getBreakDisplay() {
    if (this.getBreakDuration() > 60) {
      return DatesHelpers.formatMinsToHrsMins(this.getBreakDuration());
    }
    return `${this.getBreakDuration()}min`;
  }

  select() {
    this[IPlanningDay.selected] = true;
  }

  unselect() {
    this[IPlanningDay.selected] = false;
    this[IPlanningDay.startHour] = undefined;
    this[IPlanningDay.endHour] = undefined;
    this[IPlanningDay.breakDuration] = DEFAULT_BREAK_DURATION;
  }

  isSelected() {
    return this[IPlanningDay.selected] === true;
  }

  endBeforeStart() {
    return (
      this[IPlanningDay.startHour] > 0 &&
      this[IPlanningDay.endHour] > 0 &&
      this[IPlanningDay.endHour] < this[IPlanningDay.startHour] + ScheduleHelpers.MINIMAL_SHIFT_DURATION * 60
    );
  }

  breakTooLong() {
    return (
      this[IPlanningDay.startHour] > 0 &&
      this[IPlanningDay.endHour] > 0 &&
      this[IPlanningDay.breakDuration] > (this[IPlanningDay.endHour] - this[IPlanningDay.startHour]) / 2
    );
  }

  getFullStartDate() {
    return DatesHelpers.getFullDateHourMinutes(this[IPlanningDay.date], this[IPlanningDay.startHour]);
  }

  getFullEndDate() {
    return DatesHelpers.getFullDateHourMinutes(this[IPlanningDay.date], this[IPlanningDay.endHour]);
  }

  getTotalDuration() {
    const duration = this[IPlanningDay.endHour] - this[IPlanningDay.startHour];
    return Number.isNaN(duration) ? 0 : duration;
  }

  getBreakDuration() {
    return this[IPlanningDay.breakDuration];
  }

  isToday() {
    return DatesHelpers.dateIsToday(this[IPlanningDay.date]);
  }

  startHourIsValid() {
    return !this.isToday() || this[IPlanningDay.startHour] > DatesHelpers.getDayjs().hour() * 60;
  }

  isValid() {
    return (
      !this.isSelected() ||
      (!this.endBeforeStart() &&
        this.startHourIsValid() &&
        !this.breakTooLong() &&
        this[IPlanningDay.startHour] !== undefined &&
        this[IPlanningDay.endHour] !== undefined &&
        this[IPlanningDay.breakDuration] !== undefined)
    );
  }
}
