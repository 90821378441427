import { JobsService } from "@core/services/jobs";
import { JobsDynamicPageModel, JobsSearchListModel } from "@core/models";
import {
  ACTION_JOBS_GET_POPULAR_SEARCHES,
  MUTATION_JOBS_GET_POPULAR_SEARCHES,
  GETTER_JOBS_GET_POPULAR_SEARCHES,
  ACTION_JOBS_GET_LOCALIZATION_SEARCHES,
  MUTATION_JOBS_GET_LOCALIZATION_SEARCHES,
  GETTER_JOBS_GET_LOCALIZATION_SEARCHES,
  ACTION_JOBS_GET_DYNAMICS_PAGES,
  MUTATION_JOBS_GET_DYNAMICS_PAGES,
  GETTER_JOBS_GET_DYNAMICS_PAGES,
  ACTION_JOBS_GET_JOB_OFFERS,
  MUTATION_JOBS_GET_JOB_OFFERS,
  GETTER_JOBS_GET_JOB_OFFERS,
} from "@jobs/store/types";

function initialState() {
  return {
    jobsSearchList: new JobsSearchListModel(),
    popularSearches: [],
    searchByLocalization: [],
    dynamicsPages: [],
  };
}

export const state = {
  initialState,
  ...initialState(),
};

export const actions = {
  async [ACTION_JOBS_GET_POPULAR_SEARCHES]({ commit }) {
    const popularSearches = await JobsService.getJobsPopularPages();
    commit(MUTATION_JOBS_GET_POPULAR_SEARCHES, popularSearches);
  },
  async [ACTION_JOBS_GET_LOCALIZATION_SEARCHES]({ commit }) {
    const searchByLocalization = await JobsService.getJobsLocalisation();
    commit(MUTATION_JOBS_GET_LOCALIZATION_SEARCHES, searchByLocalization);
  },
  async [ACTION_JOBS_GET_DYNAMICS_PAGES]({ commit }) {
    const dynamicsPages = await JobsService.getJobsDynamicPages();
    commit(MUTATION_JOBS_GET_DYNAMICS_PAGES, dynamicsPages);
  },
  async [ACTION_JOBS_GET_JOB_OFFERS]({ commit }, rowsPerPage = 6) {
    const jobsOffers = await JobsService.searchJobs({ rowsPerPage });
    commit(MUTATION_JOBS_GET_JOB_OFFERS, jobsOffers);
  },
};

export const mutations = {
  [MUTATION_JOBS_GET_POPULAR_SEARCHES](_state, popularJobsSearches) {
    _state.popularSearches = popularJobsSearches ?? [];
  },
  [MUTATION_JOBS_GET_LOCALIZATION_SEARCHES](_state, searchByLocalization) {
    _state.searchByLocalization = searchByLocalization ?? [];
  },
  [MUTATION_JOBS_GET_DYNAMICS_PAGES](_state, dynamicsPages) {
    _state.dynamicsPages = dynamicsPages ?? [];
  },
  [MUTATION_JOBS_GET_JOB_OFFERS](_state, jobsOffers) {
    _state.jobsSearchList = jobsOffers ?? {};
  },
};

export const getters = {
  [GETTER_JOBS_GET_POPULAR_SEARCHES]: (_state) => _state.popularSearches.map((search) => new JobsDynamicPageModel(search)),
  [GETTER_JOBS_GET_LOCALIZATION_SEARCHES]: (_state) => _state.searchByLocalization.map((region) => new JobsDynamicPageModel(region)),
  [GETTER_JOBS_GET_DYNAMICS_PAGES]: (_state) => _state.dynamicsPages.map((page) => new JobsDynamicPageModel(page)),
  [GETTER_JOBS_GET_JOB_OFFERS]: (_state) => new JobsSearchListModel(_state.jobsSearchList),
};

export default function createModule() {
  return {
    namespaced: false,
    state: () => ({ ...initialState() }),
    actions,
    mutations,
    getters,
  };
}
