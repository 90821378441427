import { BasicOptionModel } from "@core/models/common/basic-option.model";

export const ICourse = Object.freeze({
  id: "id",
  school: "school",
  duration: "duration",
  title: "title",
  year: "year",
});

export class CandidateCourseModel {
  constructor(data = {}) {
    this[ICourse.id] = data[ICourse.id];
    this[ICourse.school] = data[ICourse.school];
    this[ICourse.duration] = data[ICourse.duration];
    this[ICourse.title] = data[ICourse.title];
    this[ICourse.year] = data[ICourse.year];
  }

  getId() {
    return this[ICourse.id];
  }

  getSchool() {
    return this[ICourse.school];
  }

  getDuration() {
    return new BasicOptionModel(this[ICourse.duration]);
  }

  getTitle() {
    return this[ICourse.title];
  }

  getYear() {
    return this[ICourse.year];
  }
}
