import { DatesHelpers, SanitationHelper } from "@core/helpers";
import { CandidateReportsModel } from "../../common";

export const ICandidateProfile = Object.freeze({
  about: "about",
  birthdate: "birthdate",
  candidateReports: "reports",
  city: "city",
  experiences: "experiences",
  firstname: "firstname",
  gender: "gender",
  email: "email",
  lastname: "lastname",
  isBlocked: "is_blocked",
  origin: "origin",
  originDetail: "origin_detail",
  avatar: "picture",
  postalCode: "zipcode",
  nationality: "nationality",
  qualities: "qualities",
  street: "address",
  hasVehicle: "has_vehicle",
  jobsAmount: "jobs_amount",
  jobNumber: "job_number",
  workedHours: "worked_hours_amount",
  averageNote: "average_note",
  phone: "phone",
  extraId: "extra_id",
  interim: "interim",
});

export const ICandidateInterim = Object.freeze({
  isInterim: "is_interim",
  url: "url",
});

export class CandidateInterimModel {
  constructor(data = {}) {
    this[ICandidateInterim.isInterim] = data[ICandidateInterim.isInterim];
    this[ICandidateInterim.url] = data[ICandidateInterim.url];
  }

  getUrl() {
    return this[ICandidateInterim.url];
  }
}

export class CandidateProfileModel {
  constructor(data = {}) {
    this[ICandidateProfile.about] = data[ICandidateProfile.about];
    this[ICandidateProfile.birthdate] = data[ICandidateProfile.birthdate];
    this[ICandidateProfile.city] = data[ICandidateProfile.city];
    this[ICandidateProfile.candidateReports] = data[ICandidateProfile.candidateReports];
    this[ICandidateProfile.experiences] = data[ICandidateProfile.experiences];
    this[ICandidateProfile.firstname] = data[ICandidateProfile.firstname];
    this[ICandidateProfile.gender] = data[ICandidateProfile.gender];
    this[ICandidateProfile.isBlocked] = data[ICandidateProfile.isBlocked];
    this[ICandidateProfile.email] = data[ICandidateProfile.email];
    this[ICandidateProfile.lastname] = data[ICandidateProfile.lastname];
    this[ICandidateProfile.origin] = data[ICandidateProfile.origin];
    this[ICandidateProfile.originDetail] = data[ICandidateProfile.originDetail];
    this[ICandidateProfile.phone] = data[ICandidateProfile.phone];
    this[ICandidateProfile.avatar] = data[ICandidateProfile.avatar];
    this[ICandidateProfile.postalCode] = data[ICandidateProfile.postalCode];
    this[ICandidateProfile.nationality] = data[ICandidateProfile.nationality];
    this[ICandidateProfile.qualities] = data[ICandidateProfile.qualities];
    this[ICandidateProfile.street] = data[ICandidateProfile.street];
    this[ICandidateProfile.hasVehicle] = data[ICandidateProfile.hasVehicle];
    this[ICandidateProfile.jobsAmount] = data[ICandidateProfile.jobsAmount];
    this[ICandidateProfile.jobNumber] = data[ICandidateProfile.jobNumber];
    this[ICandidateProfile.workedHours] = data[ICandidateProfile.workedHours];
    this[ICandidateProfile.averageNote] = data[ICandidateProfile.averageNote];
    this[ICandidateProfile.extraId] = data[ICandidateProfile.extraId];
    this[ICandidateProfile.interim] = data[ICandidateProfile.interim];
  }

  getExtraId() {
    return this[ICandidateProfile.extraId];
  }

  getFirstName() {
    return this[ICandidateProfile.firstname];
  }

  getLastName() {
    return this[ICandidateProfile.lastname];
  }

  getFullName() {
    return `${this.getFirstName()} ${this.getLastName()}`;
  }

  getEmail() {
    return this[ICandidateProfile.email];
  }

  getPicture() {
    return this[ICandidateProfile.avatar];
  }

  hasNoPicture() {
    return !this.getPicture();
  }

  getAbout() {
    return this[ICandidateProfile.about];
  }

  getBirthDateDisplay() {
    return DatesHelpers.getFormattedDate(this[ICandidateProfile.birthdate]);
  }

  getExperiences() {
    return this[ICandidateProfile.experiences] || [];
  }

  getSelectedQualities() {
    return this[ICandidateProfile.qualities] || [];
  }

  getOrigin() {
    return this[ICandidateProfile.origin];
  }

  getOriginDetail() {
    return this[ICandidateProfile.originDetail];
  }

  getCity() {
    return SanitationHelper.sanitizeCityName(this[ICandidateProfile.city]);
  }

  getStreet() {
    return this[ICandidateProfile.street];
  }

  getPostalCode() {
    return this[ICandidateProfile.postalCode];
  }

  getNationality() {
    return this[ICandidateProfile.nationality] || null;
  }

  getPhoneNumber() {
    return this[ICandidateProfile.phone];
  }

  hasVehicle() {
    return this[ICandidateProfile.hasVehicle];
  }

  getJobsAmount() {
    return this[ICandidateProfile.jobsAmount] || this[ICandidateProfile.jobNumber];
  }

  getWorkedHours() {
    return this[ICandidateProfile.workedHours];
  }

  getAverageNote() {
    return this[ICandidateProfile.averageNote];
  }

  candidateReports() {
    return new CandidateReportsModel(this[ICandidateProfile.candidateReports]);
  }

  isBlocked() {
    return this[ICandidateProfile.isBlocked];
  }

  isProfileLoaded() {
    return !!this.getEmail();
  }

  getInterim() {
    return new CandidateInterimModel(this[ICandidateProfile.interim]);
  }
}
