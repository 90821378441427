export const ICandidateOfferEtabContact = Object.freeze({
  firstname: "firstname",
  lastname: "lastname",
  phoneNumber: "phone_number",
  email: "email",
});

export class CandidateOfferEtabContactModel {
  constructor(data = {}) {
    this[ICandidateOfferEtabContact.firstname] = data[ICandidateOfferEtabContact.firstname];
    this[ICandidateOfferEtabContact.lastname] = data[ICandidateOfferEtabContact.lastname];
    this[ICandidateOfferEtabContact.phoneNumber] = data[ICandidateOfferEtabContact.phoneNumber];
    this[ICandidateOfferEtabContact.email] = data[ICandidateOfferEtabContact.email];
  }

  getFirstname() {
    return this[ICandidateOfferEtabContact.firstname];
  }

  getLastname() {
    return this[ICandidateOfferEtabContact.lastname];
  }

  getFormattedName() {
    return `${this.getFirstname()} ${this.getLastname()}`;
  }

  getPhoneNumber() {
    return this[ICandidateOfferEtabContact.phoneNumber];
  }

  getEmail() {
    return this[ICandidateOfferEtabContact.email];
  }
}
