export const IEtabCategory = Object.freeze({
  code: "code",
  familyCategory: "categorie_famille",
  family: "family",
  favorite: "favori",
  id: "id",
  label: "nom",
  segment: "segment",
  order: "ordre",
  name: "name",
});

export const IEtabCategoryFamily = Object.freeze({
  id: "id",
  name: "name",
  nom: "nom",
});

export class EtabCategoryModel {
  constructor(data = {}) {
    this[IEtabCategory.code] = data[IEtabCategory.code];
    this[IEtabCategory.familyCategory] = data[IEtabCategory.familyCategory];
    this[IEtabCategory.family] = data[IEtabCategory.family];
    this[IEtabCategory.favorite] = data[IEtabCategory.favorite];
    this[IEtabCategory.id] = data[IEtabCategory.id];
    this[IEtabCategory.label] = data[IEtabCategory.label] || data[IEtabCategory.name];
    this[IEtabCategory.name] = data[IEtabCategory.name];
    this[IEtabCategory.order] = data[IEtabCategory.order];
    this[IEtabCategory.segment] = data[IEtabCategory.segment];
  }

  getId() {
    return this[IEtabCategory.id];
  }

  getLabel() {
    return this[IEtabCategory.label];
  }

  getName() {
    return this[IEtabCategory.label];
  }

  isFavorite() {
    return this[IEtabCategory.favorite] === 1;
  }

  getSegment() {
    return this[IEtabCategory.segment];
  }

  getFamily() {
    return new EtabCategoryFamilyModel(this[IEtabCategory.family]);
  }

  getFamilyCategory() {
    return this[IEtabCategory.familyCategory];
  }

  getFamilyLabel() {
    return this.getFamily().getName();
  }
}

export class EtabCategoryFamilyModel {
  constructor(data = {}) {
    this[IEtabCategoryFamily.id] = data[IEtabCategoryFamily.id];
    this[IEtabCategoryFamily.name] = data[IEtabCategoryFamily.name];
    this[IEtabCategoryFamily.nom] = data[IEtabCategoryFamily.nom];
  }

  getId() {
    return this[IEtabCategoryFamily.id];
  }

  getName() {
    return this[IEtabCategoryFamily.name] || this[IEtabCategoryFamily.nom];
  }
}
