import { QUALIFICATION_FAMILY, QUALIFICATION_FAMILY_LABEL } from "@core/constants";
import { CreationStepQualificationModel } from "./creation-step-qualification.model";

export const ICreationStepQualificationFamily = Object.freeze({
  code: "code",
  id: "id",
  name: "name",
  qualifications: "qualifications",
  familyId: "family_id",
});

export class CreationStepQualificationFamilyModel {
  constructor(data = {}) {
    this[ICreationStepQualificationFamily.code] = data[ICreationStepQualificationFamily.code];
    this[ICreationStepQualificationFamily.id] = data[ICreationStepQualificationFamily.id];
    this[ICreationStepQualificationFamily.name] = data[ICreationStepQualificationFamily.name];
    this[ICreationStepQualificationFamily.qualifications] = data[ICreationStepQualificationFamily.qualifications] ?? [];
    this[ICreationStepQualificationFamily.familyId] = data[ICreationStepQualificationFamily.familyId];
  }

  getId() {
    return this[ICreationStepQualificationFamily.id];
  }

  getName() {
    return this[ICreationStepQualificationFamily.name] || "";
  }

  getCode() {
    return this[ICreationStepQualificationFamily.code];
  }

  getQualifications() {
    return this[ICreationStepQualificationFamily.qualifications].map((qualification) => new CreationStepQualificationModel(qualification));
  }

  getIcon() {
    switch (this.getCode()) {
      case QUALIFICATION_FAMILY.HOSTEL:
        return "icon-extra-hostel";
      case QUALIFICATION_FAMILY.BAR:
        return "icon-extra-room";
      case QUALIFICATION_FAMILY.KITCHEN:
        return "icon-extra-kitchen";
      case QUALIFICATION_FAMILY.RETAIL:
        return "icon-extra-takeaway";
      case QUALIFICATION_FAMILY.HEALTH:
        return "icon-extra-health";
      case QUALIFICATION_FAMILY.AUTRE:
        return "icon-extra-others";
      default:
        return "";
    }
  }

  getIconByLabel() {
    switch (this.getLabel()) {
      case QUALIFICATION_FAMILY_LABEL.HOSTEL:
        return "icon-extra-hostel";
      case QUALIFICATION_FAMILY_LABEL.BAR:
        return "icon-extra-room";
      case QUALIFICATION_FAMILY_LABEL.KITCHEN:
        return "icon-extra-kitchen";
      case QUALIFICATION_FAMILY_LABEL.RETAIL:
        return "icon-extra-takeaway";
      case QUALIFICATION_FAMILY_LABEL.HEALTH:
        return "icon-extra-health";
      case QUALIFICATION_FAMILY_LABEL.OTHER:
        return "icon-extra-others";
      default:
        return "";
    }
  }

  getSelectOptions() {
    return {
      code: this.getCode(),
      value: this.getId(),
      label: this.getName(),
      icon: this.getIcon(),
    };
  }
}
