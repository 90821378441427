import { SanitationHelper } from "@core/helpers";
import { JobsSearchCriteriasModel } from "./jobs-search-criterias.model";

export const IJobsDynamicPage = Object.freeze({
  id: "slug",
  label: "label",
  img: "img_url",
  imgTitle: "img_alt",
  title: "title",
  description: "description",
  criterias: "criterias",
  type: "type",
  subText: "sub_text",
  offers: "total_offer",
  typeformLink: "typeform_link",
});

export class JobsDynamicPageModel {
  constructor(data = {}) {
    this[IJobsDynamicPage.id] = data[IJobsDynamicPage.id];
    this[IJobsDynamicPage.label] = data[IJobsDynamicPage.label];
    this[IJobsDynamicPage.img] = data[IJobsDynamicPage.img];
    this[IJobsDynamicPage.imgTitle] = data[IJobsDynamicPage.imgTitle];
    this[IJobsDynamicPage.title] = data[IJobsDynamicPage.title];
    this[IJobsDynamicPage.description] = data[IJobsDynamicPage.description];
    this[IJobsDynamicPage.criterias] = data[IJobsDynamicPage.criterias];
    this[IJobsDynamicPage.type] = data[IJobsDynamicPage.type];
    this[IJobsDynamicPage.subText] = data[IJobsDynamicPage.subText];
    this[IJobsDynamicPage.offers] = data[IJobsDynamicPage.offers];
    this[IJobsDynamicPage.typeformLink] = data[IJobsDynamicPage.typeformLink];
    this[IJobsDynamicPage.label] = data[IJobsDynamicPage.label];
  }

  getId() {
    return this[IJobsDynamicPage.id];
  }

  getLabel() {
    return this[IJobsDynamicPage.label];
  }

  getType() {
    return this[IJobsDynamicPage.type];
  }

  getTitle() {
    return SanitationHelper.sanitize(this[IJobsDynamicPage.title] ?? "");
  }

  getDescription() {
    return SanitationHelper.sanitize(this[IJobsDynamicPage.description] ?? "");
  }

  getImg() {
    return this[IJobsDynamicPage.img];
  }

  getImgTitle() {
    return this[IJobsDynamicPage.imgTitle];
  }

  getCriterias() {
    return new JobsSearchCriteriasModel(this[IJobsDynamicPage.criterias]);
  }

  isRegionThematics() {
    return this.getType() === "region";
  }

  getSubText() {
    return this[IJobsDynamicPage.subText];
  }

  getTotalOffers() {
    return this[IJobsDynamicPage.offers];
  }

  getTypeformLink() {
    return this[IJobsDynamicPage.typeformLink];
  }
}
