export const ICandidateAccount = Object.freeze({
  email: "email",
  password: "password",
  confirmPassword: "confirmPassword",
  optoutNewsletter: "optout_mail",
  phoneNumber: "phone",
});

export class CandidateAccountModel {
  constructor(data = {}) {
    this[ICandidateAccount.email] = data[ICandidateAccount.email];
    this[ICandidateAccount.password] = data[ICandidateAccount.password];
    this[ICandidateAccount.confirmPassword] = data[ICandidateAccount.confirmPassword];
  }

  getEmail() {
    return this[ICandidateAccount.email];
  }

  getPassword() {
    return this[ICandidateAccount.password];
  }
}
