export const IBoAssociatedEtab = Object.freeze({
  id: "etab_id",
  companyName: "social_reason",
  subCompanyName: "sub_social_reason",
  customerStripeId: "stp_customer_id",
});

export class BoAssociatedEtabModel {
  constructor(data = {}) {
    this[IBoAssociatedEtab.id] = data[IBoAssociatedEtab.id];
    this[IBoAssociatedEtab.companyName] = data[IBoAssociatedEtab.companyName];
    this[IBoAssociatedEtab.subCompanyName] = data[IBoAssociatedEtab.subCompanyName] || "";
    this[IBoAssociatedEtab.customerStripeId] = data[IBoAssociatedEtab.customerStripeId] || "";
  }

  getId() {
    return this[IBoAssociatedEtab.id];
  }

  getCompanyName() {
    return this[IBoAssociatedEtab.companyName];
  }

  getSubCompanyName() {
    return this[IBoAssociatedEtab.subCompanyName];
  }

  getCustomerStripeId() {
    return this[IBoAssociatedEtab.customerStripeId];
  }
}
