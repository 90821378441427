export const INoShowReason = Object.freeze({
  id: "id",
  label: "label",
});

export class NoShowReasonModel {
  constructor(data = {}) {
    this[INoShowReason.id] = data[INoShowReason.id];
    this[INoShowReason.label] = data[INoShowReason.label];
  }

  getId() {
    return this[INoShowReason.id];
  }

  getLabel() {
    return this[INoShowReason.label];
  }
}
