export const IQualification = Object.freeze({
  // LEGACY
  legacyPrice: "prixMini",
  legacyFamilyId: "familleId",
  legacyFavourite: "favori", // be advised: favoUrite=legacy, favorite=new
  legacyId: "id",
  legacyLabel: "nom",
  legacyAlternatePrice: "prix_mini",
  // NEW API
  familyId: "family_id",
  familyLabel: "family_name",
  favorite: "favorite",
  price: "minimum_price",
  qualificationId: "qualification_id",
  qualificationLabel: "qualification_name",
});

export class QualificationModel {
  constructor(data = {}) {
    this[IQualification.familyId] = data[IQualification.familyId] || data[IQualification.legacyFamilyId];
    this[IQualification.familyLabel] = data[IQualification.familyLabel];
    this[IQualification.favorite] = data[IQualification.favorite] || data[IQualification.legacyFavourite];
    this[IQualification.price] = data[IQualification.price] || data[IQualification.legacyPrice] || data[IQualification.legacyAlternatePrice];
    this[IQualification.qualificationId] = data[IQualification.qualificationId] || data[IQualification.legacyId];
    this[IQualification.qualificationLabel] = data[IQualification.qualificationLabel] || data[IQualification.legacyLabel];
  }

  getId() {
    return this[IQualification.qualificationId];
  }

  getLabel() {
    return this[IQualification.qualificationLabel] || "";
  }

  getFamilyLabel() {
    return this[IQualification.familyLabel];
  }

  getFamilyId() {
    return this[IQualification.familyId];
  }

  getPrice() {
    return this[IQualification.price];
  }

  isFavorite() {
    return this[IQualification.favorite] === 1;
  }
}
