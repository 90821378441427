export const CANDIDATE_EXPERIENCE_CONTRACTS = [
  {
    code: "extra",
    label: "CORE.MODELS.CONTRACTS.@EXTRA",
    color: "secondary",
  },
  {
    code: "cdd",
    label: "CORE.MODELS.CONTRACTS.@CDD",
    color: "secondary",
  },
  {
    code: "cdi",
    label: "CORE.MODELS.CONTRACTS.@CDI",
    color: "primary",
  },
  {
    code: "saisonnier",
    label: "CORE.MODELS.CONTRACTS.@SEASON",
    color: "primary",
  },
  {
    code: "stage",
    label: "CORE.MODELS.CONTRACTS.@INTERNSHIP",
    color: "secondary",
  },
];

export const CANDIDATE_EXPERIENCE_STATUSES = {
  UNVERIFIED: {
    label: "CORE.MODELS.EXPERIENCE_STATUS.@UNVALIDATED",
    code: "unverified",
    color: "grey",
  },
  PENDING_INTERNAL_VERIFICATION: {
    code: "pending_internal_verification",
    label: "CORE.MODELS.EXPERIENCE_STATUS.@PENDING_INTERNAL_VALIDATION",
    color: "primary",
  },
  PENDING: {
    code: "pending",
    label: "CORE.MODELS.EXPERIENCE_STATUS.@PENDING",
    color: "warning",
  },
  VERIFIED: {
    label: "CORE.MODELS.EXPERIENCE_STATUS.@VALIDATED",
    code: "verified",
    color: "positive",
  },
  REFUSED: {
    code: "refused",
    label: "CORE.MODELS.EXPERIENCE_STATUS.@REFUSED",
    color: "negative",
  },
};
