export const ICandidateTag = Object.freeze({
  novice: "novice",
  isCollectiveChefCertified: "is_collective_chef_certified",
  isPartyLeaderCeritified: "is_collective_party_leader_certified",
  isTEButlerCertified: "is_TE_butler_certified",
});

export class CandidateTagModel {
  constructor(data = {}) {
    this[ICandidateTag.novice] = data[ICandidateTag.novice];
    this[ICandidateTag.isCollectiveChefCertified] = data[ICandidateTag.isCollectiveChefCertified];
    this[ICandidateTag.isPartyLeaderCeritified] = data[ICandidateTag.isPartyLeaderCeritified];
    this[ICandidateTag.isTEButlerCertified] = data[ICandidateTag.isTEButlerCertified];
  }

  isNovice() {
    return this[ICandidateTag.novice] === true;
  }

  isCollectiveChefCertified() {
    return this[ICandidateTag.isCollectiveChefCertified] === true;
  }

  isCollectivePartyLeaderCeritified() {
    return this[ICandidateTag.isPartyLeaderCeritified] === true;
  }

  isTEButlerCertified() {
    return this[ICandidateTag.isTEButlerCertified] === true;
  }
}
