export const IBoEtabPaymentMean = Object.freeze({
  paymentType: "payment_type",
  delay: "delay",
  endOfMonth: "end_of_month",
  hasDefaultPaymentMean: "has_default_payment_mean",
  stripeUrl: "stripe_url",
});

export class BoEtabPaymentMeanModel {
  constructor(data = {}) {
    this[IBoEtabPaymentMean.paymentType] = data[IBoEtabPaymentMean.paymentType];
    this[IBoEtabPaymentMean.delay] = data[IBoEtabPaymentMean.delay];
    this[IBoEtabPaymentMean.endOfMonth] = data[IBoEtabPaymentMean.endOfMonth];
    this[IBoEtabPaymentMean.hasDefaultPaymentMean] = data[IBoEtabPaymentMean.hasDefaultPaymentMean];
    this[IBoEtabPaymentMean.stripeUrl] = data[IBoEtabPaymentMean.stripeUrl];
  }

  getPaymentType() {
    return this[IBoEtabPaymentMean.paymentType];
  }

  getDelay() {
    return this[IBoEtabPaymentMean.delay] || 0;
  }

  getEndOfMonth() {
    return this[IBoEtabPaymentMean.endOfMonth] || false;
  }

  hasDefaultPaymentMean() {
    return this[IBoEtabPaymentMean.hasDefaultPaymentMean] || false;
  }

  getStripeUrl() {
    return this[IBoEtabPaymentMean.stripeUrl];
  }
}
