export const ISupervisor = Object.freeze({
  etabId: "etabId",
  supervisorId: "supervisorId",
});

export class SupervisorModel {
  constructor(data = {}) {
    this[ISupervisor.etabId] = data[ISupervisor.etabId];
    this[ISupervisor.supervisorId] = data[ISupervisor.supervisorId];
  }

  getEtabId() {
    return this[ISupervisor.etabId];
  }

  getSupervisorId() {
    return this[ISupervisor.supervisorId];
  }

  isValid() {
    return this.getEtabId() && this.getSupervisorId();
  }
}
