export const ICandidateOfferReview = Object.freeze({
  rating: "rating",
  comment: "comment",
});

export class CandidateOfferReviewModel {
  constructor(data = {}) {
    this[ICandidateOfferReview.rating] = data[ICandidateOfferReview.rating];
    this[ICandidateOfferReview.comment] = data[ICandidateOfferReview.comment];
  }

  getRating() {
    return this[ICandidateOfferReview.rating];
  }

  getComment() {
    return this[ICandidateOfferReview.comment];
  }
}
