import { SUBSCRIPTION_ACTION_BUTTON } from "@core/constants";

export const ISubscriptionActionButton = Object.freeze({
  label: "libelle",
  type: "type",
  link: "link",
});

export class SubscriptionActionButtonModel {
  constructor(data = {}) {
    this[ISubscriptionActionButton.label] = data[ISubscriptionActionButton.label];
    this[ISubscriptionActionButton.type] = data[ISubscriptionActionButton.type];
    this[ISubscriptionActionButton.link] = data[ISubscriptionActionButton.link];
  }

  getLabel() {
    return this[ISubscriptionActionButton.label];
  }

  getType() {
    return this[ISubscriptionActionButton.type];
  }

  getLink() {
    return this[ISubscriptionActionButton.link];
  }

  isOkBtn() {
    return this[ISubscriptionActionButton.type] === SUBSCRIPTION_ACTION_BUTTON.OK;
  }

  isNotOkBtn() {
    return this[ISubscriptionActionButton.type] === SUBSCRIPTION_ACTION_BUTTON.NOT_OK;
  }
}
