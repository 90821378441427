import { SEOMetadataModel } from "@core/models";
import { JobsService } from "@core/services/jobs";
import { JOBS_ROUTES } from "@jobs/config";
import {
  ACTION_JOBS_GET_METADATA_BY_ROUTENAME,
  MUTATION_JOBS_CURRENT_METADATA,
  GETTER_JOBS_GET_CURRENT_METADATA,
  MUTATION_JOBS_SET_DEFAULT_METADATA,
} from "@jobs/store/types";

function initialState() {
  return {
    currentPageMetadata: {},
  };
}

export const state = {
  initialState,
  ...initialState(),
};

export const actions = {
  async [ACTION_JOBS_GET_METADATA_BY_ROUTENAME]({ commit }, params) {
    let seoMeta;
    try {
      switch (params.routeName) {
        case JOBS_ROUTES.public.home.name:
          seoMeta = await JobsService.getJobsHomePageMetaData();
          break;
        case JOBS_ROUTES.public.search.name:
          seoMeta = await JobsService.getJobsSearchPageMetaData();
          break;
        case JOBS_ROUTES.public.offer.detail.name:
          seoMeta = await JobsService.getJobsDetailMetaDataById(params.slug);
          break;
        case JOBS_ROUTES.public.dynamic.name:
          seoMeta = await JobsService.getJobsDynamicMetadataById(params.slug);
          break;
        case JOBS_ROUTES.public.etablissement.name:
          seoMeta = await JobsService.getJobsEtabPageMetaData(params.slug);
          break;
        default:
          seoMeta = await JobsService.getJobsDefaultPageMetaData();
          break;
      }

      commit(MUTATION_JOBS_CURRENT_METADATA, seoMeta);
    } catch {
      const defaultMetaData = JobsService.getMetaDataFallback();
      commit(MUTATION_JOBS_SET_DEFAULT_METADATA, defaultMetaData);
    }
  },
};

export const mutations = {
  [MUTATION_JOBS_CURRENT_METADATA](_state, seoMeta) {
    _state.currentPageMetadata = seoMeta;
  },
  [MUTATION_JOBS_SET_DEFAULT_METADATA](_state, defaultMetaData) {
    _state.currentPageMetadata = defaultMetaData;
  },
};

export const getters = {
  [GETTER_JOBS_GET_CURRENT_METADATA]: (_state) => new SEOMetadataModel(_state.currentPageMetadata),
};

export default function createModule() {
  return {
    namespaced: false,
    state: () => ({ ...initialState() }),
    actions,
    mutations,
    getters,
  };
}
