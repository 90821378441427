import { ArrayHelpers } from "@core/helpers";
import { ICandidateLocationFilter, LocationOptionModel } from "@core/models/common";

export const ICandidateOfferfilters = Object.freeze({
  locations: "location",
  cities: "cities", // for interal usage only, do not send to server
  contracts: "contract",
  categories: "category",
  qualifications: "qualif",
  formerEmployer: "etab_only",
});

export class CandidateOfferFiltersModel {
  constructor(data = {}) {
    this[ICandidateOfferfilters.locations] = data[ICandidateOfferfilters.locations];
    this[ICandidateOfferfilters.cities] = data[ICandidateOfferfilters.cities];
    this[ICandidateOfferfilters.contracts] = data[ICandidateOfferfilters.contracts];
    this[ICandidateOfferfilters.categories] = data[ICandidateOfferfilters.categories];
    this[ICandidateOfferfilters.qualifications] = data[ICandidateOfferfilters.qualifications];
    this[ICandidateOfferfilters.formerEmployer] = data[ICandidateOfferfilters.formerEmployer];
  }

  getCategory() {
    return this[ICandidateOfferfilters.categories];
  }

  getContract() {
    return this[ICandidateOfferfilters.contracts];
  }

  getLocation() {
    return this[ICandidateOfferfilters.locations];
  }

  getQualification() {
    return this[ICandidateOfferfilters.qualifications];
  }

  isFormerEmployer() {
    return this[ICandidateOfferfilters.formerEmployer] === 1;
  }

  hasActiveFilters() {
    return this.getActiveFiltersAmount() > 0;
  }

  getActiveFiltersAmount() {
    let activeFiltersCount = 0;
    if ((this.getCategory() || []).length > 0) {
      activeFiltersCount += 1;
    }
    if ((this.getContract() || []).length > 0) {
      activeFiltersCount += 1;
    }
    if ((this.getLocation() || []).length > 0) {
      activeFiltersCount += 1;
    }
    if ((this.getQualification() || []).length > 0) {
      activeFiltersCount += 1;
    }
    if (this.isFormerEmployer()) {
      activeFiltersCount += 1;
    }
    return activeFiltersCount;
  }

  getQueryFromFilters() {
    const categories = this.getCategory()?.length > 0 ? this.getCategory() : undefined;
    const contracts = this.getContract()?.length > 0 ? this.getContract() : undefined;
    const formerEmployer = this.isFormerEmployer() ? 1 : undefined;
    const qualifications = this.getQualification()?.length > 0 ? this.getQualification() : undefined;
    // we save locations (zipcodes + cities) as two entities
    const zipCodes = this.getLocation()?.length > 0 ? this.getLocation().map((location) => location.getCode()) : undefined;
    const cities = this.getLocation()?.length > 0 ? this.getLocation().map((location) => location.getCity()) : undefined;

    return {
      [ICandidateOfferfilters.categories]: categories,
      [ICandidateOfferfilters.contracts]: contracts,
      [ICandidateOfferfilters.formerEmployer]: formerEmployer,
      [ICandidateOfferfilters.locations]: zipCodes,
      [ICandidateOfferfilters.cities]: cities,
      [ICandidateOfferfilters.qualifications]: qualifications,
    };
  }

  getFiltersFromQuery({ query } = {}) {
    const isFormerEmployer = query[ICandidateOfferfilters.formerEmployer];
    const categories = ArrayHelpers.forceArray(query[ICandidateOfferfilters.categories]).map((cat) => parseInt(cat, 10));
    const contracts = ArrayHelpers.forceArray(query[ICandidateOfferfilters.contracts]);
    const formerEmployer = isFormerEmployer ? parseInt(query[ICandidateOfferfilters.formerEmployer], 10) : 0;
    const qualifications = ArrayHelpers.forceArray(query[ICandidateOfferfilters.qualifications]).map((qualif) => parseInt(qualif, 10));
    const zipCodes = ArrayHelpers.forceArray(query[ICandidateOfferfilters.locations]);
    const cities = ArrayHelpers.forceArray(query[ICandidateOfferfilters.cities]);
    const locations = [];

    if (zipCodes.length === cities.length) {
      // we build locations from zipCodes and cities
      // we want it to be the same length otherwise it might do weird stuff
      // (if it's different lengths it means the user typed something so we bypass them)
      for (let i = 0; i < zipCodes.length; i++) {
        const zipCode = zipCodes[i];
        const city = cities[i];
        const location = new LocationOptionModel({ [ICandidateLocationFilter.city]: city, [ICandidateLocationFilter.zipcode]: zipCode });
        locations.push(location);
      }
    }

    this[ICandidateOfferfilters.categories] = categories;
    this[ICandidateOfferfilters.contracts] = contracts;
    this[ICandidateOfferfilters.formerEmployer] = formerEmployer;
    this[ICandidateOfferfilters.locations] = locations;
    this[ICandidateOfferfilters.qualifications] = qualifications;
  }
}
