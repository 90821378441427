import { ArrayHelpers } from "@core/helpers";
import { EtabCategoryModel } from "@core/models/common/etab-category.model";
import { CandidateOfferEtabContactModel } from "@core/models/candidates/offers/candidate-offer-etab-contact.model";

export const ICandidateEtab = Object.freeze({
  name: "name",
  category: "category",
  contact: "contact",
  pictures: "pictures",
  about: "about",
});

export class CandidateEtabModel {
  constructor(data = {}) {
    this[ICandidateEtab.name] = data[ICandidateEtab.name];
    this[ICandidateEtab.about] = data[ICandidateEtab.about];
    this[ICandidateEtab.category] = data[ICandidateEtab.category];
    this[ICandidateEtab.contact] = data[ICandidateEtab.contact];
    this[ICandidateEtab.pictures] = data[ICandidateEtab.pictures];
  }

  getPictures() {
    return ArrayHelpers.forceArray(this[ICandidateEtab.pictures]);
  }

  getFirstPicture() {
    return this.getPictures()[0];
  }

  getName() {
    return this[ICandidateEtab.name];
  }

  getAbout() {
    return this[ICandidateEtab.about];
  }

  getCategory() {
    return new EtabCategoryModel(this[ICandidateEtab.category]);
  }

  getContact() {
    return new CandidateOfferEtabContactModel(this[ICandidateEtab.contact]);
  }
}
