export const ICandidateOfferNotifications = Object.freeze({
  incomingJob: "total_incoming_job",
  cdiSelected: "total_cdi_selected",
  unseenCdiOffer: "total_unviewed_cdi",
  unseenExtraOffer: "total_unviewed_extra",
});

export class CandidateOfferNotificationsModel {
  constructor(data = {}) {
    this[ICandidateOfferNotifications.incomingJob] = data[ICandidateOfferNotifications.incomingJob];
    this[ICandidateOfferNotifications.cdiSelected] = data[ICandidateOfferNotifications.cdiSelected];
    this[ICandidateOfferNotifications.unseenCdiOffer] = data[ICandidateOfferNotifications.unseenCdiOffer];
    this[ICandidateOfferNotifications.unseenExtraOffer] = data[ICandidateOfferNotifications.unseenExtraOffer];
  }

  getIncomingJob() {
    return this[ICandidateOfferNotifications.incomingJob];
  }

  getCdiSelected() {
    return this[ICandidateOfferNotifications.cdiSelected];
  }

  getUnseenCdiOffer() {
    return this[ICandidateOfferNotifications.unseenCdiOffer];
  }

  getUnseenExtraOffer() {
    return this[ICandidateOfferNotifications.unseenExtraOffer];
  }

  hasIncomingJob() {
    return this.getIncomingJob() > 0;
  }

  hasCdiSelected() {
    return this.getCdiSelected() > 0;
  }

  hasUnseenCdiOffer() {
    return this.getUnseenCdiOffer() > 0;
  }

  hasUnseenExtraOffer() {
    return this.getUnseenExtraOffer() > 0;
  }

  hasNotifications() {
    return this.hasIncomingJob() || this.hasCdiSelected() || this.hasUnseenCdiOffer() || this.hasUnseenExtraOffer();
  }
}
