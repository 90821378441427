export const ALLOWED_KEYS = [
  "page_site",
  "page_type",
  "page_name",
  "page_url",
  "formulaire",
  "language",
  "user_connect",
  "user_type",
  "user_profile_etablissement",
  "event",
  "method",
  "formulaire_step_number",
  "user_profile_entreprise",
  "creation_type",
  "job_qualification",
  "job_postal_code",
  "job_locality",
  "job_type",
];

export const DEFAULT_STEP = 0;
