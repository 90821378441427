import { i18n } from "@core/plugins/i18n";
import { OutfitColorModel } from "./outfit-color.model";

export const IOutfits = Object.freeze({
  id: "id",
  colorId: "color_id",
  label: "label",
  color: "color",
  colorable: "colorable",
  availableColors: "outfit_colors",
  img: "img",
  autoselected: "selected",
});

export class OutfitModel {
  constructor(data = {}) {
    this[IOutfits.id] = data[IOutfits.id];
    this[IOutfits.label] = data[IOutfits.label];
    this[IOutfits.colorable] = data[IOutfits.colorable];
    this[IOutfits.availableColors] = data[IOutfits.availableColors];
    this[IOutfits.img] = data[IOutfits.img];
    this[IOutfits.autoselected] = data[IOutfits.autoselected];
  }

  getId() {
    return this[IOutfits.id];
  }

  getLabel() {
    return i18n.global.t(this[IOutfits.label]);
  }

  isColorable() {
    return this[IOutfits.colorable] === true;
  }

  isAutoSelected() {
    return this[IOutfits.autoselected] === true;
  }

  getAvailableColors() {
    return (this[IOutfits.availableColors] || []).map((color) => new OutfitColorModel(color));
  }

  getImg() {
    return this[IOutfits.img];
  }
}
