import { ContractModel, JobsSearchListModel, QualificationModel } from "@core/models";
import { JobsService } from "@core/services/jobs";
import {
  ACTION_JOBS_CONTRACTS_GET_ALL,
  ACTION_JOBS_QUALIFICATIONS_GET_ALL,
  ACTION_JOBS_SEARCH_ACTION,
  ACTION_JOBS_RESET_SEARCH,
  GETTER_JOBS_CONTRACTS_GET_ALL,
  GETTER_JOBS_QUALIFICATIONS_GET_ALL,
  GETTER_JOBS_SEARCH_RESULTS_GET_ALL,
  MUTATION_JOBS_CONTRACTS_SET_ALL,
  MUTATION_JOBS_QUALIFICATIONS_SET_ALL,
  MUTATION_JOBS_SET_SEARCH_RESULTS,
} from "@jobs/store/types";

function initialState() {
  return {
    searchResults: {},
    qualifications: [],
    contracts: [],
  };
}

export const state = {
  initialState,
  ...initialState(),
};

export const actions = {
  async [ACTION_JOBS_QUALIFICATIONS_GET_ALL]({ commit }) {
    const qualifications = await JobsService.getJobsQualifications();
    commit(MUTATION_JOBS_QUALIFICATIONS_SET_ALL, qualifications);
  },
  async [ACTION_JOBS_CONTRACTS_GET_ALL]({ commit }) {
    const contracts = await JobsService.getJobsContracts();
    commit(MUTATION_JOBS_CONTRACTS_SET_ALL, contracts);
  },
  async [ACTION_JOBS_SEARCH_ACTION]({ commit }, params) {
    const searchResults = await JobsService.searchJobs({ ...params });
    commit(MUTATION_JOBS_SET_SEARCH_RESULTS, searchResults);
  },
  async [ACTION_JOBS_RESET_SEARCH]({ commit }) {
    commit(MUTATION_JOBS_SET_SEARCH_RESULTS, {});
  },
};

export const mutations = {
  [MUTATION_JOBS_QUALIFICATIONS_SET_ALL](_state, qualifications) {
    _state.qualifications = qualifications ?? [];
  },
  [MUTATION_JOBS_CONTRACTS_SET_ALL](_state, contracts) {
    _state.contracts = contracts ?? [];
  },
  [MUTATION_JOBS_SET_SEARCH_RESULTS](_state, searchResults) {
    _state.searchResults = searchResults;
  },
};

export const getters = {
  [GETTER_JOBS_SEARCH_RESULTS_GET_ALL]: (_state) => new JobsSearchListModel(_state.searchResults),
  [GETTER_JOBS_QUALIFICATIONS_GET_ALL]: (_state) => (_state.qualifications || []).map((qualification) => new QualificationModel(qualification)),
  [GETTER_JOBS_CONTRACTS_GET_ALL]: (_state) => (_state.contracts || []).map((contract) => new ContractModel(contract)),
};

export default function createModule() {
  return {
    namespaced: false,
    state: () => ({ ...initialState() }),
    actions,
    mutations,
    getters,
  };
}
