import { CONTRACTS } from "./contracts.constants";

export const CRITERIAS = {
  AVAILABITIIES: {
    code: "availabilities",
  },
  SEARCHED_CRITERIAS: {
    code: "search-criterias",
  },
};

export const CANDIDATE_AVAILABILITY_CRITERIA = [
  {
    iconName: "fa-duotone fa-sunrise",
    scheduleTime: "CORE.COMPONENTS.AVAILABILITIES.@MORNING",
    scheduleHours: "CORE.COMPONENTS.AVAILABILITIES.@MORNING_HOURS",
  },
  {
    iconName: "fa-duotone fa-sun",
    scheduleTime: "CORE.COMPONENTS.AVAILABILITIES.@NOON",
    scheduleHours: "CORE.COMPONENTS.AVAILABILITIES.@NOON_HOURS",
  },
  {
    iconName: "fa-duotone fa-sunset",
    scheduleTime: "CORE.COMPONENTS.AVAILABILITIES.@AFTERNOON",
    scheduleHours: "CORE.COMPONENTS.AVAILABILITIES.@AFTERNOON_HOURS",
  },
  {
    iconName: "fa-duotone fa-moon-stars",
    scheduleTime: "CORE.COMPONENTS.AVAILABILITIES.@EVENING",
    scheduleHours: "CORE.COMPONENTS.AVAILABILITIES.@EVENING_HOURS",
  },
];

export const CANDIDATE_CRITERIAS_CONTRACTS = [
  {
    code: CONTRACTS.AE,
    label: "CORE.MODELS.CONTRACTS.@MISSIONS",
    contractActivationLabel: "CORE.COMPONENTS.SEARCH_CRITERIAS.@ACTIVATED_EXTRA",
    color: "secondary",
    lightColor: "secondary-medium",
  },
  {
    code: CONTRACTS.CDI,
    label: "CORE.MODELS.CONTRACTS.@CDI",
    contractActivationLabel: "CORE.COMPONENTS.SEARCH_CRITERIAS.@ACTIVATED_CDI",
    color: "primary",
    lightColor: "primary-medium",
  },
  {
    code: CONTRACTS.SEASON,
    label: "CORE.MODELS.CONTRACTS.@SEASON",
    contractActivationLabel: "CORE.COMPONENTS.SEARCH_CRITERIAS.@ACTIVATED_SEASON",
    color: "extra-orange",
    lightColor: "extra-light-orange",
  },
];

export const MINIMAL_SALARY_STEP = 0.1;
