export const IRecruitmentNumbers = Object.freeze({
  upcomingTotal: "a_venir",
  upcomingAE: "ae_a_venir",
  inProgressAE: "ae_en_cours",
  upcomingCDI: "cdi_a_venir",
  inProgressCDI: "cdi_en_cours",
  inProgressTotal: "en_cours",
  upcomingMER: "mer_a_venir",
  inProgressMER: "mer_en_cours",
});

export class RecruitmentNumbers {
  constructor(data = {}) {
    this[IRecruitmentNumbers.upcomingTotal] = data[IRecruitmentNumbers.upcomingTotal];
    this[IRecruitmentNumbers.upcomingAE] = data[IRecruitmentNumbers.upcomingAE];
    this[IRecruitmentNumbers.inProgressAE] = data[IRecruitmentNumbers.inProgressAE];
    this[IRecruitmentNumbers.upcomingCDI] = data[IRecruitmentNumbers.upcomingCDI];
    this[IRecruitmentNumbers.inProgressCDI] = data[IRecruitmentNumbers.inProgressCDI];
    this[IRecruitmentNumbers.inProgressTotal] = data[IRecruitmentNumbers.inProgressTotal];
    this[IRecruitmentNumbers.upcomingMER] = data[IRecruitmentNumbers.upcomingMER];
    this[IRecruitmentNumbers.inProgressMER] = data[IRecruitmentNumbers.inProgressMER];
  }

  hasUpcomingRecruitments() {
    return this[IRecruitmentNumbers.upcomingTotal] > 0;
  }

  hasInProgressRecruitments() {
    return this[IRecruitmentNumbers.inProgressTotal] > 0;
  }

  hasNoRecruitments() {
    return !this.hasUpcomingRecruitments() && !this.hasInProgressRecruitments();
  }
}
