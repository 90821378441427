import dayjs from "dayjs";

/**
 * Collections of array helpers
 */
export class ArrayHelpers {
  /**
   * Deduplicate provided array via Array.from
   * @param Array array
   * @returns deduplicated array
   */
  static deduplicate(array = []) {
    const deduplicatedSet = new Set(array);
    return Array.from(deduplicatedSet);
  }

  /**
   * Deduplicate provided array by provided property
   * @param Array array
   * @param String property
   * @returns deduplicated array
   */
  static deduplicateByProperty(array = [], property) {
    if (property) {
      return array.filter((object, index) => index === array.findIndex((obj) => String(obj[property]) === String(object[property])));
    }
    return ArrayHelpers.deduplicate(array);
  }

  /**
   * Ensure the provided data returns an array (either the data is an array, or a empty array)
   * @param Any data
   * @returns array
   */
  static ensureArray(data) {
    return Array.isArray(data) ? data : [];
  }

  /**
   * Ensure the provided data to be returned as an array (either the data is already an array, or it will be set inside an array)
   * If the data is null or undefined it will return an empty array
   * @param Any data
   * @returns array
   */
  static forceArray(data) {
    if (data === undefined || data === null) {
      return [];
    }
    return Array.isArray(data) ? data : [data];
  }

  /**
   * Sort alphabetically regardless of case
   * taken from: https://stackoverflow.com/a/8900824
   * @example
   * shifts.sort((a, b) => ArrayHelpers.alphabeticallySort(a.firstname, b.firstname));
   * @static
   * @returns compare function results
   */
  static alphabeticallySort(a = "", b = "") {
    // be sure we don't get caught up in casing problems on some browsers
    const textA = a.toUpperCase();
    const textB = b.toUpperCase();
    // eslint-disable-next-line no-nested-ternary
    return textA < textB ? -1 : textA > textB ? 1 : 0;
  }

  /**
   * Sort by date from strings
   * @param {*} a date in the right format
   * @param {*} b date in the right format
   * @param {*} format default DD/MM/YYYY
   * @example
   * array.sort((a, b) => ArrayHelpers.dateSort(a.date, b.date));
   * or if you want to reverse: (notice the position of a and b)
   * array.sort((a, b) => ArrayHelpers.dateSort(b.date, a.date));
   * @returns
   */
  static dateSort(a = "", b = "", format = "DD/MM/YYYY") {
    // Turn your strings into timestamp, and then subtract them
    // to get a value that is either negative, positive, or zero.
    const dateA = dayjs(a, format).valueOf();
    const dateB = dayjs(b, format).valueOf();
    return dateA - dateB;
  }
}
