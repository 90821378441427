import { DatesHelpers } from "./dates.helpers";

export class ScheduleHelpers {
  static get DAY_DURATION_MINUTES() {
    return DatesHelpers.DAY_DURATION_MINUTES;
  }

  static get FIRST_HOUR() {
    return 0; // 00:00
  }

  static get LAST_HOUR_START_TIME() {
    return 24; // 12:00AM
  }

  static get LAST_HOUR_END_TIME() {
    return 12; // 12:00PM (J+1)
  }

  static get BREAK_MAXIMAL_DURATION() {
    return 10; // 10 hours long
  }

  static get MINIMAL_SHIFT_DURATION() {
    return 1; // 1 hour long
  }

  static get STEP() {
    return 15; // 15 minutes
  }

  static get NULL_BREAK_LABEL() {
    return "--";
  }

  static get STAMP_TEXT() {
    return "(J+1)";
  }

  static getFullScheduleOptions({
    firstHour = ScheduleHelpers.FIRST_HOUR,
    lastHour = ScheduleHelpers.LAST_HOUR_END_TIME,
    stampText = ScheduleHelpers.STAMP_TEXT,
    nullBreakLabel = ScheduleHelpers.NULL_BREAK_LABEL,
    minimalShift = ScheduleHelpers.MINIMAL_SHIFT_DURATION,
    step = ScheduleHelpers.STEP,
    maxBreakDuration = ScheduleHelpers.BREAK_MAXIMAL_DURATION,
  } = {}) {
    return {
      start: ScheduleHelpers.setStartOptions({ firstHour, stampText, step }),
      end: ScheduleHelpers.setEndOptions({ firstHour, lastHour, stampText, step, minimalShift }),
      breakDuration: ScheduleHelpers.getBreakDurationOptions({ maxBreakDuration, nullBreakLabel }),
    };
  }

  static setStartOptions({ firstHour, stampText, step }) {
    const min = firstHour * 60;
    const max = ScheduleHelpers.LAST_HOUR_START_TIME * 60 - ScheduleHelpers.STEP;

    return ScheduleHelpers.getScheduleHours({ min, max, stampText, step });
  }

  static setEndOptions({ firstHour, lastHour, stampText, minimalShift, step }) {
    const min = firstHour * 60 + minimalShift * 60;
    const max = (24 + lastHour) * 60;

    return ScheduleHelpers.getScheduleHours({ min, max, stampText, step });
  }

  static getScheduleHours({ min, max, step = ScheduleHelpers.STEP, stampText }) {
    const scheduleHours = [];

    for (let i = min; i <= max; i += step) {
      const value = i;
      const label = DatesHelpers.formatMinsToHrsMins(i % ScheduleHelpers.DAY_DURATION_MINUTES, ":");
      const stamp = i >= ScheduleHelpers.DAY_DURATION_MINUTES ? `${stampText}` : "";

      scheduleHours.push({ value, label, stamp });
    }

    return scheduleHours;
  }

  static getBreakDurationOptions({ maxBreakDuration = ScheduleHelpers.BREAK_MAXIMAL_DURATION, nullBreakLabel }) {
    const breakDurationOptions = [];
    for (let i = 0; i <= maxBreakDuration; i++) {
      const hour = DatesHelpers.formatTime(i);

      if (i === maxBreakDuration) {
        // we create the last entry (ex: 10h00)
        const value = i * 60;
        const label = `${hour}h00`;
        breakDurationOptions.push({ value, label });
      } else {
        // we create all the entries from 0 to max
        for (let j = 0; j < 4; j++) {
          const minutes = DatesHelpers.formatTime(j * ScheduleHelpers.STEP);
          // if 0, we put a special label
          if (hour === "00" && minutes === "00") {
            breakDurationOptions.push({ value: 0, label: nullBreakLabel });
          } else {
            const value = i * 60 + j * ScheduleHelpers.STEP;
            const label = hour === "00" ? `${minutes} minutes` : `${hour}h${minutes}`;
            breakDurationOptions.push({ value, label });
          }
        }
      }
    }
    return breakDurationOptions;
  }

  static getFormattedBreak(breakDuration) {
    if (breakDuration < 60 && breakDuration > 0) {
      return `${breakDuration} min`;
    }
    if (breakDuration === 0) {
      return "";
    }
    const breakDurationObject = DatesHelpers.convertMinsToHrsMins(breakDuration);
    const breakDurationMinute = breakDurationObject.minutes === 0 ? "00" : breakDurationObject.minutes;
    return `${breakDurationObject.hours}h${breakDurationMinute}`;
  }

  static endHourIsNextDay(endHour) {
    const dif = endHour - DatesHelpers.DAY_DURATION_MINUTES;
    return `${DatesHelpers.formatMinsToHrsMins(dif)} ${ScheduleHelpers.STAMP_TEXT}`;
  }

  static formatDaySchedule({ daySchedule }) {
    const schedule = { start: null, end: null, breakDuration: null };
    if (daySchedule.getStartHour() !== undefined && daySchedule.getEndHour() !== undefined) {
      schedule.breakDuration = daySchedule.getBreakDuration() ? DatesHelpers.formatMinsToHrsMins(daySchedule.getBreakDuration()) : null;
      schedule.start = DatesHelpers.formatMinsToHrsMins(daySchedule.getStartHour());
      schedule.end =
        daySchedule.getEndHour() >= DatesHelpers.DAY_DURATION_MINUTES
          ? ScheduleHelpers.endHourIsNextDay(daySchedule.getEndHour())
          : DatesHelpers.formatMinsToHrsMins(daySchedule.getEndHour());
    }
    return schedule;
  }
}
