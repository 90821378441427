export const IUser = Object.freeze({
  id: "id",
  email: "email",
  fullname: "fullname",
  firstname: "firstname",
  lastname: "lastname",
  avatar: "avatar",
});

export class UserModel {
  constructor(data = {}) {
    this[IUser.id] = data[IUser.id];
    this[IUser.email] = data[IUser.email];
    this[IUser.firstname] = data[IUser.firstname];
    this[IUser.lastname] = data[IUser.lastname];
    this[IUser.avatar] = data[IUser.avatar];
  }

  get fullname() {
    return `${this[IUser.firstname]} ${this[IUser.lastname]}`;
  }
}
