export const IAvailability = Object.freeze({
  label: "label",
  morning: "morning",
  night: "night",
  afternoon: "afternoon",
  evening: "evening",
  dayId: "day_id",
});

export class AvailabilityModel {
  constructor(data = {}) {
    this[IAvailability.label] = data[IAvailability.label];
    this[IAvailability.morning] = data[IAvailability.morning];
    this[IAvailability.afternoon] = data[IAvailability.afternoon];
    this[IAvailability.evening] = data[IAvailability.evening];
    this[IAvailability.night] = data[IAvailability.night];
    this[IAvailability.dayId] = data[IAvailability.dayId];
  }

  getLabel() {
    return this[IAvailability.label];
  }

  isMorningSelected() {
    return this[IAvailability.morning] === true;
  }

  isNightSelected() {
    return this[IAvailability.night] === true;
  }

  isAfterNoonSelected() {
    return this[IAvailability.afternoon] === true;
  }

  isEveningSelected() {
    return this[IAvailability.evening] === true;
  }
}
